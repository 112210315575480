import { useState } from 'react';
/* eslint-disable */
import { Select } from 'antd';
const { Option }: any = Select;
import { IMainList } from '../../../types/basicComponentsTypes';
import CaretUpIcon from '../CaretUpIcon';
import CaretDownIcon from '../CaretDownIcon';

interface Props {
  defaultValue?: string;
  value?: string;
  onChange?: (value: string, event: any) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  label: string;
  options: IMainList[];
  isOptional?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  showSearch?: boolean;
}

const Dropdown = ({
  defaultValue,
  value,
  onChange,
  placeholder,
  className,
  disabled,
  label,
  options,
  isOptional = false,
  mode = undefined,
  showSearch = false,
}: Props) => {
  const [open, setOpen] = useState(false);

  let suffixIcon;
  if (open) {
    suffixIcon = (
      <div className='dropdown__icon dropdown__icon--rotate-180'>
        <CaretDownIcon />
      </div>
    );
  } else {
    suffixIcon = (
      <div className='dropdown__icon dropdown__icon--rotate-0'>
        <CaretUpIcon />
      </div>
    );
  }

  return (
    <div className={className}>
      {isOptional ? (
        <div className='d-flex align-items-center justify-content-between'>
          <label className='dropdown__label' htmlFor='dropdown'>
            {label}
          </label>
          <p className='mb-4 d-block paragrapgh paragrapgh--3 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
            Optional
          </p>
        </div>
      ) : (
        <label className='dropdown__label' htmlFor='dropdown'>
          {label}
        </label>
      )}
      <Select
        mode={mode}
        size='large'
        open={open}
        disabled={disabled}
        suffixIcon={suffixIcon}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onDropdownVisibleChange={o => setOpen(o)}
        onChange={onChange}
        showSearch={showSearch}
        filterOption={(inputValue, option) =>
          option?.children.toLowerCase().includes(inputValue.toLowerCase())
        }
      >
        {options &&
          options.map((option: IMainList) => (
            <Option value={option.value} key={option.id}>
              {option.label}
            </Option>
          ))}
      </Select>
    </div>
  );
};

export default Dropdown;

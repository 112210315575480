import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dropdown, Input, Subtitle, Title } from '../../components/shared';
import { ILineResource } from '../../entities/farms.entities';
import {
  defaultDateFormat,
  toMillisecond,
} from '../../util/toggleSecondMillisecond';
import { Table } from 'antd';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectUserMeta } from '../../store/auth/auth.selector';
import { amountDays, getLineStatus } from '../../lib/farm.helpers';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';
import moment from 'moment';
import { selectAllTags } from '../../store/extra/extra.selector';
import './styles.scss';

const getLastAssDate = (line: ILineResource) =>
  line.growing_cycle?.last_assessment?.assessment_date ?? null;

const getSizeMin = (line: ILineResource) =>
  line.growing_cycle?.last_assessment?.shell_size.min ??
  line.growing_cycle?.main_seed.spat_size ??
  null;

const getSizeMax = (line: ILineResource) =>
  line.growing_cycle?.last_assessment?.shell_size.max ??
  line.growing_cycle?.main_seed.spat_size_max ??
  null;

const sizeRange = (line: ILineResource) => {
  const min = getSizeMin(line),
    max = getSizeMax(line);
  return max ? `${min}-${max} mm` : `${min ?? 0} mm`;
};

const compareReady = (a: ILineResource, b: ILineResource) => {
  if (a.growing_cycle) {
    if (!b.growing_cycle) return -1;

    if (a.growing_cycle.ready_harvest) {
      return b.growing_cycle.ready_harvest
        ? a.growing_cycle.ready_harvest - b.growing_cycle.ready_harvest
        : -1;
    } else {
      if (b.growing_cycle.ready_harvest) return 1;

      const adt =
        a.growing_cycle.last_assessment?.planned_date_harvest ??
        a.growing_cycle.main_seed.planned_date_harvest;
      const bdt =
        b.growing_cycle.last_assessment?.planned_date_harvest ??
        b.growing_cycle.main_seed.planned_date_harvest;

      return adt - bdt;
    }
  } else {
    return b.growing_cycle ? 1 : 0;
  }
};

const options = (lang: TLang | undefined) => [
  { id: '0', label: translate(lang, 'All'), value: 'all' },
  { id: '1', label: translate(lang, 'Oldest line'), value: 'oldest_line' },
  {
    id: '2',
    label: translate(lang, 'Oldest assessment'),
    value: 'oldest_assessment',
  },
  {
    id: '3',
    label: translate(lang, 'Ready to harvest'),
    value: 'ready_harvest',
  },
  { id: '4', label: translate(lang, 'Catching Spat'), value: 'catch_spat' },
  {
    id: '5',
    label: translate(lang, 'Needing Assessment'),
    value: 'require_assessment',
  },
  {
    id: '6',
    label: translate(lang, 'Recent Assessment'),
    value: 'recent_assessment',
  },
  { id: '7', label: translate(lang, 'Empty Lines'), value: 'empty_lines' },
];

const columns = (line_assess_expire_days: number, lang: TLang | undefined) => [
  {
    title: translate(lang, 'Farm'),
    key: 'farm',
    render: (x: ILineResource) => (
      <>
        <span className='farm-name'>{x.farm_name}</span>
        <br />
        <span className='farm-number'>{x.farm_number}</span>
      </>
    ),
    sorter: (a: ILineResource, b: ILineResource) =>
      a.farm_name.localeCompare(b.farm_name),
  },
  {
    title: translate(lang, 'Line'),
    key: 'line_name',
    render: (x: ILineResource) => <span>{x.line_name}</span>,
    sorter: (a: ILineResource, b: ILineResource) =>
      a.line_name.localeCompare(b.line_name),
  },
  {
    title: translate(lang, 'Length'),
    key: 'length',
    render: (x: ILineResource) => <span>{(x as any).length ?? '-'}</span>,
    sorter: (a: ILineResource, b: ILineResource) =>
      (a as any).length - (b as any).length,
  },
  {
    title: translate(lang, 'Date seeded'),
    key: 'date_seeded',
    render: (x: ILineResource) => (
      <>
        {x.growing_cycle ? (
          defaultDateFormat(x.growing_cycle.main_seed.planned_date_seed)
        ) : (
          <div className='d-flex flex-wrap'>
            <span className='min-width-74 pr-4'>
              {translate(lang, 'Line empty for')}
            </span>
            <Subtitle size={5} color='black' align='left' fontWeight={600}>
              {amountDays(x.line_idle)}
            </Subtitle>
          </div>
        )}
      </>
    ),
    sorter: (a: ILineResource, b: ILineResource) => {
      if (a.growing_cycle) {
        return b.growing_cycle
          ? a.growing_cycle.main_seed.planned_date_seed -
              b.growing_cycle.main_seed.planned_date_seed
          : 1;
      } else {
        return b.growing_cycle ? -1 : (a.line_idle ?? 0) - (b.line_idle ?? 0);
      }
    },
  },
  {
    title: translate(lang, 'Planned harvest date'),
    key: 'planned_harvest_date',
    render: (x: ILineResource) => (
      <span>
        {x.growing_cycle
          ? defaultDateFormat(
              x.growing_cycle.last_assessment?.planned_date_harvest ??
                x.growing_cycle.main_seed.planned_date_harvest,
            )
          : ''}
      </span>
    ),
    sorter: (a: ILineResource, b: ILineResource) =>
      (a.growing_cycle?.main_seed.planned_date_harvest ?? -1) -
      (b.growing_cycle?.main_seed.planned_date_harvest ?? -1),
  },
  {
    title: translate(lang, 'Last Assessment Date'),
    key: 'last_assessment',
    render: (line: ILineResource) => {
      const x = getLastAssDate(line);
      const d =
        getLineStatus(line, line_assess_expire_days) === 'REQUIRE_ASSESSMENT';
      return (
        <span className={d ? 'text-red' : ''}>{defaultDateFormat(x, '-')}</span>
      );
    },
    sorter: (a: ILineResource, b: ILineResource) =>
      (getLastAssDate(a) ?? -1) - (getLastAssDate(b) ?? -1),
  },
  {
    title: translate(lang, 'Ready to harvest'),
    key: 'ready_to_harvest',
    render: (x: ILineResource) => {
      if (x.growing_cycle) {
        const al = x.growing_cycle.last_assessment
          ? defaultDateFormat(
              x.growing_cycle.last_assessment.planned_date_harvest,
            )
          : null;
        const sl = defaultDateFormat(
          x.growing_cycle.main_seed.planned_date_harvest,
        );
        const el =
          al && al !== sl ? (
            <div style={{ color: 'purple' }}>
              {`${translate(lang, 'original harvest date')}: ${sl}`}
            </div>
          ) : (
            <></>
          );

        if (x.growing_cycle.ready_harvest) {
          const d = moment().diff(
            toMillisecond(x.growing_cycle.ready_harvest),
            'days',
          );
          return (
            <span>
              <div>
                {`Ready ${
                  d === 0
                    ? 'today'
                    : d < 0
                    ? `in ${-d} day(s)`
                    : `${d} day(s) ago`
                }`}
              </div>
              {el}
            </span>
          );
        } else {
          const d = moment().diff(
            toMillisecond(
              x.growing_cycle.last_assessment?.planned_date_harvest ??
                x.growing_cycle.main_seed.planned_date_harvest,
            ),
            'days',
          );
          return (
            <span>
              <div>
                {`Planned ${
                  d === 0
                    ? 'today'
                    : d < 0
                    ? `in ${-d} day(s)`
                    : `${d} day(s) ago`
                }`}
              </div>
              {el}
            </span>
          );
        }
      } else {
        return <span>-</span>;
      }
    },
    sorter: compareReady,
  },
  {
    title: translate(lang, 'Ready to harvest (date)'),
    key: 'ready_harvest_date',
    render: (x: ILineResource) =>
      x.growing_cycle?.ready_harvest ? (
        <span>
          {translate(
            lang,
            'marked as ready by %s on %s',
            x.growing_cycle.last_assessment?.verifier?.name ??
              x.growing_cycle.last_assessment?.user?.name,
            defaultDateFormat(x.growing_cycle.ready_harvest),
          )}
        </span>
      ) : (
        <span>{'-'}</span>
      ),
    sorter: compareReady,
  },
  {
    title: translate(lang, 'Size Range'),
    key: 'size_range',
    render: (x: ILineResource) => <span>{sizeRange(x)}</span>,
    sorter: (a: ILineResource, b: ILineResource) =>
      (getSizeMin(a) ?? 0) - (getSizeMin(b) ?? 0),
    align: 'center',
  } as any,
];

type optionType =
  | 'all'
  | 'oldest_line'
  | 'oldest_assessment'
  | 'ready_harvest'
  | 'catch_spat'
  | 'require_assessment'
  | 'recent_assessment'
  | 'empty_lines';

const LinesPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const farms = useSelector(selectFarmsData);
  const userMeta = useSelector(selectUserMeta);
  const lineTags = useSelector(selectAllTags);
  const lang = useSelector(selectLang);

  const [selectedOption, setSelectedOption] = useState<optionType>(
    (query.get('filter') as any) ?? 'all',
  );
  const [sizeFrom, setSizeFrom] = useState('');
  const [sizeTo, setSizeTo] = useState('');
  const [filterName, setFilterName] = useState('');
  const [chkTags, setChkTags] = useState<number[]>();

  const filteredLines = useMemo(() => {
    let result: ILineResource[] = [];
    for (let farm of farms) {
      result.push(...farm.lines.map(t => t as any));
    }
    if (selectedOption === 'oldest_line') {
      result = result.filter(x => !!x.growing_cycle);
      result.sort((a, b) =>
        (a.growing_cycle?.main_seed.planned_date_seed ?? 0) <
        (b.growing_cycle?.main_seed.planned_date_seed ?? 0)
          ? -1
          : 1,
      );
    } else if (selectedOption === 'oldest_assessment') {
      result = result.filter(x => getLastAssDate(x) !== null);
      result.sort((a, b) =>
        (getLastAssDate(a) ?? 0) < (getLastAssDate(b) ?? 0) ? -1 : 1,
      );
    } else if (selectedOption === 'catch_spat') {
      result = result.filter(
        t => t.growing_cycle && t.growing_cycle.main_seed.is_catch_spat,
      );
      result.sort((a, b) =>
        (a.growing_cycle?.main_seed.planned_date_seed ?? 0) <
        (b.growing_cycle?.main_seed.planned_date_seed ?? 0)
          ? -1
          : 1,
      );
    } else if (selectedOption === 'ready_harvest') {
      result = result.filter(x => !!x.growing_cycle);
      result.sort(compareReady);
    } else if (selectedOption === 'require_assessment') {
      result = result.filter(
        x =>
          getLineStatus(x, userMeta?.line_assess_expire_days ?? 90) ===
          'REQUIRE_ASSESSMENT',
      );
    } else if (selectedOption === 'recent_assessment') {
      result = result.filter(x => {
        if (!x.growing_cycle?.last_assessment) return false;
        let d = Date.now() - toMillisecond(getLastAssDate(x));
        return d < 7 * 3600 * 24 * 1000;
      });
    } else if (selectedOption === 'empty_lines') {
      result = result.filter(x => !x.growing_cycle);
    }
    if (sizeFrom.length > 0) {
      result = result.filter(x => {
        const min = getSizeMin(x);
        return (min ?? 0) >= parseInt(sizeFrom);
      });
    }
    if (sizeTo.length > 0) {
      result = result.filter(x => {
        const max = getSizeMax(x),
          min = getSizeMin(x);
        return (max ?? min ?? 0) <= parseInt(sizeTo);
      });
    }
    if (chkTags && chkTags.length > 0 && lineTags) {
      const tags = lineTags
        .filter(x => chkTags.includes(x.id))
        .map(t => t.tag_lines)
        .reduce((p, c) => [...p, ...c], [])
        .map(t => t.line_id);
      result = result.filter(x => tags.includes(x.id));
    }
    if (filterName.length > 0) {
      result = result.filter(x =>
        x.line_name.toLowerCase().includes(filterName.toLowerCase()),
      );
    }
    return result;
  }, [sizeFrom, sizeTo, selectedOption, farms, userMeta, chkTags, filterName]);

  const { redirectToLine } = useMenuHandler();

  return (
    <div className='lines-page'>
      <div className='h-calc-80 bg-secondary'>
        <div className='container pos-relative'>
          <div className='farms'>
            <div className='farms__header d-flex justify-content-between align-items-center'>
              <Title size={5} color='black-3' align='default' fontWeight={700}>
                {translate(lang, 'Lines')}
              </Title>
            </div>
            <div className='farms__content'>
              <div className='farms__line--header__name mb-17'>
                <div className='d-flex justify-content-between align-items-end'>
                  <div className='d-flex'>
                    <div className='mr-12'>
                      <Input
                        type='number'
                        label={translate(lang, 'Search by Size From')}
                        value={sizeFrom}
                        placeholder=''
                        onChange={e => setSizeFrom(e.target.value)}
                        unit='mm'
                      />
                    </div>
                    <div className='ml-12 mr-12'>
                      <Input
                        type='number'
                        label={translate(lang, 'Search by Size To')}
                        value={sizeTo}
                        placeholder=''
                        onChange={e => setSizeTo(e.target.value)}
                        unit='mm'
                      />
                    </div>
                    <div className='ml-12'>
                      <Input
                        type='text'
                        label={translate(lang, 'Search by Line name')}
                        value={filterName}
                        placeholder=''
                        onChange={e => setFilterName(e.target.value)}
                      />
                    </div>
                  </div>
                  <Dropdown
                    className='filter-option'
                    label=''
                    value={selectedOption}
                    options={options(lang)}
                    onChange={(v: any) => setSelectedOption(v)}
                  />
                </div>
                {!!lineTags && lineTags.length > 0 && (
                  <div className='mt-7'>
                    <Dropdown
                      className='filter-option'
                      mode='multiple'
                      label={translate(lang, 'Line tags')}
                      options={lineTags.map(x => ({
                        id: x.id.toString(),
                        value: x.id.toString(),
                        label: x.name,
                      }))}
                      value={chkTags?.map(t => t.toString()) as any}
                      onChange={(v: any) =>
                        setChkTags(v.map((x: any) => Number(x)))
                      }
                    />
                  </div>
                )}
              </div>
              <div className='width-100 mt-17 pb-32'>
                <Table
                  rowKey='id'
                  className='table table--isFarm'
                  pagination={false}
                  columns={columns(
                    userMeta?.line_assess_expire_days ?? 90,
                    lang,
                  )}
                  dataSource={filteredLines}
                  onRow={r => ({
                    onClick: redirectToLine.bind(this, r.farm_id, r.id),
                  })}
                  scroll={{ y: 600 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinesPage;

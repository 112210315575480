import { useState } from 'react';
import {
  Button,
  CheckboxButton,
  Dropdown,
  Input,
  Spinner,
  Subtitle,
  Title,
} from '../../components/shared';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectProfile } from '../../store/auth/auth.selector';
import './styles.scss';

const uploadExcel = async (
  file: any,
  params: any = null,
  fileType: any = null,
) => {
  try {
    const Authorization = {
      Authorization: `Bearer ${localStorage.getItem('marine-farm')}`,
    };
    const formData = new FormData();
    formData.append('excel', file);
    if (params) {
      formData.append('params', params);
    }
    return axios.post(
      `${process.env.REACT_APP_API_URL}api/import/cycles?type=${fileType}`,
      formData,
      {
        headers: {
          ...Authorization,
          Accept: '*',
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  } catch (error) {
    throw error;
  }
};

interface IResult {
  total_count: number;
  failed_count: number;
  failed_data: any[];
}

const ImportCyclesPage = () => {
  const dispatch = useDispatch<any>();
  const isGrower = useSelector(selectProfile)?.account_type === 'grower';

  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [result, setResult] = useState<IResult>();
  const [special, setSpecial] = useState(false);
  const [defaultSeason, setDefaultSeason] = useState('2021');
  const [fileType, setFileType] = useState('normal');

  const onFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
  };
  const submitFile = () => {
    setResult(undefined);
    if (!file) {
      window.alert('No file selected');
    } else {
      setDisabled(true);
      uploadExcel(
        file,
        JSON.stringify({
          special,
          default_season: defaultSeason,
        }),
        fileType,
      )
        .then(r => {
          setDisabled(false);
          setResult(r.data);
          if ((r.data?.failed_count ?? 0) <= 0) {
            dispatch(
              showFeedback({
                type: 'success',
                isMessage: true,
                message: 'Imported successfully',
              }),
            );
          }
        })
        .catch(err => {
          setDisabled(false);
          alert(err?.message ?? 'Failed to upload');
        });
    }
  };

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        <div className='report-page upload-page'>
          <div className='report--header d-flex justify-content-between align-items-center'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              Upload Excel file
            </Title>
          </div>
          <div className='report--body'>
            <div className='farm-line'>
              <div className='d-flex mt-17 mb-17 justify-content-center align-items-end'>
                <div style={{ maxWidth: '800px' }} className='mr-32'>
                  <div className='mb-17'>
                    <Dropdown
                      label='File Type'
                      options={[
                        { id: 'n', value: 'normal', label: 'Normal' },
                        { id: 'm', value: 'exported', label: 'Exported Data' },
                      ]}
                      value={fileType}
                      onChange={v => setFileType(v)}
                    />
                  </div>
                  {fileType === 'normal' && (
                    <div className='d-flex justify-content-between'>
                      <CheckboxButton
                        label='Special'
                        className='mr-32'
                        checked={special}
                        onChange={e => setSpecial(e.target.checked)}
                      />
                      {!special && (
                        <Input
                          label='Default Season'
                          type='text'
                          value={defaultSeason}
                          onChange={e => setDefaultSeason(e.target.value)}
                        />
                      )}
                    </div>
                  )}
                  <div className='mt-17'>
                    <input
                      type='file'
                      className='input'
                      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      onChange={onFileChange}
                    />
                  </div>
                  <div className='mt-16'>
                    <Button
                      width={'wide'}
                      size={2}
                      type='fill'
                      color='green'
                      onClick={submitFile}
                      disabled={disabled}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
                <div className='ml-32 result-summary'>
                  <div className='info-box'>
                    Total: {result?.total_count ?? '-'}
                  </div>
                  <div className='info-box'>
                    Success:
                    {result ? result.total_count - result.failed_count : ' -'}
                  </div>
                  <div className='info-box'>
                    Failed: {result?.failed_count ?? '-'}
                  </div>
                </div>
              </div>
              {disabled ? (
                <div className='mt-32'>
                  <Spinner />
                </div>
              ) : (
                <div className='mt-17 result-body'>
                  <div style={{ flex: 5 }}>
                    <Subtitle
                      className='mb-17'
                      size={5}
                      color='black-3'
                      align='default'
                      fontWeight={700}
                    >
                      Failed data
                    </Subtitle>
                    {result &&
                      result.failed_data.map((item, idx) =>
                        isGrower ? (
                          <div key={idx} className='data-container'>
                            <div className='data-info'>
                              <div className='--info-item'>
                                <div className='--label'>Farm</div>
                                <div className='--message'>
                                  {item.data.farm_number}
                                </div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>Owner</div>
                                <div className='--message'>
                                  {item.data.owner}
                                </div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>Line</div>
                                <div className='--message'>
                                  {item.data.line}
                                </div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>Date</div>
                                <div className='--message'>
                                  {item.data.date}
                                </div>
                              </div>
                            </div>
                            <div className='message-info'>
                              <div className='--label'>Message</div>
                              <div className='--message'>{item.message}</div>
                            </div>
                          </div>
                        ) : (
                          <div key={idx} className='data-container'>
                            <div className='data-info'>
                              <div className='--info-item'>
                                <div className='--label'>Activity ID</div>
                                <div className='--value'>
                                  {item.data?.activity_id}
                                </div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>Line uid</div>
                                <div className='--value'>{item.data?.uid}</div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>Client</div>
                                <div className='--value'>
                                  {item.data?.owner}
                                </div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>License</div>
                                <div className='--value'>
                                  {item.data?.farm_number}
                                </div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>Activity</div>
                                <div className='--value'>{item.data?.type}</div>
                              </div>
                              <div className='--info-item'>
                                <div className='--label'>Vessel</div>
                                <div className='--value'>
                                  {item.data?.vessel}
                                </div>
                              </div>
                            </div>
                            <div className='message-info'>
                              <div className='--label'>Message</div>
                              <div className='--message'>{item.message}</div>
                            </div>
                          </div>
                        ),
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportCyclesPage;

import { NavLink } from 'react-router-dom';
import { ILink } from '../../types/basicComponentsTypes';
import { TLang } from '../../entities/ui.entities';
import './styles.scss';
import { translate } from '../../lib/lang.helper';

interface Props {
  menuItems: ILink[];
  onClick: () => void;
  lang?: TLang;
}

const MobileMenu = ({ menuItems, onClick, lang }: Props) => (
  <nav className='header__mobile-menu'>
    <ul className='list-reset header__mobile-nav'>
      {menuItems.map(item => (
        <li key={item.link} className='header__item'>
          <NavLink
            exact={item.isExact}
            className='header__link'
            activeClassName='header__link--active'
            onClick={onClick}
            to={item.link}
          >
            {translate(lang, item.name)}
          </NavLink>
        </li>
      ))}
    </ul>
  </nav>
);

export default MobileMenu;

import { useDispatch, useSelector } from 'react-redux';
import { ILineResource } from '../../entities/farms.entities';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CheckboxButton,
  Datepicker,
  Dropdown,
  Input,
} from '../../components/shared';
import { selectMusselFarms } from '../../store/farms/farms.selector';
import { useState } from 'react';
import { showFeedback } from '../../store/ui/ui.actions';
import moment from 'moment';
import { selectAssessmentFields } from '../../store/automation/automation.selector';
import { ICustomValue } from '../../entities/automation.entities';
import DateTimePicker from '../../components/shared/datepicker/DateTimePicker';
import { toSecTimeTracks } from '../../components/shared/input/TimeTrackInput';
import { sendSingleRequest } from '../../apis';
import { loadFarmsData } from '../../store/farms/farms.actions';
import PicturesWall from '../../components/shared/pictures-wall/PicturesWall';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const getJSTime = (t: number | undefined) =>
  t ? t * 1000 : moment().toDate().getTime();

interface IForm {
  size_min?: number;
  size_avg?: number;
  size_max?: number;
  assessment_date: number;
  planned_date_harvest: number;
  ready_to_harvest: boolean;
  comment?: string;
  start_time: number;
  finish_time: number;
  images?: any[];
}

interface Props {
  formID: number;
  data: any;
  lineData: ILineResource;
}

const PaperAssessForm = ({ formID, data, lineData }: Props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectMusselFarms);
  const customFields = useSelector(selectAssessmentFields);

  const [curFarm, setCurFarm] = useState(
    farmsData.find(f => f.id === lineData.farm_id),
  );
  const [curLine, setCurLine] = useState<ILineResource | undefined>(lineData);
  const [formData, setFormData] = useState<IForm>({
    size_min: data.size_min,
    size_avg: data.size_avg,
    size_max: data.size_max,
    assessment_date: getJSTime(data.assessment_date),
    planned_date_harvest: getJSTime(
      lineData.growing_cycle?.last_assessment?.planned_date_harvest ??
        lineData.growing_cycle?.main_seed.planned_date_harvest,
    ),
    ready_to_harvest: data.ready_to_harvest ?? false,
    start_time: getJSTime(data.start_time),
    finish_time: getJSTime(data.finish_time),
    comment: data.comment,
    images: data.images,
  });
  const [customValues, setCustomValues] = useState(
    customFields.map(x => {
      const d =
        data && data.custom_values
          ? data.custom_values.find((y: ICustomValue) => y.field_id === x.id)
          : null;
      return {
        input_type: x.input_type,
        field_label: x.name,
        options: x.options
          ?.split(',')
          .map(t => ({ id: t, label: t, value: t })),
        field_id: x.id,
        field_val: d?.field_val ?? '',
        data_type: x.data_type,
        unit: x.unit,
      };
    }),
  );
  const [disabled, setDisabled] = useState(false);

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        type: 'error',
        isMessage: true,
        message: translate(lang, message),
      }),
    );
  const selectFarm = (v: string) => {
    const farm = farmsData.find(x => x.id === Number(v));
    setCurFarm(farm);
    setCurLine(undefined);
  };
  const selectLine = (v: string) => {
    const line = curFarm?.lines.find(x => x.id === Number(v));
    setCurLine(line);
  };
  const updateForm = (key: keyof IForm, value: any) => {
    let tmp = { ...formData, [key]: value };
    if (key === 'size_min' || key === 'size_max') {
      tmp.size_avg = (Number(tmp.size_min) + Number(tmp.size_max)) / 2;
    }
    setFormData(tmp);
  };
  const updateCustomValue = (fieldID: number, val: string) => {
    let tmp = [...customValues];
    const i = tmp.findIndex(x => x.field_id === fieldID);
    if (i >= 0) {
      tmp[i].field_val = val;
    }
    setCustomValues(tmp);
  };
  const confirmClick = async () => {
    if (!curFarm || !curLine) {
      showError('Please select farm and line');
      return;
    }
    if (!formData.size_min || !formData.size_avg || !formData.size_max) {
      showError('Please fill in size fields');
      return;
    }
    if (Number(formData.size_min) > Number(formData.size_max)) {
      showError('Size min cannot be greater than size max');
      return;
    }
    const {
      start_time,
      finish_time,
      assessment_date,
      planned_date_harvest,
      ...params
    } = formData;
    const time_tracks = toSecTimeTracks([{ start_time, finish_time }]);
    if (time_tracks === false) {
      showError('_invalid_time_range');
      return;
    }
    const custom_values = customValues
      .filter(x => !!x.field_val)
      .map(({ field_id, field_val }) => ({ field_id, field_val }));
    const submitData = {
      ...params,
      assessment_date: Math.floor(assessment_date / 1000),
      planned_date_harvest: Math.floor(planned_date_harvest / 1000),
      line_id: curLine.id,
      seeding_id: curLine.growing_cycle?.main_seed.id ?? data.seeding_id,
      custom_values,
      id: formID,
    };
    if (curLine.id !== lineData.id && !curLine.growing_cycle) {
      showError('_line_empty');
      return;
    }
    if (!submitData.seeding_id) {
      showError('_line_empty');
      return;
    }
    setDisabled(true);
    const res = await sendSingleRequest(
      submitData,
      'POST',
      'api/queue/confirm-assessment',
      true,
    );
    if (res.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(lang, 'Confirmed successfully'),
          type: 'success',
        }),
      );
      history.goBack();
    } else {
      setDisabled(false);
      showError(res.data?.message ?? 'Unknown error');
    }
  };

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = curFarm?.lines.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: x.line_name,
  }));

  return (
    <div>
      <div className='mb-17 d-flex'>
        <div className='w-100 mr-7'>
          <Dropdown
            label={translate(lang, 'Select farm')}
            options={farmOptions}
            value={curFarm?.id.toString()}
            onChange={v => selectFarm(v)}
          />
        </div>
        <div className='w-100 ml-7'>
          <Dropdown
            label={translate(lang, 'Select line')}
            options={lineOptions ?? []}
            value={curLine?.id.toString()}
            onChange={v => selectLine(v)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <div className='w-100'>
          <Input
            label={translate(lang, 'Size min')}
            type='number'
            value={formData.size_min?.toString() ?? ''}
            unit='mm'
            onChange={e => updateForm('size_min', e.target.value)}
          />
        </div>
        <div className='w-100 ml-12 mr-12'>
          <Input
            label={translate(lang, 'Size avg')}
            type='number'
            value={formData.size_avg?.toString() ?? ''}
            unit='mm'
            onChange={e => updateForm('size_avg', e.target.value)}
          />
        </div>
        <div className='w-100'>
          <Input
            label={translate(lang, 'Size max')}
            type='number'
            value={formData.size_max?.toString() ?? ''}
            unit='mm'
            onChange={e => updateForm('size_max', e.target.value)}
          />
        </div>
      </div>
      <div className='d-flex mb-17 mt-16 justify-content-between'>
        <div className='mr-12 w-100'>
          <Datepicker
            label={translate(lang, 'Assessment date')}
            defaultValue={formData.assessment_date}
            onChange={e =>
              updateForm(
                'assessment_date',
                e?.toDate().getTime() ?? moment().toDate().getTime(),
              )
            }
            required
          />
        </div>
        <div className='ml-12 w-100'>
          <Datepicker
            label={translate(lang, 'Planned date harvest')}
            defaultValue={formData.planned_date_harvest}
            onChange={e =>
              updateForm(
                'planned_date_harvest',
                e?.toDate().getTime() ?? moment().toDate().getTime(),
              )
            }
            required
          />
        </div>
      </div>
      <div className='d-flex mb-17 justify-content-between'>
        <DateTimePicker
          className='mr-12'
          defaultValue={formData.start_time}
          label={translate(lang, 'Start time')}
          onChange={e => updateForm('start_time', e?.toDate().getTime())}
        />
        <DateTimePicker
          className='ml-12'
          defaultValue={formData.finish_time}
          label={translate(lang, 'Finish time')}
          onChange={e => updateForm('finish_time', e?.toDate().getTime())}
        />
      </div>
      <div className='mb-17'>
        <CheckboxButton
          label={translate(lang, 'Ready to harvest')}
          checked={formData.ready_to_harvest}
          onChange={e => updateForm('ready_to_harvest', e.target.checked)}
        />
      </div>
      {customValues.map(x => (
        <div key={x.field_id} className='mb-17'>
          {x.input_type === 'select' ? (
            <>
              <Dropdown
                label={x.field_label}
                options={x.options ?? []}
                value={x.field_val}
                onChange={v => updateCustomValue(x.field_id, v)}
              />
            </>
          ) : (
            <Input
              type={x.data_type === 'number' ? 'number' : 'text'}
              label={x.field_label}
              value={x.field_val ?? ''}
              onChange={e => updateCustomValue(x.field_id, e.target.value)}
              unit={x.unit}
            />
          )}
        </div>
      ))}
      <div className='mb-17'>
        <Input
          label={translate(lang, 'Comment')}
          type='textarea'
          value={formData.comment ?? ''}
          placeholder={`${translate(lang, 'Type your comment')}...`}
          dataType='comment'
          onChange={e => updateForm('comment', e.target.value)}
        />
      </div>
      <div className='mb-17'>
        <PicturesWall
          label={translate(lang, 'Images')}
          fileList={formData.images}
          handleChangeImages={f => updateForm('images', f)}
        />
      </div>
      <div className='mt-17 d-flex justify-content-end'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </div>
  );
};

export default PaperAssessForm;

import {
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
  ISeaweedLineResource,
} from '../../../../entities/farms.entities';
import { TBusinessType } from '../../../../entities/general.entities';
import MusselLinesTable from './MusselLinesTable';
import OysterLinesTable from './OysterLinesTable';
import SeaweedLinesTable from './SeaweedLinesTable';
import '../styles.scss';

interface Props {
  type: TBusinessType;
  linesData: ILineResource[];
  isChild?: boolean;
  farmId?: number;
}

const LinesTable = ({ type, farmId, linesData, isChild }: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselLinesTable
        farmId={farmId}
        linesData={linesData as IMusselLineResource[]}
        isChild={isChild}
      />
    ) : type === 'OYSTER' ? (
      <OysterLinesTable
        farmId={farmId}
        linesData={linesData as IOysterLineResource[]}
        isChild={isChild}
      />
    ) : (
      <SeaweedLinesTable
        farmId={farmId}
        linesData={linesData as ISeaweedLineResource[]}
        isChild={isChild}
      />
    )}
  </>
);

export default LinesTable;

import moment from 'moment';
import { ICustomField } from '../entities/automation.entities';
import {
  IFarmResource,
  ISpatStorageResource,
} from '../entities/farms.entities';
import { findMostSimilar, areSimilarStrings } from './common.helpers';
import { IInventoryResource } from '../entities/inventory.entities';
import { toSecond } from '../util/toggleSecondMillisecond';

export const convertSeedImportForm = (
  form: any,
  farms: IFarmResource[],
  spats: ISpatStorageResource[],
  inventories: IInventoryResource[],
  paperImage: string,
) => {
  const {
    farm,
    line,
    planned_date_seed,
    planned_date_harvest,
    start_time,
    finish_time,
    spat_source_farm,
    spat_source_line,
    spat_source_other,
    float_type,
    ...rest
  } = form;
  let result: any = { ...rest, image: paperImage };

  if (farm) {
    const farmNames = farms.map(x => x.farm_number.toLowerCase());
    const similarFarm = findMostSimilar(farm.toLowerCase(), farmNames);
    const foundFarm = farms.find(x =>
      areSimilarStrings(x.farm_number, similarFarm),
    );
    if (foundFarm) {
      const lineNames = foundFarm.lines.map(x => x.line_name.toLowerCase());
      const similarLine = findMostSimilar(line.toLowerCase(), lineNames);
      const foundLine = foundFarm.lines.find(x =>
        areSimilarStrings(x.line_name, similarLine),
      );
      result.farm_id = foundFarm.id;
      if (foundLine) {
        result.line_id = foundLine.id;
      }
    }
  }
  let spat: ISpatStorageResource | null = null;
  if (spat_source_farm && spat_source_line) {
    spat =
      spats.find(
        x =>
          areSimilarStrings(x.source_line?.farm.name, spat_source_farm) &&
          areSimilarStrings(x.source_line?.line_name, spat_source_line),
      ) ?? null;
  }
  if (!spat && spat_source_other) {
    spat =
      spats.find(x => areSimilarStrings(x.source, spat_source_other)) ?? null;
  }
  if (spat) {
    result.spat_storage_id = spat.id;
  }
  if (planned_date_seed) {
    const tmp = moment(planned_date_seed, 'DD.MM.YYYY').toDate().getTime();
    if (!isNaN(tmp)) {
      result.planned_date_seed = Math.round(tmp / 1000);
    }
  }
  if (planned_date_harvest) {
    const tmp = moment(planned_date_harvest, 'DD.MM.YYYY').toDate().getTime();
    if (!isNaN(tmp)) {
      result.planned_date_harvest = Math.round(tmp / 1000);
    }
  }
  if (start_time) {
    let stm = moment(start_time, 'hh:mm a').toDate().getTime();
    if (!isNaN(stm)) {
      result.start_time = toSecond(stm);
    }
  }
  if (finish_time) {
    let ftm = moment(finish_time, 'hh:mm a').toDate().getTime();
    if (!isNaN(ftm)) {
      result.finish_time = toSecond(ftm);
    }
  }
  if (float_type) {
    const float = inventories.find(x =>
      areSimilarStrings(x.subtype, float_type),
    );
    if (float) {
      result.float_id = float.id;
    }
  }
  return result;
};

export const convertAssessImportForm = (
  form: any,
  customFields: ICustomField[],
  farms: IFarmResource[],
  paperImage: string,
) => {
  const {
    assessment_date,
    start_time,
    finish_time,
    farm,
    line,
    condition_score,
    blues,
    harvest_quality,
    ...params
  } = form;
  let result: any = { ...params, custom_values: [], image: paperImage };
  if (assessment_date) {
    const tmp = moment(assessment_date, 'DD.MM.YYYY').toDate().getTime();
    if (!isNaN(tmp)) {
      result.assessment_date = Math.round(tmp / 1000);
    }
  }
  if (start_time) {
    let stm = Math.round(
      moment(start_time, 'hh:mm a').toDate().getTime() / 1000,
    );
    if (!isNaN(stm)) {
      result.start_time = stm;
    }
  }
  if (finish_time) {
    let ftm = Math.round(
      moment(finish_time, 'hh:mm a').toDate().getTime() / 1000,
    );
    if (!isNaN(ftm)) {
      result.finish_time = ftm;
    }
  }
  if (condition_score) {
    const cF = customFields.find(x => x.name === 'Condition score');
    if (cF) {
      result.custom_values.push({
        field_id: cF.id,
        field_val: condition_score,
      });
    }
  }
  if (blues) {
    const bF = customFields.find(x => x.name === 'Blues');
    if (bF) {
      result.custom_values.push({
        field_id: bF.id,
        field_val: blues,
      });
    }
  }
  if (harvest_quality) {
    const hF = customFields.find(x => x.name === 'Harvest quality');
    if (hF) {
      result.custom_values.push({
        field_id: hF.id,
        field_val: harvest_quality,
      });
    }
  }
  if (farm) {
    const farmNames = farms.map(x => x.farm_number.toLowerCase());
    const similarFarm = findMostSimilar(farm.toLowerCase(), farmNames);
    const foundFarm = farms.find(x =>
      areSimilarStrings(x.farm_number, similarFarm),
    );
    if (foundFarm) {
      result.farm_id = foundFarm.id;

      if (line) {
        const lineNames = foundFarm.lines.map(x => x.line_name.toLowerCase());
        const similarLine = findMostSimilar(line.toLowerCase(), lineNames);
        const foundLine = foundFarm.lines.find(x =>
          areSimilarStrings(x.line_name, similarLine),
        );
        if (foundLine) {
          result.line_id = foundLine.id;
          result.seeding_id = foundLine.growing_cycle?.main_seed.id;
        }
      }
    }
  }
  return result;
};

export const convertFloatingImportForm = (
  form: any,
  farms: IFarmResource[],
  inventories: IInventoryResource[],
  paperImage: string,
) => {
  const {
    farm,
    line,
    subtype,
    quantity,
    type,
    manage_date,
    start_time,
    finish_time,
  } = form;
  let result: any = {
    quantity,
    type,
    image: paperImage,
  };
  if (farm) {
    const farmNames = farms.map(x => x.farm_number.toLowerCase());
    const similarFarm = findMostSimilar(farm.toLowerCase(), farmNames);
    const foundFarm = farms.find(x =>
      areSimilarStrings(x.farm_number, similarFarm),
    );
    if (foundFarm) {
      result.farm_id = foundFarm.id;

      const lineNames = foundFarm.lines.map(x => x.line_name.toLowerCase());
      const similarLine = findMostSimilar(line.toLowerCase(), lineNames);
      const foundLine = foundFarm.lines.find(x =>
        areSimilarStrings(x.line_name, similarLine),
      );
      if (foundLine) {
        result.line_id = foundLine.id;
        result.seeding_id = foundLine.growing_cycle?.main_seed.id;
      }
    }
  }
  if (manage_date) {
    const tmp = moment(manage_date, 'DD.MM.YYYY').toDate().getTime();
    if (!isNaN(tmp)) {
      result.manage_date = Math.round(tmp / 1000);
    }
  }
  if (start_time) {
    let stm = moment(start_time, 'hh:mm a').toDate().getTime();
    if (!isNaN(stm)) {
      result.start_time = toSecond(stm);
    }
  }
  if (finish_time) {
    let ftm = moment(finish_time, 'hh:mm a').toDate().getTime();
    if (!isNaN(ftm)) {
      result.finish_time = toSecond(ftm);
    }
  }
  if (subtype) {
    const inventory = inventories.find(x =>
      areSimilarStrings(x.subtype, subtype),
    );
    if (inventory) {
      result.inventory_id = inventory.id;
    }
  }
  return result;
};

export const convertHarvestImportForm = (
  form: any,
  farms: IFarmResource[],
  inventories: IInventoryResource[],
  paperImage: string,
) => {
  const {
    farm,
    line,
    complete_date,
    start_time,
    finish_time,
    inventories: floats,
    ...rest
  } = form;
  let result: any = {
    ...rest,
    image: paperImage,
  };
  if (farm) {
    const farmNames = farms.map(x => x.farm_number.toLowerCase());
    const similarFarm = findMostSimilar(farm.toLowerCase(), farmNames);
    const foundFarm = farms.find(x =>
      areSimilarStrings(x.farm_number, similarFarm),
    );
    if (foundFarm) {
      result.farm_id = foundFarm.id;

      const lineNames = foundFarm.lines.map(x => x.line_name.toLowerCase());
      const similarLine = findMostSimilar(line.toLowerCase(), lineNames);
      const foundLine = foundFarm.lines.find(x =>
        areSimilarStrings(x.line_name, similarLine),
      );
      if (foundLine) {
        result.line_id = foundLine.id;
        result.seeding_id = foundLine.growing_cycle?.main_seed.id;
      }
    }
  }
  if (floats) {
    result.inventories = floats
      .filter(
        (x: any) =>
          x.subtype &&
          inventories.some(i => areSimilarStrings(i.subtype, x.subtype)),
      )
      .map((x: any) => ({
        inventory_id: inventories.find(i =>
          areSimilarStrings(i.subtype, x.subtype),
        )?.id,
        quantity: Number(x.quantity),
      }));
  }
  if (complete_date) {
    const tmp = moment(complete_date, 'DD.MM.YYYY').toDate().getTime();
    if (!isNaN(tmp)) {
      result.complete_date = Math.round(tmp / 1000);
    }
  }
  if (start_time) {
    let stm = moment(start_time, 'hh:mm a').toDate().getTime();
    if (!isNaN(stm)) {
      result.start_time = toSecond(stm);
    }
  }
  if (finish_time) {
    let ftm = moment(finish_time, 'hh:mm a').toDate().getTime();
    if (!isNaN(ftm)) {
      result.finish_time = toSecond(ftm);
    }
  }
  return result;
};

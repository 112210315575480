import { useDispatch, useSelector } from 'react-redux';
import { Modal, Radio } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  DollarIcon,
  Dropdown,
  Feedback,
  Input,
  Paragrapgh,
  RadioButton,
  Subtitle,
  Title,
} from '../../shared';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  defaultDateFormat,
  toMillisecond,
  toSecond,
} from '../../../util/toggleSecondMillisecond';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { isEmailFormat } from '../../../util/validation';
import { sendSingleRequest } from '../../../apis';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { showFeedback } from '../../../store/ui/ui.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { IOysterLineResource } from '../../../entities/farms.entities';
import { loadInventories } from '../../../store/inventories/inventories.actions';
import { toStrSlug } from '../../../lib/common.helpers';
import { selectLang } from '../../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../../lib/lang.helper';
import { ICustomValue } from '../../../entities/automation.entities';
import { Link } from 'react-router-dom';
import {
  SelectHarvestFields,
  SelectInventoriesByFarmID,
  SelectIsBudgetVisible,
  SelectOysterFarmsByFarmID,
  SelectProfileByFarmID,
  SelectSettingsByFarmID,
  SelectUtilsByFarmID,
} from '../../../store/extra/extra.selector';

interface IFormData {
  is_final: boolean;
  is_full: boolean;
  season_name: string;
  amount_pcs: number | null;
  amount_dz: number | null;
  income: number | null;
  complete_date: number;
  harvest_number?: string | null;
  line_length: number | null;
  shell_length: number | null;
  shell_length_max: number | null;
  comment?: string;
  images: any[];
  cost?: number;
  signature?: string;
  time_tracks?: ITimeRange[];
  inventories?: IPickedInventory[];
  return_amount_dz: number | null;
  return_amount_pcs: number | null;
  [key: string]: any;
}

const defaultForm: IFormData = {
  is_final: true,
  is_full: true,
  complete_date: moment().toDate().getTime(),
  line_length: null,
  season_name: '',
  amount_dz: null,
  amount_pcs: null,
  income: null,
  shell_length: null,
  shell_length_max: null,
  images: [],
  time_tracks: [],
  return_amount_dz: null,
  return_amount_pcs: null,
};

interface IAvlInventory {
  inventory_id: number;
  available: number;
}

interface IPickedInventory {
  inventory_id: number;
  seeding_id: number;
  quantity: number | null;
}

interface InventoriesProps {
  farmID: number;
  seedingId: number;
  availableInventories: IAvlInventory[];
  pickedItems: IPickedInventory[];
  setPickedItems: (x: any) => void;
}

const ReturnInventories = ({
  farmID,
  seedingId,
  availableInventories,
  pickedItems,
  setPickedItems,
}: InventoriesProps) => {
  const lang = useSelector(selectLang);
  const allIVTs = SelectInventoriesByFarmID(farmID);

  const updateForm = (ivt_id: number, val: string) => {
    const v = val.length <= 0 ? null : Number(val);
    let f = [...pickedItems];
    const i = f.findIndex(x => x.inventory_id === ivt_id);
    if (i < 0) {
      f.push({ inventory_id: ivt_id, seeding_id: seedingId, quantity: v });
    } else {
      f[i].quantity = v;
    }
    setPickedItems(f);
  };
  const label = (i: number) => {
    const t = allIVTs.find(x => x.id === i);
    return translate(
      lang,
      'How many %s are you returning?',
      `${t?.type} ${t?.subtype}`,
    );
  };

  return availableInventories.length > 0 ? (
    <div className='mb-32 mt-17'>
      {availableInventories.map(item => (
        <div className='mb-17' key={item.inventory_id}>
          <Input
            label={label(item.inventory_id)}
            placeholder={translate(lang, 'Maximum value is %s', item.available)}
            type='number'
            onChange={e => updateForm(item.inventory_id, e.target.value)}
            value={
              pickedItems
                .find(x => x.inventory_id === item.inventory_id)
                ?.quantity?.toString() ?? ''
            }
            max={item.available}
          />
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

interface Props {
  visible: boolean;
  title?: string;
  lineData: IOysterLineResource;
  data?: any;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  overConfirm?: (data: any) => Promise<any>;
  updateID?: number;
  onlyView?: boolean;
}

const OysterHarvestModal = ({
  visible,
  title,
  lineData,
  data,
  onCancel,
  onConfirm,
  overConfirm,
  updateID,
  onlyView,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const profile = SelectProfileByFarmID(lineData.farm_id);
  const showBudget = SelectIsBudgetVisible(lineData.farm_id);
  const friendEmails = SelectSettingsByFarmID(lineData.farm_id)?.friendEmails;
  const farmUtils = SelectUtilsByFarmID(lineData.farm_id);
  const customFields = SelectHarvestFields(lineData.farm_id);
  const metaFields = farmUtils
    .filter(x => x.type === 'oyster_harvest')
    .map(x => ({
      key: toStrSlug(x.name),
      label: x.name,
    }));
  const port_of_unloads = farmUtils.filter(x => x.type === 'port_of_unload'),
    delivered_tos = farmUtils.filter(x => x.type === 'delivered_to'),
    packhouses = farmUtils.filter(x => x.type === 'packhouse');
  const currentFarm = SelectOysterFarmsByFarmID(lineData.farm_id).find(
    x => x.id === lineData.farm_id,
  );
  const metaDefaults = metaFields.reduce((p, c) => {
    p[c.key] = 0;
    return p;
  }, {} as any);

  const signatureCanvas = useRef<SignatureCanvas | null>();

  const [errorMsg, setErrorMsg] = useState<string>();
  const [errors, setErrors] = useState<any>();
  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    ...metaDefaults,
    is_final: lineData.growing_cycle?.last_harvest
      ? !!lineData.growing_cycle.last_harvest.is_final
      : defaultForm.is_final,
    inventories: lineData.growing_cycle?.inventories_sum,
    port_of_unload:
      port_of_unloads && port_of_unloads.length > 0
        ? port_of_unloads[0].name
        : defaultForm.port_of_unload,
    delivered_to:
      delivered_tos && delivered_tos.length > 0
        ? delivered_tos[0].name
        : defaultForm.delivered_to,
    packhouse:
      packhouses && packhouses.length > 0
        ? packhouses[0].name
        : defaultForm.packhouse,
    crop_owner:
      currentFarm && currentFarm.owners && currentFarm.owners.length > 0
        ? currentFarm.owners[0].title
        : null,
    growing_area: `${currentFarm?.name} - ${lineData.line_name}`,
    harvester_name: profile?.name ?? null,
    line_length: lineData.growing_cycle
      ? lineData.growing_cycle.total_seeded_basket_count -
        lineData.growing_cycle.total_harvested_basket_count
      : defaultForm.line_length,
    amount_dz: lineData.growing_cycle
      ? lineData.growing_cycle.total_seeded_dozens -
        lineData.growing_cycle.total_harvested_dozens
      : defaultForm.amount_dz,
    amount_pcs: lineData.growing_cycle
      ? (lineData.growing_cycle.total_seeded_dozens -
          lineData.growing_cycle.total_harvested_dozens) *
        12
      : defaultForm.amount_pcs,
  });
  const [sigCanLoaded, setSigCanLoaded] = useState(false);
  const [reqPdf, setReqPdf] = useState(false);
  const [emails, setEmails] = useState(friendEmails?.join(',') ?? '');
  const [disabled, setDisabled] = useState(false);
  const [showPort, setShowPort] = useState(
    !port_of_unloads || port_of_unloads?.length <= 0,
  );
  const [showDel, setShowDel] = useState(
    !delivered_tos || delivered_tos?.length <= 0,
  );
  const [showPac, setShowPac] = useState(
    !packhouses || packhouses?.length <= 0,
  );
  const [customValues, setCustomValues] = useState(
    customFields.map(x => {
      const d =
        data && data.custom_values
          ? data.custom_values.find((y: ICustomValue) => y.field_id === x.id)
          : null;
      return {
        input_type: x.input_type,
        field_label: x.name,
        options: x.options
          ?.split(',')
          .map(t => ({ id: t, label: t, value: t })),
        field_id: x.id,
        field_val: d?.field_val ?? '',
        data_type: x.data_type,
        unit: x.unit,
      };
    }),
  );

  const { currentSeed, ivtFloats, lastAssessment, lastHarvest } = useMemo(
    () => ({
      currentSeed: lineData.growing_cycle?.main_seed,
      ivtFloats: lineData.growing_cycle?.inventories_sum,
      lastAssessment: lineData.growing_cycle?.last_assessment,
      lastHarvest: lineData.growing_cycle?.last_harvest,
    }),
    [lineData],
  );

  const availableInventories = useMemo(() => {
    let res: any[] = [];
    if (ivtFloats) {
      for (let x of ivtFloats) {
        res.push({
          inventory_id: x.inventory_id,
          available: x.quantity,
        });
      }
    }
    if (data?.inventories) {
      for (let x of data.inventories) {
        if (res.some(t => t.inventory_id === x.inventory_id)) continue;
        res.push({
          inventory_id: x.inventory_id,
          available: 0,
        });
      }
    }
    return res;
  }, [data?.inventories, ivtFloats]);

  const updateForm = (key: keyof IFormData, value: any) => {
    if (
      [
        'income',
        'shell_length',
        'shell_length_max',
        'cost',
        'line_length',
      ].includes(String(key))
    ) {
      value = value.length <= 0 ? undefined : Number(value);
      setFormData(prv => ({ ...prv, [key]: value }));
    } else if (key === 'amount_dz') {
      value = value.length <= 0 ? undefined : Number(value);
      if (!value) {
        setFormData(prv => ({ ...prv, amount_dz: value, amount_pcs: value }));
      } else {
        setFormData(prv => ({
          ...prv,
          amount_dz: value,
          amount_pcs: 12 * value,
        }));
      }
    } else if (key === 'amount_pcs') {
      value = value.length <= 0 ? undefined : Number(value);
      if (!value) {
        setFormData(prv => ({ ...prv, amount_pcs: value, amount_dz: value }));
      } else {
        setFormData(prv => ({
          ...prv,
          amount_pcs: value,
          amount_dz: value / 12,
        }));
      }
    } else if (key === 'return_amount_dz') {
      value = value.length <= 0 ? undefined : Number(value);
      if (!value) {
        setFormData(prv => ({
          ...prv,
          return_amount_dz: value,
          return_amount_pcs: value,
        }));
      } else {
        setFormData(prv => ({
          ...prv,
          return_amount_dz: value,
          return_amount_pcs: 12 * value,
        }));
      }
    } else if (key === 'return_amount_pcs') {
      value = value.length <= 0 ? undefined : Number(value);
      if (!value) {
        setFormData(prv => ({
          ...prv,
          return_amount_pcs: value,
          return_amount_dz: value,
        }));
      } else {
        setFormData(prv => ({
          ...prv,
          return_amount_pcs: value,
          return_amount_dz: value / 12,
        }));
      }
    } else if (metaFields.some(x => x.key === String(key))) {
      value = value.length <= 0 ? undefined : Number(value);
      const amountSum =
        metaFields.reduce((p, c) => p + (formData[c.key] ?? 0), 0) -
        (formData[key] ?? 0) +
        (value ?? 0);
      setFormData(prv => ({
        ...prv,
        [key]: value,
        amount_dz: amountSum,
        amount_pcs: 12 * amountSum,
      }));
    } else if (key === 'is_full') {
      setFormData(prv => ({
        ...prv,
        is_full: value,
        line_length: value
          ? lineData.growing_cycle
            ? lineData.growing_cycle.total_seeded_basket_count -
              lineData.growing_cycle.total_harvested_basket_count
            : null
          : null,
        amount_dz: value
          ? lineData.growing_cycle
            ? lineData.growing_cycle.total_seeded_dozens -
              lineData.growing_cycle.total_harvested_dozens
            : null
          : null,
        amount_pcs: value
          ? lineData.growing_cycle
            ? (lineData.growing_cycle.total_seeded_dozens -
                lineData.growing_cycle.total_harvested_dozens) *
              12
            : null
          : null,
      }));
    } else {
      if (typeof value === 'string' && value.length <= 0) {
        value = undefined;
      }
      setFormData(prv => ({ ...prv, [key]: value }));
    }
    setErrors((prv: any) => ({ ...(prv ?? {}), [key]: null }));
  };
  const validForm = () => {
    if (!updateID && !lineData.growing_cycle) {
      setErrorMsg('_line_empty');
      return null;
    }
    if (!updateID && !currentSeed) {
      setErrorMsg('_line_empty');
      return null;
    }
    if (
      updateID &&
      !data.is_full !== !formData.is_full &&
      lineData?.growing_cycle?.last_harvest?.id !== updateID
    ) {
      setErrorMsg('_impossible_harvest_update');
      return null;
    }
    if (formData.amount_dz === null || formData.amount_dz <= 0) {
      const es = translate(
        lang,
        formData.amount_dz === null
          ? 'Please enter harvested amount'
          : '_invalid_harvest_amount_length',
      );
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), amount: es }));
      return null;
    }
    if (
      formData.shell_length_max !== null &&
      (formData.shell_length === null ||
        formData.shell_length > formData.shell_length_max)
    ) {
      const es = translate(lang, 'Invalid shell length range');
      setErrorMsg(es);
      setErrors((prv: any) => ({ ...(prv ?? {}), shell_length: es }));
      return null;
    }
    if (formData.is_full) {
      let seeded = lineData.growing_cycle?.total_seeded_basket_count ?? 0,
        harvested = lineData.growing_cycle?.total_harvested_basket_count ?? 0;
      if (
        formData.line_length !== seeded - harvested &&
        !window.confirm(
          `${seeded - harvested} ${
            lineData.farming_method
          } are left but you are going to harvest ${formData.line_length} ${
            lineData.farming_method
          } as a full harvest. Are you sure?`,
        )
      )
        return null;
      seeded = lineData.growing_cycle?.total_seeded_dozens ?? 0;
      harvested = lineData.growing_cycle?.total_harvested_dozens ?? 0;
      if (
        formData.amount_dz !== seeded - harvested &&
        !window.confirm(
          `${seeded - harvested} dozens are left but you are going to harvest ${
            formData.amount_dz
          } dozens as a full harvest. Are you sure?`,
        )
      )
        return null;
    }
    if (emails && emails.split(',').some(x => !isEmailFormat(x))) {
      setErrorMsg('_invalid_emails');
      return null;
    }
    if (formData.inventories) {
      for (let ivt of formData.inventories) {
        const fd = ivtFloats?.find(x => x.inventory_id === ivt.inventory_id);
        if (!fd) {
          setErrorMsg('Invalid inventory information');
          return null;
        }
        if (fd.quantity < (ivt.quantity ?? 0)) {
          setErrorMsg(
            translate(
              lang,
              'You are trying to return more than available for inventory %s',
              `${fd.inventory_type} ${fd.inventory_subtype}`,
            ),
          );
          return null;
        }
      }
    }
    let res: any = {
      ...formData,
      seeding_id: data?.seeding_id ?? currentSeed?.id,
      season_name: data?.season_name ?? currentSeed?.season_name,
      line_id: lineData.id,
      complete_date: toSecond(formData.complete_date),
      require_pdf: reqPdf,
      request_emails: emails.split(','),
      return_amount_dz: formData.is_final
        ? formData.return_amount_dz
        : undefined,
      return_amount_pcs: formData.is_final
        ? formData.return_amount_pcs
        : undefined,
    };
    if (!res.income) res.income = 0;
    if (!updateID && signatureCanvas.current?.toDataURL()) {
      res.signature = signatureCanvas.current?.toDataURL() ?? undefined;
    } else {
      delete res.signature;
    }
    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        setErrorMsg('_invalid_time_range');
        return null;
      }
      res.time_tracks = tt;
    }
    if (
      metaFields.some(
        x => formData[x.key] === null || formData[x.key] === undefined,
      )
    ) {
      setErrorMsg(
        translate(
          lang,
          'Please fill %s field',
          metaFields.find(
            x => formData[x.key] === null || formData[x.key] === undefined,
          )?.label ?? '',
        ),
      );
      return null;
    }
    const cvs = customValues
      .filter(x => !!x.field_val)
      .map(({ field_id, field_val }) => ({ field_id, field_val }));
    if (cvs.length > 0) {
      res.custom_values = cvs;
    } else {
      res.custom_values = undefined;
    }
    setErrors(undefined);
    return res;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    const form = validForm();
    if (!form) return;

    setDisabled(true);
    if (overConfirm) {
      const res = await overConfirm(form);
      if (!res.status) {
        setErrorMsg(res.data?.message ?? 'Request failed');
        if (res.data.errors) setErrors(res.data.errors);
        setDisabled(false);
      }
      return;
    }
    const res = !updateID
      ? await sendSingleRequest(
          form,
          'POST',
          'api/farm/line/oyster-harvest',
          true,
        )
      : await sendSingleRequest(
          form,
          'PUT',
          `api/farm/line/oyster-harvest/${updateID}`,
          true,
        );
    if (res.status) {
      dispatch(loadFarmsData());
      if (form.inventories) {
        dispatch(loadInventories());
      }
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
    } else {
      setErrorMsg(res.data?.message ?? 'Request failed');
      if (res.data.errors) setErrors(res.data.errors);
      setDisabled(false);
    }
  };
  const updateCustomValue = (fieldID: number, val: string) => {
    let tmp = [...customValues];
    const i = tmp.findIndex(x => x.field_id === fieldID);
    if (i >= 0) {
      tmp[i].field_val = val;
    }
    setCustomValues(tmp);
  };

  useEffect(() => {
    if (data) {
      let tmp = { ...data };
      if (showBudget && data.expense_items && data.expense_items.length > 0) {
        tmp.cost = data.expense_items.reduce(
          (p: number, c: any) => p + c.amount,
          0,
        );
      }
      if (tmp.complete_date)
        tmp.complete_date = toMillisecond(tmp.complete_date);
      if (tmp.images) {
        tmp.images = tmp.images.map((x: any) =>
          typeof x === 'object' && x !== null ? x : parseImageUrlToFileItem(x),
        );
      }
      if (data.time_tracks) {
        tmp.time_tracks = data.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      setFormData(prv => ({ ...prv, ...tmp }));
    }
  }, [data, showBudget]);

  useEffect(() => {
    if (sigCanLoaded && data && data.signature) {
      signatureCanvas.current?.fromDataURL(data.signature);
    }
  }, [data, sigCanLoaded, signatureCanvas.current]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {title ?? 'Oyster harvest complete'}
          </Subtitle>
        </div>
        <div className='harvest-modal'>
          {!!errorMsg && (
            <Feedback
              message={translate(lang, errorMsg)}
              type={'error'}
              theme='light'
              position={10}
              isGlobal
              onClose={() => setErrorMsg(undefined)}
            />
          )}
          <div>
            {!!currentSeed && (
              <>
                <Paragrapgh
                  size={2}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='pb-25'
                >
                  {componentTranslate(
                    lang,
                    '_harvest_intro',
                    <span key={1} className='pr-6 pl-3 font-weight-500'>
                      {defaultDateFormat(currentSeed.planned_date_seed)}
                    </span>,
                    <span key={2} className='pl-4 font-weight-500'>
                      {defaultDateFormat(
                        lastAssessment?.planned_date_harvest ??
                          currentSeed.planned_date_harvest,
                      )}
                    </span>,
                  )}
                  <br />
                </Paragrapgh>
                <ReturnInventories
                  farmID={lineData.farm_id}
                  seedingId={currentSeed.id}
                  availableInventories={availableInventories}
                  pickedItems={formData.inventories ?? []}
                  setPickedItems={d =>
                    setFormData(prv => ({ ...prv, inventories: d }))
                  }
                />
              </>
            )}
            <div className='pl-17 mb-32 mt-7 pr-17'>
              <Subtitle size={4} fontWeight={700} align='left' color='black-3'>
                {translate(lang, 'Have you harvested the entire line?')}
              </Subtitle>
              <Radio.Group
                onChange={e => updateForm('is_full', e.target.value === 'F')}
                value={formData.is_full ? 'F' : 'P'}
              >
                <RadioButton
                  label={translate(lang, 'Yes, the line is now empty')}
                  value='F'
                  className='mt-7'
                />
                <RadioButton
                  label={translate(lang, 'No, the crop is still there')}
                  value='P'
                  className='mt-7'
                />
              </Radio.Group>
            </div>
            <div className='mb-17'>
              {!!lastHarvest && lastHarvest.is_final == formData.is_final && (
                <div>
                  <Title size={6} color='black' align='center' fontWeight={500}>
                    {translate(
                      lang,
                      lastHarvest.is_final
                        ? `This is final harvest continuation`
                        : `This is intermediate harvest continuation`,
                    )}
                  </Title>
                </div>
              )}
              <div className='pl-17 mb-32 mt-7 pr-17'>
                <Radio.Group
                  onChange={e =>
                    updateForm('is_final', e.target.value === 'FINAL')
                  }
                  value={!formData.is_final ? 'MIDDLE' : 'FINAL'}
                >
                  <RadioButton
                    label={translate(lang, '_radio_final')}
                    value='FINAL'
                    className='mt-7'
                  />
                  <RadioButton
                    label={translate(lang, '_radio_intermediate')}
                    value='MIDDLE'
                    className='mt-7'
                  />
                </Radio.Group>
              </div>
            </div>
            <>
              {metaFields.map(x => (
                <div key={x.key} className='mb-17'>
                  <Input
                    type={'number'}
                    label={x.label}
                    value={formData[x.key] ?? ''}
                    onChange={e => updateForm(x.key, e.target.value)}
                    unit={'dozens'}
                    disabled={onlyView}
                  />
                </div>
              ))}
              <div className='mt-32 mb-17'>
                <div className={errors?.amount ? 'invalid-form' : ''}>
                  <Input
                    label={translate(lang, 'Number of oysters')}
                    type='number'
                    onChange={e => updateForm('amount_dz', e.target.value)}
                    value={formData.amount_dz?.toString() ?? ''}
                    unit='dozens'
                  />
                  {!!errors?.amount && (
                    <div className='invalid-feedback'>{errors?.amount}</div>
                  )}
                </div>
              </div>
              {!!formData.is_final && (
                <div className='mb-17'>
                  <Input
                    label={translate(lang, '_graded_oysters')}
                    type='number'
                    onChange={e =>
                      updateForm('return_amount_dz', e.target.value)
                    }
                    value={formData.return_amount_dz?.toString() ?? ''}
                    unit='dozens'
                  />
                </div>
              )}
              {!!showBudget && (
                <div className='mb-17'>
                  <Input
                    label={translate(lang, 'Harvest income')}
                    type='number'
                    onChange={e => updateForm('income', e.target.value)}
                    value={formData.income?.toString() ?? ''}
                    unit={<DollarIcon />}
                  />
                </div>
              )}
              <div className='mb-17'>
                <Input
                  label={translate(
                    lang,
                    'Number of %s',
                    lineData.farming_method ?? '',
                  )}
                  type='number'
                  onChange={e => updateForm('line_length', e.target.value)}
                  value={formData.line_length?.toString() ?? ''}
                />
              </div>
              <div
                className={
                  errors?.complete_date ? 'invalid-form pb-17' : 'pb-17'
                }
              >
                <Datepicker
                  label={translate(lang, 'Harvest complete date')}
                  className='mb-17'
                  defaultValue={formData.complete_date}
                  onChange={e =>
                    e && updateForm('complete_date', e.toDate().getTime())
                  }
                />
                {!!errors?.complete_date && (
                  <div className='invalid-feedback'>
                    {errors?.complete_date}
                  </div>
                )}
              </div>
              <div className='mb-17'>
                <Input
                  type='text'
                  onChange={e => updateForm('harvest_number', e.target.value)}
                  value={formData.harvest_number ?? ''}
                  label={`${translate(lang, 'Harvest number')}${
                    lineData.last_harvest?.harvest_number
                      ? ` ${translate(
                          lang,
                          'last value is %s',
                          lineData.last_harvest.harvest_number,
                        )}`
                      : ''
                  }`}
                  disabled={onlyView}
                />
              </div>
              <div className='pb-10 d-flex justify-content-between'>
                <div className='w-100 mr-7'>
                  <Input
                    label={translate(lang, 'Shell length from')}
                    type='number'
                    onChange={e => updateForm('shell_length', e.target.value)}
                    value={formData.shell_length?.toString() ?? ''}
                  />
                </div>
                <div className='w-100 ml-7'>
                  <Input
                    label={translate(lang, 'Shell length to')}
                    type='number'
                    onChange={e =>
                      updateForm('shell_length_max', e.target.value)
                    }
                    value={formData.shell_length_max?.toString() ?? ''}
                  />
                </div>
              </div>
              <div className='pb-10'>
                <Dropdown
                  label={translate(lang, 'Port of unload')}
                  placeholder={translate(lang, 'Select port of unload')}
                  options={[
                    ...(port_of_unloads?.map(x => ({
                      id: x.id.toString(),
                      value: x.name,
                      label: x.name,
                    })) ?? []),
                    { id: '', value: '', label: translate(lang, 'Other') },
                  ]}
                  value={showPort ? '' : formData.port_of_unload ?? undefined}
                  onChange={v => {
                    updateForm('port_of_unload', v);
                    setShowPort(v.length <= 0);
                  }}
                />
                {showPort && (
                  <Input
                    label=''
                    placeholder={translate(lang, 'Port of unload')}
                    type='text'
                    className='mt-7'
                    onChange={e => updateForm('port_of_unload', e.target.value)}
                    value={formData.port_of_unload ?? ''}
                    disabled={onlyView}
                  />
                )}
              </div>
              <div className='pb-10'>
                <Input
                  label={translate(lang, 'Crop owner')}
                  type='text'
                  onChange={e => updateForm('crop_owner', e.target.value)}
                  value={formData.crop_owner ?? ''}
                  disabled={onlyView}
                />
              </div>
              <div className='pb-10'>
                <Input
                  type='text'
                  onChange={e => updateForm('growing_area', e.target.value)}
                  value={formData.growing_area ?? ''}
                  label={translate(lang, 'Growing area')}
                  disabled={onlyView}
                />
              </div>
              <div className='pb-10'>
                <Dropdown
                  label={translate(lang, 'Delivered to')}
                  placeholder={translate(lang, 'Select delivered to')}
                  options={[
                    ...(delivered_tos?.map(x => ({
                      id: x.id.toString(),
                      value: x.name,
                      label: x.name,
                    })) ?? []),
                    { id: '', value: '', label: translate(lang, 'Other') },
                  ]}
                  value={showDel ? '' : formData.delivered_to ?? undefined}
                  onChange={v => {
                    updateForm('delivered_to', v);
                    setShowDel(v.length <= 0);
                  }}
                />
                {showDel && (
                  <Input
                    label=''
                    placeholder={translate(lang, 'Delivered to')}
                    type='text'
                    className='mt-7'
                    onChange={e => updateForm('delivered_to', e.target.value)}
                    value={formData.delivered_to ?? ''}
                    disabled={onlyView}
                  />
                )}
              </div>
              <div className='pb-10'>
                <Dropdown
                  label={translate(lang, 'Packhouse')}
                  placeholder={translate(lang, 'Select packhouse')}
                  options={[
                    ...(packhouses?.map(x => ({
                      id: x.id.toString(),
                      value: x.name,
                      label: x.name,
                    })) ?? []),
                    { id: '', value: '', label: translate(lang, 'Other') },
                  ]}
                  value={showPac ? '' : formData.packhouse ?? undefined}
                  onChange={v => {
                    updateForm('packhouse', v);
                    setShowPac(v.length <= 0);
                  }}
                />
                {showPac && (
                  <Input
                    label=''
                    placeholder={translate(lang, 'Packhouse')}
                    type='text'
                    className='mt-7'
                    onChange={e => updateForm('packhouse', e.target.value)}
                    value={formData.packhouse ?? ''}
                    disabled={onlyView}
                  />
                )}
              </div>
              <div className='pb-10'>
                <Input
                  label={translate(lang, 'Shell condition')}
                  type='text'
                  onChange={e => updateForm('shell_condition', e.target.value)}
                  value={formData.shell_condition ?? ''}
                  disabled={onlyView}
                />
              </div>
              <div className='pb-10'>
                <Input
                  label={translate(lang, 'Oyster type')}
                  type='text'
                  onChange={e => updateForm('oyster_type', e.target.value)}
                  value={formData.oyster_type ?? ''}
                  disabled={onlyView}
                />
              </div>
              <div className='pb-10'>
                <Input
                  label={translate(lang, "User's name")}
                  type='text'
                  onChange={e => updateForm('harvester_name', e.target.value)}
                  value={formData.harvester_name ?? ''}
                  disabled={onlyView}
                />
              </div>
              {!!formData.time_tracks && (
                <div className='pb-7'>
                  <TimeTrackInput
                    data={formData.time_tracks}
                    onChange={t => updateForm('time_tracks', t)}
                  />
                </div>
              )}
              {!!showBudget && (
                <div className='pb-10'>
                  <Input
                    label={translate(lang, 'Cost')}
                    type='number'
                    onChange={e => updateForm('cost', e.target.value)}
                    value={formData.cost?.toString() ?? ''}
                    unit='$'
                  />
                </div>
              )}
              {customValues.map(x => (
                <div key={x.field_id} className='mb-17'>
                  {x.input_type === 'select' ? (
                    <>
                      <Dropdown
                        label={x.field_label}
                        options={x.options ?? []}
                        value={x.field_val}
                        onChange={v => updateCustomValue(x.field_id, v)}
                        disabled={onlyView}
                      />
                      {(!x.options || x.options.length <= 0) && (
                        <Link to={'/custom-fields'}>
                          <span style={{ color: '#008ed1' }}>Set up field</span>
                        </Link>
                      )}
                    </>
                  ) : (
                    <Input
                      type={x.data_type === 'number' ? 'number' : 'text'}
                      label={x.field_label}
                      value={x.field_val ?? ''}
                      onChange={e =>
                        updateCustomValue(x.field_id, e.target.value)
                      }
                      unit={x.unit}
                      disabled={onlyView}
                    />
                  )}
                </div>
              ))}
              <div className='pb-10'>
                <Input
                  label={translate(lang, 'Comment')}
                  type='textarea'
                  onChange={e => updateForm('comment', e.target.value)}
                  value={formData.comment ?? ''}
                />
              </div>
              <div className='pb-10'>
                <PicturesWall
                  label={translate(lang, 'Images')}
                  fileList={formData.images}
                  handleChangeImages={v => updateForm('images', v)}
                />
              </div>
              <div className='pb-20'>
                <p className='mb-4 d-block paragrapgh paragrapgh--2 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
                  {translate(lang, 'Signature')}
                </p>
                <div
                  style={{
                    border: '1px solid #EFEFEF',
                    borderRadius: '4px',
                    height: '200px',
                  }}
                >
                  <SignatureCanvas
                    ref={r => {
                      signatureCanvas.current = r;
                      setSigCanLoaded(true);
                    }}
                    penColor='green'
                    canvasProps={{ width: 550, height: 200 }}
                  />
                </div>
              </div>
              {!updateID && (
                <div className='mb-27'>
                  <CheckboxButton
                    checked={reqPdf}
                    onChange={e => setReqPdf(e.target.checked)}
                    label={translate(
                      lang,
                      'Send me PDF of Harvest Declaration Form',
                    )}
                  />
                  {reqPdf && (
                    <div className='mt-17'>
                      <Input
                        label={translate(lang, 'Email list')}
                        placeholder={translate(
                          lang,
                          'Please enter emails separate by comma',
                        )}
                        type='text'
                        value={emails}
                        onChange={e => setEmails(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              )}
            </>
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled || onlyView}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default OysterHarvestModal;

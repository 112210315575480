import { IName } from './general.entities';

export interface IInventoryPosition {
  seeding_id: number;
  inventory_id: number;
  points: number[];
}

interface ILineItem {
  id: number;
  line_name: string;
  farm: IName;
}

interface IFloatSeed {
  id: number;
  line: ILineItem | null;
  season_name: string;
  quantity: number;
  status: 'IN' | 'OUT';
  manage_date: number;
}

export interface IInventoryResource {
  id: number;
  type: string;
  subtype: string | null;
  quantity: number;
  available_quantity: number;
  pending_quantity: number;
  price: number;
  level: number;
  inventory_seeds: IFloatSeed[];

  account_id?: number;
}

export const defaultBagMusselTypes = [
  'Harvested Mussels',
  'Brokens',
  'Smalls',
  'Biofouling (Blue mussels/Oysters)',
  'Green Waste',
];

import { useSelector } from 'react-redux';
import {
  CloseIcon,
  Datepicker,
  Dropdown,
  Subtitle,
} from '../../components/shared';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { useMemo, useState } from 'react';
import { Alert, Button, List, Modal, Typography, message } from 'antd';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { Link } from 'react-router-dom';
import { selectProfile } from '../../store/auth/auth.selector';
import moment from 'moment';
import { selectBoats } from '../../store/users/users.selector';
import { ITaskRequest } from '../../entities/task.entities';
import { sendSingleRequest } from '../../apis';
import { ILineResource } from '../../entities/farms.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../lib/lang.helper';

interface ILineLabel {
  farm_id: number;
  farm_name: string;
  line_id: number;
  line_name: string;
}

interface IBaseData {
  last_seed: {
    farm_id: number;
    farm_name: string;
    line_id: number;
    line_name: string;
    date_seed: number;
  } | null;
  last_harvest: {
    farm_id: number;
    farm_name: string;
    line_id: number;
    line_name: string;
    complete_date: number;
  } | null;
  suggest: {
    from_line: ILineLabel;
    target_line: ILineLabel;
  } | null;
}

interface Props {
  baseData: IBaseData;
  loadData: () => void;
}

const SeedingSuggest = ({ baseData, loadData }: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);
  const profile = useSelector(selectProfile);
  const boats = useSelector(selectBoats);

  const { last_seed, last_harvest, suggest } = baseData;
  const [showAccept, setShowAccept] = useState(false);
  const [boatId, setBoatId] = useState(profile?.boat_id ?? undefined);
  const [dueDate, setDueDate] = useState(moment().toDate().getTime());
  const [disabled, setDisabled] = useState(false);
  const [sourceLine, setSourceLine] = useState(suggest?.from_line.line_id);
  const [targetLine, setTargetLine] = useState(suggest?.target_line.line_id);

  const { farmsInfo, totalEmpty, emptyLines, firstStateLines } = useMemo(() => {
    const farmsInfo = farmsData.map(x => {
      const emptyLines = x.lines.filter(y => !y.growing_cycle);
      const firstStageLines = x.lines.filter(
        y =>
          y.growing_cycle &&
          (!y.growing_cycle.main_seed.spat_storage ||
            !y.growing_cycle.main_seed.spat_storage.harvest_id),
      );
      return {
        id: x.id,
        name: x.name,
        emptyLines,
        firstStageLines,
      };
    });
    const emptyLines = farmsInfo.reduce(
      (p, c) => [...p, ...c.emptyLines],
      [] as ILineResource[],
    );
    const firstStateLines = farmsInfo.reduce(
      (p, c) => [...p, ...c.firstStageLines],
      [] as ILineResource[],
    );
    const totalEmpty = farmsInfo.reduce((p, c) => p + c.emptyLines.length, 0);

    return { farmsInfo, totalEmpty, emptyLines, firstStateLines };
  }, [farmsData]);

  const acceptClick = async () => {
    if (!sourceLine || !targetLine) {
      message.error(translate(lang, '_no_suggested'));
      return;
    }
    if (!boatId) {
      message.error(translate(lang, 'Please select a boat'));
      return;
    }
    setDisabled(true);
    const srcLine = firstStateLines.find(x => x.id === sourceLine),
      tarLine = emptyLines.find(x => x.id === targetLine);
    if (!srcLine || !tarLine) {
      message.error('Invalid error! Please contact to developer team');
      return;
    }
    let form: ITaskRequest = {
      farm_id: srcLine.farm_id,
      line_id: srcLine.id,
      title: `Harvest farm ${srcLine.farm_name} line ${srcLine.line_name}`,
      content: 'Please harvest this line as intermediate',
      due_date: dueDate,
      assigned_to: 0,
      boat_id: boatId,
      type: 'HARVEST',
    };
    let res = await sendSingleRequest(form, 'POST', 'api/task/tasks', true);
    if (res.status) {
      message.success(
        translate(lang, 'Task %s has been created successfully', form.title),
      );
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'));
    }
    form = {
      farm_id: tarLine.farm_id,
      line_id: tarLine.id,
      title: `Seed farm ${tarLine.farm_name} line ${tarLine.line_name}`,
      content: `Please seed the spats harvested from farm ${srcLine.farm_name} line ${srcLine.line_name} into this line`,
      due_date: dueDate,
      assigned_to: 0,
      boat_id: boatId,
      type: 'SEEDING',
    };
    res = await sendSingleRequest(form, 'POST', 'api/task/tasks', true);
    if (res.status) {
      message.success(
        translate(lang, 'Task %s has been created successfully', form.content),
      );
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'));
    }
    setShowAccept(false);
    setDisabled(false);
    await loadData();
  };

  return (
    <div id='seeding-suggest' className='pt-28 pb-28'>
      <div className='mb-18'>
        <Subtitle size={1} color='black' align='left' fontWeight={600}>
          {translate(lang, 'Seeding suggestions')}
        </Subtitle>
      </div>
      <div className='ml-17'>
        <div className='suggest-sentence'>
          {componentTranslate(
            lang,
            'You have %s lines empty',
            <Typography.Text mark key={1}>
              {totalEmpty}
            </Typography.Text>,
          )}
        </div>
        <div className='suggest-sentence'>
          {!!last_seed &&
            componentTranslate(
              lang,
              'Your last seeding was on farm %s line %s on day %s',
              <Typography.Text mark key={1}>
                {last_seed.farm_name}
              </Typography.Text>,
              <Typography.Text mark key={2}>
                {last_seed.line_name}
              </Typography.Text>,
              <Typography.Text mark key={3}>
                {defaultDateFormat(last_seed.date_seed)}
              </Typography.Text>,
            )}
          {!!last_harvest && (
            <>
              {componentTranslate(
                lang,
                !last_seed
                  ? 'Your last intermediate harvest on farm %s line %s on day %s'
                  : ' and last intermediate harvest on farm %s line %s on day %s',
                <Typography.Text mark key={1}>
                  {last_harvest.farm_name}
                </Typography.Text>,
                <Typography.Text mark key={2}>
                  {last_harvest.line_name}
                </Typography.Text>,
                <Typography.Text mark key={3}>
                  {defaultDateFormat(last_harvest.complete_date)}
                </Typography.Text>,
              )}
            </>
          )}
        </div>
      </div>
      {farmsInfo && (
        <div className='mt-18 mb-18'>
          <List
            bordered
            dataSource={farmsInfo}
            renderItem={item => (
              <List.Item>
                <Link to={`/farms/${item.id}`}>
                  {componentTranslate(
                    lang,
                    'Farm %s has %s lines empty and %s first stage crop',
                    <strong key={1}>{item.name}</strong>,
                    <strong key={2}>{item.emptyLines.length}</strong>,
                    <strong key={3}>{item.firstStageLines.length}</strong>,
                  )}
                </Link>
              </List.Item>
            )}
          />
          {suggest && (
            <div className='mt-18'>
              <div className='suggest-sentence'>
                <Alert
                  className='suggest-warning'
                  message={translate(lang, '_suggest_warning')}
                  type='warning'
                  showIcon
                />
              </div>
              <Alert
                className='mt-18 mb-18 suggest-info'
                message={translate(lang, '(EXPERIMENT) Suggest')}
                description={
                  <div>
                    <div className='mb-7'>
                      {componentTranslate(
                        lang,
                        '_seed_suggest_line',
                        <strong key={1}>
                          <Link to={`/farms/${suggest.from_line.farm_id}`}>
                            {suggest.from_line.farm_name}
                          </Link>
                        </strong>,
                        <strong key={2}>
                          <Link
                            to={`/farms/${suggest.from_line.farm_id}/${suggest.from_line.line_id}`}
                          >
                            {suggest.from_line.line_name}
                          </Link>
                        </strong>,
                        <strong key={3}>
                          <Link to={`/farms/${suggest.target_line.farm_id}`}>
                            {suggest.target_line.farm_name}
                          </Link>
                        </strong>,
                        <strong key={4}>
                          <Link
                            to={`/farms/${suggest.target_line.farm_id}/${suggest.target_line.line_id}`}
                          >
                            {suggest.target_line.line_name}
                          </Link>
                        </strong>,
                      )}
                    </div>
                    <div className='d-flex justify-content-end'>
                      <Button
                        size='small'
                        type='primary'
                        onClick={() => setShowAccept(true)}
                      >
                        {translate(lang, 'Accept')}
                      </Button>
                    </div>
                  </div>
                }
                showIcon={true}
                type='info'
                closable={false}
              />
            </div>
          )}
        </div>
      )}
      {showAccept && suggest && (
        <Modal
          visible={true}
          onCancel={() => setShowAccept(false)}
          closable
          closeIcon={<CloseIcon />}
          footer={null}
        >
          <div className='wrap'>
            <div className='mt-28 mb-28'>
              <Dropdown
                label={translate(lang, 'Source line to intermediate harvest')}
                options={firstStateLines.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.farm_name} - ${x.line_name}`,
                }))}
                value={sourceLine?.toString()}
                onChange={v => setSourceLine(Number(v))}
              />
            </div>
            <div className='mt-28 mb-28'>
              <Dropdown
                label={translate(lang, 'Destination line to reseed')}
                options={emptyLines.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.farm_name} - ${x.line_name}`,
                }))}
                value={targetLine?.toString()}
                onChange={v => setTargetLine(Number(v))}
              />
            </div>
            <div className='mt-28 mb-28'>
              <Dropdown
                label={translate(lang, '_suggest_boat_select_q')}
                options={boats.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} (${x.reg_number})`,
                }))}
                value={boatId?.toString()}
                onChange={v => setBoatId(Number(v))}
              />
            </div>
            <div className='mt-28 mb-28'>
              <Datepicker
                label={translate(lang, '_suggest_date_q')}
                defaultValue={dueDate}
                onChange={e => e && setDueDate(e.toDate().getTime())}
                required
              />
            </div>
            <div className='mt-32 d-flex justify-content-end'>
              <Button
                size='large'
                disabled={disabled}
                onClick={acceptClick}
                type='primary'
              >
                {translate(lang, 'Convert to task')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default SeedingSuggest;

import { ILineResource } from '../entities/farms.entities';

type conditionNum = number | undefined | null;

export const transformFarmWithKey = (data: any = []): any => {
  const dataWithKey = data.map((farm: any, i: number) => {
    const newFarm = { ...farm };
    newFarm.key = i + 1;

    const newLines = farm?.lines?.map((line: any, y: number) => {
      const newLine = { ...line };
      newLine.key = y + 1;
      return newLine;
    });

    return { ...newFarm, lines: newLines };
  });

  return dataWithKey;
};

export const rangeLabel = (
  min: conditionNum,
  max: conditionNum,
  unit: string = '',
) => {
  if (max === undefined || max === null) {
    if (min === null || min === undefined) return '-';
    return `${min} ${unit}`;
  }
  return `${min ?? 0}-${max} ${unit}`;
};

export const defaultUnitLength = (
  val: string | number | null | undefined,
  unit: string = 'm',
  emptyCharacter: string = '',
) => {
  return val ? `${val} ${unit}` : emptyCharacter;
};

export const averageValue = (
  min: number | null | undefined,
  max: number | null | undefined,
  unit = '',
) =>
  min !== undefined && min !== null
    ? `${max !== undefined && max !== null ? (min + max) / 2 : min} ${unit}`
    : '-';

export const lineSizeLabel = (line: ILineResource) =>
  line.growing_cycle
    ? `${
        line.growing_cycle.last_assessment?.shell_size.avg ??
        (line.growing_cycle.main_seed.spat_size_max
          ? (line.growing_cycle.main_seed.spat_size_max +
              line.growing_cycle.main_seed.spat_size) /
            2
          : line.growing_cycle.main_seed.spat_size)
      } mm`
    : '-';

interface IValues {
  value?: number;
  isGrow?: boolean;
  interest?: number;
  isReverse?: boolean;
}

export const getInterest = (
  firstValue: number,
  secondValue: number | null,
): IValues => {
  let interest;
  let values: IValues = {};
  if (secondValue !== null && secondValue !== 0) {
    interest = ((firstValue - secondValue) / secondValue) * 100;
    values = {
      isGrow: !(secondValue > firstValue),
      interest: Number(Math.abs(interest).toFixed(2)),
      value: firstValue,
    };
  } else if (secondValue === 0) {
    values = {
      isGrow: !(+firstValue < 0),
      interest: +firstValue === 0 ? 0 : 100,
      value: firstValue,
    };
  } else if (secondValue === null) {
    values = {
      isGrow: true,
      interest: 0,
      value: firstValue,
    };
  } else {
    values = {
      value: firstValue,
    };
  }

  return values;
};

export const invertColor = (hex: string) => {
  hex = hex.replace(/^#/, '');
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('');
  }
  const r = 255 - parseInt(hex.substring(0, 2), 16);
  const g = 255 - parseInt(hex.substring(2, 4), 16);
  const b = 255 - parseInt(hex.substring(4, 6), 16);

  return '#' + [r, g, b].map(c => c.toString(16).padStart(2, '0')).join('');
};

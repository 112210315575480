import { RootState, IThunkType } from '../rootReducer';
import { isSpinner, showFeedback } from '../ui/ui.actions';
import { sendSingleRequest } from '../../apis/index';
import { IInventoryAction } from './inventories.type';

const setInventory = (ivt: IInventoryAction): IInventoryAction => ivt;

let loadingInventories = false;

export const loadInventories = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingInventories) return;
    loadingInventories = false;

    const response = await sendSingleRequest(
      {},
      'GET',
      'api/inventory/inventories',
      true,
    );
    if (response.status) {
      dispatch(
        setInventory({
          type: 'INVENTORIES/SET_INVENTORIES',
          payload: response.data,
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Something went wrong',
        }),
      );
    }

    loadingInventories = false;
  };
};

export const loadSubtypes = (filter: any = null) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingInventories) return;
    loadingInventories = true;

    dispatch(isSpinner(true));
    const response = await sendSingleRequest(
      filter,
      'GET',
      'api/inventory/subtypes',
      true,
    );
    if (response.status) {
      dispatch(
        setInventory({
          type: 'INVENTORIES/SET_SUBTYPES',
          payload: response.data,
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Something went wrong',
        }),
      );
    }
    dispatch(isSpinner(false));

    loadingInventories = false;
  };
};

export const updateInventoryQuantity = (id: number, quantity: number) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    let inventories = [...getState().inventories.inventories];
    const i = inventories.findIndex(x => x.id === id);
    if (i < -1) {
      return `Inventory not found`;
    }

    const response = await sendSingleRequest(
      { quantity },
      'PUT',
      `api/inventory/inventories/${id}`,
      true,
    );
    if (response.status) {
      inventories[i] = response.data;
      dispatch(
        setInventory({
          type: 'INVENTORIES/SET_INVENTORIES',
          payload: inventories,
        }),
      );

      return true;
    } else {
      return response.data?.message ?? 'Update failed';
    }
  };
};

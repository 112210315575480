import moment from 'moment';
import { ITimeRange } from '../../../entities/general.entities';
import { ISeaweedLineResource } from '../../../entities/farms.entities';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { Modal } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Input,
  Paragrapgh,
  Subtitle,
} from '../../shared';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import ModalFeedbackView from '../../shared/feedback/ModalFeedbackView';
import { showFeedback } from '../../../store/ui/ui.actions';
import { sendSingleRequest } from '../../../apis';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';

interface IFormData {
  size_min: number | null;
  size_max: number | null;
  size_avg: number | null;
  assessment_date: number;
  planned_date_harvest: number;
  ready_to_harvest: boolean;
  comment: string | null;
  time_tracks?: ITimeRange[];
  images: any[];
}

const defaultForm: IFormData = {
  size_min: null,
  size_max: null,
  size_avg: null,
  assessment_date: moment().toDate().getTime(),
  planned_date_harvest: moment().toDate().getTime(),
  ready_to_harvest: false,
  comment: null,
  time_tracks: [],
  images: [],
};

interface Props {
  visible: boolean;
  title?: string;
  lineData: ISeaweedLineResource;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  data?: any;
  updateID?: number;
  onlyView?: boolean;
}

const SeaweedAssessModal = ({
  visible,
  title,
  lineData,
  onCancel,
  onConfirm,
  data,
  updateID,
  onlyView,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    planned_date_harvest: lineData.growing_cycle?.main_seed.planned_date_harvest
      ? toMillisecond(lineData.growing_cycle.main_seed.planned_date_harvest)
      : defaultForm.planned_date_harvest,
  });
  const [warning, setWaring] = useState(false);
  const [errors, setErrors] = useState<{
    min: string | null;
    max: string | null;
    avg: string | null;
  }>({
    min: null,
    max: null,
    avg: null,
  });
  const [disabled, setDisabled] = useState(false);

  const updateForm = (key: keyof IFormData, val: any) => {
    if (['size_min', 'size_max', 'size_avg'].includes(key)) {
      val = val.length <= 0 ? null : Number(val);
    }
    let tmp = { ...formData, [key]: val };
    if (key === 'size_min') {
      setErrors(p => ({ ...p, min: null, avg: null }));
      tmp.size_avg = Math.round(
        (Number(tmp.size_max) + Number(tmp.size_min)) / 2,
      );
    } else if (key === 'size_max') {
      setErrors(p => ({ ...p, max: null, avg: null }));
      tmp.size_avg = Math.round(
        (Number(tmp.size_max) + Number(tmp.size_min)) / 2,
      );
    }
    setFormData(tmp);
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );

  const validForm = () => {
    let res: any = true;
    let tmpErr = { ...errors };
    if (!formData.size_min) {
      tmpErr.min = translate(lang, 'Required field');
      res = false;
    }
    if (!formData.size_max) {
      tmpErr.max = translate(lang, 'Required field');
      res = false;
    }
    if (!formData.size_avg) {
      tmpErr.avg = translate(lang, 'Required field');
      res = false;
    }
    if (Number(formData.size_min) > Number(formData.size_max)) {
      tmpErr.avg = translate(
        lang,
        'Maximum value should be equal or greater than minimum value',
      );
      res = false;
    }
    if (
      formData.time_tracks &&
      toSecTimeTracks(formData.time_tracks) === false
    ) {
      showError('_invalid_time_range');
      res = false;
    }
    setErrors(tmpErr);
    if (!res) return null;

    res = {
      ...formData,
      assessment_date: toSecond(formData.assessment_date),
      planned_date_harvest: toSecond(formData.planned_date_harvest),
      seeding_id: lineData.growing_cycle?.main_seed.id,
      line_id: lineData.id,
    };
    res.btm_size_min = res.btm_size_max = res.btm_size_avg = undefined;

    if (res.time_tracks && res.time_tracks.length > 0) {
      const tt = toSecTimeTracks(res.time_tracks);
      if (!tt) {
        showError('Invalid time tracks information');
        return null;
      }
      res.time_tracks = tt;
    }
    return res;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    const form = validForm();
    if (!form) return;

    if (
      !warning &&
      lineData.growing_cycle?.last_assessment &&
      (lineData.growing_cycle.last_assessment.shell_size.min >= form.size_min ||
        lineData.growing_cycle.last_assessment.shell_size.max >= form.size_max)
    ) {
      setWaring(true);
      return;
    }
    setDisabled(true);
    const res = !updateID
      ? await sendSingleRequest(
          form,
          'POST',
          'api/farm/line/assessment/assessments',
          true,
        )
      : await sendSingleRequest(
          form,
          'PUT',
          `api/farm/line/assessment/assessments/${updateID}`,
          true,
        );
    if (res.status) {
      dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
      onConfirm(res.data);
    } else {
      showError(res.data?.message ?? 'Request failed');
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (data) {
      let form = { ...data };
      if (form.assessment_date)
        form.assessment_date = toMillisecond(form.assessment_date);
      if (form.planned_date_harvest)
        form.planned_date_harvest = toMillisecond(form.planned_date_harvest);
      form.ready_to_harvest = !!data.ready_harvest || !!data.ready_to_harvest;
      if (form.time_tracks && form.time_tracks.length > 0) {
        form.time_tracks = data.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      if (data.images && data.images.length > 0) {
        let tmp: any = [];
        for (let x of data.images) {
          if (typeof x === 'object' && x !== null) {
            tmp.push(x);
          } else {
            tmp.push(parseImageUrlToFileItem(x));
          }
        }
        if (tmp && tmp.length > 0) {
          form.images = tmp;
        }
      }
      setFormData(prv => ({ ...prv, ...form }));
    }
  }, [data]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Seaweed assessment')}
          </Subtitle>
        </div>
        <div className='assessment-modal'>
          {warning ? (
            <div className='mt-32 mb-64 pb-32 pt-17 pl-17 pr-17'>
              <Paragrapgh
                size={1}
                fontWeight={400}
                color='black-2'
                align='default'
              >
                {translate(lang, '_assessment_warning')}
              </Paragrapgh>
            </div>
          ) : (
            <div className='assessment-modal'>
              <div className='assessment-modal__condition d-flex justify-content-between'>
                <div className={`mx-180 ${errors.min ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size min')} (mm)`}
                    value={formData.size_min?.toString() ?? ''}
                    onChange={e => updateForm('size_min', e.target.value)}
                    required={true}
                  />
                  {errors.min && (
                    <div className='invalid-feedback'>{errors.min}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors.max ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size max')} (mm)`}
                    value={formData.size_max?.toString() ?? ''}
                    onChange={e => updateForm('size_max', e.target.value)}
                    required={true}
                  />
                  {errors.max && (
                    <div className='invalid-feedback'>{errors.max}</div>
                  )}
                </div>
                <div className={`mx-180 ${errors.avg ? 'invalid-form' : ''}`}>
                  <Input
                    type='number'
                    label={`${translate(lang, 'Size average')} (mm)`}
                    value={formData.size_avg?.toString() ?? ''}
                    onChange={e => updateForm('size_avg', e.target.value)}
                    required={true}
                  />
                  {errors.avg && (
                    <div className='invalid-feedback'>{errors.avg}</div>
                  )}
                </div>
              </div>
              <Datepicker
                className='mb-17'
                defaultValue={formData.assessment_date}
                label={translate(lang, 'Assessment date')}
                onChange={e =>
                  updateForm(
                    'assessment_date',
                    e!.toDate().getTime() <
                      Number(
                        lineData.growing_cycle?.main_seed.planned_date_seed,
                      )
                      ? moment().toDate().getTime()
                      : e!.toDate().getTime(),
                  )
                }
                required={true}
              />
              <Datepicker
                className='mb-17'
                defaultValue={formData.planned_date_harvest}
                label={translate(lang, 'Planned harvest date')}
                onChange={e =>
                  updateForm(
                    'planned_date_harvest',
                    e!.toDate().getTime() <
                      Number(
                        lineData.growing_cycle?.main_seed.planned_date_seed,
                      )
                      ? moment().toDate().getTime()
                      : e!.toDate().getTime(),
                  )
                }
                required={true}
              />
              <CheckboxButton
                className='mb-17'
                label={translate(lang, 'Ready to harvest')}
                checked={formData.ready_to_harvest}
                onChange={e => updateForm('ready_to_harvest', e.target.checked)}
              />
              {formData.time_tracks && (
                <TimeTrackInput
                  data={formData.time_tracks}
                  onChange={t => updateForm('time_tracks', t)}
                />
              )}
              <Input
                label={translate(lang, 'Comment')}
                placeholder={`${translate(lang, 'Type your comment')}...`}
                className='mb-17'
                type='textarea'
                value={formData.comment ?? ''}
                dataType='comment'
                onChange={e => updateForm('comment', e.target.value)}
                isOptional={true}
              />
              {!!formData.images && (
                <PicturesWall
                  label={translate(lang, 'Images')}
                  fileList={formData.images}
                  handleChangeImages={f => updateForm('images', f)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        {!warning ? (
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={handleConfirmClick}
            disabled={disabled || onlyView}
          >
            {translate(lang, 'Confirm')}
          </Button>
        ) : (
          <>
            <Button
              className='rsp-btn'
              width='small'
              size={2}
              type='fill'
              color='blue'
              onClick={() => setWaring(false)}
              disabled={disabled}
            >
              {translate(lang, 'Let me correct')}
            </Button>
            <Button
              width='small'
              size={2}
              type='fill'
              color='green'
              className='rsp-btn ml-16'
              onClick={handleConfirmClick}
              disabled={disabled || onlyView}
            >
              {translate(lang, "Yes, it's valid")}
            </Button>
          </>
        )}
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default SeaweedAssessModal;

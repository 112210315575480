import { useSelector } from 'react-redux';
import { ISeaweedLineDetail } from '../../entities/farms.entities';
import { ISeaweedCycle } from '../../entities/growing.entities';
import {
  Button,
  Caret,
  DropdownMenu,
  Pen,
  Subtitle,
} from '../../components/shared';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { averageValue, rangeLabel } from '../../util/farmUtil';
import { amountDays } from '../../lib/farm.helpers';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectIsEditable,
  SelectIsGrower,
} from '../../store/extra/extra.selector';

interface Props {
  lineData: ISeaweedLineDetail;
  cyclePart: ISeaweedCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup: () => void;
  onClickNextGroup: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onAssessmentClick: () => void;
  onCatchSpatClick: () => void;
  onHarvestClick: () => void;
  onMaintenanceClick: () => void;
  onExtraSeedClick: () => void;
}

const SeaweedLineMobile = ({
  lineData,
  cyclePart,
  isPrevEnabled,
  isNextEnabled,
  onClickPrevGroup,
  onClickNextGroup,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onAssessmentClick,
  onCatchSpatClick,
  onHarvestClick,
  onMaintenanceClick,
  onExtraSeedClick,
}: Props) => {
  const lang = useSelector(selectLang);
  const isGrower = SelectIsGrower(lineData.farm_id);
  const isEditable = SelectIsEditable(lineData.farm_id);
  const mainSeed = cyclePart?.main_seed,
    lastAssessment =
      cyclePart && cyclePart.assessments.length > 0
        ? cyclePart.assessments.reduce(
            (p, c) => (p.assessment_date < c.assessment_date ? c : p),
            cyclePart.assessments[0],
          )
        : null;

  return (
    <>
      <div className='pt-16 pb-4'>
        <div className={'table-mobile__card'} onKeyDown={() => undefined}>
          {isEditable && (
            <div className={'table-mobile__card-dots'}>
              <DropdownMenu
                data={lineData}
                onEdit={showEditLineModal}
                column='isFarm'
                isRedirect={`/farms/${lineData.farm_id}`}
              />
            </div>
          )}
          <div className='d-flex justify-content-between align-items-center'>
            <Subtitle size={5} color='black-3' align='left' fontWeight={600}>
              {translate(lang, 'Line - %s', lineData.line_name)}
            </Subtitle>
          </div>
          <div className='pt-16'>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Tank area')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <>
                    <span className='pr-6'>
                      {`${
                        lineData.growing_cycle?.total_seeded_tank_area ?? '-'
                      }/${lineData.tank_area}`}
                    </span>
                    <span>{'m²'}</span>
                  </>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Date seeded')}
                </Subtitle>
                {mainSeed ? (
                  <Subtitle
                    size={5}
                    color='black-5'
                    align='left'
                    fontWeight={400}
                  >
                    {defaultDateFormat(mainSeed.planned_date_seed)}
                  </Subtitle>
                ) : (
                  <div className='d-flex flex-wrap'>
                    <Subtitle
                      size={5}
                      color='black-5'
                      align='left'
                      fontWeight={400}
                      className='pr-4'
                    >
                      {translate(lang, 'Line empty for')}
                    </Subtitle>
                    <Subtitle
                      size={5}
                      color='black-5'
                      align='left'
                      fontWeight={600}
                    >
                      {amountDays(lineData.line_idle)}
                    </Subtitle>
                  </div>
                )}
              </div>
            </div>

            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Planned harvest date')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {defaultDateFormat(
                    lastAssessment?.planned_date_harvest ??
                      mainSeed?.planned_date_harvest,
                    '-',
                  )}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'></div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Seeded Spat Size')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {rangeLabel(
                    mainSeed?.spat_size,
                    mainSeed?.spat_size_max,
                    'mm',
                  )}
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Avg. Size')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  {mainSeed
                    ? `${
                        lastAssessment?.shell_size.avg ??
                        averageValue(
                          mainSeed?.spat_size,
                          mainSeed?.spat_size_max,
                        )
                      } mm`
                    : '-'}
                </Subtitle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='white-card pt-16 pl-12 pb-16 pr-12 mb-8'>
        <div className='d-flex justify-content-between'>
          <div className='width-100 d-flex justify-content-between align-items-center  white-card-small pt-3 pb-3'>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='mr-26'
              name='prev'
              onClick={onClickPrevGroup}
              disabled={!isPrevEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='left' />
            </Button>
            <Subtitle
              size={4}
              color='black'
              align='left'
              fontWeight={500}
              disabled={!mainSeed}
            >
              {mainSeed?.season_name ?? translate(lang, 'Empty')}
            </Subtitle>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='ml-26'
              name='next'
              onClick={onClickNextGroup}
              disabled={!isNextEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='right' />
            </Button>
          </div>
          <Button
            color='blue'
            size={0}
            width='default'
            type='bordered'
            className='ml-12'
            iconOnly
            onClick={showEditSeedModal}
            disabled={!isEditable || !mainSeed?.is_growing}
          >
            <Pen />
          </Button>
        </div>
        <div className='pt-16'>
          <>
            {mainSeed ? (
              <div className='d-flex flex-direction-col'>
                {mainSeed.is_growing ? (
                  <>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onAssessmentClick}
                      disabled={!isEditable}
                    >
                      {translate(lang, 'Add assessment')}
                    </Button>
                    {!mainSeed.is_catch_spat && (
                      <Button
                        className='mt-8'
                        color='blue'
                        size={1}
                        width='wide'
                        type='fill'
                        onClick={onExtraSeedClick}
                        disabled={!isEditable}
                      >
                        {translate(lang, 'Add extra seed')}
                      </Button>
                    )}
                    <Button
                      className='mt-8'
                      color='green'
                      size={1}
                      width='wide'
                      type='fill'
                      onClick={onMaintenanceClick}
                      disabled={!isEditable}
                    >
                      {translate(lang, 'Add maintenance')}
                    </Button>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='bordered'
                      className='mt-8'
                      onClick={onHarvestClick}
                      disabled={!isEditable}
                    >
                      {translate(lang, 'Harvest Complete')}
                    </Button>
                  </>
                ) : (
                  <span></span>
                )}
              </div>
            ) : (
              <>
                {!isGrower && (
                  <Button
                    color='blue'
                    size={1}
                    width='wide'
                    type='fill'
                    onClick={onCatchSpatClick}
                    disabled={!isEditable}
                  >
                    {translate(lang, 'Catch Spat')}
                  </Button>
                )}
                <Button
                  className='mt-10'
                  color='blue'
                  size={1}
                  width='wide'
                  type='fill'
                  onClick={onSeedingClick}
                  disabled={!isEditable}
                >
                  {translate(lang, 'Seed the line')}
                </Button>
                <div className='d-flex justify-content-center align-items-center mt-10'>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line empty for')}
                  </Subtitle>
                  <Subtitle
                    size={5}
                    color='black'
                    align='left'
                    fontWeight={600}
                    className='ml-4 mr-27'
                  >
                    {amountDays(lineData?.line_idle)}
                  </Subtitle>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default SeaweedLineMobile;

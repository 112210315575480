import { useSelector } from 'react-redux';
import {
  CloseIcon,
  Datepicker,
  Dropdown,
  Subtitle,
} from '../../components/shared';
import { selectInventories } from '../../store/inventories/inventories.selector';
import { Alert, Button, Modal, Table, Typography, message } from 'antd';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { useHistory } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { formatNumber } from '../../entities/util-functions';
import { selectProfile } from '../../store/auth/auth.selector';
import moment from 'moment';
import { selectBoats } from '../../store/users/users.selector';
import { ITaskRequest } from '../../entities/task.entities';
import { sendSingleRequest } from '../../apis';
import { selectLang } from '../../store/ui/ui.selector';
import { componentTranslate, translate } from '../../lib/lang.helper';

interface ILineFloat {
  line_id: number;
  line_name: string;
  last_float_date: number | null;
  estimate_weight: number;
  used_floats: Array<{ id: number; quantity: number }>;
  suggest_floats: Array<{ id: number; quantity: number }>;
}

interface IFloatFarm {
  farm_id: number;
  farm_name: string;
  unit_weight: number;
  unit_size: number;
  lines: ILineFloat[];
}

interface ILastFloating {
  inventory_id: number;
  quantity: number;
  status: 'IN' | 'OUT';
  manage_date: number;
  farm_id: number;
  farm_name: string;
  line_id: number;
  line_name: string;
}

interface IBaseData {
  farms: IFloatFarm[];
  last_floating: ILastFloating | null;
  tasks: Array<{ id: number; farm_id: number; line_id: number }>;
}

interface Props {
  baseData: IBaseData;
  loadData: () => void;
}

const FloatingSuggest = ({ baseData, loadData }: Props) => {
  const profile = useSelector(selectProfile);
  const inventories = useSelector(selectInventories);
  const boats = useSelector(selectBoats);
  const lang = useSelector(selectLang);

  const history = useHistory();
  const { farms, last_floating, tasks } = baseData;

  const [curFarm, setCurFarm] = useState(
    farms.length > 0 ? farms[0].farm_id : null,
  );
  const [accept, setAccept] = useState<ILineFloat>();
  const [boatId, setBoatId] = useState(profile?.boat_id ?? undefined);
  const [dueDate, setDueDate] = useState(moment().toDate().getTime());
  const [disabled, setDisabled] = useState(false);

  const goToLine = (lineID: number) =>
    history.push(`/farms/${curFarm}/${lineID}`);

  const columns = useMemo(
    () => [
      {
        title: translate(lang, 'Line'),
        key: 'line_id',
        render: (x: ILineFloat) => <span>{x.line_name}</span>,
      },
      {
        title: translate(lang, 'Last floating'),
        key: 'last_float_date',
        render: (x: ILineFloat) => (
          <span>{defaultDateFormat(x.last_float_date, '-')}</span>
        ),
      },
      {
        title: translate(lang, 'Current floats'),
        key: 'used_floats',
        render: (x: ILineFloat) => (
          <div>
            {x.used_floats.map(y => (
              <div key={y.id}>{`${y.quantity} of ${
                inventories.find(t => t.id === y.id)?.subtype
              }`}</div>
            ))}
          </div>
        ),
      },
      {
        title: translate(lang, 'Current estimated weight'),
        key: 'estimate_weight',
        render: (x: ILineFloat) => (
          <span>{`${formatNumber(x.estimate_weight)}`}</span>
        ),
      },
      {
        title: translate(lang, 'Suggested floats'),
        key: 'suggest_floats',
        render: (x: ILineFloat) => (
          <div>
            {x.suggest_floats.map(f => (
              <div key={f.id}>{`${f.quantity < 0 ? 'Remove' : 'Add'} ${Math.abs(
                f.quantity,
              )} ${inventories.find(i => i.id === f.id)?.subtype}`}</div>
            ))}
          </div>
        ),
      },
      {
        title: translate(lang, 'Action'),
        key: 'farm_id',
        render: (x: ILineFloat) =>
          x.suggest_floats.length > 0 && (
            <Button
              size='small'
              type='primary'
              onClick={e => {
                e.stopPropagation();
                setAccept(x);
              }}
              disabled={tasks.some(t => t.line_id === x.line_id)}
            >
              {translate(
                lang,
                tasks.some(t => t.line_id === x.line_id)
                  ? 'Floating task is created'
                  : 'Create a floating task',
              )}
            </Button>
          ),
      },
    ],
    [inventories, tasks],
  );
  const acceptClick = async () => {
    if (!accept) {
      message.error(translate(lang, '_no_suggested'));
      return;
    }
    if (!boatId) {
      message.error(translate(lang, 'Please select a boat'));
      return;
    }
    if (!curFarm) {
      message.error(translate(lang, 'No suggested farm'));
      return;
    }
    const farm = farms.find(x => x.farm_id === curFarm);
    setDisabled(true);
    const form: ITaskRequest = {
      farm_id: curFarm,
      line_id: accept.line_id,
      title: `Manage floats on farm ${farm?.farm_name} line ${accept.line_name}`,
      content: 'Please manage floats on this line correctly',
      due_date: dueDate,
      assigned_to: 0,
      boat_id: boatId,
      type: 'INVENTORY_SEED',
    };
    const res = await sendSingleRequest(form, 'POST', 'api/task/tasks', true);
    if (res.status) {
      message.success(
        translate(lang, 'Task %s has been created successfully', form.title),
      );
    } else {
      message.error(translate(lang, res.data?.message ?? 'Server error'));
    }
    setDisabled(false);
    setAccept(undefined);
    await loadData();
  };

  return (
    <div id='float-suggest' className='pt-28 pb-28'>
      <div className='mb-18'>
        <Subtitle size={1} color='black' align='left' fontWeight={600}>
          {translate(lang, 'Floating suggestions')}
        </Subtitle>
      </div>
      <div className='ml-17'>
        <div className='suggest-sentence'>
          {!!last_floating ? (
            <span>
              {componentTranslate(
                lang,
                'Last floating was managed on farm %s line %s on day %s',
                <Typography.Text mark key={1}>
                  {last_floating.farm_name}
                </Typography.Text>,
                <Typography.Text mark key={2}>
                  {last_floating.line_name}
                </Typography.Text>,
                <Typography.Text mark key={3}>
                  {defaultDateFormat(last_floating.manage_date)}
                </Typography.Text>,
              )}
            </span>
          ) : (
            <span>{translate(lang, 'No floating managed')}</span>
          )}
        </div>
        <div className='suggest-sentence'>
          <Alert
            className='suggest-warning'
            message={translate(lang, '_suggest_warning')}
            type='warning'
            showIcon
          />
        </div>
      </div>
      {farms && (
        <div className='mt-18 mb-18'>
          <div className='w-100' style={{ width: 300 }}>
            <Dropdown
              label=''
              placeholder={translate(lang, 'Filter by farm')}
              options={farms.map(x => ({
                id: x.farm_id.toString(),
                value: x.farm_id.toString(),
                label: x.farm_name,
              }))}
              value={curFarm?.toString()}
              onChange={v => setCurFarm(Number(v))}
            />
          </div>
          <div className='w-100' style={{ overflow: 'auto' }}>
            <Table
              rowKey='line_id'
              className='table table--isFarm'
              pagination={false}
              columns={columns}
              dataSource={farms.find(x => x.farm_id === curFarm)?.lines}
              onRow={r => ({
                onClick: () => goToLine(r.line_id),
              })}
            />
          </div>
        </div>
      )}
      {accept && (
        <Modal
          visible={true}
          onCancel={() => setAccept(undefined)}
          closable
          closeIcon={<CloseIcon />}
          footer={null}
        >
          <div className='wrap'>
            <div className='mt-28 mb-28'>
              <Dropdown
                label={translate(lang, '_suggest_boat_select_q')}
                options={boats.map(x => ({
                  id: x.id.toString(),
                  value: x.id.toString(),
                  label: `${x.name} (${x.reg_number})`,
                }))}
                value={boatId?.toString()}
                onChange={v => setBoatId(Number(v))}
              />
            </div>
            <div className='mt-28 mb-28'>
              <Datepicker
                label={translate(lang, '_suggest_date_q')}
                defaultValue={dueDate}
                onChange={e => e && setDueDate(e.toDate().getTime())}
                required
              />
            </div>
            <div className='mt-32 d-flex justify-content-end'>
              <Button
                size='large'
                disabled={disabled}
                onClick={acceptClick}
                type='primary'
              >
                {translate(lang, 'Convert to task')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FloatingSuggest;

import { FC, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { showFeedback } from '../../../store/ui/ui.actions';
import Subtitle from '../subtitle/Subtitle';
import LineFormModal from '../../farm-modals/LineFormModal';
import {
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
  ISeaweedLineResource,
} from '../../../entities/farms.entities';
import DotsIcon from '../DotsIcon';
import { TBusinessType } from '../../../entities/general.entities';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import { amountDays } from '../../../lib/farm.helpers';
import { lineSizeLabel } from '../../../util/farmUtil';
import { selectProfile } from '../../../store/auth/auth.selector';
import { sendSingleRequest } from '../../../apis';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import './styles.scss';

const MusselLine: FC<{ line: IMusselLineResource }> = ({ line }) => (
  <div className='flex-basis-50'>
    <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
      Length
    </Subtitle>
    <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
      <span className='pr-6'>
        {line.growing_cycle ? line.growing_cycle.total_seeded_length : 0}
      </span>
      <span>m</span>
    </Subtitle>
  </div>
);

const OysterLine: FC<{ line: IOysterLineResource }> = ({ line }) => (
  <div className='flex-basis-50'>
    <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
      Number of baskets
    </Subtitle>
    <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
      <span className='pr-6'>
        {line.growing_cycle ? line.growing_cycle.total_seeded_basket_count : 0}
      </span>
      <span></span>
    </Subtitle>
  </div>
);

const SeaweedLine: FC<{ line: ISeaweedLineResource }> = ({ line }) => (
  <div className='flex-basis-50'>
    <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
      Tank area
    </Subtitle>
    <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
      <span className='pr-6'>
        {line.growing_cycle ? line.growing_cycle.total_seeded_tank_area : 0}
      </span>
      <span>m²</span>
    </Subtitle>
  </div>
);

interface Props {
  data: ILineResource[];
  type: TBusinessType;
  isEditable: boolean;
}

const LinesMobile: FC<Props> = memo(
  ({ data, type: businessType, isEditable }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const lang = useSelector(selectLang);
    const profile = useSelector(selectProfile);

    const [editData, setEditData] = useState<ILineResource>();

    const menuClickHandler = async (e: any, line: ILineResource) => {
      if (e.key === 'edit') {
        setEditData(line);
      } else if (e.key === 'delete') {
        if (!window.confirm('Are you sure to delete this line')) return;
        const res = await sendSingleRequest(
          {},
          'DELETE',
          `api/farm/line/lines/${line.id}`,
          true,
        );
        if (res.status) {
          await dispatch(loadFarmsData());
          dispatch(
            showFeedback({
              isMessage: true,
              type: 'success',
              message: 'Line deleted successfully',
            }),
          );
        } else {
          dispatch(
            showFeedback({
              isMessage: true,
              type: 'error',
              message: res.data?.message ?? 'Unknown error',
            }),
          );
        }
      } else {
        history.push(`/farms/${line.farm_id}/${line.id}`);
      }
    };

    return (
      <>
        <div className='table-mobile'>
          {data.map(line => (
            <div
              key={line.id}
              className={'table-mobile__card table-mobile__card--cursor'}
              onKeyDown={() => undefined}
              onClick={() => history.push(`/farms/${line.farm_id}/${line.id}`)}
            >
              <div className={'table-mobile__card-dots'}>
                {isEditable && (
                  <div className={'wwrap'}>
                    <div
                      className='dropdown'
                      onClick={e => e.stopPropagation()}
                    >
                      <Dropdown
                        overlay={
                          <Menu onClick={e => menuClickHandler(e, line)}>
                            <Menu.Item key='info'>
                              {translate(lang, 'Info')}
                            </Menu.Item>
                            {!!profile?.edit_permission && (
                              <>
                                <Menu.Item key='edit'>
                                  {translate(lang, 'Edit')}
                                </Menu.Item>
                                <Menu.Item key='delete'>
                                  {translate(lang, 'Delete')}
                                </Menu.Item>
                              </>
                            )}
                          </Menu>
                        }
                        placement='bottomRight'
                        trigger={['click']}
                      >
                        <div>
                          <DotsIcon />
                        </div>
                      </Dropdown>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex justify-content-between align-items-center'>
                <Subtitle
                  size={5}
                  color='black-3'
                  align='left'
                  fontWeight={600}
                >
                  {translate(lang, 'Line - %s', line.line_name)}
                </Subtitle>
              </div>
              <div className='pt-16'>
                <div className='d-flex pb-23'>
                  {businessType === 'MUSSEL' ? (
                    <MusselLine line={line as IMusselLineResource} />
                  ) : businessType === 'OYSTER' ? (
                    <OysterLine line={line as IOysterLineResource} />
                  ) : (
                    <SeaweedLine line={line as ISeaweedLineResource} />
                  )}
                  <div className='flex-basis-50 ml-24'>
                    <Subtitle
                      size={3}
                      color='black-2'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'Date seed')}
                    </Subtitle>
                    <>
                      {line.growing_cycle ? (
                        <Subtitle
                          size={5}
                          color='black-5'
                          align='left'
                          fontWeight={400}
                        >
                          {defaultDateFormat(
                            line.growing_cycle.main_seed.planned_date_seed,
                          )}
                        </Subtitle>
                      ) : (
                        <div className='d-flex flex-wrap'>
                          <Subtitle
                            size={5}
                            color='black-5'
                            align='left'
                            fontWeight={400}
                            className='pr-4'
                          >
                            {translate(lang, 'Line empty')}
                          </Subtitle>
                          <Subtitle
                            size={5}
                            color='black-5'
                            align='left'
                            fontWeight={600}
                          >
                            {amountDays(line.line_idle)}
                          </Subtitle>
                        </div>
                      )}
                    </>
                  </div>
                </div>
                <div className='d-flex pb-23'>
                  <div className='flex-basis-50'>
                    <Subtitle
                      size={3}
                      color='black-2'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'Planned harvest date')}
                    </Subtitle>
                    <Subtitle
                      size={5}
                      color='black-5'
                      align='left'
                      fontWeight={400}
                    >
                      {defaultDateFormat(
                        line.growing_cycle?.last_assessment
                          ?.planned_date_harvest ??
                          line.growing_cycle?.main_seed.planned_date_harvest,
                        '-',
                      )}
                    </Subtitle>
                  </div>
                  <div className='flex-basis-50 ml-24'>
                    <Subtitle
                      size={3}
                      color='black-2'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'Seed type')}
                    </Subtitle>
                    <Subtitle
                      size={5}
                      color='black-5'
                      align='left'
                      fontWeight={400}
                    >
                      {line.growing_cycle?.main_seed.spat_storage?.seed_type ??
                        '-'}
                    </Subtitle>
                  </div>
                </div>
                <div className='d-flex pb-23'>
                  <div className='flex-basis-50'>
                    <Subtitle
                      size={3}
                      color='black-2'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'Income per meter')}
                    </Subtitle>
                    <Subtitle
                      size={5}
                      color='black-5'
                      align='left'
                      fontWeight={400}
                    >
                      {(line as any)?.last_harvest?.profit_per_meter ?? '-'}
                    </Subtitle>
                  </div>
                  <div className='flex-basis-50 ml-24'>
                    <Subtitle
                      size={3}
                      color='black-2'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'Avg. size')}
                    </Subtitle>
                    <Subtitle
                      size={5}
                      color='black-5'
                      align='left'
                      fontWeight={400}
                    >
                      {lineSizeLabel(line)}
                    </Subtitle>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {data.length <= 0 && (
            <div className='table-mobile__not-data'>
              <Subtitle size={4} color='black-5' align='left' fontWeight={400}>
                {translate(lang, 'No data available')}
              </Subtitle>
            </div>
          )}
        </div>
        {!!editData && (
          <LineFormModal
            visible={true}
            title={translate(lang, 'Edit line details')}
            lineData={editData}
            onClose={() => setEditData(undefined)}
            type={businessType}
          />
        )}
      </>
    );
  },
);

export default LinesMobile;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
  CloseIcon,
  Button,
} from '../shared';
import { createTask, updateTask } from '../../store/tasks/tasks.actions';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../shared/input/TimeTrackInput';
import { Modal } from 'antd';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { showFeedback } from '../../store/ui/ui.actions';
import { IFarmResource, ILineResource } from '../../entities/farms.entities';
import { IBoatResource } from '../../entities/boat.entities';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectBoats } from '../../store/users/users.selector';
import { ITimeRange } from '../../entities/general.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectCustomTasks } from '../../store/tasks/tasks.selector';
import { getTaskOptions } from '../../lib/task.helper';
import './styles.scss';

const todayStr = moment().format('YYYY-MM-DD');

interface IFormData {
  farm_id: number | null;
  line_id: number | null;
  title: string;
  content: string | null;
  due_date: number;
  assigned_to?: number | null;
  is_completed: boolean;
  type: string | null;
  boat_id?: number | null;
  time_tracks?: ITimeRange[] | null;
}

const defaultForm: IFormData = {
  farm_id: null,
  line_id: null,
  title: '',
  content: null,
  due_date: moment().toDate().getTime(),
  is_completed: false,
  type: null,
};

interface Props {
  title: string;
  visible: boolean;
  data?: any;
  updateId?: number;
  viewOnly?: boolean;
  onCancel: () => void;
  onConfirm: (d: any) => void;
}

const ModalTask = ({
  visible,
  title,
  data,
  updateId,
  viewOnly,
  onCancel,
  onConfirm,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);
  const profile = useSelector(selectProfile);
  const boats = useSelector(selectBoats);
  const customTasks = useSelector(selectCustomTasks);

  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState<IFormData>({
    ...defaultForm,
    boat_id: profile?.boat_id,
  });

  const [curFarm, setCurFarm] = useState<IFarmResource>();
  const [curLine, setCurLine] = useState<ILineResource>();
  const [curBoat, setCurBoat] = useState<IBoatResource | undefined>(
    profile?.current_boat ?? undefined,
  );

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );
  const handleOnSelectFarm = (val: string) => {
    const v = Number(val);
    let tmp: any = { farm_id: v };

    if (v <= 0) tmp.type = 'GENERAL';

    setFormData(prv => ({ ...prv, ...tmp }));
    setCurFarm(farmsData.find(x => x.id === v));
  };
  const handleOnSelectLine = (val: string) => {
    const v = Number(val);
    let tmp: any = {};
    const line = curFarm?.lines.find(x => x.id === v);
    tmp.line_id = line ? line.id : null;

    if (!line) tmp.type = 'GENERAL';

    setCurLine(line);
    setFormData(prv => ({ ...prv, ...tmp }));
  };
  const handleSelectBoat = (val: string) => {
    const v = Number(val);
    const b = boats.find(x => x.id === v);
    setCurBoat(b);
    setFormData(prv => ({ ...prv, boat_id: b ? b.id : null }));
  };
  const updateForm = (key: keyof IFormData, val: any) =>
    setFormData(prv => ({ ...prv, [key]: val }));

  const handleOnConfirm = async () => {
    if (formData.title.length <= 0) {
      showError('Please fill task title');
      return;
    }
    if (!curBoat) {
      showError('Boat is not selected');
      return;
    }
    setDisabled(true);
    let tmp = { ...formData };
    if (tmp.time_tracks && tmp.time_tracks.length > 0) {
      const tt = toSecTimeTracks(tmp.time_tracks);
      if (!tt) {
        alert(translate(lang, '_invalid_time_range'));
        return;
      }
      tmp.time_tracks = tt;
    }
    if (!updateId) {
      await dispatch(createTask(tmp as any));
    } else {
      await dispatch(updateTask({ ...tmp, id: updateId } as any, history));
    }
    onConfirm(tmp);
  };

  useEffect(() => {
    if (data) {
      let tmp = { ...data };
      if (tmp.time_tracks && tmp.time_tracks.length > 0) {
        tmp.time_tracks = tmp.time_tracks.map((x: any) => ({
          start_time: x.start_time * 1000,
          finish_time: x.finish_time * 1000,
        }));
      }
      setFormData(prv => ({ ...prv, ...tmp }));

      const farm = farmsData.find(x => x.id === data?.farm_id);
      const line = farm?.lines.find(x => x.id === data?.line_id);
      setCurFarm(farm);
      setCurLine(line);
      if (tmp.boat_id !== undefined) {
        setCurBoat(boats.find(x => x.id === data?.boat_id));
      }
    }
  }, [data]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <ModalFeedbackView />
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        {todayStr > moment(formData.due_date).format('YYYY-MM-DD') ? (
          <Subtitle color='red' align='center' size={0} fontWeight={400}>
            {translate(
              lang,
              viewOnly ? 'Past task' : 'You are adding a task in the past',
            )}
          </Subtitle>
        ) : todayStr < moment(formData.due_date).format('YYYY-MM-DD') ? (
          <Subtitle color='green' align='center' size={0} fontWeight={400}>
            {translate(lang, 'Scheduling task')}
          </Subtitle>
        ) : (
          <></>
        )}
        <Datepicker
          label={translate(lang, 'Due Date')}
          className='mb-24'
          required
          disabled={viewOnly}
          defaultValue={Number(formData.due_date)}
          onChange={e =>
            !!e &&
            setFormData(prv => ({ ...prv, due_date: e.toDate().getTime() }))
          }
        />
        <Input
          label={translate(lang, 'Task title')}
          type='text'
          onChange={e => updateForm('title', e.target.value)}
          className='mb-16 w-100'
          value={formData.title}
          disabled={viewOnly}
        />
        <Input
          label={translate(lang, 'Task content')}
          type='textarea'
          onChange={e => updateForm('content', e.target.value)}
          className='mb-16 w-100'
          value={formData.content ?? ''}
          disabled={viewOnly}
        />
        <Dropdown
          className='mb-16'
          label={translate(lang, viewOnly ? 'Selected Farm' : 'Select farm')}
          placeholder={`-- ${translate(lang, 'No Farm')} --`}
          disabled={viewOnly}
          value={curFarm?.id.toString()}
          onChange={v => handleOnSelectFarm(v)}
          options={farmsData.map(x => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: x.name,
          }))}
        />
        <Dropdown
          label={translate(lang, viewOnly ? 'Selected Line' : 'Select line')}
          placeholder={`-- ${translate(lang, 'No Line')} --`}
          className='mb-16'
          disabled={viewOnly}
          value={
            curFarm?.lines.some(x => x.id === curLine?.id)
              ? curLine?.id.toString()
              : undefined
          }
          onChange={v => handleOnSelectLine(v)}
          options={
            curFarm?.lines.map(x => ({
              id: x.id.toString(),
              value: x.id.toString(),
              label: x.line_name,
            })) ?? []
          }
        />
        <Dropdown
          label={translate(lang, 'Type')}
          placeholder='-- General --'
          disabled={!curFarm || !curLine}
          className='mb-16'
          value={formData.type ?? undefined}
          options={getTaskOptions(lang, customTasks)}
          onChange={v => updateForm('type', v)}
        />
        {(profile?.role === 'owner' || profile?.role === 'admin') &&
          boats !== undefined && (
            <Dropdown
              label={translate(
                lang,
                viewOnly
                  ? 'Selected boat responsible'
                  : 'Select boat responsible',
              )}
              className='mb-16'
              placeholder={`-- ${translate(lang, 'No Boat')} --`}
              disabled={viewOnly}
              value={curBoat?.id.toString()}
              onChange={v => handleSelectBoat(v)}
              options={boats.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: `${x.name} (${x.reg_number})`,
              }))}
            />
          )}
        <TimeTrackInput
          data={formData.time_tracks ?? []}
          onChange={t => updateForm('time_tracks', t)}
          disabled={viewOnly}
        />
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='ml-16'
          onClick={handleOnConfirm}
          disabled={disabled || viewOnly}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalTask;

import { FC, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CheckboxButton,
  Dropdown,
  ModalComponent,
  PlusIcon,
  Search,
  SearchBigIcon,
  SortIcon,
  Spinner,
  TagComponent,
  Title,
} from '../../components/shared';
import {
  activateUser,
  deactivateUser,
  getAllUsers,
} from '../../store/users/users.actions';
import { useWidth } from '../../util/useWidth';
import UserCard from '../../components/users/UserCard';
import { Modal, Table } from 'antd';
import { sendSingleRequest } from '../../apis';
import UserPermissionsModal from '../../components/users/UserPermissionsModal';
import { IUserResource } from '../../entities/user.entities';
import UpdateUserModal from '../../components/users/UpdateUserModal';
import InvitedUsers from './InvitedUsers';
import { checkRolePermission } from '../../entities/util-functions';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectUsers } from '../../store/users/users.selector';
import { QRCode } from 'react-qrcode-logo';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

interface IQRToken {
  id: number;
  token: string;
}

const QRImageModal: FC<{
  qrCode: IQRToken;
  onClose: () => void;
  lang: TLang | undefined;
}> = ({ qrCode, onClose, lang }) => {
  const copyQRImage = () => {
    const canvas: any = document.getElementById('login-qr-canvas');
    canvas?.toBlob(
      async (blob: any) => {
        try {
          await navigator.clipboard.write([
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ]);
        } catch (error) {
          window.alert(error);
        }
      },
      'image/png',
      1,
    );
  };

  return (
    <Modal
      title={`Login QR Code`}
      onCancel={onClose}
      centered={true}
      footer={null}
      visible={true}
      width={380}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <QRCode
          id='login-qr-canvas'
          value={JSON.stringify(qrCode)}
          size={300}
        />
        <div style={{ marginTop: 20 }}>
          <Button
            size={3}
            type='fill'
            color='green'
            width='large'
            onClick={copyQRImage}
          >
            {translate(lang, 'Copy QR Image')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ViewQRButton = ({ onClick }: { onClick: () => void }) => {
  const [disabled, setDisabled] = useState(false);

  const handleClick = async () => {
    setDisabled(true);
    await onClick();
    setDisabled(false);
  };

  return (
    <Button
      size={0}
      color='green'
      type='fill'
      width='small'
      disabled={disabled}
      onClick={handleClick}
    >
      View Login
    </Button>
  );
};

const deleteUser = async (data: any) => {
  const res = await sendSingleRequest(
    data,
    'POST',
    'api/user/destroy-user',
    true,
  );
  if (res.status) {
    return true;
  } else {
    return res.data?.message ?? 'Failed to delete';
  }
};

const defaultColumns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    className: 'user-status',
    render: (status: string) => {
      return (
        <div>
          {status === 'active' && (
            <TagComponent color='green'>{status}</TagComponent>
          )}
          {status === 'pending' && (
            <TagComponent color='orange'>{status}</TagComponent>
          )}
          {status === 'deactivated' && (
            <TagComponent color='gray'>{status}</TagComponent>
          )}
        </div>
      );
    },
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    render: (x: any) => <span>{roles.find(y => y.id === x)?.label}</span>,
  },
];

const roles = [
  { value: '3', label: 'All', id: '' },
  { value: '0', label: 'Admin', id: 'admin' },
  { value: '1', label: 'User', id: 'user' },
  { value: '2', label: 'Owner', id: 'owner' },
  { value: '4', label: 'Processing plant', id: 'processing_plant' },
];

const status = [
  { value: '', label: 'All', id: '' },
  { value: 'active', label: 'Active', id: 'active' },
  { value: 'pending', label: 'Pending', id: 'pending' },
  { value: 'deactivated', label: 'Deactivated', id: 'deactivated' },
];

const Users = () => {
  const dispatch = useDispatch<any>();
  const width = useWidth();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const usersStore = useSelector(selectUsers);
  const isEditable = profile?.edit_permission;

  const [value, setValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [roleSelected, setRoleSelected] = useState();
  const [statusSelected, setStatusSelected] = useState<string>();
  const [users, setUsers] = useState<IUserResource[]>([]);
  const [delUser, setDelUser] = useState<any>();
  const [isDeactivate, setIsDeactivate] = useState(false);
  const [isActivate, setIsActivate] = useState(false);
  const [deactivateId, setDeactivateId] = useState('');
  const [isSpinner, setIsSpinner] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUserResource>();
  const [ownerSelUser, setOwnerSelUser] = useState<IUserResource>();
  const [userQR, setUserQR] = useState<IQRToken>();

  const handleFilter = () => {
    setIsSpinner(true);
    let defaultUser = usersStore;
    if (statusSelected) {
      defaultUser = defaultUser.filter(user => user.status === statusSelected);
    }
    if (roleSelected) {
      defaultUser = defaultUser.filter(user => user.role === roleSelected);
    }
    if (value) {
      defaultUser = defaultUser.filter(
        user => user.email?.includes(value) || user.name?.includes(value),
      );
    }
    setUsers(defaultUser);
    setIsSpinner(false);
  };

  const handleOnStatus = (select: string) => {
    setStatusSelected(select);
  };
  const handleOnRole = (event: any) => {
    setRoleSelected(event.key);
  };
  const handleOnActiveSearch = () => {
    setIsSearch(!isSearch);
    if (isSort) {
      setIsSort(false);
    }
  };
  const handleOnActiveSort = () => {
    setIsSort(!isSort);
    if (isSearch) {
      setIsSearch(false);
    }
  };
  const handleOnDelete = (data: IUserResource) => {
    setDelUser(
      data?.status !== 'pending'
        ? { user_id: Number(data.id) }
        : { email: data.email },
    );
  };
  const handleConfirmDelete = async () => {
    if (!delUser) return;
    const r = await deleteUser(delUser);
    if (r !== true) {
      alert(r);
    } else {
      setDelUser(undefined);
      await dispatch(getAllUsers());
    }
  };
  const handleOnDeactivate = (data: IUserResource) => {
    setDeactivateId(data.id.toString());
    setIsDeactivate(true);
  };
  const handleConfirmDeactivate = async () => {
    if (isDeactivate) {
      await dispatch(deactivateUser(Number(deactivateId), history));
      setIsDeactivate(false);
    }
    if (isActivate) {
      await dispatch(activateUser(deactivateId, history));
      setIsActivate(false);
    }
  };
  const handleOnActivate = (data: IUserResource) => {
    setDeactivateId(data.id.toString());
    setIsActivate(true);
  };
  const toggleUserVerified = async (
    user_id: string | undefined,
    is_verified: boolean,
  ) => {
    const res = await sendSingleRequest(
      { user_id, is_verified },
      'PUT',
      'api/user/user-verify',
      true,
    );
    if (res.status) {
      setUsers(prv =>
        prv.map(x =>
          x?.id.toString() === user_id ? { ...x, is_verified } : x,
        ),
      );
    }
  };
  const loginQRGenerate = async (user: IUserResource) => {
    const res = await sendSingleRequest(
      {},
      'GET',
      `api/user/onetime-token/${user.id}`,
      true,
    );
    if (res.status) {
      setUserQR(res.data);
    } else {
      window.alert(res.data?.message ?? 'Failed to generate QR code');
    }
  };

  const columns = useMemo(() => {
    let cols = [...defaultColumns];
    if (isEditable) {
      cols.push({
        title: 'Data validation required',
        key: 'is_verified',
        dataIndex: '',
        render: (u: IUserResource) => (
          <CheckboxButton
            label={translate(lang, u.is_verified ? 'Not required' : 'Required')}
            className={u.is_verified ? 'blue' : 'red'}
            checked={!u.is_verified}
            onChange={e =>
              toggleUserVerified(u.id.toString(), !e.target.checked)
            }
          />
        ),
      });
    }
    if (profile?.role !== 'user') {
      cols.push({
        title: 'Permissions',
        key: 'permissions',
        dataIndex: '',
        render: (u: IUserResource) =>
          u.role !== 'owner' ? (
            <Button
              size={0}
              width='small'
              type='fill'
              color='blue'
              onClick={() => setSelectedUser(u)}
            >
              {translate(lang, 'View')}
            </Button>
          ) : (
            <></>
          ),
      });
    }
    if (profile?.role === 'owner') {
      cols.push(
        {
          title: 'Password',
          key: 'update',
          dataIndex: '',
          render: (u: IUserResource) => (
            <Button
              size={0}
              color='blue'
              type='fill'
              width='small'
              onClick={() => setOwnerSelUser(u)}
            >
              {translate(lang, 'Change')}
            </Button>
          ),
        },
        {
          title: 'Action',
          key: 'action',
          dataIndex: '',
          render: (u: IUserResource) => (
            <>
              {checkRolePermission(
                {
                  allowedRoles: ['user', 'processing_plant'],
                },
                u.role,
              ) && (
                <Button
                  size={0}
                  color='red'
                  type='fill'
                  width='small'
                  onClick={() => handleOnDelete(u)}
                >
                  Delete
                </Button>
              )}
              {checkRolePermission({ allowedRoles: ['admin', 'owner'] }) && (
                <ViewQRButton onClick={() => loginQRGenerate(u)} />
              )}
            </>
          ),
        },
      );
    }
    cols.push({ title: '', key: '', dataIndex: '' });
    return cols.map(x => ({ ...x, title: translate(lang, x.title) }));
  }, [defaultColumns, profile]);

  useEffect(() => handleFilter(), [roleSelected, statusSelected]);
  useEffect(() => handleFilter(), [usersStore]);

  useEffect(() => {
    setIsSpinner(true);
    dispatch(getAllUsers()).then(() => setIsSpinner(false));
  }, [dispatch]);

  return (
    <>
      <div className='bg-secondary min-height'>
        <div className='container'>
          <div className='users d-flex justify-content-between align-items-center'>
            <Title size={5} color='black' align='default' fontWeight={700}>
              {translate(lang, 'Users')}
            </Title>
            {profile?.role !== 'user' && (
              <Link to='/users/add-user'>
                {width > 768 ? (
                  <Button color='blue' size={1} width='middle' type='fill'>
                    {translate(lang, 'Add new user')}
                  </Button>
                ) : (
                  <Button
                    color='blue'
                    size={0}
                    width='default'
                    type='fill'
                    iconOnly
                  >
                    <PlusIcon />
                  </Button>
                )}
              </Link>
            )}
          </div>
          {width < 769 ? (
            <>
              <div className='filter mobile-sort '>
                <span
                  className='filter__icon'
                  onClick={handleOnActiveSort}
                  onKeyDown={handleOnActiveSort}
                  role='button'
                  tabIndex={0}
                >
                  <SortIcon active={isSort} />
                </span>
                <span
                  className='filter__icon'
                  onClick={handleOnActiveSearch}
                  onKeyDown={handleOnActiveSearch}
                  role='button'
                  tabIndex={0}
                >
                  <SearchBigIcon active={isSearch} />
                </span>
              </div>
              {(isSearch || isSort) && (
                <div className='filter'>
                  {isSort && (
                    <div className='d-flex'>
                      <Dropdown
                        label=''
                        placeholder={translate(lang, 'Role')}
                        onChange={(select, event) => handleOnRole(event)}
                        options={roles}
                      />
                      <span className='horizontal-line' />
                      <Dropdown
                        label=''
                        placeholder={translate(lang, 'Status')}
                        onChange={select => handleOnStatus(select)}
                        options={status}
                      />
                    </div>
                  )}
                  {isSearch && (
                    <Search
                      onSearch={handleFilter}
                      onChange={e => setValue(e.target.value)}
                      value={value}
                    />
                  )}
                </div>
              )}
            </>
          ) : (
            <div className='filter'>
              <div className='d-flex'>
                <Dropdown
                  label=''
                  placeholder={translate(lang, 'Role')}
                  onChange={(select, event) => handleOnRole(event)}
                  options={roles}
                />
                <span className='horizontal-line' />
                <Dropdown
                  label=''
                  placeholder={translate(lang, 'Status')}
                  onChange={(select, event) => handleOnStatus(select)}
                  options={status}
                />
              </div>
              <Search
                onSearch={handleFilter}
                onChange={e => setValue(e.target.value)}
                value={value}
              />
            </div>
          )}
          {isSpinner ? (
            <div className='mt-20'>
              <Spinner />
            </div>
          ) : (
            <>
              {width > 768 ? (
                <div className='users__content'>
                  <Table
                    rowKey='id'
                    className='table table--isUsers'
                    pagination={false}
                    columns={columns}
                    dataSource={users}
                  />
                </div>
              ) : (
                <div className='d-flex flex-wrap mt-6'>
                  {users.map((user, index) => (
                    <UserCard
                      name={user.name}
                      email={user.email}
                      key={index.toString()}
                      status={user.status}
                      role={user.role}
                      id={user.id.toString()}
                      onDeactivate={(data: any) => handleOnDeactivate(data)}
                      onDeleteRow={(data: any) => handleOnDelete(data)}
                      onActivateUser={(data: any) => handleOnActivate(data)}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </div>
        <InvitedUsers />
      </div>
      {!!delUser && (
        <ModalComponent
          visible={true}
          onCancel={() => setDelUser(undefined)}
          type='delete'
          title={translate(lang, 'Delete')}
          text={translate(lang, 'Are you sure to delete this user?')}
          onConfirm={() => handleConfirmDelete()}
        />
      )}
      <ModalComponent
        visible={isDeactivate || isActivate}
        onCancel={() => {
          if (isDeactivate) {
            setIsDeactivate(false);
          } else {
            setIsActivate(false);
          }
        }}
        type='confirm'
        title='Modal dialog'
        text='Are you sure to deactivate this user?'
        onConfirm={() => handleConfirmDeactivate()}
      />
      {!!selectedUser && (
        <UserPermissionsModal
          visible={true}
          title={translate(lang, 'Permissions')}
          onClose={() => setSelectedUser(undefined)}
          onConfirm={() => {
            setSelectedUser(undefined);
            dispatch(getAllUsers());
          }}
          data={selectedUser}
        />
      )}
      {!!ownerSelUser && (
        <UpdateUserModal
          visible={!!ownerSelUser}
          user={ownerSelUser}
          onClose={() => setOwnerSelUser(undefined)}
        />
      )}
      {!!userQR && (
        <QRImageModal
          qrCode={userQR}
          onClose={() => setUserQR(undefined)}
          lang={lang}
        />
      )}
    </>
  );
};

export default Users;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { updateUserMeta } from '../../store/auth/auth.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { Button, Dropdown, Input, Title } from '../shared';
import { selectUserMeta } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const SeedStagesSize = () => {
  const dispatch = useDispatch<any>();
  const userMeta = useSelector(selectUserMeta);
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [stages, setStages] = useState<{ size: number; time: number }[]>([]);

  const handleSelectStagesCount = (val: string) => {
    let l = Number(val);
    let s = [...stages];

    while (s.length < l) s.push({ size: 0, time: 0 });
    s = s.splice(0, l);

    setStages(s);
  };

  const handleValueChange = (
    idx: number,
    key: 'size' | 'time',
    val: string,
  ) => {
    let s = [...stages];
    if (idx >= s.length) return;
    s[idx][key] = Number(val);
    setStages(s);
  };

  const handleSubmitClick = () => {
    setDisabled(true);
    sendSingleRequest(
      { seed_stages_size: stages },
      'POST',
      'api/user-meta',
      true,
    ).then(r => {
      setDisabled(false);
      if (r.status) {
        dispatch(
          showFeedback({
            isMessage: true,
            message: translate(lang, 'Updated successfully'),
            type: 'success',
          }),
        );
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            message: translate(lang, r.data?.message ?? 'Unknown error'),
            type: 'error',
          }),
        );
      }
      dispatch(updateUserMeta());
    });
  };

  useEffect(() => {
    if (userMeta?.seed_stages_size && userMeta.seed_stages_size.length > 0) {
      setStages(userMeta.seed_stages_size);
    } else {
      setStages([{ size: 0, time: 0 }]);
    }
  }, [userMeta]);

  return (
    <div>
      <div className='d-flex mb-16 mt-27 align-items-center justify-content-between'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'How many stages of seeding you farm')}
        </Title>
      </div>
      <div className='ml-21'>
        <Dropdown
          label={translate(
            lang,
            'For instance if after initial seeding you reseed once you farm 2 stages',
          )}
          value={stages.length.toString()}
          options={['1', '2', '3', '4'].map(x => ({
            id: x,
            label: x,
            value: x,
          }))}
          onChange={handleSelectStagesCount.bind(this)}
        />
        <div className='mt-17'>
          {stages.map((item, idx) => (
            <div className='d-flex mt-17' key={idx}>
              <div className='mr-17'>
                <Input
                  type='number'
                  value={item.size.toString()}
                  label={translate(lang, 'Expected harvest size [mm]')}
                  onChange={e => handleValueChange(idx, 'size', e.target.value)}
                />
              </div>
              <div className='ml-17'>
                <Input
                  type='number'
                  value={item.time.toString()}
                  label={translate(lang, 'Growth time [months]')}
                  onChange={e => handleValueChange(idx, 'time', e.target.value)}
                />
              </div>
            </div>
          ))}
        </div>
        <Button
          className='mt-16 mb-16'
          type='fill'
          width='small'
          size={0}
          color='blue'
          onClick={handleSubmitClick}
          disabled={disabled}
        >
          {translate(lang, 'Update')}
        </Button>
      </div>
    </div>
  );
};

export default SeedStagesSize;

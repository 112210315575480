import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import {
  formatNumber,
  numberToMoneyStr,
} from '../../../entities/util-functions';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { defaultDateFormat } from '../../../util/toggleSecondMillisecond';
import { Button, CloseIcon, Input, Subtitle } from '../../shared';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { rangeLabel } from '../../../util/farmUtil';
import { ISeaweedHarvest } from '../../../entities/growing.entities';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import {
  SelectIsBudgetVisible,
  SelectSeaweedFarmsByFarmID,
} from '../../../store/extra/extra.selector';

interface Props {
  visible: boolean;
  harvest: ISeaweedHarvest;
  onClose: () => void;
}

const SeaweedHarvestViewModal = ({ visible, harvest, onClose }: Props) => {
  const farmsData = SelectSeaweedFarmsByFarmID(harvest.line.farm_id);
  const showBudget = SelectIsBudgetVisible(harvest.line.farm_id);
  const lang = useSelector(selectLang);

  const farm = farmsData.find(x => x.id === harvest.line.farm_id);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap view-modal'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Harvest Details')}:
          </Subtitle>
          <Subtitle
            className='ml-7'
            color='black-3'
            align='left'
            size={1}
            fontWeight={500}
          >
            {`${farm?.name} - ${harvest.line.line_name}`}
          </Subtitle>
        </div>
        <div className='pt-16 pb-24'>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Season')}</div>
              <div className='info-value'>{harvest.season_name}</div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Complete date')}
              </div>
              <div className='info-value'>
                {defaultDateFormat(harvest.complete_date)}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>{translate(lang, 'Final')}</div>
              <div className='info-value'>
                {translate(lang, !harvest.is_final ? 'Middle' : 'Final')}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>{translate(lang, 'Amount')}</div>
              <div className='info-value'>
                {`${formatNumber(harvest.amount)} kg`}
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <div className='info-card mr-17'>
              <div className='info-label'>F/P</div>
              <div className='info-value'>
                {translate(lang, !!harvest.is_full ? 'Full' : 'Partial')}
              </div>
            </div>
            <div className='info-card ml-17'>
              <div className='info-label'>
                {translate(lang, 'Harvested tank area')}
              </div>
              <div className='info-value'>{harvest.tank_area}</div>
            </div>
          </div>
          {!!showBudget && (
            <div className='d-flex'>
              <div className='info-card mr-17'>
                <div className='info-label'>{translate(lang, 'Income')}</div>
                <div className='info-value'>
                  {`$ ${numberToMoneyStr(harvest.income ?? 0)}`}
                </div>
              </div>
              <div className='info-card ml-17'>
                <div className='info-label'>{translate(lang, 'Expense')}</div>
                <div className='info-value'>
                  {`$ ${numberToMoneyStr(
                    harvest.expense_items?.reduce((p, c) => p + c.amount, 0) ??
                      0,
                  )}`}
                </div>
              </div>
            </div>
          )}
          <div className='info-card'>
            <div className='info-label'>{translate(lang, 'Shell length')}</div>
            <div className='info-value'>
              {rangeLabel(harvest.shell_length, harvest.shell_length_max)}
            </div>
          </div>
          <div className='mt-7 mb-7'>
            <Input
              label={translate(lang, 'Comment')}
              type='textarea'
              value={harvest.comment ?? ''}
              disabled={true}
            />
          </div>
          {!!harvest.images && harvest.images.length > 0 && (
            <div className='mt-7 mb-7'>
              <PicturesWall
                label={translate(lang, 'Images')}
                fileList={
                  harvest.images.map(x => parseImageUrlToFileItem(x)) as any
                }
                handleChangeImages={() => {}}
                disabled={true}
              />
            </div>
          )}
        </div>
        {!!harvest.signature && (
          <div className='pb-20'>
            <p className='mb-4 d-block paragrapgh paragrapgh--2 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
              {translate(lang, 'Signature')}
            </p>
            <div
              style={{
                border: '1px solid #EFEFEF',
                borderRadius: '4px',
              }}
            >
              <img
                style={{ width: '100%' }}
                src={harvest.signature}
                alt='signature'
              />
            </div>
          </div>
        )}
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SeaweedHarvestViewModal;

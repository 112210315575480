import { Link } from 'react-router-dom';
import { IMaintenanceResource } from '../../entities/farms.entities';
import { useSelector } from 'react-redux';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

const typeLabel: any = {
  VISUAL_ASSESSMENT: 'Visual Assessment',
  LINE_CLEANING: 'Line Cleaning',
  GENERAL_MAINTENANCE: 'General Maintenance',
};

interface Props {
  mntData: { line_id: number; data: IMaintenanceResource };
  onShowMaintenance: (d: any) => void;
}

const MaintenanceCell = ({ mntData, onShowMaintenance }: Props) => {
  const lang = useSelector(selectLang);
  const farm = useSelector(selectFarmsData).find(f =>
    f.lines.some(x => x.id === mntData.line_id),
  );
  const line = farm?.lines.find(x => mntData.line_id);
  const handleClick = (event: any) => {
    event.preventDefault();
    onShowMaintenance({ line: line, data: mntData.data });
  };

  return (
    <div className='--entity --maintenance' onClick={handleClick.bind(this)}>
      <div className='--header'>
        <span>{translate(lang, 'maintenance')}</span>
      </div>
      <div className='--title'>
        <div>{`${farm?.name} - ${line?.line_name}`}</div>
        <div className='mt-7'>
          {translate(
            lang,
            typeLabel[mntData.data.type] ?? 'General Maintenance',
          )}
        </div>
      </div>
      <div className='--btn-bar'>
        <Link to={`/farms/${farm?.id}/${line?.id}`} className='--view'>
          {translate(lang, 'View')}
        </Link>
      </div>
    </div>
  );
};

export default MaintenanceCell;

import {
  ILineResource,
  IMusselLineResource,
  IOysterLineResource,
} from '../../../entities/farms.entities';
import { TBusinessType } from '../../../entities/general.entities';
import {
  IHarvestResource,
  IMusselHarvest,
} from '../../../entities/growing.entities';
import MusselGrowerHarvestModal from './MusselGrowerHarvestModal';
import OysterGrowerHarvestModal from './OysterGrowerHarvestModal';
import '../styles.scss';

interface Props {
  type: TBusinessType;
  visible: boolean;
  lineData?: ILineResource;
  onClose: (d: any) => void; // only for MUSSEL
  onConfirm: (d: any) => void; // only for MUSSEL
  harvestData?: IHarvestResource; // only for MUSSEL
  updateId?: number; // only for MUSSEL
  paramData?: any; // only for OYSTER
  overConfirm?: (d: any) => void; // only for OYSTER
  confirmID?: number;
  className?: string; // only for MUSSEL
}

const GrowerHarvestModal = ({
  type,
  visible,
  lineData,
  onClose,
  onConfirm,
  harvestData,
  updateId,
  paramData,
  overConfirm,
  confirmID,
  className,
}: Props) => (
  <>
    {type === 'MUSSEL' ? (
      <MusselGrowerHarvestModal
        visible={visible}
        lineData={lineData as IMusselLineResource}
        onClose={onClose}
        onConfirm={onConfirm}
        harvestData={harvestData as IMusselHarvest}
        updateId={updateId}
        paramData={paramData}
        overConfirm={overConfirm}
        confirmID={confirmID}
        className={className}
      />
    ) : type === 'OYSTER' ? (
      <OysterGrowerHarvestModal
        visible={visible}
        lineData={lineData as IOysterLineResource}
      />
    ) : (
      <></>
    )}
  </>
);

export default GrowerHarvestModal;

import { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Dropdown,
  DropdownMenu,
  Input,
  Subtitle,
  Title,
} from '../../components/shared';
import { useDispatch, useSelector } from 'react-redux';
import { loadTags } from '../../store/automation/automation.actions';
import { selectTags } from '../../store/automation/automation.selector';
import { ITag } from '../../entities/automation.entities';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { isColorFormat } from '../../util/validation';
import { invertColor } from '../../util/farmUtil';
import { calcPeriodUnit, periodToStr } from '../../lib/common.helpers';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const periodOptions = [
  { id: 'm', value: 'month', label: 'Month(s)' },
  { id: 'w', value: 'week', label: 'Week(s)' },
  { id: 'd', value: 'day', label: 'Day(s)' },
  { id: 'h', value: 'hour', label: 'Hour(s)' },
];

interface IForm {
  name: string;
  color: string;
  lines?: number[];
  is_urgent: boolean;
}

interface ModalProps {
  tag?: ITag;
  onClose: () => void;
  editId?: number;
}

const TagModal = ({ tag, onClose, editId }: ModalProps) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);

  const linesOption = useSelector(selectFarmsData).reduce(
    (p, c) => [
      ...p,
      ...c.lines.map(x => ({
        id: x.id,
        value: x.id.toString(),
        label: `${c.name} - ${x.line_name}`,
      })),
    ],
    [] as any,
  );
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IForm>({
    name: tag?.name ?? '',
    color: tag?.color ?? '',
    lines: tag?.tag_lines.map(x => x.line_id) ?? [],
    is_urgent: tag?.is_urgent ?? false,
  });
  const [periodUnit, setPeriodUnit] = useState(
    tag?.period ? calcPeriodUnit(tag.period) : { unit: 'day', value: null },
  );

  const updateForm = (key: keyof IForm, value: any) => {
    setForm({ ...form, [key]: value });
  };
  const confirmClick = async () => {
    if (!form.name) {
      window.alert(translate(lang, 'Please enter tag name'));
      return;
    }
    if (form.color && !isColorFormat(form.color)) {
      window.alert(translate(lang, 'Please enter valid color'));
      return;
    }
    let data: any = { ...form };
    if (form.is_urgent) {
      if (
        !periodUnit ||
        !periodUnit.unit ||
        periodUnit.value === undefined ||
        periodUnit.value === null
      ) {
        window.alert(translate(lang, 'Please enter valid period unit'));
        return;
      }
      const period =
        periodUnit.unit === 'month'
          ? periodUnit.value * 3600 * 24 * 30
          : periodUnit.unit === 'week'
          ? periodUnit.value * 3600 * 24 * 7
          : periodUnit.unit === 'day'
          ? periodUnit.value * 3600 * 24
          : periodUnit.value * 3600;
      data.period = period;
    }
    setDisabled(true);
    const res = editId
      ? await sendSingleRequest(data, 'PUT', `api/tag/${editId}`, true)
      : await sendSingleRequest(data, 'POST', 'api/tag', true);

    if (res.status) {
      await dispatch(loadTags());
      onClose();
    } else {
      setDisabled(false);
      dispatch(
        showFeedback({
          isMessageModal: true,
          message: translate(lang, res.data?.message ?? 'Unknown error'),
          type: 'error',
        }),
      );
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={onClose}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, `${editId ? 'Update Tag' : 'Add New Tag'}`)}
          </Subtitle>
        </div>
        <Input
          className='mr-16 w-100 mb-17'
          type='text'
          label={translate(lang, 'Name')}
          value={form.name}
          onChange={e => updateForm('name', e.target.value)}
          disabled={disabled}
        />
        <Input
          className='mr-16 w-100 mb-17'
          type='text'
          label={translate(lang, 'Color')}
          placeholder='#000000'
          value={form.color ?? ''}
          onChange={e => updateForm('color', e.target.value)}
          disabled={disabled}
        />
        <div className='mb-17'>
          <Dropdown
            label={translate(lang, 'Tagged lines')}
            placeholder={translate(lang, 'Select tagged lines')}
            mode='multiple'
            options={linesOption}
            value={form.lines?.map(x => x.toString()) as any}
            onChange={(v: any) =>
              updateForm(
                'lines',
                v.map((x: string) => Number(x)),
              )
            }
            showSearch={true}
            disabled={disabled}
          />
        </div>
        <div className='mt-32 mb-17'>
          <CheckboxButton
            label={translate(lang, 'Check if tag requires urgent attention')}
            checked={form.is_urgent}
            onChange={e => updateForm('is_urgent', e.target.checked)}
            disabled={disabled}
          />
        </div>
        {form.is_urgent && (
          <>
            <div className='mb-7'>
              <Subtitle color='black-1' align='left' size={4} fontWeight={500}>
                {translate(lang, 'How long it will be delayed')}
              </Subtitle>
            </div>
            <div className='mb-17 d-flex'>
              <div className='w-100 mr-7'>
                <Input
                  type='number'
                  label=''
                  value={periodUnit.value?.toString() ?? ''}
                  onChange={e =>
                    setPeriodUnit((prv: any) => ({
                      ...prv,
                      value:
                        e.target.value.length <= 0
                          ? null
                          : Number(e.target.value),
                    }))
                  }
                />
              </div>
              <div className='w-100 ml-7'>
                <Dropdown
                  label=''
                  options={periodOptions}
                  value={periodUnit.unit}
                  onChange={v =>
                    setPeriodUnit((prv: any) => ({ ...prv, unit: v }))
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16 mt-24'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

interface TableProps {
  tags: ITag[];
  onEditClick: (d: ITag) => void;
  onDeleteClick: (d: ITag) => void;
}

const TagsTable = ({ tags, onEditClick, onDeleteClick }: TableProps) => {
  const lang = useSelector(selectLang);

  const columns = [
    {
      title: translate(lang, 'Name'),
      key: 'name',
      render: (x: ITag) => <div>{x.name}</div>,
    },
    {
      title: translate(lang, 'Color'),
      key: 'color',
      render: (x: ITag) =>
        x.color && (
          <div
            style={{
              backgroundColor: x.color,
              padding: 3,
              borderRadius: 5,
              textAlign: 'center',
              fontWeight: 'bold',
              color: invertColor(x.color),
            }}
          >
            {x.color}
          </div>
        ),
    },
    {
      title: translate(lang, 'Lines'),
      key: 'lines',
      render: (x: ITag) => <div>{`tagged ${x.tag_lines.length} lines`}</div>,
    },
    {
      title: translate(lang, 'Required urgent attention'),
      key: 'is_urgent',
      render: (x: ITag) => <div>{x.is_urgent ? 'Yes' : 'No'}</div>,
    },
    {
      title: translate(lang, 'If required attention is delayed'),
      key: 'period',
      render: (x: ITag) => (
        <div>
          {translate(
            lang,
            x.period !== null
              ? x.period
                ? `after ${periodToStr(x.period)}`
                : 'immediately'
              : '-',
          )}
        </div>
      ),
    },
    {
      title: '',
      key: 'more',
      render: (x: ITag) => (
        <div className='wrap'>
          <DropdownMenu
            data={x}
            column='isUtil'
            onEdit={() => onEditClick(x)}
            onDeleteRow={() => onDeleteClick(x)}
          />
        </div>
      ),
    },
  ];

  return (
    <Table
      className='table table--units'
      rowKey='id'
      pagination={false}
      columns={columns}
      dataSource={tags}
    />
  );
};

const TagsPage = () => {
  const dispatch = useDispatch<any>();
  const tags = useSelector(selectTags);
  const lang = useSelector(selectLang);

  const [showAdd, setShowAdd] = useState(false);
  const [editTag, setEditTag] = useState<ITag>();

  const deleteClick = async (x: ITag) => {
    if (!window.confirm(translate(lang, 'Are you sure to delete this tag?')))
      return;

    const res = await sendSingleRequest({}, 'DELETE', `api/tag/${x.id}`, true);
    if (res.status) {
      await dispatch(loadTags());
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(lang, res.data?.message ?? 'Failed'),
          type: 'error',
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(loadTags());
  }, [dispatch]);

  return (
    <div className='container w-100'>
      <div
        className='pb-32 pt-32 mb-32'
        style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: 1000 }}
      >
        <div className='d-flex mb-16 align-items-center justify-content-between'>
          <Title
            className='mb-16'
            size={6}
            color='black-3'
            align='default'
            fontWeight={500}
          >
            {translate(lang, 'Line tags')}
          </Title>
        </div>
        <div className='d-flex white-card pt-12 pl-16 mb-8'>
          <div className='d-flex align-items-center'>
            <Button
              color='blue'
              size={3}
              width='small'
              type='fill'
              onClick={() => setShowAdd(true)}
            >
              {`${translate(lang, 'Add')} ${translate(lang, 'Line tag')}`}
            </Button>
          </div>
        </div>
        <TagsTable
          tags={tags ?? []}
          onEditClick={x => setEditTag(x)}
          onDeleteClick={deleteClick}
        />
      </div>
      {showAdd && <TagModal onClose={() => setShowAdd(false)} />}
      {editTag && (
        <TagModal
          tag={editTag}
          editId={editTag.id}
          onClose={() => setEditTag(undefined)}
        />
      )}
    </div>
  );
};

export default TagsPage;

import { useSelector } from 'react-redux';
import { selectProfile } from '../../store/auth/auth.selector';
import { Title } from '../../components/shared';
import moment from 'moment';
import { selectFarmsData } from '../../store/farms/farms.selector';
import FarmsOverviewChart from './FarmsOverviewChart';
import HarvestPerformanceChart from './PerformanceCharts';
import CalendarView from './CalendarView';
import MessagesView from './MessagesView';
import UpcomingHarvests from './UpcomingHarvests';
import { useEffect, useState } from 'react';
import { IFarmWeatherResource } from '../../entities/farms.entities';
import { sendSingleRequest } from '../../apis';
import { formatNumber } from '../../entities/util-functions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import 'chart.js/auto';
import './styles.scss';

const oneKnot = 1.94384;

interface IWeather {
  temperature: number;
  wind: number;
}

const HomePage = () => {
  const profile = useSelector(selectProfile);
  const farmsData = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [weather, setWeather] = useState<IWeather>();

  useEffect(() => {
    (async () => {
      const min_date = moment().startOf('day').toDate().getTime();
      const max_date = moment().endOf('day').toDate().getTime();
      const farm = farmsData.find(f => f.bounds && f.bounds.length > 0);
      if (!farm) return;

      const response = await sendSingleRequest(
        { min_date, max_date, farm_id: farm.id },
        'GET',
        'api/farm/weather',
        true,
      );
      if (!response.status || response.data.length <= 0) return;
      const data: IFarmWeatherResource[] = response.data;
      let temp = 0,
        wind_u = 0,
        wind_v = 0;
      for (let d of data) {
        temp += Number(d.temperature);
        wind_u += Number(d.wind_u);
        wind_v += Number(d.wind_v);
      }
      temp /= data.length;
      wind_u /= data.length;
      wind_v /= data.length;
      const wind = Math.sqrt(wind_u * wind_u + wind_v * wind_v);
      setWeather({ temperature: temp, wind: wind * oneKnot });
    })();
  }, [farmsData]);

  return (
    <div className='bg-secondary min-h-100'>
      <div className='container'>
        <div className='mb-32 pt-24'>
          <Title size={5} color='black' align='default' fontWeight={600}>
            {`${translate(
              lang,
              'Hello %s, today is %s',
              profile?.name,
              moment().format('D of MMMM YYYY'),
            )}${
              weather
                ? `, ${translate(
                    lang,
                    'It will be %s °C at noon and wind will be %s knots.',
                    formatNumber(weather.temperature),
                    formatNumber(weather.wind),
                  )}`
                : ''
            }`}
          </Title>
          {farmsData.some(x => x.type === 'OYSTER') && (
            <div className='mt-12 text-right'>
              <Link to='/oyster-summary/growth'>
                <Button type='primary'>
                  {translate(lang, 'Oyster Summary')}
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className='home-body'>
          <div className='mr-24' style={{ flex: 2 }}>
            <FarmsOverviewChart farms={farmsData} />
            <HarvestPerformanceChart />
          </div>
          <div className='ml-24' style={{ flex: 3 }}>
            <CalendarView />
            <MessagesView />
            <UpcomingHarvests farms={farmsData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Title, Button, Paragrapgh } from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import { updateEmail, updatePassword } from '../../store/auth/auth.actions';
import { IChangePassword } from '../../entities/auth.entities';
import { selectProfile } from '../../store/auth/auth.selector';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

const Security = () => {
  const dispatch = useDispatch<any>();
  const width = useWidth();

  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);

  const [emailFields, setEmailFields] = useState({ email: '', password: '' });
  const [passwordFields, setPasswordFields] = useState({
    password: '',
    new_password: '',
  });
  const [isClearEmail, setIsClearEmail] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [isValidFieldsPassword, setIsValidFieldsPassword] = useState<boolean>();
  const [passwordFieldsValid, setPasswordFieldsValid] = useState([
    {
      name: 'password',
      isValidate: false,
      isErrorRes: false,
    },
    {
      name: 'new_password',
      isValidate: false,
      isErrorRes: false,
    },
  ]);
  const [isValidFields, setIsValidFields] = useState<boolean>();
  const [fieldsValid, setFieldsValid] = useState([
    {
      name: 'password',
      isValidate: false,
      isErrorRes: false,
    },
    {
      name: 'email',
      isValidate: false,
      isErrorRes: false,
    },
  ]);

  useEffect(() => {
    if (isClearEmail) {
      setIsClearEmail(false);
    }
  }, [isClearEmail]);

  useEffect(() => {
    if (isClear) {
      setIsClear(false);
    }
  }, [isClear]);

  useEffect(() => {
    if (passwordFieldsValid[0].isErrorRes) {
      setPasswordFieldsValid(
        passwordFieldsValid.map(item => {
          if (item.name === 'password') {
            return { ...item, isErrorRes: false };
          }
          return { ...item };
        }),
      );
    }
  }, [passwordFieldsValid[0].isErrorRes]);

  useEffect(() => {
    if (fieldsValid[0].isErrorRes) {
      setFieldsValid(
        fieldsValid.map(item => {
          if (item.name === 'password') {
            return { ...item, isErrorRes: false };
          }
          return { ...item };
        }),
      );
    }
  }, [fieldsValid[0].isErrorRes]);

  const handleOnChangePassword = async () => {
    setIsValidFieldsPassword(false);
    const changeData: IChangePassword = {
      password: passwordFields.password,
      new_password: passwordFields.new_password,
    };
    await dispatch(updatePassword(changeData));
    setIsValidFieldsPassword(true);
    setPasswordFields({ password: '', new_password: '' });
  };

  const handleOnChangeEmail = async () => {
    setIsValidFields(false);
    await dispatch(updateEmail(emailFields));
    setIsValidFields(true);
  };

  const handleOnValidFields = (value: boolean, type: string | undefined) => {
    let counter = 0;
    const newArr = fieldsValid.map(field => {
      /* eslint-disable*/
      if (field.name === type) {
        if (value) counter++;
        return { ...field, isValidate: value };
      }
      if (field.name !== type && field.isValidate) counter++;
      return field;
    });

    setFieldsValid(newArr);
    setIsValidFields(2 === counter || false);
  };

  const handleOnValidatePassword = (
    value: boolean,
    type: string | undefined,
  ) => {
    let counter = 0;
    const newArr = passwordFieldsValid.map(field => {
      /* eslint-disable*/
      if (field.name === type) {
        if (value) counter++;
        return { ...field, isValidate: value };
      }
      if (field.name !== type && field.isValidate) counter++;
      return field;
    });

    setPasswordFieldsValid(newArr);
    setIsValidFieldsPassword(2 === counter || false);
  };

  return (
    <div className='content pb-32'>
      {width > 768 && (
        <Title
          className='mb-24'
          size={5}
          color='black-3'
          align='default'
          fontWeight={700}
        >
          {translate(lang, 'Security')}
        </Title>
      )}
      <Title
        className='mb-16'
        size={6}
        color='black-3'
        align='default'
        fontWeight={500}
      >
        {translate(lang, 'Change email')}
      </Title>
      <Paragrapgh
        className='mb-16'
        size={1}
        color='black'
        align='default'
        fontWeight={400}
      >
        {translate(lang, 'Current email')} -
        <span className='font-weight-600'>{profile?.email}</span>
      </Paragrapgh>
      <Input
        label={translate(lang, 'New email')}
        className='mb-16'
        type='email'
        onChange={e =>
          setEmailFields({ ...emailFields, email: e.target.value })
        }
        value={emailFields.email}
        dataType='email'
        onValidate={(e, data) => handleOnValidFields(e, data)}
        placeholder=''
        isClear={isClearEmail}
      />
      <Input
        className='mb-24'
        label={translate(lang, 'Current password')}
        type='password'
        onChange={e =>
          setEmailFields({ ...emailFields, password: e.target.value })
        }
        value={emailFields.password}
        dataType='password'
        onValidate={(e, data) => handleOnValidFields(e, data)}
        placeholder=''
        isClear={isClearEmail}
        isErrorRes={fieldsValid[0].isErrorRes}
      />
      <Button
        className={width < 769 ? 'mt-12' : 'h-max'}
        color='blue'
        size={2}
        width={width < 769 ? 'wide' : 'small'}
        type='bordered'
        disabled={!isValidFields}
        onClick={handleOnChangeEmail}
      >
        <span>{translate(lang, 'Change email')}</span>
      </Button>
      <Title
        className='mb-16 mt-32'
        size={6}
        color='black-3'
        align='default'
        fontWeight={500}
      >
        {translate(lang, 'Change password')}
      </Title>
      <Input
        className='mb-16'
        label={translate(lang, 'Current password')}
        type='password'
        onChange={e =>
          setPasswordFields({ ...passwordFields, password: e.target.value })
        }
        value={passwordFields.password}
        dataType='password'
        onValidate={(e, data) => handleOnValidatePassword(e, data)}
        placeholder=''
        isClear={isClear}
        isErrorRes={passwordFieldsValid[0].isErrorRes}
      />
      <Input
        className='mb-24'
        label={translate(lang, 'New password')}
        type='password'
        value={passwordFields.new_password}
        onChange={e =>
          setPasswordFields({ ...passwordFields, new_password: e.target.value })
        }
        dataType='new_password'
        onValidate={(e, data) => handleOnValidatePassword(e, data)}
        placeholder=''
        isClear={isClear}
      />
      <Button
        className={width < 769 ? 'mt-12' : 'h-max'}
        color='blue'
        size={2}
        width={width < 769 ? 'wide' : 'small'}
        type='bordered'
        disabled={!isValidFieldsPassword}
        onClick={handleOnChangePassword}
      >
        <span>{translate(lang, 'Change password')}</span>
      </Button>
    </div>
  );
};

export default Security;

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Paragrapgh, DotsIcon, DropdownMenu } from '../shared';
import TagComponent from '../shared/tag/Tag';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  name?: string;
  email?: string;
  status?: string;
  role?: string;
  id?: string;
  onDeleteRow?: (data: any) => void;
  onDeactivate?: (data: any) => void;
  onActivateUser?: (data: any) => void;
}

const UserCard = ({
  email,
  name,
  status,
  role,
  onDeactivate,
  id,
  onDeleteRow,
  onActivateUser,
}: Props) => {
  const history = useHistory();
  const profile = useSelector(selectProfile);
  const lang = useSelector(selectLang);

  const handleOnEdit = (d: any) => {
    history.push(`/users/edit-user/${d.id}`);
  };

  return (
    <div className='user-smallCard'>
      <div className='d-flex mb-28 justify-content-between align-items-center'>
        <Paragrapgh size={2} color='black' align='left' fontWeight={600}>
          {email}
        </Paragrapgh>
        {profile?.role !== 'user' &&
          role !== 'owner' &&
          email !== profile?.email && (
            <DropdownMenu
              type='farms'
              onEdit={d => handleOnEdit(d)}
              onDeleteRow={onDeleteRow}
              onDeactivate={onDeactivate}
              onActivateUser={onActivateUser}
              data={{ id, email, status }}
              column='isUsers'
              icon={<DotsIcon />}
              isAdmin
            />
          )}
      </div>
      <div className='d-flex justify-content-between align-items-end'>
        <div>
          <Paragrapgh size={3} color='black-2' align='left' fontWeight={400}>
            {translate(lang, 'Full Name')}
          </Paragrapgh>
          <Paragrapgh size={2} color='black' align='left' fontWeight={400}>
            {name}
          </Paragrapgh>
        </div>
        <div>
          <Paragrapgh size={3} color='black-2' align='left' fontWeight={400}>
            {translate(lang, 'Role')}
          </Paragrapgh>
          <Paragrapgh size={2} color='black' align='left' fontWeight={400}>
            {role}
          </Paragrapgh>
        </div>
        <div>
          {status === 'active' && (
            <TagComponent color='green'>{status}</TagComponent>
          )}
          {status === 'pending' && (
            <TagComponent color='orange'>{status}</TagComponent>
          )}
          {status === 'deactivated' && (
            <TagComponent color='gray'>{status}</TagComponent>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCard;

import { useMemo } from 'react';
import { Subtitle } from '../../components/shared';
import { IFarmProperty, ILineProperty } from '../../entities/farms.entities';
import {
  IMusselHarvest,
  IMusselSeeding,
} from '../../entities/growing.entities';
import { defaultUnitLength } from '../../util/farmUtil';
import { formatNumber } from '../../entities/util-functions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import moment from 'moment';
import { toMillisecond } from '../../util/toggleSecondMillisecond';

interface Props {
  farm: IFarmProperty;
  line: ILineProperty;
  seedings: IMusselSeeding[];
  harvests: IMusselHarvest[];
  harvestID: number;
}

const MusselHarvestHeader = ({
  farm,
  line,
  seedings,
  harvests,
  harvestID,
}: Props) => {
  const lang = useSelector(selectLang);

  const {
    longlineLength,
    amountSum,
    finalSum,
    firstRatio,
    lastRatio,
    harvest,
    grownDays,
  } = useMemo(() => {
    const longlineLength = seedings.reduce((p, c) => p + c.line_length, 0);
    const amountSum = harvests.reduce((p, c) => p + c.amount, 0);
    const finalSum = harvests
      .filter(x => x.is_final)
      .reduce((p, c) => p + c.amount, 0);
    const ll =
      longlineLength -
      harvests.reduce((p, c) => p + (c.line_length ?? 0), 0) +
      (harvests[harvests.length - 1]?.line_length ?? 0);
    const fr = !seedings[0].line_length ? 0 : ll / seedings[0].line_length;
    const lr = !harvests[harvests.length - 2]?.line_length
      ? 0
      : ll / (harvests[harvests.length - 2].line_length ?? 1);

    const startDate = seedings.reduce(
      (p, c) => Math.min(p, c.planned_date_seed),
      Date.now(),
    );
    const endDate = harvests.reduce((p, c) => Math.max(p, c.complete_date), 0);
    const grownDays = moment(toMillisecond(endDate)).diff(
      toMillisecond(startDate),
      'days',
    );

    return {
      longlineLength,
      amountSum,
      finalSum,
      firstRatio: !fr ? '- : -' : `1 : ${formatNumber(fr)}`,
      lastRatio: !lr ? '- : -' : `1 : ${formatNumber(lr)}`,
      harvest: harvests.find(x => x.id === harvestID),
      grownDays,
    };
  }, [seedings, harvests, harvestID]);

  return (
    <div className='d-flex justify-content-between white-card pt-28 pr-16 pb-28 pl-24 mb-16 overflow-auto'>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.name}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Line name')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {line.line_name}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm number')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {farm.farm_number}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Longline length')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {defaultUnitLength(longlineLength, 'm', '-')}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Farm area')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {defaultUnitLength(farm.area, 'ha', '-')}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Backbone')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {defaultUnitLength(line.backbone, 'm', '-')}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Harvest amount')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${formatNumber(harvest?.amount ?? 0)} kg`}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Total harvest amount')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${formatNumber(finalSum)} kg`}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'First seed ratio')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {firstRatio}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Last seed ratio')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {lastRatio}
        </Subtitle>
      </div>
      <div>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4'
        >
          {translate(lang, 'Age of growth')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {`${grownDays} days`}
        </Subtitle>
        <Subtitle
          size={3}
          color='black'
          align='left'
          fontWeight={400}
          className='mb-4 mt-28'
        >
          {translate(lang, 'Mussels per meters of line')}
        </Subtitle>
        <Subtitle size={4} color='black' align='left' fontWeight={500}>
          {(amountSum / longlineLength).toFixed(2)} kg/m
        </Subtitle>
      </div>
    </div>
  );
};

export default MusselHarvestHeader;

import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Title,
  Spinner,
  Paragrapgh,
  Button,
  CaretDownIcon,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { Table } from 'antd';
import { IBudgetValue, IBudgetFarm } from '../../entities/budget.entities';
import BudgetYearArrows from '../../components/budget/BudgetYearArrows';
import { formatNumber, numberToMoneyStr } from '../../entities/util-functions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';

interface ILabel {
  label: string;
  planned: number;
  actual: number;
  unit: 'm' | '$' | 't';
}

interface ValueProps {
  val: IBudgetValue;
  unit: 'm' | '$' | 't';
}

const ValueCell = ({ val, unit }: ValueProps) => (
  <div className='d-flex align-items-center'>
    <div className='pr-6'>
      {unit === 't'
        ? `${formatNumber(val.actual / 1000)} t`
        : unit === '$'
        ? `$ ${numberToMoneyStr(val.actual)}`
        : `${formatNumber(val.actual)} m`}
    </div>
    {/* <PlannedActualRate planned={val.planned} actual={val.actual} /> */}
  </div>
);

const ProfitSpan = (val: number) => (
  <div className='d-flex' style={val < 0 ? { color: 'red' } : {}}>
    {`$ ${numberToMoneyStr(val)}`}
  </div>
);

const farmColumns = (lang: TLang | undefined) => [
  {
    title: translate(lang, 'Farm'),
    dataIndex: 'name',
    key: 'name',
    render: (x: string) => <span>{x}</span>,
  },
  {
    title: translate(lang, 'Total length'),
    dataIndex: 'total_length',
    key: 'total_length',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='m' />,
  },
  {
    title: translate(lang, 'Total seeding cost'),
    dataIndex: 'total_seeding_cost',
    key: 'total_seeding_cost',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Total maintenance cost'),
    dataIndex: 'total_maintenance_cost',
    key: 'total_maintenance_cost',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Total harvest tonnes'),
    dataIndex: 'total_harvest_amount',
    key: 'total_harvest_amount',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='t' />,
  },
  {
    title: translate(lang, 'Total harvest cost'),
    dataIndex: 'total_harvest_cost',
    key: 'total_harvest_cost',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Total harvest income'),
    dataIndex: 'total_harvest_income',
    key: 'total_harvest_income',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Total profit'),
    key: 'total_profit',
    render: (x: IBudgetFarm) =>
      ProfitSpan(
        x.total_harvest_income.actual -
          x.total_seeding_cost.actual -
          x.total_maintenance_cost.actual -
          x.total_harvest_cost.actual,
      ),
  },
];

const lineColumns = (lang: TLang | undefined) => [
  {
    title: translate(lang, 'Line'),
    dataIndex: 'line_name',
    key: 'line_name',
    render: (x: string) => <span>{x}</span>,
  },
  {
    title: translate(lang, 'Length'),
    dataIndex: 'length',
    key: 'length',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='m' />,
  },
  {
    title: translate(lang, 'Seeding cost'),
    dataIndex: 'seeding_cost',
    key: 'seeding_cost',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Maintenance cost'),
    dataIndex: 'maintenance_cost',
    key: 'maintenance_cost',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Harvest tonnes'),
    dataIndex: 'harvest_amount',
    key: 'harvest_amount',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='t' />,
  },
  {
    title: translate(lang, 'Harvest cost'),
    dataIndex: 'harvest_cost',
    key: 'harvest_cost',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Harvest income'),
    dataIndex: 'harvest_income',
    key: 'harvest_income',
    render: (x: IBudgetValue) => <ValueCell val={x} unit='$' />,
  },
  {
    title: translate(lang, 'Profit'),
    key: 'profit',
    render: (x: any) =>
      ProfitSpan(
        x.harvest_income.actual -
          x.seeding_cost.actual -
          x.maintenance_cost.actual -
          x.harvest_cost.actual,
      ),
  },
  { title: '', key: 'end', render: (x: any) => undefined },
];

const BudgetTotalPage = () => {
  const width = useWidth();
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(selectLang);

  const [year, setYear] = useState(new Date().getFullYear());
  const [data, setData] = useState<IBudgetFarm[]>([]);
  const [loading, setLoading] = useState(false);

  const summary = useMemo(() => {
    let result: Array<ILabel> = [];
    let length = { planned: 0, actual: 0 },
      seeding = { planned: 0, actual: 0 },
      maintenance = { planned: 0, actual: 0 },
      harvest_amount = { planned: 0, actual: 0 },
      harvest_income = { planned: 0, actual: 0 },
      harvest_cost = { planned: 0, actual: 0 };

    for (let d of data) {
      length.planned += d.total_length.planned;
      length.actual += d.total_length.actual;

      seeding.planned += d.total_seeding_cost.planned;
      seeding.actual += d.total_seeding_cost.actual;

      maintenance.planned += d.total_maintenance_cost.planned;
      maintenance.actual += d.total_maintenance_cost.actual;

      harvest_amount.planned += d.total_harvest_amount.planned / 1000;
      harvest_amount.actual += d.total_harvest_amount.actual / 1000;

      harvest_income.planned += d.total_harvest_income.planned;
      harvest_income.actual += d.total_harvest_income.actual;

      harvest_cost.planned += d.total_harvest_cost.planned;
      harvest_cost.actual += d.total_harvest_cost.actual;
    }
    result = [
      { label: translate(lang, 'Total length'), unit: 'm', ...length },
      { label: translate(lang, 'Total seeding cost'), unit: '$', ...seeding },
      {
        label: translate(lang, 'Total maintenance cost'),
        unit: '$',
        ...maintenance,
      },
      {
        label: translate(lang, 'Total harvest tonnes'),
        unit: 't',
        ...harvest_amount,
      },
      {
        label: translate(lang, 'Total harvest cost'),
        unit: '$',
        ...harvest_cost,
      },
      {
        label: translate(lang, 'Total harvest income'),
        unit: '$',
        ...harvest_income,
      },
      {
        label: translate(lang, 'Total profit'),
        unit: '$',
        planned: 0,
        actual:
          harvest_income.actual -
          seeding.actual -
          maintenance.actual -
          harvest_cost.actual,
      },
    ];
    return result;
  }, [data]);

  const loadData = useCallback(async () => {
    setLoading(true);
    const res = await sendSingleRequest(
      { year },
      'GET',
      'api/farm/line/budgets',
      true,
    );
    setLoading(false);
    if (res.status) {
      setData(res.data);
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          message: res.data?.message ?? 'Failed to load budget information',
          type: 'error',
        }),
      );
    }
  }, [year]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className='budget bg-secondary min-height'>
      <div className='container'>
        <div className='d-flex justify-content-between align-items-center'>
          <Title
            className={width > 768 ? 'pl-21 pt-32 pb-34' : 'pb-14 pt-20'}
            size={5}
            color='black-3'
            align='default'
            fontWeight={700}
          >
            {translate(lang, 'Budget')}
          </Title>
          <div className='pr-17'>
            <Button
              size={3}
              type='fill'
              width='small'
              color='blue'
              onClick={() => history.push('/budget-log')}
            >
              {translate(lang, 'Budget Log')}
            </Button>
          </div>
        </div>
        <div className='mb-14 pl-21'>
          <BudgetYearArrows
            disabledPrev={loading}
            disabledNext={year === new Date().getFullYear() || loading}
            year={year}
            onChange={value => setYear(value)}
          />
        </div>
        {loading ? (
          <div className='mt-20'>
            <Spinner />
          </div>
        ) : (
          <>
            <div className='budget__title-line'>
              {summary.map((item, i) => (
                <div key={i} className='budget__item'>
                  <Paragrapgh
                    size={3}
                    color='black-2'
                    align='left'
                    fontWeight={400}
                  >
                    {item.label}
                  </Paragrapgh>
                  <div className='d-flex align-items-center'>
                    <Paragrapgh
                      className={width > 768 ? 'pr-10' : 'pr-4'}
                      size={width > 768 ? 1 : 2}
                      color='black'
                      align='left'
                      fontWeight={width > 768 ? 500 : 400}
                    >
                      {item.unit === '$'
                        ? `$ ${numberToMoneyStr(item.actual)}`
                        : `${formatNumber(item.actual)} ${item.unit}`}
                    </Paragrapgh>
                  </div>
                </div>
              ))}
            </div>
            <div
              className={
                width > 768 ? 'd-flex justify-content-between pl-15 pr-15' : ''
              }
            >
              <div className='width-100 pos-relative'>
                <Table
                  rowKey={'id'}
                  className='table table--isBudget'
                  style={{
                    width: '1200px',
                    minWidth: '100%',
                    maxWidth: '1200px',
                  }}
                  columns={farmColumns(lang)}
                  pagination={false}
                  dataSource={data}
                  expandable={{
                    expandedRowRender: (d: IBudgetFarm) => (
                      <Table
                        rowKey='id'
                        className='table table--isBudgetLine is__child'
                        columns={lineColumns(lang)}
                        pagination={false}
                        dataSource={d.lines}
                        onRow={r => ({
                          onClick: () =>
                            history.push(
                              `/budget/${d.id}?line=${r.id}&year=${year}`,
                            ),
                        })}
                      />
                    ),
                    expandIcon: ({ onExpand, record }) => (
                      <div
                        className='pt-20 pb-20'
                        onKeyDown={() => undefined}
                        onClick={event => {
                          event.stopPropagation();
                          onExpand(record, event);
                        }}
                      >
                        <CaretDownIcon />
                      </div>
                    ),
                  }}
                  onRow={d => ({
                    onClick: () => history.push(`/budget/${d.id}`),
                  })}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BudgetTotalPage;

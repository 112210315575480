import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  CloseIcon,
  Map,
  ModalComponent,
  Spinner,
  Subtitle,
  Title,
} from '../../components/shared';
import { loadQueueData } from '../../store/farms/farms.actions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { showFeedback } from '../../store/ui/ui.actions';
import { Modal } from 'antd';
import SeedingModal from '../../components/farm-modals/SeedingModal';
import MaintenanceModal from '../../components/farm-modals/MaintenanceModal';
import FloatManageModal from '../../components/farm-modals/FloatManageModal';
import AssessmentModal from '../../components/farm-modals/AssessmentModal';
import HarvestCompleteModal from '../../components/farm-modals/HarvestCompleteModal';
import { selectQueueData } from '../../store/farms/farms.selector';
import {
  IQueueItem,
  TValidationType,
} from '../../entities/automation.entities';
import PaperFormModal from './PaperFormModal';
import { useHistory } from 'react-router-dom';
import {
  IWeatherLocation,
  TBusinessType,
} from '../../entities/general.entities';
import BulkHarvestModal from '../../components/farm-modals/BulkHarvestModal';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import GrowerSeedModal from '../../components/farm-modals/GrowerSeedModal';
import GrowerHarvestModal from '../../components/farm-modals/GrowerHarvestModal';
import { SelectIsGrower } from '../../store/extra/extra.selector';
import GrowerMaintenanceModal from '../../components/farm-modals/GrowerMaintenanceModal';
import CatchSpatModal from '../../components/farm-modals/CatchSpatModal';
import './styles.scss';

const WarningBox = ({
  onClick,
  lang,
}: {
  onClick: (x: any) => void;
  lang: TLang | undefined;
}) => (
  <>
    <div>{translate(lang, '_validate_action_warning')}</div>
    <div
      style={{
        textDecoration: 'underline',
        fontStyle: 'italic',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {translate(lang, '_validate_click_here')}
    </div>
  </>
);

interface MapProps {
  title: string;
  visible: boolean;
  location: IWeatherLocation;
  onClose: () => void;
}

const MapModal = ({ title, visible, onClose, location }: MapProps) => (
  <Modal
    visible={visible}
    onCancel={onClose}
    closable
    closeIcon={<CloseIcon />}
    width={600}
    footer={null}
  >
    <div className='wrap'>
      <div className='d-flex align-items-center mb-16'>
        <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
          {title}
        </Subtitle>
      </div>
      <div style={{ height: '600px' }}>
        <Map
          zoom={10}
          position={{ lat: location.latitude, lng: location.longitude }}
          defaultPosition={{
            lat: location.latitude,
            lng: location.longitude,
          }}
        />
      </div>
    </div>
  </Modal>
);

const titles = {
  assessment: 'Assessment data',
  maintenance: 'Maintenance data',
  seeding: 'Seed data',
  inventory_seed: 'Floats data',
  harvest: 'Harvest data',
  paper_seeding: 'Paper Seeding form',
  paper_floating: 'Paper Floating form',
  paper_assessment: 'Paper Assessment form',
  paper_harvest: 'Paper Harvest form',
  bulk_mussel_harvest: 'Bulk Mussel Harvests',
  bulk_oyster_harvest: 'Bulk Oyster Harvests',
  bulk_mussel_seed: 'Bulk Mussel Seeding',
};

interface IDetail {
  [key: string]: any;
  id: number;
  _type: TValidationType;
  crop_type: TBusinessType;
}

const deleteRequest = async (data: any) =>
  await sendSingleRequest(data, 'DELETE', 'api/queue/entity', true);

const QueuedDataPage = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const queueData = useSelector(selectQueueData);
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState<IDetail>();
  const [disable, setDisable] = useState(false);
  const [mapLoc, setMapLoc] = useState<IWeatherLocation>();
  const [deletingItem, setDeletingItem] = useState<{
    type: string;
    item_id: number;
  }>();
  const [updatingItem, setUpdatingItem] = useState<IQueueItem>();
  const [warning, setWarning] = useState<any>();
  const [showCustom, setShowCustom] = useState(false);

  const loadData = useCallback(async () => {
    setLoading(true);
    await dispatch(loadQueueData());
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const showSuccess = (message: string) =>
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'success',
        message: translate(lang, message),
      }),
    );
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );

  const confirmMaintenance = async (data: any) => {
    if (!selectedData) return;
    if (updatingItem !== undefined) {
      const res = await updateQueueItem(data, updatingItem.id, 'maintenance');
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
    } else {
      setDisable(true);
      const res = await sendSingleRequest(
        {
          id: selectedData.id,
          ...data,
          weather_condition: selectedData.weather_condition,
        },
        'POST',
        'api/queue/confirm-maintenance',
        true,
      );
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
      setDisable(false);
    }
  };
  const confirmAssessment = async (data: any) => {
    if (!selectedData) return;
    if (updatingItem) {
      const res = await updateQueueItem(data, updatingItem.id, 'assessment');
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
    } else {
      setDisable(true);
      const res = await sendSingleRequest(
        {
          id: selectedData.id,
          ...data,
          weather_condition: selectedData.weather_condition,
        },
        'POST',
        'api/queue/confirm-assessment',
        true,
      );
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
      setDisable(false);
    }
  };
  const confirmInventorySeed = async (data: any) => {
    if (!selectedData) return;
    if (updatingItem) {
      const res = await updateQueueItem(
        data,
        updatingItem.id,
        'inventory_seed',
      );
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
    } else {
      setDisable(true);
      const res = await sendSingleRequest(
        {
          id: selectedData.id,
          ...data,
          weather_condition: selectedData.weather_condition,
        },
        'POST',
        'api/queue/confirm-inventory_seed',
        true,
      );
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
      setDisable(false);
    }
  };
  const confirmSeeding = async (data: any) => {
    if (!selectedData) return;
    if (updatingItem) {
      const res = await updateQueueItem(data, updatingItem.id, 'seeding');
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
      return true;
    } else {
      setDisable(true);
      const r = await sendSingleRequest(
        {
          id: selectedData.id,
          ...data,
          weather_condition: selectedData.weather_condition,
        },
        'POST',
        `api/queue/confirm-seeding/${selectedData.crop_type}`,
        true,
      );
      setDisable(false);
      if (!r.status) {
        return r.data;
      } else {
        await loadData();
        setSelectedData(undefined);
        showSuccess(r.data?.message ?? 'Success');
        return true;
      }
    }
  };
  const confirmHarvest = async (data: any) => {
    if (!selectedData) return;
    if (updatingItem) {
      const res = await updateQueueItem(data, updatingItem.id, 'harvest');
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      } else {
        showError(res.data?.message ?? 'Something went wrong');
      }
      return res;
    } else {
      setDisable(true);
      const res = await sendSingleRequest(
        {
          id: selectedData.id,
          ...data,
          weather_condition: selectedData.weather_condition,
        },
        'POST',
        `api/queue/confirm-harvest/${selectedData.crop_type}`,
        true,
      );
      if (res.status) {
        await loadData();
        setSelectedData(undefined);
        showSuccess(res.data?.message ?? 'Success');
      }
      setDisable(false);
      return res;
    }
  };
  const confirmBulkItem = async (data: any) => {
    if (!selectedData || !updatingItem) return;
    const res = await updateQueueItem(data, updatingItem.id, updatingItem.type);
    if (res.status) {
      await loadData();
      setSelectedData(undefined);
      showSuccess(res.data?.message ?? 'Success');
    } else {
      showError(res.data?.message ?? 'Something went wrong');
    }
  };
  const deleteQueueItem = async () => {
    if (!deletingItem) return;
    setDisable(true);
    const res = await deleteRequest(deletingItem);
    setDisable(false);
    setDeletingItem(undefined);
    if (res.status) {
      showSuccess('Deleted successfully');
      loadData();
    } else {
      showError(
        res.data?.message ??
          translate(lang, 'Failed to delete %s', 'validate data'),
      );
    }
  };
  const updateQueueItem = async (data: any, item_id: number, type: any) => {
    setDisable(true);
    const res = await sendSingleRequest(
      { data, type, item_id },
      'PUT',
      'api/queue/entity',
      true,
    );
    if (res.status) {
      setUpdatingItem(undefined);
    }
    setDisable(false);

    return res;
  };
  const clickQueueItem = async (
    item: IQueueItem,
    isUpdate: boolean = false,
  ) => {
    if (item.type.startsWith('paper_')) {
      history.push(`/queue/${item.id}`);
      return;
    }

    setUpdatingItem(undefined);

    if (!isUpdate && (item.type === 'seeding' || item.type === 'harvest')) {
      let ms = queueData?.find(
        x =>
          x.type === 'seeding' &&
          x.line.id === item.line.id &&
          x.date < item.date,
      );
      let mh = queueData?.find(
        x =>
          x.type === 'harvest' &&
          x.line.id === item.line.id &&
          x.date < item.date,
      );
      if (!ms || (mh && mh.date < ms.date)) ms = mh;
      if (!!ms) {
        setWarning(
          <WarningBox
            onClick={() => {
              setSelectedData(undefined);
              setTimeout(() => {
                const q = queueData?.find(x => x.id === ms?.id);
                if (q) clickQueueItem(q);
              }, 300);
            }}
            lang={lang}
          />,
        );
      } else {
        setWarning(undefined);
      }
    } else {
      setWarning(undefined);
    }

    setDisable(true);
    const res = await sendSingleRequest(
      {},
      'GET',
      `api/queue/detail/${item.id}`,
      true,
    );
    if (res.status) {
      setDisable(false);
      setSelectedData(res.data);
      if (isUpdate) {
        setUpdatingItem(item);
      }
    } else {
      dispatch(
        showFeedback({
          message: translate(lang, res.data?.message ?? 'Unknown error'),
          type: 'error',
          isMessage: true,
        }),
      );
      setDisable(false);
    }
  };
  const isGrower = SelectIsGrower(selectedData?.lineData?.farm_id);

  return (
    <div className='bg-secondary min-height'>
      <div className='container'>
        <div className='queued-page-header d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Unconfirmed data')}
          </Title>
          <Button
            color='blue'
            size={2}
            width='large'
            type='fill'
            onClick={() => setShowCustom(true)}
            disabled={disable}
          >
            {translate(lang, 'Upload paper form')}
          </Button>
        </div>
        <div className='queued-data-page'>
          {loading ? (
            <Spinner position='local' />
          ) : (
            <div className='queue-items'>
              {queueData?.map((item, i) => (
                <div key={i} className='queue-item'>
                  {item.type.startsWith('bulk_') ? (
                    <div className='--queue-left'>
                      <div className='--item-farm'>{`${item.lines_count} lines`}</div>
                    </div>
                  ) : (
                    <div className='--queue-left'>
                      <div className='--item-farm'>Farm: {item.farm.name}</div>
                      <div className='--item-line'>
                        Line: {item.line.line_name}
                      </div>
                    </div>
                  )}
                  <div className='--queue-middle'>
                    {item.is_unfinished && (
                      <div className='--queue-item-error'>
                        Entry not finished
                      </div>
                    )}
                    <div className='--queue-item-type'>{titles[item.type]}</div>
                    <div className='--queue-item-author'>
                      {`Submitted by ${item.user.name} at ${defaultDateFormat(
                        item.created_at,
                      )}`}
                    </div>
                    {item.weather_condition && (
                      <div style={{ textAlign: 'center', marginTop: 10 }}>
                        <button
                          className='view-location-btn'
                          onClick={() =>
                            setMapLoc(item.weather_condition ?? undefined)
                          }
                        >
                          {translate(lang, 'View the data entry location')}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className='--queue-right'>
                    <Button
                      color='green'
                      size={0}
                      width='small'
                      type='fill'
                      onClick={() => clickQueueItem(item)}
                      disabled={disable}
                    >
                      {translate(lang, 'View')}
                    </Button>
                    {!item.type.startsWith('paper_') && (
                      <Button
                        className='mt-7'
                        color='blue'
                        size={0}
                        width='small'
                        type='fill'
                        disabled={disable}
                        onClick={() => clickQueueItem(item, true)}
                      >
                        {translate(lang, 'Update')}
                      </Button>
                    )}
                    <Button
                      className='mt-7'
                      color='red'
                      size={0}
                      width='small'
                      type='fill'
                      disabled={disable}
                      onClick={() =>
                        setDeletingItem({
                          type: item.type,
                          item_id: item.id,
                        })
                      }
                    >
                      {translate(lang, 'Delete')}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {selectedData &&
        (selectedData._type === 'bulk_mussel_harvest' ? (
          <GrowerHarvestModal
            type='MUSSEL'
            visible={true}
            onConfirm={async () => {
              await loadData();
              setSelectedData(undefined);
            }}
            onClose={() => setSelectedData(undefined)}
            paramData={selectedData}
            overConfirm={updatingItem ? confirmBulkItem : undefined}
            confirmID={selectedData.id}
          />
        ) : selectedData._type === 'bulk_oyster_harvest' ? (
          <BulkHarvestModal
            visible={true}
            type={'OYSTER'}
            data={selectedData}
            onConfirm={async r => {
              await loadData();
              setSelectedData(undefined);
            }}
            overConfirm={updatingItem ? confirmBulkItem : undefined}
            onCancel={() => setSelectedData(undefined)}
            confirmID={selectedData.id}
          />
        ) : selectedData._type === 'seeding' ? (
          <>
            {!!selectedData.is_catch_spat ? (
              <CatchSpatModal
                visible={true}
                type={selectedData.crop_type}
                title={translate(lang, 'Catch spat')}
                lineData={selectedData.lineData}
                onCancel={() => setSelectedData(undefined)}
                onConfirm={() => {}}
                data={selectedData}
                overConfirm={confirmSeeding}
              />
            ) : (
              <SeedingModal
                type={selectedData.crop_type}
                visible={true}
                title={!updatingItem ? 'Seed the line' : 'Update data'}
                onCancel={() => setSelectedData(undefined)}
                onConfirm={() => {}}
                overConfirm={confirmSeeding}
                data={selectedData}
                confirmBtnLabel={
                  !updatingItem ? 'Seed the line' : 'Update data'
                }
                lineData={selectedData.lineData}
                parentID={
                  !selectedData.parent_id ? undefined : selectedData.parent_id
                }
                warningMessage={warning}
                lineChangeable={true}
              />
            )}
          </>
        ) : selectedData._type === 'assessment' ? (
          <AssessmentModal
            type={selectedData.crop_type}
            title='Add assessment'
            visible={true}
            onCancel={() => setSelectedData(undefined)}
            onConfirm={() => {}}
            overConfirm={confirmAssessment}
            lineData={selectedData.lineData}
            data={selectedData}
            lineChangeable={true}
          />
        ) : selectedData._type === 'harvest' ? (
          <HarvestCompleteModal
            type={selectedData.crop_type}
            title={!updatingItem ? 'Harvest Complete' : 'Update data'}
            visible={true}
            onCancel={() => setSelectedData(undefined)}
            onConfirm={() => {}}
            overConfirm={confirmHarvest}
            data={selectedData}
            lineData={selectedData.lineData}
            warningMessage={warning}
            lineChangeable={true}
          />
        ) : selectedData._type === 'inventory_seed' ? (
          <FloatManageModal
            visible={true}
            title={!updatingItem ? 'Inventory management' : 'Update data'}
            onCancel={() => setSelectedData(undefined)}
            onConfirm={() => {}}
            data={selectedData as any}
            lineData={selectedData.lineData}
            overConfirm={confirmInventorySeed}
            lineChangeable
          />
        ) : selectedData._type === 'maintenance' ? (
          <>
            {isGrower ? (
              <GrowerMaintenanceModal
                visible={true}
                lineData={selectedData.lineData}
                onClose={() => setSelectedData(undefined)}
                onConfirm={() => {}}
                data={selectedData}
                overConfirm={confirmMaintenance}
              />
            ) : (
              <MaintenanceModal
                visible={true}
                title={!updatingItem ? 'Add maintenance' : 'Update data'}
                onCancel={() => setSelectedData(undefined)}
                onConfirm={() => {}}
                overConfirm={confirmMaintenance}
                data={selectedData}
                lineData={selectedData.lineData}
                confirmBtnLabel={!updatingItem ? 'Confirm' : 'Update data'}
                lineChangeable={true}
              />
            )}
          </>
        ) : selectedData._type === 'bulk_mussel_seed' ? (
          <GrowerSeedModal
            type='MUSSEL'
            visible={true}
            onConfirm={async () => {
              setSelectedData(undefined);
              await deleteRequest({
                type: selectedData._type,
                item_id: selectedData.id,
              });
              await loadData();
            }}
            onClose={() => setSelectedData(undefined)}
            overConfirm={updatingItem ? confirmBulkItem : undefined}
            data={selectedData}
          />
        ) : (
          <></>
        ))}
      {!!mapLoc && (
        <MapModal
          title={translate(lang, 'The data entry location')}
          visible={!!mapLoc}
          location={mapLoc}
          onClose={() => setMapLoc(undefined)}
        />
      )}
      {deletingItem && (
        <ModalComponent
          visible={true}
          disabled={disable}
          onCancel={() => setDeletingItem(undefined)}
          type='delete'
          title={translate(lang, 'Delete')}
          text={translate(lang, 'Are you sure to delete this data?')}
          onConfirm={deleteQueueItem}
        />
      )}
      {showCustom && (
        <PaperFormModal
          visible={showCustom}
          onClose={() => setShowCustom(false)}
        />
      )}
    </div>
  );
};

export default QueuedDataPage;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { updateUserMeta } from '../../store/auth/auth.actions';
import { Button, Input, Title } from '../shared';
import { selectUserMeta } from '../../store/auth/auth.selector';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';

const LineAssessExpireDaysInput = () => {
  const dispatch = useDispatch();
  const userMeta = useSelector(selectUserMeta);
  const lang = useSelector(selectLang);

  const [val, setVal] = useState('');

  const handleInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setVal(event.target.value);
  };
  const submitVal = () => {
    let line_assess_expire_days = parseInt(val);
    if (isNaN(line_assess_expire_days) || line_assess_expire_days < 0) return;
    sendSingleRequest(
      { line_assess_expire_days },
      'POST',
      `api/user-meta`,
      true,
    ).then(() => dispatch(updateUserMeta()));
  };

  useEffect(() => {
    setVal((userMeta?.line_assess_expire_days ?? 90).toString());
  }, [userMeta?.line_assess_expire_days]);

  return (
    <div>
      <div className='d-flex mb-16 mt-27 align-items-center justify-content-between'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Line Assessment Expire Days')}
        </Title>
      </div>
      <div className='ml-21'>
        <Input
          label={translate(lang, 'Line Assessment Expire Days')}
          type='number'
          value={val}
          placeholder=''
          onChange={handleInput}
        />
        <Button
          className='mt-16 mb-16'
          type='fill'
          width='small'
          size={0}
          color='blue'
          onClick={submitVal}
        >
          {translate(lang, 'Update')}
        </Button>
      </div>
    </div>
  );
};

export default LineAssessExpireDaysInput;

import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, Collapse } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import classnames from 'classnames';
import { useWidth } from '../../util/useWidth';
import { createUser, updateUser } from '../../store/users/users.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { checkRolePermission } from '../../entities/util-functions';
import { sendSingleRequest } from '../../apis';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  Button,
  CaretDownIcon,
  CheckboxButton,
  Input,
  Paragrapgh,
  RadioButton,
  Spinner,
  Title,
  ToggleButton,
} from '../../components/shared';

interface ILinesUser {
  name?: string;
  id?: string;
  value?: boolean;
}

interface IFarmsUser {
  title: string;
  id: string;
  typeChecked: string;
  lines: ILinesUser[];
}

const list = [
  { label: 'View', value: false, key: '1' },
  { label: 'Edit', value: false, key: '2' },
  { label: 'Show financials', value: false, key: '3' },
  { label: 'Manage farm and lists', value: false, key: 'manage' },
];

const AddUsers = () => {
  const params = useParams<{ id: string }>();
  const width = useWidth();
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const profile = useSelector(selectProfile);
  const farmsData = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [disabled, setDisabled] = useState(false);
  const [isValidateEmail, setIsValidateEmail] = useState(false);
  const { Panel } = useMemo(() => Collapse, []);
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState<
    'admin' | 'user' | 'processing_plant'
  >('admin');
  const [isSpinner, setIsSpinner] = useState(true);
  const [switchList, setSwitchList] = useState<any>([]);
  const [farms, setFarms] = useState<IFarmsUser[]>([]);

  const getUser = async (defaultFarms: IFarmsUser[] = []) => {
    const res = await sendSingleRequest(
      { user_id: Number(params?.id) },
      'POST',
      'api/user/role-permissions',
      true,
    );
    if (res.status && res.data.data) {
      let newPermissions = [...list];
      if (res.data.data.view_permission) newPermissions[0].value = true;
      if (res.data.data.edit_permission) newPermissions[1].value = true;
      if (res.data.data.finance_permission) newPermissions[2].value = true;
      if (defaultFarms.length > 0) newPermissions[3].value = true;

      setSwitchList(newPermissions);
      setEmail(res?.data?.email ? res?.data?.email : '');
      setUserType(res.data.data.role);

      /* eslint eqeqeq: 1 */
      const defaultFarm: IFarmsUser[] = defaultFarms.map(farm => {
        let counter = 0;
        const newLine = farm.lines.map(line => {
          if (
            res.data.data.lines.some(
              (item: any) => Number(item.id) === Number(line.id),
            )
          ) {
            counter += 1;
            return { ...line, value: true };
          }
          return { ...line, value: false };
        });
        if (
          farm.lines.length === counter &&
          res.data.data.farms.find((item: any) => item.farm_id === farm.id)
        ) {
          return { ...farm, typeChecked: 'full', lines: newLine };
        }
        if (counter) {
          return { ...farm, typeChecked: 'part', lines: newLine };
        }
        return { ...farm, line: newLine };
      });
      setFarms(defaultFarm);
    }
    setIsSpinner(false);
  };

  useEffect(() => {
    const defaultFarm: IFarmsUser[] = farmsData.map(farm => {
      const newLine = farm.lines.map(line => {
        return {
          name: line?.line_name as string,
          id: line?.id.toString(),
          value: false,
        };
      });
      return {
        title: farm.name as string,
        id: farm?.id?.toString() as string,
        typeChecked: 'empty',
        lines: newLine,
      };
    });

    if (params?.id) {
      getUser(defaultFarm);
    } else {
      setUserType('admin');
      setSwitchList(list);
      setFarms(defaultFarm);
      setIsSpinner(false);
    }
  }, []);

  const handleOnEmail = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setEmail(e.target.value);
  };

  const onChangeUserType = (e: RadioChangeEvent) => {
    setUserType(e.target.value);
  };

  const handleOnPermissions = (e: boolean, key: string) => {
    setSwitchList(
      switchList.map((item: any) => {
        if (item.key === key) return { ...item, value: e };
        if (key === '2' && e && item.key === '1') return { ...item, value: e };
        return item;
      }),
    );
  };

  const handleOnFarmCheck = (checked: boolean, farmId: string) => {
    const newArr = farms.map(farm => {
      if (farm.id === farmId) {
        const newLine = farm.lines.map(line => {
          return { ...line, value: checked };
        });
        if (checked) {
          return { ...farm, typeChecked: 'full', lines: newLine };
        }
        return { ...farm, typeChecked: 'empty', lines: newLine };
      }
      return farm;
    });

    setFarms(newArr);
  };

  const genExtra = (type: string, farmId: string) => (
    <CheckboxButton
      disabled={!switchList[switchList.length - 1].value}
      label=''
      checked={type === 'part' || type === 'full'}
      isNegative={type === 'part'}
      onChange={e => handleOnFarmCheck(e.target.checked, farmId)}
    />
  );

  const CaretLeftOutlined = ({ rotate }: any) => (
    <div
      className={classnames(
        'accordion__icon',
        `accordion__icon--rotate-${rotate}`,
      )}
    >
      <CaretDownIcon />
    </div>
  );

  const handleOnSelectFarm = (
    checked: boolean,
    farmId: string,
    lineId: string,
  ) => {
    let counter = 0;
    const newArr = farms.map(farm => {
      if (farm.id === farmId) {
        const newLine = farm.lines.map(line => {
          if (line.id === lineId) {
            /* eslint-disable*/
            if (checked) counter++;
            return { ...line, value: checked };
          }
          if (line.value) counter++;
          return line;
        });
        if (farm.lines.length === counter) {
          return { ...farm, typeChecked: 'full', lines: newLine };
        }
        if (counter) {
          return { ...farm, typeChecked: 'part', lines: newLine };
        }
        return { ...farm, typeChecked: 'empty', lines: newLine };
      }
      return farm;
    });

    setFarms(newArr);
  };

  const handleOnConfirm = async () => {
    setDisabled(true);
    const permissions = getPermissions();
    const farmsAndLines = getFarms();
    let data: any = {
      email,
      inviting_user_id: Number(profile?.id),
      account_id: Number(profile?.account_id),
      role: userType,
      ...permissions,
      farms_id: farmsAndLines.farm_id,
      lines_id: farmsAndLines.line_id,
    };
    let res: any = true;
    if (params?.id) {
      data.user_id = Number(params?.id);
      delete data.inviting_user_id;
      delete data.email;
      res = await dispatch(updateUser(data, history));
    } else {
      res = await dispatch(createUser(data, history));
    }
    setDisabled(false);
    if (res === true) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(
            lang,
            params?.id
              ? 'Updated user successfully'
              : 'Invitation has been sent',
          ),
        }),
      );
      history.replace('/users');
    } else {
      dispatch(showFeedback({ isMessage: true, type: 'error', message: res }));
    }
  };

  const getPermissions = () => {
    if (userType === 'admin') {
      return {
        view_permission: true,
        edit_permission: true,
        finance_permission: true,
      };
    } else if (userType === 'processing_plant') {
      return {
        view_permission: true,
        edit_permission: false,
        finance_permission: false,
      };
    }
    let permissions = {
      view_permission: false,
      edit_permission: false,
      finance_permission: false,
    };
    switchList.map((item: any, index: number) => {
      if (item.value && switchList.length - 1 !== index) {
        if (item.key === '1') {
          permissions.view_permission = true;
        } else if (item.key === '2') {
          permissions.edit_permission = true;
        } else if (item.key === '3') {
          permissions.finance_permission = true;
        }
      }
    });
    return permissions;
  };

  const getFarms = () => {
    let counterFarm = 0;
    let counterLine = 0;
    let farm_id: any = null;
    let line_id: any = null;
    if (
      checkRolePermission(
        { deniedRoles: ['admin', 'processing_plant'] },
        userType,
      )
    ) {
      farm_id = line_id = {};
      farms.map((farm, index) => {
        farm.lines?.map(line => {
          if (line.value) {
            line_id = {
              ...line_id,
              [counterLine]: Number(line.id),
            };
            counterLine++;
          }
        });
        if (farm.typeChecked !== 'empty') {
          farm_id = {
            ...farm_id,
            [counterFarm]: Number(farm.id),
          };
          counterFarm++;
        }
      });
    }
    return { farm_id, line_id };
  };

  return (
    <>
      <div className='bg-secondary min-height d-flex justify-content-center'>
        <div className='card user-card'>
          <div className='card-wrapper'>
            <Title
              className='mb-20'
              size={5}
              color='black'
              align='default'
              fontWeight={700}
            >
              {translate(lang, params?.id ? 'Edit user' : 'Add new user')}
            </Title>
            {isSpinner ? (
              <div className='mt-20'>
                <Spinner />
              </div>
            ) : (
              <>
                <Input
                  label={translate(lang, 'Email')}
                  placeholder=''
                  className='mb-24'
                  type='email'
                  onChange={handleOnEmail}
                  value={email}
                  dataType='email'
                  onValidate={(e, data) => setIsValidateEmail(!e)}
                  disabled={params?.id ? true : false}
                />
                <Paragrapgh
                  size={1}
                  color='black'
                  align='left'
                  fontWeight={500}
                >
                  {translate(lang, 'User type')}
                </Paragrapgh>
                <Radio.Group
                  className='d-flex mt-14 mb-32'
                  onChange={onChangeUserType}
                  value={userType}
                >
                  <RadioButton
                    label={translate(lang, 'Admin')}
                    value={'admin'}
                  />
                  <RadioButton
                    className='ml-34'
                    label={translate(lang, 'User')}
                    value={'user'}
                  />
                  <RadioButton
                    className='ml-34'
                    label={translate(lang, 'Processing plant')}
                    value={'processing_plant'}
                  />
                </Radio.Group>
                {checkRolePermission(
                  { deniedRoles: ['admin', 'owner', 'processing_plant'] },
                  userType,
                ) && (
                  <>
                    <Paragrapgh
                      className='mb-14'
                      size={1}
                      color='black'
                      align='left'
                      fontWeight={500}
                    >
                      {translate(lang, 'Permissions')}
                    </Paragrapgh>
                    <div className='w-100 mb-16'>
                      {switchList &&
                        switchList.map((item: any) => (
                          <ToggleButton
                            key={item.key}
                            className='mb-16'
                            label={item.label}
                            isfullWidth
                            checked={item.value}
                            isLeftText
                            onChange={e => handleOnPermissions(e, item.key)}
                          />
                        ))}
                    </div>
                    <Paragrapgh
                      className='mb-8'
                      size={2}
                      color='black-2'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'Select farm and lines')}
                    </Paragrapgh>
                    <div className='user-collapse'>
                      <Collapse
                        expandIcon={({ isActive }) => (
                          <CaretLeftOutlined
                            rotate={
                              isActive &&
                              switchList[switchList.length - 1].value
                                ? 180
                                : 0
                            }
                          />
                        )}
                      >
                        {farms.map(farm => (
                          <Panel
                            className={
                              switchList[switchList.length - 1].value
                                ? 'ant-collapse-item'
                                : 'ant-collapse-item-disabled'
                            }
                            header={farm.title}
                            key={farm.id}
                            extra={genExtra(farm.typeChecked, farm.id)}
                          >
                            {farm.lines.map(line => (
                              <CheckboxButton
                                key={line?.id}
                                label={line?.name as string}
                                checked={line?.value}
                                isfullWidth
                                isLeftText
                                onChange={e =>
                                  handleOnSelectFarm(
                                    e.target.checked,
                                    farm.id,
                                    line?.id as string,
                                  )
                                }
                              />
                            ))}
                          </Panel>
                        ))}
                      </Collapse>
                    </div>
                  </>
                )}
                <div className='mt-24 user-button d-flex justify-content-end align-items-center'>
                  <Link to='/users'>
                    <Button
                      width={width < 769 ? 'wide' : 'default'}
                      size={1}
                      type='transparent'
                      color='blue'
                    >
                      {translate(lang, 'Cancel')}
                    </Button>
                  </Link>
                  <Button
                    width={width < 769 ? 'wide' : 'default'}
                    size={1}
                    type='fill'
                    color='blue'
                    className='ml-16'
                    disabled={disabled || isValidateEmail}
                    onClick={handleOnConfirm}
                  >
                    {translate(lang, 'Confirm')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUsers;

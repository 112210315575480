import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectUtils = createSelector(
  (state: RootState) => state.utils.farmUtils,
  utils => utils,
);

export const selectSeasons = createSelector(
  (state: RootState) => state.utils.farmUtils,
  seasons => seasons.filter(x => x.type === 'season'),
);

export const selectPusher = createSelector(
  (state: RootState) => state.utils,
  utils => ({ key: utils.pusher_key, cluster: utils.pusher_cluster }),
);

export const selectServerEnv = createSelector(
  (state: RootState) => state.utils.server_env,
  env => env,
);

export const selectFarmingMethods = createSelector(
  (state: RootState) => state.utils.farmUtils,
  methods => methods.filter(x => x.type === 'farming_method'),
);

export const selectOysterHarvestFields = createSelector(
  (state: RootState) => state.utils.farmUtils,
  fields => fields.filter(x => x.type === 'oyster_harvest'),
);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UtilsTable from '../../components/farm-util/UtilsTable';
import { loadUtilsData } from '../../store/utils/utils.actions';
import { selectUtils } from '../../store/utils/utils.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const StaticFieldsSetting = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const farmUtils = useSelector(selectUtils);
  const ports = farmUtils.filter(x => x.type === 'port_of_unload'),
    delivers = farmUtils.filter(x => x.type === 'delivered_to'),
    packhouses = farmUtils.filter(x => x.type === 'packhouse'),
    seasons = farmUtils.filter(x => x.type === 'season'),
    bioTypes = farmUtils.filter(x => x.type === 'biosecurity_type'),
    farmingMethods = farmUtils.filter(x => x.type === 'farming_method'),
    expenseItems = farmUtils.filter(x => x.type === 'expense_item'),
    customers = farmUtils.filter(x => x.type === 'customer');

  useEffect(() => {
    dispatch(loadUtilsData());
  }, [dispatch]);

  return (
    <div className='content pb-32'>
      <div className='mb-32'>
        <UtilsTable
          data={seasons}
          type='season'
          label={`${translate(lang, 'Season')} ${translate(lang, 'options')}`}
          btnLabel={`${translate(lang, 'Add')} ${translate(
            lang,
            'Season',
          )} ${translate(lang, 'Option')}`}
        />
      </div>
      <div className='mb-32'>
        <UtilsTable
          data={ports}
          type='port_of_unload'
          label={`${translate(lang, 'Port of unload')} ${translate(
            lang,
            'options',
          )}`}
          btnLabel={`${translate(lang, 'Add')} ${translate(
            lang,
            'Port of unload',
          )} ${translate(lang, 'Option')}`}
        />
      </div>
      <div className='mb-32'>
        <UtilsTable
          data={delivers}
          type='delivered_to'
          label={`${translate(lang, 'Delivered to')} ${translate(
            lang,
            'options',
          )}`}
          btnLabel={`${translate(lang, 'Add')} ${translate(
            lang,
            'Delivered to',
          )} ${translate(lang, 'Option')}`}
        />
      </div>
      <div className='mb-32'>
        <UtilsTable
          data={packhouses}
          type='packhouse'
          label={`${translate(lang, 'Packhouse')} ${translate(
            lang,
            'options',
          )}`}
          btnLabel={`${translate(lang, 'Add')} ${translate(
            lang,
            'Packhouse',
          )} ${translate(lang, 'Option')}`}
        />
      </div>
      <div className='mb-32'>
        <UtilsTable
          data={bioTypes}
          type='biosecurity_type'
          label={`${translate(lang, 'Biosecurity type')} ${translate(
            lang,
            'options',
          )}`}
          btnLabel={`${translate(lang, 'Add')} ${translate(
            lang,
            'Biosecurity type',
          )} ${translate(lang, 'Option')}`}
        />
      </div>
      <div className='mb-32'>
        <UtilsTable
          data={farmingMethods}
          type='farming_method'
          label={`${translate(lang, 'Farming method')} ${translate(
            lang,
            'options',
          )}`}
          btnLabel={`${translate(lang, 'Add')} ${translate(
            lang,
            'Farming method',
          )} ${translate(lang, 'Option')}`}
        />
      </div>
      <div className='mb-32'>
        <UtilsTable
          data={expenseItems}
          type='expense_item'
          label={`${translate(lang, 'Expense item')} ${translate(
            lang,
            'options',
          )}`}
          btnLabel={`${translate(lang, 'Add')} ${translate(
            lang,
            'Expense item',
          )} ${translate(lang, 'Option')}`}
        />
      </div>
      <div className='mb-32'>
        <UtilsTable
          data={customers}
          label={translate(lang, 'Customer')}
          type='customer'
          flagLabel={translate(lang, 'Internal Customer')}
          flagPlaceholder={translate(
            lang,
            'Internal customer in most cases means your company so you can calculate your own costs',
          )}
        />
      </div>
    </div>
  );
};

export default StaticFieldsSetting;

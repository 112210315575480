import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useWidth } from '../../util/useWidth';
import {
  Button,
  Title,
  TablesCard,
  Spinner,
  PlusIcon,
  FarmIcon,
  Input,
} from '../../components/shared';
import GrowerImportSelectModal from '../../components/farm-modals/GrowerImportSelectModal';
import { checkRolePermission } from '../../entities/util-functions';
import { sendSingleRequest } from '../../apis';
import FarmsTable from '../../components/shared/tables/FarmsTable';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang, selectSpin } from '../../store/ui/ui.selector';
import { IFarmCard } from '../../entities/farms.entities';
import FarmsMobile from '../../components/shared/table-mobile/FarmsMobile';
import { translate } from '../../lib/lang.helper';
import { selectAllFarms } from '../../store/extra/extra.selector';
import { toMillisecond } from '../../util/toggleSecondMillisecond';
import moment from 'moment';
import './styles.scss';

const Farms = () => {
  const width = useWidth();
  const history = useHistory();

  const lang = useSelector(selectLang);
  const allFarms = useSelector(selectAllFarms);
  const profile = useSelector(selectProfile);
  const isEditable = profile?.edit_permission;
  const isSpinner = useSelector(selectSpin);

  const [total, setTotal] = useState<IFarmCard[]>([]);
  const [isCardsSpinner, setIsCardsSpinner] = useState(false);
  const [visibleImport, setVisibleImport] = useState(false);

  const getTotals = useCallback(async () => {
    const response = await sendSingleRequest(
      {},
      'GET',
      'api/overview/farm-review',
      true,
    );
    if (response.status) {
      setTotal(response.data);
    }
  }, []);

  const { nextSeedData, nextHarvestData } = useMemo(() => {
    let nextSeedData: IFarmCard[] = [];
    let nextHarvestData: IFarmCard[] = [];
    for (const farm of allFarms) {
      for (const line of farm.lines) {
        if (
          line.growing_cycle &&
          toMillisecond(line.growing_cycle.main_seed.planned_date_seed) >=
            Date.now()
        ) {
          nextSeedData.push({
            name: `${farm.name} - ${line.line_name}`,
            date: line.growing_cycle.main_seed.planned_date_seed,
          });
        }
        if (line.growing_cycle && line.growing_cycle.ready_harvest) {
          const rd = toMillisecond(line.growing_cycle.ready_harvest);
          nextHarvestData.push({
            name: `${farm.name} - ${line.line_name}`,
            date: rd,
            unit: translate(
              lang,
              'Ready for %s days',
              moment().diff(rd, 'days'),
            ),
          });
        }
      }
    }
    nextSeedData.sort((a, b) => (a.date ?? 0) - (b.date ?? 0));
    nextSeedData = nextSeedData.slice(0, 3);
    nextHarvestData.sort((a, b) => (a.date ?? 0) - (b.date ?? 0));
    nextHarvestData = nextHarvestData.slice(0, 3);

    return { nextSeedData, nextHarvestData };
  }, [allFarms]);

  const loadData = useCallback(async () => {
    setIsCardsSpinner(true);
    await getTotals();
    setIsCardsSpinner(false);
  }, []);

  const [searchKey, setSearchKey] = useState('');

  const filteredFarms = useMemo(
    () =>
      allFarms.filter(
        x =>
          x.farm_number.toLowerCase().includes(searchKey.toLowerCase()) ||
          x.name.toLowerCase().includes(searchKey.toLowerCase()),
      ),
    [allFarms, searchKey],
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        <div className='farms'>
          <div className='farms__header d-flex justify-content-between align-items-center'>
            <div>
              <Title size={5} color='black-3' align='default' fontWeight={700}>
                {translate(lang, 'Farms')}
              </Title>
              {checkRolePermission(
                { deniedRoles: ['processing_plant'] },
                profile?.role,
              ) && (
                <div>
                  <Button
                    size={width > 768 ? 3 : 5}
                    type='fill'
                    width='small'
                    color='blue'
                    onClick={() => history.push('/lines')}
                  >
                    {translate(lang, 'All lines')}
                  </Button>
                  <Button
                    size={width > 768 ? 3 : 5}
                    type='fill'
                    width='small'
                    color='blue'
                    className={width > 768 ? 'ml-7' : 'mt-7'}
                    onClick={() => history.push('/farms/visual-mode')}
                  >
                    {translate(lang, 'Farms map')}
                  </Button>
                  {profile?.account_type === 'grower' ? (
                    <Button
                      size={width > 768 ? 3 : 5}
                      type='bordered'
                      width='small'
                      color='green'
                      className={width > 768 ? 'ml-7' : 'mt-7'}
                      onClick={() => setVisibleImport(true)}
                    >
                      {translate(lang, 'Import')}
                    </Button>
                  ) : (
                    <Button
                      size={width > 768 ? 3 : 5}
                      type='fill'
                      width='small'
                      color='blue'
                      className={width > 768 ? 'ml-7' : 'mt-7'}
                      onClick={() => history.push('/line-spats')}
                    >
                      {translate(lang, 'Spat count')}
                    </Button>
                  )}
                </div>
              )}
            </div>
            {!!isEditable && (
              <div className='d-flex justify-content-right align-items-center'>
                <Link to='/farms/farm-add'>
                  {width > 768 ? (
                    <Button color='blue' size={1} width='middle' type='fill'>
                      {translate(lang, 'Add Farm')}
                    </Button>
                  ) : (
                    <Button
                      color='blue'
                      size={0}
                      width='default'
                      type='fill'
                      iconOnly
                    >
                      <PlusIcon />
                    </Button>
                  )}
                </Link>
                {profile.account_type === 'operator' && (
                  <Link className='ml-10' to='/line-group'>
                    {width > 768 ? (
                      <Button color='green' size={1} width='middle' type='fill'>
                        {translate(lang, 'Line Group')}
                      </Button>
                    ) : (
                      <Button
                        color='blue'
                        size={0}
                        width='default'
                        type='fill'
                        iconOnly
                      >
                        <FarmIcon />
                      </Button>
                    )}
                  </Link>
                )}
              </div>
            )}
          </div>
          <div className='farms__content d-flex justify-content-between'>
            <div className='width-100 pos-relative mxw-100 overflow-auto'>
              <div className='mb-17' style={{ maxWidth: '350px' }}>
                <Input
                  type='text'
                  label=''
                  placeholder={translate(lang, 'Search farms')}
                  value={searchKey}
                  onChange={e => setSearchKey(e.target.value)}
                />
              </div>
              {width > 768 ? (
                <FarmsTable farmsData={filteredFarms} />
              ) : (
                <FarmsMobile data={filteredFarms} />
              )}
            </div>
            <div className='farms__content-cards flex-basis-32'>
              {!isCardsSpinner ? (
                <>
                  <TablesCard data={nextSeedData} type='next-seed' />
                  <TablesCard data={nextHarvestData} type='next-harvest' />
                  {total?.length > 0 && <TablesCard data={total} />}
                </>
              ) : (
                <div className='mt-24'>
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
        {isSpinner && <Spinner position='global' />}
      </div>
      {visibleImport && (
        <GrowerImportSelectModal
          visible={visibleImport}
          onClose={() => setVisibleImport(false)}
          onConfirm={() => {
            setVisibleImport(false);
            loadData();
          }}
        />
      )}
    </div>
  );
};

export default Farms;

import { useEffect, useMemo, useState } from 'react';
import { IHarvestResource } from '../../entities/growing.entities';
import { toMillisecond, toSecond } from '../../util/toggleSecondMillisecond';
import { Bar } from 'react-chartjs-2';
import { sendSingleRequest } from '../../apis';
import { Spinner, Subtitle } from '../../components/shared';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectTasks } from '../../store/tasks/tasks.selector';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

function getLastSixMonths(): string[] {
  const months = [];
  const date = new Date();
  for (let i = 5; i >= 0; i--) {
    const d = new Date(date.getFullYear(), date.getMonth() - i, 1);
    months.push(d.toLocaleString('default', { month: 'long' }));
  }
  return months;
}

const PerformanceCharts = () => {
  const tasks = useSelector(selectTasks);
  const farms = useSelector(selectFarmsData);
  const lang = useSelector(selectLang);

  const [harvests, setHarvests] = useState<IHarvestResource[]>([]);
  const [loadingHarvests, setLoadingHarvests] = useState(false);
  const [loadingPerformance, setLoadingPerformance] = useState(false);
  const [performance, setPerformance] = useState({
    harvests: 0,
    assessments: 0,
    seedings: 0,
    maintenances: 0,
    tasks: 0,
  });

  const harvestsData = useMemo(() => {
    const months = getLastSixMonths();
    let musselSums: number[] = months.map(x => 0),
      oysterSums: number[] = months.map(x => 0);
    for (let harvest of harvests) {
      if (!harvest.is_final) continue;

      const month = new Date(
        toMillisecond(harvest.complete_date),
      ).toLocaleString('default', { month: 'long' });

      const index = months.indexOf(month);
      if (index >= 0) {
        const f = farms.find(x => x.id === harvest.line.farm_id);
        if (f?.type === 'MUSSEL') {
          musselSums[index] += harvest.amount;
        } else if (f?.type === 'OYSTER') {
          oysterSums[index] += harvest.amount;
        }
      }
    }
    let datasets = [
      {
        data: musselSums,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
        label: translate(lang, 'Mussels'),
      },
    ];
    if (!musselSums.some(x => x > 0)) {
      datasets = [];
    }
    if (oysterSums.some(y => y > 0)) {
      datasets.push({
        data: oysterSums,
        backgroundColor: 'rgba(22, 163, 74, 0.2)',
        borderColor: 'rgba(22, 163, 74, 1)',
        borderWidth: 1,
        label: translate(lang, 'Oysters'),
      });
    }
    return {
      labels: months,
      datasets,
    };
  }, [harvests, farms]);

  const performData = useMemo(
    () => ({
      labels: [
        translate(lang, 'Harvest completed'),
        translate(lang, 'Assessments'),
        translate(lang, 'Seeding jobs'),
        translate(lang, 'Maintenance jobs'),
        translate(lang, 'Tasks completed'),
      ],
      datasets: [
        {
          data: [
            performance.harvests,
            performance.assessments,
            performance.seedings,
            performance.maintenances,
            performance.tasks,
          ],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
        },
      ],
    }),
    [performance],
  );

  useEffect(() => {
    (async () => {
      setLoadingHarvests(true);
      const today = new Date();
      const fDay = new Date(today.getFullYear(), today.getMonth() - 6, 1);
      const min_date = toSecond(today.getTime());
      const max_date = toSecond(fDay.getTime());
      const response = await sendSingleRequest(
        { min_date, max_date },
        'GET',
        'api/overview/harvests',
        true,
      );
      setLoadingHarvests(false);
      if (response.status) {
        setHarvests(response.data);
      } else {
        window.alert(
          translate(lang, response.data?.message ?? 'Unknown error'),
        );
      }
    })();

    (async () => {
      setLoadingPerformance(true);
      const min_date = toSecond(
        moment().add(-30, 'days').startOf('day').toDate().getTime(),
      );
      const max_date = toSecond(
        moment().add(1, 'days').endOf('day').toDate().getTime(),
      );
      const response = await sendSingleRequest(
        { min_date, max_date },
        'GET',
        'api/overview/entries',
        true,
      );
      setLoadingPerformance(false);
      if (response.status) {
        const seedings = response.data.seedings.length;
        const assessments = response.data.assessments.length;
        const maintenances = response.data.maintenances.length;
        const harvests = response.data.harvests.length;
        setPerformance(prev => ({
          ...prev,
          seedings,
          assessments,
          maintenances,
          harvests,
        }));
      } else {
        window.alert(
          translate(lang, response.data?.message ?? 'Unknown error'),
        );
      }
    })();
  }, []);

  useEffect(() => {
    const cnt = tasks.filter(
      x =>
        x.is_completed &&
        x.updated_at &&
        x.updated_at >=
          toSecond(moment().add(-30, 'days').startOf('day').toDate().getTime()),
    ).length;
    setPerformance(prev => ({
      ...prev,
      tasks: cnt,
    }));
  }, [tasks]);

  return (
    <div className='mt-32 pb-32'>
      <div className='pt-32 pb-32'>
        <Subtitle size={4} color='black' align='center' fontWeight={600}>
          {translate(lang, 'Final Harvest Performance')}
          {harvestsData.datasets.length > 1
            ? ' (kgs/dozens)'
            : harvestsData.datasets.length <= 0 ||
              harvestsData.datasets[0].label === 'Mussels'
            ? ' (kgs)'
            : ' (dozens)'}
        </Subtitle>
        {loadingHarvests ? (
          <div className='mt-32 mb-32'>
            <Spinner />
          </div>
        ) : (
          <div className='mt-16'>
            <Bar
              data={harvestsData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: harvestsData.datasets.length > 1 ? true : false,
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
            />
          </div>
        )}
        <Link className='mt-24' to='/summary/harvest'>
          <Button type='primary'>{translate(lang, 'View Harvests')}</Button>
        </Link>
      </div>
      <div className='pt-32 pb-32'>
        <Subtitle size={4} color='black' align='center' fontWeight={600}>
          {translate(lang, 'Farm Performance %s days', 30)}
        </Subtitle>
        {loadingPerformance ? (
          <div className='mt-32 mb-32'>
            <Spinner />
          </div>
        ) : (
          <div className='mt-16'>
            <Bar
              data={performData}
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    beginAtZero: true,
                  },
                },
                indexAxis: 'y',
              }}
            />
          </div>
        )}
        <Link className='mt-24' to='/report'>
          <Button type='primary'>{translate(lang, 'View Reports')}</Button>
        </Link>
      </div>
    </div>
  );
};

export default PerformanceCharts;

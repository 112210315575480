import { RootState, IThunkType } from '../rootReducer';
import { IUsersAction } from './users.type';
import { sendSingleRequest } from '../../apis';

const setUsers = (users: IUsersAction): IUsersAction => users;

let loadingUsers = false;

export const getAllUsers = () => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingUsers) return true;
    loadingUsers = true;

    const response = await sendSingleRequest({}, 'GET', 'api/user/users', true);
    loadingUsers = false;

    if (response.status) {
      dispatch(setUsers({ type: 'USERS/SET_USERS', payload: response.data }));

      return true;
    } else {
      return response.data?.message;
    }
  };
};

export const createUser = (data: any, history: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const response = await sendSingleRequest(
      data,
      'POST',
      'api/user/invite',
      true,
    );
    if (response.status) {
      const users = [...getState().users.users, response.data.data];
      dispatch(setUsers({ type: 'USERS/SET_USERS', payload: users }));

      return true;
    } else {
      return response.data?.message ?? 'Failed to invite user';
    }
  };
};

export const updateUser = (data: any, history: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      data,
      'POST',
      'api/user/role-permissions-update',
      true,
    );

    if (res.status) {
      await dispatch(getAllUsers());
      return true;
    } else {
      return res.data?.message ?? 'Error';
    }
  };
};

export const deactivateUser = (id: number, history: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const response = await sendSingleRequest(
      { user_id: id },
      'POST',
      'api/user/deactivate',
      true,
    );
    if (response.status) {
      await dispatch(getAllUsers());

      return true;
    } else {
      return response.data?.message ?? 'Error';
    }
  };
};

export const activateUser = (id: string, history: any) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const response = await sendSingleRequest(
      { user_id: id },
      'POST',
      'api/user/activate-deactivated-user',
      true,
    );
    if (response.status) {
      await dispatch(getAllUsers());

      return true;
    } else {
      return response.data?.message ?? 'Error';
    }
  };
};

let loadingBoats = false;

export const loadUserBoats = (filter: any = {}) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    if (loadingBoats) return true;
    loadingBoats = true;

    const res = await sendSingleRequest(filter, 'GET', 'api/boat/boats', true);

    loadingBoats = false;

    if (res.status) {
      dispatch(setUsers({ type: 'USERS/SET_BOATS', payload: res.data }));
      return true;
    } else {
      return res.data?.message;
    }
  };
};

export const deleteUserBoat = (boatId: number) => {
  return async (dispatch: IThunkType, getState: () => RootState) => {
    const res = await sendSingleRequest(
      { boat_id: boatId },
      'DELETE',
      `api/boat/boat/${boatId}`,
      true,
    );
    if (res.status) {
      let boats = getState().users.boats ? [...getState().users.boats] : [];
      dispatch(
        setUsers({
          type: 'USERS/SET_BOATS',
          payload: boats.filter(x => x.id !== boatId),
        }),
      );
      return true;
    } else {
      return res.data?.message;
    }
  };
};

export const newMessageReceivedAction = (
  actionType: 'ACCOUNT' | 'USER' | 'BOAT',
  message: any,
) => {
  return (dispatch: IThunkType, getState: () => RootState) => {
    const type =
      actionType === 'ACCOUNT'
        ? 'USERS/SET_NEW_ACCOUNT_MESSAGE'
        : actionType === 'BOAT'
        ? 'USERS/SET_NEW_BOAT_MESSAGE'
        : 'USERS/SET_NEW_USER_MESSAGE';
    dispatch(setUsers({ type, payload: message }));
  };
};

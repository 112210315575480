import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  IAssessmentResource,
  IFarmWeatherResource,
  ILineResource,
  IMaintenanceResource,
  ISeedInventoryResource,
  ISeedManage,
} from '../../entities/farms.entities';
import { useWidth } from '../../util/useWidth';
import HarvestViewModal from '../view-modals/HarvestViewModal';
import AssessmentViewModal from '../view-modals/AssessmentViewModal';
import SeedViewModal from '../view-modals/SeedViewModal';
import MaintenanceViewModal from '../view-modals/MaintenanceViewModal';
import FloatsViewModal from '../view-modals/FloatsViewModal';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Dropdown, MinusIcon, PlusIcon, Spinner, Subtitle } from '../shared';
import { sendSingleRequest } from '../../apis';
import { formatNumber } from '../../entities/util-functions';
import {
  IHarvestResource,
  IMusselHarvest,
  IOysterHarvest,
  ISeedingResource,
} from '../../entities/growing.entities';
import { IName, TBusinessType } from '../../entities/general.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { selectAllFarms } from '../../store/extra/extra.selector';
import './styles.scss';

const seedPropLabel = (d: any) => {
  return d.line_length
    ? `Seeding ${d.line_length} m`
    : d.basket_count
    ? `Seeding ${d.basket_count} baskets`
    : `Seeding ${d.tank_area} m²`;
};

ChartJS.register(LinearScale, Title, PointElement, LineElement);

const borderColor = 'rgb(53, 162, 235)',
  backgroundColor = 'rgba(53, 162, 235, 0.5)',
  oneKnot = 1.94384;

interface ITagLog {
  id: number;
  tag: IName;
  is_on: boolean;
  logged_date: number;
  user?: IName;
}

interface IDataItem {
  type:
    | 'SEEDING'
    | 'ASSESSMENT'
    | 'MAINTENANCE'
    | 'INVENTORY_SEED'
    | 'HARVEST'
    | 'SEED_MANAGE'
    | 'TAG_LOG'
    | 'HARVEST_PLANNED';
  data:
    | ISeedingResource
    | IAssessmentResource
    | IMaintenanceResource
    | ISeedInventoryResource
    | IHarvestResource
    | ISeedManage
    | ITagLog;
}

interface ITimeItem {
  key: string;
  label: string | React.ReactElement;
  date: number;
  type: string;
  onClick: (d: any) => void;
}

interface IChartPoint {
  date: number;
  size: number;
}

const MtLabels = {
  VISUAL_ASSESSMENT: 'Visual Assessment',
  LINE_CLEANING: 'Line Cleaning',
  GENERAL_MAINTENANCE: 'General Maintenance',
};

const llFormat = (d: number) => moment(1000 * d).format('ll');
const toFloor = (d: number, a: number) =>
  Math.floor(
    moment(d * 1000)
      .add(a, 'd')
      .toDate()
      .getTime() / 1000,
  );

interface IDataset {
  label: string;
  data: Array<number | undefined>;
  borderColor: string;
  backgroundColor: string;
  tension?: number;
  yAxisID?: string;
  hidden?: boolean;
}

interface ICalChart {
  days: string[];
  labels: string[];
  data: Array<number | undefined>;
  avgSize: number;
}

const calcChart = (points: IChartPoint[], isGrowing: boolean): ICalChart => {
  if (
    isGrowing &&
    new Date().getTime() - points[points.length - 1].date > 86400000
  ) {
    points.push({
      date: Math.floor(Date.now() / 1000),
      size: points[points.length - 1].size,
    });
  }
  let days: string[] = [],
    values: any = {};
  for (let pt of points) {
    const c = moment(1000 * pt.date).format('YYYY-MM-DD');
    if (days.length <= 0) {
      days.push(c);
    } else {
      while (days[days.length - 1] < c) {
        days.push(
          moment(days[days.length - 1])
            .add(1, 'day')
            .format('YYYY-MM-DD'),
        );
      }
    }
    if (!values[c]) {
      values[c] = [pt.size];
    } else {
      values[c].push(pt.size);
    }
  }
  let result: Array<number | undefined> = [];
  for (let i = 0, j = 0; i < days.length; i = j) {
    for (j = i + 1; j < days.length && !values[days[j]]; j++);
    const s = (values[days[i]][0] + values[days[i]].slice(-1)[0]) / 2;
    result.push(s);
    for (let k = i + 1; k < j; k++) result.push(undefined);
  }
  let labels: string[] = [];
  const needY = days[0].slice(0, 4) !== new Date().getFullYear().toString();
  for (let i = 0, Y = '', M = ''; i < days.length; i++) {
    const ty = days[i].slice(0, 4),
      tm = days[i].slice(5, 7);
    if (ty !== Y && needY) {
      Y = ty;
      M = tm;
      labels.push(moment(days[i]).format('MMM Do, YY'));
    } else if (tm !== M) {
      labels.push(moment(days[i]).format('MMM Do'));
      M = tm;
    } else {
      labels.push(moment(days[i]).format('Do'));
    }
  }
  return {
    days,
    labels,
    data: result,
    avgSize:
      (points[points.length - 1].size - points[0].size) /
      Math.max(
        1,
        moment(1000 * points[points.length - 1].date).diff(
          moment(1000 * points[0].date),
          'days',
        ),
      ),
  };
};

interface Props {
  type: TBusinessType;
  dataItems: IDataItem[];
  isGrowing?: boolean;
  weathers?: IFarmWeatherResource[];
}

const TimelineView = ({
  type: businessType,
  dataItems,
  isGrowing,
  weathers,
}: Props) => {
  const width = useWidth();
  const farmsData = useSelector(selectAllFarms);
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(false);
  const [visSeed, setVisSeed] = useState<ISeedingResource>();
  const [visAssess, setVisAssess] = useState<IAssessmentResource>();
  const [visMain, setVisMain] = useState<IMaintenanceResource>();
  const [visFloat, setVisFloat] = useState<ISeedInventoryResource>();
  const [assistSeed, setAssistSeed] = useState<ISeedingResource>();
  const [viewHar, setViewHar] = useState<IHarvestResource>();
  const [compLine, setCompLine] = useState<ILineResource>();
  const [compPoints, setCompPoints] = useState<IChartPoint[]>();
  const [height, setHeight] = useState(70);
  const [showLine, setShowLine] = useState(true);

  const { days, items, points, dateFrom, dateTo } = useMemo(() => {
    let items: ITimeItem[] = [],
      points: IChartPoint[] = [];
    for (let t of dataItems) {
      const { type, data } = t;
      if (type === 'SEEDING') {
        const d: ISeedingResource = data as any;
        const farm = farmsData.find(x => x.id === d.line.farm_id);
        items.push({
          key: `s-${d.id}`,
          type,
          label: (
            <>
              <span>
                {`${farm?.name} - ${d.line.line_name}`}
                <br />
              </span>
              <span>
                {`Season ${d.season_name}`}
                <br />
              </span>
              <span>{seedPropLabel(d)}</span>
            </>
          ),
          date: d.planned_date_seed,
          onClick: () => setVisSeed(d),
        });
        if (d.spat_size) {
          const size = d.spat_size_max
            ? (d.spat_size + d.spat_size_max) / 2
            : d.spat_size;
          points.push({ size, date: d.planned_date_seed });
        }
      } else if (type === 'ASSESSMENT') {
        const d: IAssessmentResource = data as any;
        const seed = dataItems.find(
          x => x.type === 'SEEDING' && x.data.id === d.seeding_id,
        )?.data as ISeedingResource;
        const farm = farmsData.find(x => x.id === seed.line.farm_id);
        items.push({
          key: `a-${d.id}`,
          type,
          label: (
            <>
              <span>
                {`${farm?.name} - ${seed.line.line_name}`}
                <br />
              </span>
              <span>{`Assessment ${d.shell_size.min} ~ ${d.shell_size.max}, ${d.shell_size.avg} mm`}</span>
            </>
          ),
          date: d.assessment_date,
          onClick: () => {
            setVisAssess(d);
            setAssistSeed(seed);
          },
        });
        points.push({ date: d.assessment_date, size: d.shell_size.avg });
      } else if (type === 'MAINTENANCE') {
        const d: IMaintenanceResource = data as any;
        const seed = dataItems.find(
          x => x.type === 'SEEDING' && x.data.id === d.seeding_id,
        )?.data as ISeedingResource;
        const farm = farmsData.find(x => x.id === seed.line.farm_id);
        items.push({
          key: `m-${d.id}`,
          type,
          label: (
            <>
              <span>
                {`${farm?.name} - ${seed.line.line_name}`}
                <br />
              </span>
              <span>
                {translate(lang, 'Maintenance')}
                <br />
              </span>
              <span>{MtLabels[d.type] ?? d.type}</span>
            </>
          ),
          date: d.maintain_date,
          onClick: () => {
            setVisMain(d);
            setAssistSeed(seed);
          },
        });
      } else if (type === 'INVENTORY_SEED') {
        const d: ISeedInventoryResource = data as any;
        const seed = dataItems.find(
          x => x.type === 'SEEDING' && x.data.id === d.seeding_id,
        )?.data as ISeedingResource;
        const farm = farmsData.find(x => x.id === seed.line.farm_id);
        items.push({
          key: `i-${d.id}`,
          type,
          label: (
            <>
              <span>
                {`${farm?.name} - ${seed.line.line_name}`}
                <br />
              </span>
              <span>
                {`Inventory ${d.status === 'IN' ? 'removed' : 'added'}`}
                <br />
              </span>
              <span>{`${d.quantity} ${d.inventory.subtype}`}</span>
            </>
          ),
          date: d.manage_date,
          onClick: () => {
            setVisFloat(d);
            setAssistSeed(seed);
          },
        });
      } else if (type === 'HARVEST') {
        const d: IHarvestResource = data as any;
        const farm = farmsData.find(x => x.id === d.line.farm_id);
        items.push({
          key: `h-${d.id}`,
          type,
          label: (
            <>
              <span>
                {`${farm?.name} - ${d.line.line_name}`}
                <br />
              </span>
              <span>
                {`Season ${d.season_name}`}
                <br />
              </span>
              <span>
                {farm?.type === 'MUSSEL'
                  ? `Harvest ${(d as IMusselHarvest).amount} kg`
                  : `Harvest ${(d as IOysterHarvest).amount_dz} dz`}
              </span>
            </>
          ),
          date: d.complete_date,
          onClick: () => setViewHar(d),
        });
        if (d.shell_length) {
          const size = d.shell_length_max
            ? (d.shell_length + d.shell_length_max) / 2
            : d.shell_length;
          points.push({ date: d.complete_date, size });
        }
      } else if (type === 'SEED_MANAGE') {
        const farm = farmsData.find(x =>
          x.lines.some(y => y.id === (data as any).line_id),
        );
        const line = farm?.lines.find(x => x.id === (data as any).line_id);
        items.push({
          key: `sm-${data.id}`,
          type,
          label: (
            <>
              <span>
                {`${farm?.name} - ${line?.line_name}`}
                <br />
              </span>
              <span>{`Line depth ${(data as any).submersion} m`}</span>
            </>
          ),
          date: (data as any).manage_date,
          onClick: () => {},
        });
      } else if (type === 'HARVEST_PLANNED') {
        const d: any = data;
        items.push({
          key: `hp-${d.id}`,
          type,
          label: (
            <>
              <span>
                {translate(lang, 'Harvest date updated')}
                <br />
              </span>
              <span>
                {`Old: ${defaultDateFormat(d.old_date)}`}
                <br />
              </span>
              <span>{`New: ${defaultDateFormat(d.new_date)}`}</span>
            </>
          ),
          date: d.new_date,
          onClick: () => {},
        });
      } else if (type === 'TAG_LOG') {
        const d: ITagLog = data as any;
        items.push({
          key: `tl-${d.id}-${d.is_on}`,
          type,
          label: (
            <>
              <span>{`${d.tag.name} - ${d.is_on ? 'On' : 'Off'}`}</span>
              <br />
              <span>{d.user ? `by ${d.user.name}` : 'by Automation'}</span>
            </>
          ),
          date: d.logged_date,
          onClick: () => {},
        });
      }
    }
    items.sort((a, b) => a.date - b.date);
    const days = items.reduce(
      (p: string[], c) =>
        p.includes(llFormat(c.date)) ? p : [...p, llFormat(c.date)],
      [],
    );
    points.sort((a, b) => a.date - b.date);
    const dateFrom = points.length > 0 ? points[0].date : undefined,
      dateTo = points.length > 0 ? points.slice(-1)[0].date : undefined;
    return { days, items, points, dateFrom, dateTo };
  }, [dataItems]);

  const chartData = useMemo(() => {
    if (!isGrowing || points.length <= 0) return null;

    const getWs = (d: string) => {
      if (!weathers) return { temp: 0, wind: 0 };
      const tmp = weathers.filter(
        y => moment(y.rts).format('YYYY-MM-DD') === d,
      );
      const temp =
        tmp.length <= 0
          ? undefined
          : tmp.reduce((p, c) => p + Number(c.temperature), 0) / tmp.length;
      const wind =
        tmp.length <= 0
          ? undefined
          : (tmp.reduce(
              (p, c) =>
                p +
                Math.sqrt(
                  Number(c.wind_u) * Number(c.wind_u) +
                    Number(c.wind_v) * Number(c.wind_v),
                ),
              0,
            ) /
              tmp.length) *
            oneKnot;
      const waves = tmp.filter(x => !!x.waves_height);
      const wh =
        waves.length <= 0
          ? undefined
          : waves.reduce((p, c) => p + Number(c.waves_height), 0) /
            waves.length;
      return { temp, wind, waves: wh };
    };

    if (!compPoints || compPoints.length <= 0) {
      const { labels, days, data, avgSize } = calcChart(points, isGrowing);
      let datasets: IDataset[] = [
        {
          label: 'Size',
          data,
          borderColor,
          backgroundColor,
          tension: 0.35,
          yAxisID: 'yl',
        },
      ];
      if (weathers) {
        const ws = days.map(d => getWs(d));
        datasets.push(
          {
            label: 'Temperature',
            data: ws.map(x => x.temp),
            borderColor: '#ffcc09',
            backgroundColor: 'rgba(94,100,53,0.5)',
            tension: 0.35,
            yAxisID: 'yl',
          },
          {
            label: 'Wind (knots)',
            data: ws.map(x => x.wind),
            borderColor: '#043c9a',
            backgroundColor: 'rgba(56,88,249,0.5)',
            tension: 0.35,
            yAxisID: 'yr',
            hidden: true,
          },
          {
            label: 'Waves (m)',
            data: ws.map(x => x.waves),
            borderColor: '#003b61',
            backgroundColor: 'rgba(0,59,97,0.5)',
            tension: 0.35,
            yAxisID: 'yr',
            hidden: true,
          },
        );
      }
      return {
        labels,
        datasets,
        title: `Average spat growth: ${formatNumber(
          avgSize,
        )} mm, Average daily temperature`,
      };
    } else {
      const data1 = calcChart(points, isGrowing),
        data2 = calcChart(compPoints, !!compLine?.growing_cycle?.main_seed);
      let second: Array<number | undefined> = [];
      for (
        let i = 0;
        i < data1.days.length && data1.days[i] < data2.days[0];
        i++
      ) {
        second.push(undefined);
      }
      for (let i = 0; i < data2.days.length; i++) {
        second.push(data2.data[i]);
      }
      for (let i = data2.days.length; i < data1.days.length; i++) {
        second.push(undefined);
      }
      let datasets: IDataset[] = [
        {
          label: 'Size',
          data: data1.data,
          backgroundColor,
          borderColor,
          tension: 0.35,
          yAxisID: 'yl',
        },
        {
          label: 'Size',
          data: second,
          backgroundColor: 'rgba(29,181,47,0.5)',
          borderColor: '#1db52f',
          tension: 0.35,
          yAxisID: 'yl',
        },
      ];
      if (weathers) {
        const ws = data1.days.map(d => getWs(d));
        datasets.push(
          {
            label: 'Temperature',
            data: ws.map(x => x.temp),
            borderColor: '#ffcc09',
            backgroundColor: 'rgba(94,100,53,0.5)',
            tension: 0.35,
            yAxisID: 'yl',
          },
          {
            label: 'Wind (knots)',
            data: ws.map(x => x.wind),
            borderColor: '#043c9a',
            backgroundColor: 'rgba(56,88,249,0.5)',
            tension: 0.35,
            yAxisID: 'yr',
            hidden: true,
          },
          {
            label: 'Waves (m)',
            data: ws.map(x => x.waves),
            borderColor: '#003b61',
            backgroundColor: 'rgba(0,59,97,0.5)',
            tension: 0.35,
            yAxisID: 'yr',
            hidden: true,
          },
        );
      }
      return {
        labels: data1.labels,
        datasets,
        title: `Average spat growth ${formatNumber(
          data1.avgSize,
        )} mm, and ${formatNumber(
          data2.avgSize,
        )} mm, Average daily temperature`,
      };
    }
  }, [points, compPoints, isGrowing, weathers]);

  const selectCompLine = async (v: string) => {
    const l = Number(v);

    const line = farmsData
      .find(x => x.lines.some(y => y.id === l))
      ?.lines.find(y => y.id === l);
    if (!line || !dateFrom || !dateTo) return;

    setCompLine(line);
    setLoading(true);
    const response = await sendSingleRequest(
      {
        line_id: line.id,
        min_date: toFloor(dateFrom, -1),
        max_date: toFloor(dateTo, 1),
      },
      'GET',
      `api/farm/line/seeding`,
      true,
    );
    if (response.status) {
      let points: IChartPoint[] = [];
      for (let d of response.data.seedings) {
        if (d.spat_size) {
          const size = d.spat_size_max
            ? (d.spat_size + d.spat_size_max) / 2
            : d.spat_size;
          points.push({ size, date: d.planned_date_seed });
        }
      }
      for (let d of response.data.assessments) {
        points.push({
          date: d.assessment_date,
          size: d.shell_size.avg,
        });
      }
      for (let d of response.data.harvests) {
        if (d.shell_length) {
          const size = d.shell_length_max
            ? (d.shell_length + d.shell_length_max) / 2
            : d.shell_length;
          points.push({ date: d.complete_date, size });
        }
      }
      points.sort((a, b) => a.date - b.date);
      setCompPoints(points);
    } else {
      setCompPoints(undefined);
    }
    setLoading(false);
  };
  const changeHeight = (v: number) => {
    setHeight(height + v);
    setShowLine(false);
    setTimeout(() => setShowLine(true), 50);
  };

  const linesOption = farmsData.reduce(
    (p: any[], c) => [
      ...p,
      ...c.lines.map(x => ({
        id: x.id.toString(),
        value: x.id.toString(),
        label: `${c.name} - ${x.line_name}`,
      })),
    ],
    [],
  );

  return (
    <div style={{ overflow: 'auto' }}>
      {loading && (
        <div className='loader'>
          <Spinner />
        </div>
      )}
      {chartData && (
        <div className='time-line-container'>
          <div className='d-flex justify-content-between align-items-center'>
            <div style={{ width: '390px', marginLeft: 10 }}>
              <Dropdown
                label='Compare with'
                options={linesOption}
                value={compLine?.id.toString()}
                onChange={selectCompLine}
              />
            </div>
            <div className='d-flex align-items-center mb-16'>
              <div className='zoom-icon-btn' onClick={() => changeHeight(-10)}>
                <MinusIcon />
              </div>
              <div className='ml-7 mr-7'>
                <Subtitle
                  color='black-2'
                  align='center'
                  size={0}
                  fontWeight={600}
                >
                  {height}
                </Subtitle>
              </div>
              <div className='zoom-icon-btn' onClick={() => changeHeight(10)}>
                <PlusIcon />
              </div>
            </div>
          </div>
          {showLine && (
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                  },
                  title: {
                    display: true,
                    text: chartData.title,
                  },
                },
                spanGaps: true,
                scales: {
                  yl: {
                    position: 'left',
                  },
                  yr: {
                    position: 'right',
                    grid: {
                      drawOnChartArea: false,
                    },
                  },
                },
              }}
              data={chartData}
              height={height}
            />
          )}
        </div>
      )}
      {width < 768 || days.length > 0 ? (
        <div className='time-line-container'>
          {days.map(x => (
            <div key={x} className='--data-box'>
              <div className='--day'>{x}</div>
              <div className='--circle'></div>
              <div className='--box'>
                {items
                  .filter(t => llFormat(t.date) === x)
                  .map(t => (
                    <div
                      key={t.key}
                      className={`--cell --${t.type.toLowerCase()}`}
                      onClick={t.onClick}
                    >
                      {t.label}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='time-line-container'>
          <div className='--day-bar'>
            {days.map(x => (
              <div key={x} className='--day'>
                {x}
              </div>
            ))}
          </div>
          <div className='--line-bar'>
            {days.map(x => (
              <div key={x} className='--time-item' />
            ))}
          </div>
          <div className='--box-bar'>
            {days.map(d => (
              <div key={d} className='--box'>
                {items
                  .filter(t => llFormat(t.date) === d)
                  .map(t => (
                    <div
                      key={t.key}
                      className={`--cell --${t.type.toLowerCase()}`}
                      onClick={t.onClick}
                    >
                      {t.label}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      )}
      {!!visSeed && (
        <SeedViewModal
          type={businessType}
          visible={true}
          seeding={visSeed}
          onClose={() => setVisSeed(undefined)}
        />
      )}
      {!!visAssess && !!assistSeed && (
        <AssessmentViewModal
          visible={true}
          onClose={() => setVisAssess(undefined)}
          assessment={visAssess}
          lineId={assistSeed.line_id}
        />
      )}
      {!!visMain && assistSeed && (
        <MaintenanceViewModal
          visible={true}
          onClose={() => setVisMain(undefined)}
          maintenance={visMain}
          lineId={assistSeed.line_id}
        />
      )}
      {!!visFloat && assistSeed && (
        <FloatsViewModal
          visible={true}
          inventorySeed={visFloat}
          onClose={() => setVisFloat(undefined)}
        />
      )}
      {!!viewHar && (
        <HarvestViewModal
          type={businessType}
          visible={true}
          harvest={viewHar}
          onClose={() => setViewHar(undefined)}
        />
      )}
    </div>
  );
};

export default TimelineView;

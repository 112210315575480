import React from 'react';
import { ISpatStorageResource } from '../../entities/farms.entities';

export const SpatSeedTypeLabel: React.FC<{
  spatStorages: any[];
  id: number | null;
}> = ({ spatStorages, id }) => {
  if (id === null || !spatStorages || spatStorages.length <= 0) {
    return <span>-</span>;
  }
  const spatItem = spatStorages.find(t => t.id === id);
  return spatItem?.seed_type ?? '-';
};

export const SpatLabel = (spat: ISpatStorageResource | undefined | null) => {
  if (!spat) return '-';
  return spat.seed_type ?? '-';
};

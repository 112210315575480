export const areSimilarStrings = (
  s1: string | null | undefined,
  s2: string | null | undefined,
) => {
  if (s1 === null || s1 === undefined || s2 === null || s2 === undefined)
    return false;

  const c1 = s1.split(' ').join('').toLowerCase(),
    c2 = s2.split(' ').join('').toLowerCase();

  return c1 === c2;
};

const levenshtein = (a: string, b: string): number => {
  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1),
        );
      }
    }
  }

  return matrix[b.length][a.length];
};

export const findMostSimilar = (
  target: string | null | undefined,
  words: string[],
): string | null => {
  if (!target) {
    return null;
  }
  let minDistance = Infinity;
  let mostSimilar = '';

  words.forEach(word => {
    const distance = levenshtein(target, word);
    if (distance < minDistance) {
      minDistance = distance;
      mostSimilar = word;
    }
  });

  return mostSimilar;
};

export const compareString = (
  s1: string | undefined | null,
  s2: string | undefined | null,
) => {
  return (s1 ?? '').localeCompare(s2 ?? '', 'en', {
    numeric: true,
  });
};

export const randomKey = (): string => {
  const key = `${Math.random().toString(36).substr(2, 9)}`;
  return key;
};

export const toStrSlug = (s: string) => {
  return s
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9-]+/g, '_');
};

export const calcPeriodUnit = (period: number) => {
  const m = 3600 * 24 * 30;
  const w = 3600 * 24 * 7;
  const d = 3600 * 24;
  if (period % m === 0) {
    return { unit: 'month', value: period / m };
  }
  if (period % w === 0) {
    return { unit: 'week', value: period / w };
  }
  if (period % d === 0) {
    return { unit: 'day', value: period / d };
  }
  return { unit: 'hour', value: period / 3600 };
};

export const periodToStr = (period: number) => {
  const { unit, value } = calcPeriodUnit(period);
  return `${value} ${unit}${value > 1 ? 's' : ''}`;
};

import { useEffect, useState, useMemo } from 'react';
import { Spinner, Title } from '../../components/shared';
import { sendSingleRequest } from '../../apis';
import { formatNumber } from '../../entities/util-functions';
import { Table } from 'antd';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import { TBusinessType } from '../../entities/general.entities';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IFarmType {
  id: number;
  name: string;
  type: TBusinessType;
}

interface ILineName {
  id: number;
  line_name: string;
}

interface IBasicItem {
  id: number;
  stage_order: number;
  seed_size: number;
  harvest_size: number;
  grow_months: number;
  grow_speed: number;
}

interface IMusselItem extends IBasicItem {
  line_length: number;
}

interface IOysterItem extends IBasicItem {}

interface ISeaweedItem extends IBasicItem {
  tank_area: number;
}

type IStageItem = IMusselItem | IOysterItem | ISeaweedItem;

interface IStageRow {
  farm: IFarmType;
  line: ILineName;
  stages: Array<IStageItem>;
}

interface IStageLine {
  id: string | number;
  farm: IFarmType;
  line: ILineName;
  stage: IStageItem;
}

interface IMusselStage extends IStageLine {
  stage: IMusselItem;
}

interface IOysterStage extends IStageLine {
  stage: IOysterItem;
}

interface ISeaweedStage extends IStageLine {
  stage: ISeaweedItem;
}

interface IStageTable {
  stage_order: number;
  seed_size: number;
  harvest_size: number;
  avg_months: number;
  avg_speed: number;
  lines: IStageLine[];
}

interface IMusselTable extends IStageTable {
  line_length: number;
  lines: IMusselStage[];
}

interface IOysterTable extends IStageTable {
  lines: IOysterStage[];
}

interface ISeaweedTable extends IStageTable {
  tank_area: number;
  lines: ISeaweedStage[];
}

const basicColumns = [
  {
    key: 'farm',
    title: 'Farm',
    render: (x: IStageLine) => <span>{x.farm.name}</span>,
  },
  {
    key: 'line',
    title: 'Line',
    render: (x: IStageLine) => <span>{x.line.line_name}</span>,
  },
  {
    key: 'size',
    title: 'Size',
    render: (x: IStageLine) => (
      <span>{`${formatNumber(x.stage.seed_size)} - ${formatNumber(
        x.stage.harvest_size,
      )} mm`}</span>
    ),
  },
  {
    key: 'grow_months',
    title: 'Months',
    render: (x: IStageLine) => <span>{x.stage.grow_months}</span>,
  },
  {
    key: 'speed',
    title: 'Speed',
    render: (x: IStageLine) => (
      <span>{`${formatNumber(x.stage.grow_speed)} mm per month`}</span>
    ),
  },
];

const musselColumns = [
  ...basicColumns,
  {
    key: 'line_length',
    title: 'Line Length',
    render: (x: IMusselStage) => (
      <span>{`${formatNumber(x.stage.line_length)} m`}</span>
    ),
  },
  {
    key: 'right',
    title: '',
  },
];

const oysterColumns = [...basicColumns, { key: 'right', title: '' }];

const seaweedColumns = [
  ...basicColumns,
  {
    key: 'tank_area',
    title: 'Tank Area',
    render: (x: ISeaweedStage) => <span>{x.stage.tank_area}</span>,
  },
  { key: 'right', title: '' },
];

const HarvestStagesPage = () => {
  const { redirectToLine } = useMenuHandler();
  const lang = useSelector(selectLang);

  const [data, setData] = useState<IStageRow[]>();
  const [loading, setLoading] = useState(false);
  const [pageOptions, setPageOptions] = useState<any>();

  const { musselTable, oysterTable, seaweedTable } = useMemo(() => {
    if (!data)
      return {
        musselTable: null,
        oysterTable: null,
        seaweedTable: null,
      };
    let musselTable: IMusselTable[] = [];
    let oysterTable: IOysterTable[] = [];
    let seaweedTable: ISeaweedTable[] = [];
    const stageCnt = data.reduce(
      (p, c) => Math.max(p, ...c.stages.map(x => x.stage_order)),
      0,
    );
    for (let i = 1; i <= stageCnt; i++) {
      let musselParam = {
          min_seed: 0,
          max_harvest: 0,
          months_sum: 0,
          speed_sum: 0,
          cnt: 0,
          line_length: 0,
        },
        musselLines: IMusselStage[] = [];
      let oysterParam = {
          min_seed: 0,
          max_harvest: 0,
          months_sum: 0,
          speed_sum: 0,
          cnt: 0,
        },
        oysterLines: IOysterStage[] = [];
      let seaweedParam = {
          min_seed: 0,
          max_harvest: 0,
          months_sum: 0,
          speed_sum: 0,
          cnt: 0,
          tank_area: 0,
        },
        seaweedLines: ISeaweedStage[] = [];

      for (let item of data) {
        const x = item.stages.find(y => y.stage_order === i);

        if (!!x) {
          if (item.farm.type === 'MUSSEL') {
            musselParam.min_seed =
              musselParam.cnt <= 0
                ? x.seed_size
                : Math.min(x.seed_size, musselParam.min_seed);
            musselParam.max_harvest =
              musselParam.cnt <= 0
                ? x.harvest_size
                : Math.max(x.harvest_size, musselParam.max_harvest);
            musselParam.months_sum += x.grow_months;
            musselParam.speed_sum += x.grow_speed;
            musselParam.line_length += (x as IMusselItem).line_length;
            musselLines.push({
              id: `${i}-${item.line.id}`,
              farm: item.farm,
              line: item.line,
              stage: x as IMusselItem,
            });
            musselParam.cnt++;
          } else if (item.farm.type === 'OYSTER') {
            oysterParam.min_seed =
              oysterParam.cnt <= 0
                ? x.seed_size
                : Math.min(x.seed_size, oysterParam.min_seed);
            oysterParam.max_harvest =
              oysterParam.cnt <= 0
                ? x.harvest_size
                : Math.max(x.harvest_size, oysterParam.max_harvest);
            oysterParam.months_sum += x.grow_months;
            oysterParam.speed_sum += x.grow_speed;
            oysterLines.push({
              id: `${i}-${item.line.id}`,
              farm: item.farm,
              line: item.line,
              stage: x as IOysterItem,
            });
            oysterParam.cnt++;
          } else if (item.farm.type === 'SEAWEED') {
            seaweedParam.min_seed =
              seaweedParam.cnt <= 0
                ? x.seed_size
                : Math.min(x.seed_size, seaweedParam.min_seed);
            seaweedParam.max_harvest =
              seaweedParam.cnt <= 0
                ? x.harvest_size
                : Math.max(x.harvest_size, seaweedParam.max_harvest);
            seaweedParam.months_sum += x.grow_months;
            seaweedParam.speed_sum += x.grow_speed;
            seaweedParam.tank_area += (x as ISeaweedItem).tank_area;
            seaweedLines.push({
              id: `${i}-${item.line.id}`,
              farm: item.farm,
              line: item.line,
              stage: x as ISeaweedItem,
            });
            seaweedParam.cnt++;
          }
        }
      }
      if (musselParam.cnt > 0) {
        musselTable.push({
          stage_order: i,
          seed_size: musselParam.min_seed,
          harvest_size: musselParam.max_harvest,
          avg_months: Math.round(musselParam.months_sum / musselParam.cnt),
          avg_speed: musselParam.speed_sum / musselParam.cnt,
          line_length: musselParam.line_length / musselParam.cnt,
          lines: musselLines,
        });
      }
      if (oysterParam.cnt > 0) {
        oysterTable.push({
          stage_order: i,
          seed_size: oysterParam.min_seed,
          harvest_size: oysterParam.max_harvest,
          avg_months: Math.round(oysterParam.months_sum / oysterParam.cnt),
          avg_speed: oysterParam.speed_sum / oysterParam.cnt,
          lines: oysterLines,
        });
      }
      if (seaweedParam.cnt > 0) {
        seaweedTable.push({
          stage_order: i,
          seed_size: seaweedParam.min_seed,
          harvest_size: seaweedParam.max_harvest,
          avg_months: Math.round(seaweedParam.months_sum / seaweedParam.cnt),
          avg_speed: seaweedParam.speed_sum / seaweedParam.cnt,
          tank_area: seaweedParam.tank_area / seaweedParam.cnt,
          lines: seaweedLines,
        });
      }
    }
    return { musselTable, oysterTable, seaweedTable };
  }, [data]);

  const handlePageChange = (p: any, i: number) => {
    let tmp = pageOptions ? [...pageOptions] : [];
    tmp[i] = p;
    setPageOptions(tmp);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await sendSingleRequest(
        { with_detail: true },
        'GET',
        'api/overview/stages',
        true,
      );
      setLoading(false);
      if (res.status) {
        setData(res.data);
      }
    })();
  }, []);

  return (
    <div className='bg-secondary min-h-100'>
      <div className='container pb-32 stages-page'>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Harvest Stages')}
          </Title>
        </div>
        {loading && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        {musselTable && musselTable.length > 0 && (
          <div className='pb-32'>
            <Title size={6} color='black' align='default' fontWeight={700}>
              {translate(lang, 'Mussel Stages')}
            </Title>
            {musselTable.map((table, i) => (
              <div key={i} className='stage-box'>
                <div className='stage-title'>{`STAGE ${table.stage_order}`}</div>
                <div className='labels'>
                  <div className='stage-label --size'>
                    {`SIZE: ${formatNumber(table.seed_size)} - ${formatNumber(
                      table.harvest_size,
                    )} mm`}
                  </div>
                  <div className='stage-label --time'>
                    {`MONTHS: ${table.avg_months} months`}
                  </div>
                  <div className='stage-label --speed'>
                    {`SPEED: ${formatNumber(table.avg_speed)}mm per month`}
                  </div>
                  <div className='stage-label --length'>
                    {`LINE LENGTH: ${formatNumber(table.line_length)} m`}
                  </div>
                </div>
                <div className='width-100'>
                  <Table
                    className='table table--isFarm'
                    rowKey={'id'}
                    columns={musselColumns.map(x => ({
                      ...x,
                      title: translate(lang, x.title),
                    }))}
                    dataSource={table.lines}
                    pagination={
                      pageOptions && pageOptions[i]
                        ? pageOptions[i]
                        : {
                            current: 1,
                            pageSize: 5,
                            total: table.lines.length,
                          }
                    }
                    onChange={e => handlePageChange(e, i)}
                    onRow={(h: any) => ({
                      onClick: () => redirectToLine(h.farm.id, h.line.id),
                    })}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {oysterTable && oysterTable.length > 0 && (
          <div className='pb-32'>
            <Title size={6} color='black' align='default' fontWeight={700}>
              {translate(lang, 'Oyster Stages')}
            </Title>
            {oysterTable.map((table, i) => (
              <div key={i} className='stage-box'>
                <div className='stage-title'>{`STAGE ${table.stage_order}`}</div>
                <div className='labels'>
                  <div className='stage-label --size'>
                    {`SIZE: ${formatNumber(table.seed_size)} - ${formatNumber(
                      table.harvest_size,
                    )} mm`}
                  </div>
                  <div className='stage-label --time'>
                    {`MONTHS: ${table.avg_months} months`}
                  </div>
                  <div className='stage-label --speed'>
                    {`SPEED: ${formatNumber(table.avg_speed)}mm per month`}
                  </div>
                </div>
                <div className='width-100'>
                  <Table
                    className='table table--isFarm'
                    rowKey={'id'}
                    columns={oysterColumns.map(x => ({
                      ...x,
                      title: translate(lang, x.title),
                    }))}
                    dataSource={table.lines}
                    pagination={
                      pageOptions && pageOptions[i]
                        ? pageOptions[i]
                        : {
                            current: 1,
                            pageSize: 5,
                            total: table.lines.length,
                          }
                    }
                    onChange={e => handlePageChange(e, i)}
                    onRow={(h: any) => ({
                      onClick: () => redirectToLine(h.farm.id, h.line.id),
                    })}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {seaweedTable && seaweedTable.length > 0 && (
          <div className='pb-32'>
            <Title size={6} color='black' align='default' fontWeight={700}>
              {translate(lang, 'Seaweed Stages')}
            </Title>
            {seaweedTable.map((table, i) => (
              <div key={i} className='stage-box'>
                <div className='stage-title'>{`STAGE ${table.stage_order}`}</div>
                <div className='labels'>
                  <div className='stage-label --size'>
                    {`SIZE: ${formatNumber(table.seed_size)} - ${formatNumber(
                      table.harvest_size,
                    )} mm`}
                  </div>
                  <div className='stage-label --time'>{`MONTHS: ${table.avg_months} months`}</div>
                  <div className='stage-label --speed'>
                    {`SPEED: ${formatNumber(table.avg_speed)}mm per month`}
                  </div>
                  <div className='stage-label --length'>
                    {`TANK AREA: ${formatNumber(table.tank_area)} m²`}
                  </div>
                </div>
                <div className='width-100'>
                  <Table
                    className='table table--isFarm'
                    rowKey={'id'}
                    columns={seaweedColumns.map(x => ({
                      ...x,
                      title: translate(lang, x.title),
                    }))}
                    dataSource={table.lines}
                    pagination={
                      pageOptions && pageOptions[i]
                        ? pageOptions[i]
                        : { current: 1, pageSize: 5, total: table.lines.length }
                    }
                    onChange={e => handlePageChange(e, i)}
                    onRow={(h: any) => ({
                      onClick: () => redirectToLine(h.farm.id, h.line.id),
                    })}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default HarvestStagesPage;

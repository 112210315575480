import { NavLink } from 'react-router-dom';
import { translate } from '../../lib/lang.helper';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { Spinner } from '../../components/shared';

const OysterCalenderPage = () => {
  const lang = useSelector(selectLang);

  const loading = true;

  return (
    <div className='bg-secondary'>
      <div className='container performance-page pb-32'>
        <div className='sub-header'>
          <NavLink className='header__link' to={'/oyster-summary/estimate'}>
            {translate(lang, 'Harvest Delivery Estimates')}
          </NavLink>
          <NavLink
            className='header__link ml-24 mr-24'
            to={'/oyster-summary/calender'}
          >
            {translate(lang, 'Calendar Estimates')}
          </NavLink>
          <NavLink className='header__link' to={'/oyster-summary/growth'}>
            {translate(lang, 'Growth Calculator')}
          </NavLink>
        </div>
        <div className='overview d-flex justify-content-between align-items-center'></div>
        {loading && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        <div className='content pb-32'></div>
      </div>
    </div>
  );
};

export default OysterCalenderPage;

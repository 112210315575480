import { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import {
  BreadcrumbComponent,
  Button,
  CaretDownIcon,
  DropdownMenu,
  ModalComponent,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import { useDispatch, useSelector } from 'react-redux';
import { loadInventories } from '../../store/inventories/inventories.actions';
import { ColumnsType } from 'antd/lib/table';
import { sendSingleRequest } from '../../apis';
import InventoryModal from './InventoryModal';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import InventoryMobileTable from './InventoryMobileTable';
import { formatNumber } from '../../entities/util-functions';
import { selectInventories } from '../../store/inventories/inventories.selector';
import { IInventoryResource } from '../../entities/inventory.entities';
import { IMusselFarm } from '../../entities/farms.entities';
import { selectMusselFarms } from '../../store/farms/farms.selector';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';
import { TLang } from '../../entities/ui.entities';

interface IRow extends IInventoryResource {
  performance?: number | null;
}

interface ISubRow {
  farm_id: number;
  line_id: number;
  farm_number: string;
  line_name: string;
  current_stock: number;
  last_float_job: string;
  last_assessment_date: string | null;
}

const getSubRows = (inventory: IRow, farms: IMusselFarm[]): ISubRow[] => {
  let result: ISubRow[] = [];

  for (let farm of farms) {
    for (let line of farm.lines) {
      if (!line.growing_cycle) continue;

      const found = line.growing_cycle.inventories_sum.find(
        x => x.inventory_id === inventory.id,
      );
      if (!found) continue;

      const tmp = inventory.inventory_seeds.filter(x => x.line?.id === line.id);
      if (tmp.length <= 0) continue;

      tmp.sort((a, b) => (a.manage_date > b.manage_date ? -1 : 1));
      const lastItem = tmp[0];

      result.push({
        farm_id: farm.id,
        line_id: line.id,
        farm_number: farm.farm_number,
        line_name: line.line_name,
        current_stock: found.quantity,
        last_float_job: defaultDateFormat(lastItem.manage_date) ?? '-',
        last_assessment_date: defaultDateFormat(
          line.growing_cycle.last_assessment?.assessment_date,
          '-',
        ),
      });
    }
  }

  return result;
};

const columns = (lang: TLang | undefined) => [
  {
    title: 'ID',
    key: 'id',
    render: (x: IRow) => <span>{x.id}</span>,
  },
  {
    title: translate(lang, 'Type'),
    key: 'type',
    render: (x: IRow) => <span>{x.type}</span>,
  },
  {
    title: translate(lang, 'Subtype'),
    key: 'subtype',
    render: (x: IRow) => <span>{x.subtype}</span>,
  },
  {
    title: translate(lang, 'Quantity'),
    key: 'quantity',
    render: (x: IRow) => <span>{x.quantity}</span>,
  },
  {
    title: translate(lang, 'Available Quantity'),
    key: 'available_quantity',
    render: (x: IRow) => <span>{x.available_quantity}</span>,
  },
  {
    title: translate(lang, 'Performance'),
    key: 'performance',
    render: (x: IRow) => (
      <span>
        {x.performance
          ? `1 float - ${formatNumber(
              x.performance < 1000 ? x.performance : x.performance / 1000,
            )} ${x.performance < 1000 ? 'kg' : 'tons'}`
          : '-'}
      </span>
    ),
  },
  {
    title: translate(lang, 'Level'),
    key: 'level',
    render: (x: IRow) => <span>{x.level === 1 ? 'Surface' : 'Submerged'}</span>,
  },
];

const subColumns = (lang: TLang | undefined) => [
  {
    title: translate(lang, 'Farm Number'),
    key: 'farm_number',
    render: (x: ISubRow) => <span>{x.farm_number}</span>,
  },
  {
    title: translate(lang, 'Line name'),
    key: 'line_name',
    render: (x: ISubRow) => <span>{x.line_name}</span>,
  },
  {
    title: translate(lang, 'Current stock'),
    key: 'current_stock',
    render: (x: ISubRow) => <span>{x.current_stock}</span>,
  },
  {
    title: translate(lang, 'Last float job (Date)'),
    key: 'last_float_job',
    render: (x: ISubRow) => <span>{x.last_float_job}</span>,
  },
  {
    title: translate(lang, 'Last Assessment Date'),
    key: 'last_assessment_date',
    render: (x: ISubRow) => <span>{x.last_assessment_date}</span>,
  },
  {
    title: '',
    key: 'right',
  },
];

const InventoriesPage = () => {
  const width = useWidth();
  const dispatch = useDispatch();
  const inventories = useSelector(selectInventories);
  const farmsData = useSelector(selectMusselFarms);
  const lang = useSelector(selectLang);

  const { redirectToLine } = useMenuHandler();
  const breadcrumbItems = getBreadcrumbMenu('INVENTORIES');

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [deleteShow, setDeleteShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [performs, setPerforms] =
    useState<Array<{ id: number; performance: number | null }>>();

  const loadData = useCallback(async () => {
    await dispatch(loadInventories());
    const res = await sendSingleRequest(
      {},
      'GET',
      'api/inventory/performances',
      true,
    );
    if (res.status) {
      setPerforms(res.data);
    }
  }, [dispatch]);

  const showAddModal = () => {
    setSelectedItem(null);
    setModalTitle('Add new inventory');
    setShowModal(true);
  };
  const showEditModal = (evt: any, item: any) => {
    setModalTitle('Edit inventory');
    setSelectedItem(item);
    setShowModal(true);
  };
  const showDeleteModal = (evt: any, item: any) => {
    setSelectedItem(item);
    setDeleteShow(true);
  };
  const confirmSubmit = () => {
    setDisable(false);
    setShowModal(false);
  };
  const deleteSelectedItem = () => {
    if (!selectedItem) return;
    setDisable(true);
    sendSingleRequest(
      {},
      'DELETE',
      `api/inventory/inventories/${selectedItem.id}`,
      true,
    ).then(() => {
      setDeleteShow(false);
      setDisable(false);
      loadData();
    });
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  const tableColumns = [
    ...columns(lang),
    {
      key: 'more',
      title: 'More',
      align: 'right',
      render: (x: any) => (
        <DropdownMenu
          data={x}
          column={'isUtil'}
          onEdit={evt => showEditModal(evt, x)}
          onDeleteRow={evt => showDeleteModal(evt, x)}
          type='isModal'
        />
      ),
    },
  ] as ColumnsType<any>;

  const rowsData: IRow[] = inventories.map(x => {
    const t = performs?.find(y => y.id === x.id);
    return { ...x, performance: t === undefined ? null : t.performance };
  });

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative farms__line'>
        {width > 768 ? (
          <div className='d-flex justify-content-between align-items-center pl-21 pr-15 pt-28 pb-28'>
            <BreadcrumbComponent items={breadcrumbItems} />
            <Link to='#' onClick={showAddModal}>
              <Button color='blue' size={1} width='middle' type='fill'>
                {translate(lang, 'Add Inventory')}
              </Button>
            </Link>
          </div>
        ) : (
          <div className='w-100'>
            <Button
              className='w-100 mt-17'
              color='blue'
              size={1}
              width='middle'
              type='fill'
              onClick={showAddModal}
            >
              {translate(lang, 'Add Inventory')}
            </Button>
          </div>
        )}
        <div className='farms__line-content'>
          <div className='d-flex farms__main'>
            {width > 768 ? (
              <div
                style={{ overflow: 'auto' }}
                className='width-100 pos-relative'
              >
                <Table
                  rowKey='id'
                  className='table table--isFarm'
                  style={{
                    width: '1200px',
                    minWidth: '100%',
                    maxWidth: '1200px',
                  }}
                  pagination={false}
                  columns={tableColumns}
                  dataSource={rowsData}
                  expandable={{
                    expandedRowRender: (d: IRow) => {
                      return (
                        <Table
                          rowKey='line_id'
                          className='table table--isFarm is__child'
                          columns={subColumns(lang)}
                          pagination={false}
                          dataSource={getSubRows(d, farmsData)}
                          onRow={dR => ({
                            onClick: () =>
                              redirectToLine(dR.farm_id, dR.line_id),
                          })}
                        />
                      );
                    },
                    expandIcon: ({ onExpand, record }) => (
                      <div
                        className='pt-20 pb-20'
                        onKeyDown={() => undefined}
                        onClick={event => {
                          event.stopPropagation();
                          onExpand(record, event);
                        }}
                      >
                        <CaretDownIcon />
                      </div>
                    ),
                  }}
                />
              </div>
            ) : (
              <InventoryMobileTable
                data={rowsData}
                showEditModal={showEditModal}
                showDeleteModal={showDeleteModal}
              />
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <InventoryModal
          visible={true}
          title={translate(lang, modalTitle)}
          onCancel={() => setShowModal(false)}
          onConfirm={confirmSubmit}
          data={selectedItem}
          updateId={selectedItem?.id ?? undefined}
        />
      )}
      <ModalComponent
        visible={deleteShow}
        onCancel={() => setDeleteShow(false)}
        type='delete'
        title={translate(lang, 'Delete')}
        text={translate(lang, 'Are you sure to delete this data?')}
        disabled={disable}
        onConfirm={deleteSelectedItem}
      />
    </div>
  );
};

export default InventoriesPage;

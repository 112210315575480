import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';
import { ILineResource } from '../../entities/farms.entities';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
} from '../shared';
import { useDispatch, useSelector } from 'react-redux';
import ModalFeedbackView from '../shared/feedback/ModalFeedbackView';
import { showFeedback } from '../../store/ui/ui.actions';
import { IInventoryResource } from '../../entities/inventory.entities';
import { sendSingleRequest } from '../../apis';
import moment from 'moment';
import { toMillisecond, toSecond } from '../../util/toggleSecondMillisecond';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../shared/input/TimeTrackInput';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { ITimeRange } from '../../entities/general.entities';
import { IMusselCycle } from '../../entities/growing.entities';
import { loadInventories } from '../../store/inventories/inventories.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  SelectFarmsByFarmID,
  SelectInventoriesByFarmID,
} from '../../store/extra/extra.selector';

const typeOptions = [
  { id: 'IN', value: 'IN', label: 'Remove from line to inventory' },
  { id: 'OUT', value: 'OUT', label: 'Added to the line' },
];

interface IForm {
  type: 'IN' | 'OUT';
  quantity?: number;
  manage_date: number;
  time_tracks?: ITimeRange[];
}

interface Props {
  visible: boolean;
  className?: string;
  title: string;
  data?: {
    seeding_id?: number;
    inventory_id: number;
    quantity: number;
    status: 'IN' | 'OUT';
    manage_date?: number;
    time_tracks?: ITimeRange[];
  };
  onCancel: () => void;
  onConfirm: (data: any) => void;
  lineData: ILineResource;
  onlyView?: boolean;
  updateId?: number;
  overConfirm?: (data: any) => void;
  lineChangeable?: boolean;
  manageCycle?: IMusselCycle;
}

const FloatManageModal = ({
  visible,
  className,
  title,
  data,
  onCancel,
  onConfirm,
  lineData,
  onlyView,
  updateId,
  overConfirm,
  lineChangeable,
  manageCycle,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const inventories = SelectInventoriesByFarmID(lineData.farm_id);
  const farmsData = SelectFarmsByFarmID(lineData.farm_id);

  const lastSubmersion = useMemo(() => {
    if (!manageCycle) {
      return null;
    }
    if (manageCycle.seed_manages.length <= 0) {
      return manageCycle.main_seed.submersion;
    }
    const arr = [...manageCycle.seed_manages];
    arr.sort((a, b) => b.manage_date - a.manage_date);

    return arr[0].submersion;
  }, [manageCycle]);

  const [disabled, setDisabled] = useState(false);
  const [curIvt, setCurIvt] = useState<IInventoryResource>();
  const [formData, setFormData] = useState<IForm>({
    type: data?.status ?? 'IN',
    quantity: data?.quantity,
    manage_date: toMillisecond(data?.manage_date),
    time_tracks:
      data?.time_tracks?.map(x => ({
        start_time: toMillisecond(x.start_time),
        finish_time: toMillisecond(x.finish_time),
      })) ?? [],
  });
  const [focFarmId, setFocFarmId] = useState(lineData.farm_id);
  const [curLine, setCurLine] = useState(lineData);
  const [depth, setDepth] = useState(lastSubmersion);

  const { ivtFloats, mainSeed } = useMemo(
    () => ({
      ivtFloats: curLine.growing_cycle?.inventories_sum,
      mainSeed: curLine.growing_cycle?.main_seed,
    }),
    [curLine],
  );
  const availableQty = useMemo(() => {
    if (!curIvt) return 0;
    if (formData.type === 'OUT') {
      return curIvt.available_quantity;
    } else {
      return ivtFloats?.find(x => x.inventory_id === curIvt.id)?.quantity ?? 0;
    }
  }, [curIvt, ivtFloats, formData.type]);

  const currentFarm = farmsData.find(x => x.id === focFarmId);

  const handleLineChange = (v: string) => {
    const farm = farmsData.find(x => x.id === focFarmId);
    if (!farm) return;
    const line = farm.lines.find(x => x.id === Number(v));
    if (!line?.growing_cycle) return;
    setCurLine(line);
  };
  const selectInventory = (id: string) => {
    const ivt = inventories.find(x => x.id === Number(id));
    setCurIvt(ivt);
  };
  const updateForm = (key: keyof IForm, value: any) => {
    if (key === 'quantity') {
      value = value.length <= 0 ? undefined : Number(value);
    }
    setFormData({ ...formData, [key]: value });
  };

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );

  const submitLineDepth = async () => {
    if (!manageCycle || !depth || Number(depth) === Number(lastSubmersion)) {
      return false;
    }
    const form = {
      seeding_id: manageCycle.main_seed.id,
      manage_date: toSecond(moment().toDate().getTime()),
      submersion: depth,
    };
    const res = await sendSingleRequest(
      form,
      'POST',
      'api/farm/line/seed/manage',
      true,
    );
    if (res.status) {
      alert(translate(lang, 'Line depth has been saved successfully!'));
    } else {
      alert(translate(lang, res.data?.message ?? 'Failed to save line depth'));
    }
    return true;
  };

  const validForm = () => {
    if (!curIvt) {
      return 'Please select inventory';
    }
    if (
      formData.quantity === undefined ||
      formData.quantity === null ||
      formData.quantity < 0 ||
      formData.quantity > availableQty
    ) {
      return formData.quantity === undefined || formData.quantity === null
        ? 'Please input quantity'
        : formData.quantity < 0
        ? 'Quantity must be greater than 0'
        : translate(lang, '_float_no_enough');
    }
    const time_tracks = formData.time_tracks
      ? toSecTimeTracks(formData.time_tracks)
      : [];
    if (time_tracks === false) {
      return translate(lang, '_invalid_time_range');
    }
    const seeding_id =
      lineData.id === curLine.id
        ? data?.seeding_id ?? mainSeed?.id
        : mainSeed?.id;
    if (!seeding_id) {
      return 'The line is not growing';
    }
    return {
      inventory_id: curIvt.id,
      quantity: formData.quantity,
      type: formData.type,
      manage_date: toSecond(formData.manage_date),
      time_tracks,
      seeding_id,
    };
  };
  const handleConfirmClick = async () => {
    const form = validForm();
    if (typeof form === 'string') {
      setDisabled(true);
      const dr = await submitLineDepth();
      setDisabled(false);
      if (!dr) {
        showError(form);
      } else {
        onConfirm(dr);
      }
      return;
    }
    if (!form.quantity) {
      setDisabled(true);
      const dr = await submitLineDepth();
      setDisabled(false);
      if (dr) {
        onConfirm(dr);
      } else {
        onCancel();
      }
      return;
    }
    setDisabled(true);
    if (overConfirm) {
      overConfirm(form);
      return;
    }
    const res = !updateId
      ? await sendSingleRequest(
          form,
          'POST',
          `api/farm/line/seed/inventory-seeds/${mainSeed?.id}`,
          true,
        )
      : await sendSingleRequest(
          {
            floating_id: updateId,
            quantity: form.quantity,
            manage_date: form.manage_date,
            time_tracks: form.time_tracks,
          },
          'PUT',
          'api/farm/line/seed/inventory-seeds',
          true,
        );
    if (res.status) {
      await submitLineDepth();
      dispatch(loadFarmsData());
      dispatch(loadInventories());
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(
            lang,
            res.data?.message ?? 'Floats added successfully',
          ),
        }),
      );
    } else {
      showError(res.data?.message ?? 'Failed');
      setDisabled(false);
    }
  };

  useEffect(() => {
    const tmp = data ? inventories.find(x => x.id === data.inventory_id) : null;
    if (data && tmp) {
      setCurIvt(tmp);
    }
  }, [data, inventories]);

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = farmsData
    .find(x => x.id === focFarmId)
    ?.lines.filter(x => !!x.growing_cycle)
    .map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.line_name,
    }));
  const inventoriesOption = inventories.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label:
      currentFarm?.type === 'MUSSEL' ? x.subtype : `${x.type} ${x.subtype}`,
  }));

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className={className}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {title}
          </Subtitle>
        </div>
        <div>
          {lineChangeable && (
            <div className='mb-17 d-flex'>
              <div className='w-100 mr-7'>
                <Dropdown
                  label={translate(lang, 'Select farm')}
                  options={farmOptions}
                  value={focFarmId.toString()}
                  onChange={v => setFocFarmId(Number(v))}
                />
              </div>
              <div className='w-100 ml-7'>
                <Dropdown
                  label={translate(lang, 'Select line')}
                  options={lineOptions ?? []}
                  value={
                    !!lineOptions &&
                    lineOptions.some(x => Number(x.id) === curLine.id)
                      ? curLine.id.toString()
                      : curLine.line_name
                  }
                  onChange={handleLineChange}
                />
              </div>
            </div>
          )}
          <div className='mb-16 d-flex'>
            <div className='mr-7 w-100'>
              <Datepicker
                label={translate(lang, 'Manage date')}
                defaultValue={formData.manage_date}
                onChange={e =>
                  updateForm(
                    'manage_date',
                    e?.toDate().getTime() ?? moment().toDate().getTime(),
                  )
                }
                required
                disabled={onlyView}
              />
            </div>
            <div className='ml-7 w-100'>
              <Dropdown
                label={translate(lang, 'Select inventory')}
                options={inventoriesOption}
                value={curIvt?.id.toString()}
                onChange={x => selectInventory(x)}
                disabled={onlyView || !!updateId}
              />
            </div>
          </div>
          {!!curIvt && (
            <div className='mb-16 d-flex'>
              <div className='mr-7 w-100'>
                <Dropdown
                  label={translate(lang, 'Type')}
                  options={typeOptions}
                  value={formData.type}
                  onChange={v => updateForm('type', v)}
                  disabled={onlyView || !!updateId}
                />
              </div>
              <div className='ml-7 w-100'>
                <Input
                  label={translate(lang, 'Quantity')}
                  placeholder={translate(
                    lang,
                    'Maximum allowed value is %s',
                    availableQty,
                  )}
                  type='number'
                  value={formData.quantity?.toString() ?? ''}
                  onChange={e => updateForm('quantity', e.target.value)}
                  disabled={onlyView}
                />
              </div>
            </div>
          )}
          {!!formData.time_tracks && (
            <TimeTrackInput
              data={formData.time_tracks}
              onChange={t => updateForm('time_tracks', t)}
              disabled={onlyView}
            />
          )}
        </div>
        {!!manageCycle && (
          <div className='mt-32 mb-32'>
            <div className='d-flex align-items-center mb-7'>
              <Subtitle color='black-1' align='left' size={4} fontWeight={600}>
                {translate(lang, 'Line depth manage')}
              </Subtitle>
            </div>
            <Input
              label=''
              placeholder={translate(lang, 'Please enter line depth')}
              type='number'
              unit='m'
              value={depth?.toString() ?? ''}
              onChange={e =>
                setDepth(
                  e.target.value.length <= 0 ? null : Number(e.target.value),
                )
              }
            />
          </div>
        )}
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled || onlyView}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

export default FloatManageModal;

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ILineResource } from '../../entities/farms.entities';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const unitLabel = (d: any) => {
  if (d.total_seeded_length !== undefined) {
    return `${d.total_seeded_length} m`;
  } else if (d.total_seeded_basket_count !== undefined) {
    return `${d.total_seeded_basket_count} baskets`;
  } else if (d.total_seeded_tank_area !== undefined) {
    return `${d.total_seeded_tank_area} m²`;
  } else {
    return '';
  }
};

interface Props {
  reqLine: ILineResource;
  onClickAssessment: (d: any) => void;
}

const RequireAssessmentCell = ({ reqLine, onClickAssessment }: Props) => {
  const lang = useSelector(selectLang);
  const farm = useSelector(selectFarmsData).find(x => x.id === reqLine.farm_id);
  const line = farm?.lines.find(x => x.id === reqLine.id);

  const handleShowClick = (event: any) => {
    event.preventDefault();
    onClickAssessment(line);
  };

  return (
    <div
      className='--entity --require-assessment'
      onClick={handleShowClick.bind(this)}
    >
      <div className='--header'>
        <span>{translate(lang, 'req. assessment')}</span>
      </div>
      <div className='--title'>
        <div>{`${farm?.name} - ${line?.line_name}`}</div>
        <div className='mt-7'>
          {`${line?.growing_cycle?.main_seed.season_name} - ${unitLabel(
            line?.growing_cycle,
          )}`}
        </div>
      </div>
      <div className='--btn-bar'>
        <Link to={`/farms/${farm?.id}/${line?.id}`} className='--view'>
          {translate(lang, 'View')}
        </Link>
      </div>
    </div>
  );
};

export default RequireAssessmentCell;

import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  PlusIcon,
  Title,
  Spinner,
  ModalComponent,
} from '../../components/shared';
import AutomationTable from '../../components/automation/AutomationTable';
import {
  loadAutomationData,
  removeAutomation,
} from '../../store/automation/automation.actions';
import { useWidth } from '../../util/useWidth';
import { loadUserBoats } from '../../store/users/users.actions';
import { IAutomation } from '../../entities/automation.entities';
import AutomationModal from '../../components/automation/AutomationModal';
import AutoReportModal from '../../components/todo/AutoReportModal';
import { Table } from 'antd';
import { selectProfile } from '../../store/auth/auth.selector';
import { selectLang, selectSpin } from '../../store/ui/ui.selector';
import {
  selectAutomations,
  selectAutoReports,
} from '../../store/automation/automation.selector';
import { translate } from '../../lib/lang.helper';
import TagScheduleModal from '../../components/automation/TagScheduleModal';

const periods = [
  { value: 'today', label: 'Today' },
  { value: 'last_7', label: 'Last 7 days' },
  { value: 'last_30', label: 'Last 30 days' },
  { value: 'last_month', label: 'Last month' },
  { value: 'last_year', label: 'Last year' },
];

const types = [
  { value: 'farm', label: 'Farm Overview' },
  { value: 'task', label: 'Task Report' },
  { value: 'seeding', label: 'Seeding Report' },
  { value: 'harvest', label: 'Harvest Report' },
  { value: 'assessment', label: 'Assessment Report' },
  { value: 'budget', label: 'Budget Report' },
];

const reportColumns = [
  {
    key: 'receivers',
    title: 'Receivers',
    render: (x: IAutomation) => <span>{x.action}</span>,
  },
  {
    key: 'frequency',
    title: 'Frequency',
    render: (x: IAutomation) => (
      <span>
        {x.time === 1
          ? 'Daily'
          : x.time === 7
          ? 'Weekly'
          : `Every ${x.time} days`}
      </span>
    ),
  },
  {
    key: 'period',
    title: 'Period',
    render: (x: IAutomation) => (
      <span>{periods.find(y => y.value === x.unit)?.label}</span>
    ),
  },
  {
    key: 'report_type',
    title: 'Type',
    render: (x: IAutomation) => (
      <span>{types.find(y => y.value === x.condition)?.label}</span>
    ),
  },
  {
    key: 'title',
    title: 'Title',
    render: (x: IAutomation) => <span>{x.title}</span>,
  },
  {
    key: 'comment',
    title: 'Comment',
    render: (x: IAutomation) => <span>{x.description}</span>,
  },
];

const AutomationPage = () => {
  const dispatch = useDispatch();
  const width = useWidth();

  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const isSpinner = useSelector(selectSpin);
  const automations = useSelector(selectAutomations);
  const autoReports = useSelector(selectAutoReports);
  const isEditable = profile?.edit_permission;

  const [autoData, setAutoData] = useState<IAutomation>();
  const [visAuto, setVisAuto] = useState(false);
  const [deleteAutomation, setDeleteAutomation] = useState(false);
  const [autoId, setAutoId] = useState(0);
  const [showReport, setShowReport] = useState(false);
  const [isTagType, setIsTagType] = useState(false);

  const showAutomationAddModal = () => {
    setVisAuto(true);
    setAutoData(undefined);
  };
  const onEdit = (dat: any) => {
    setIsTagType(dat.type === 'TAG_SCHEDULE');
    setAutoData(dat);
    setVisAuto(true);
  };
  const onDelete = (dat: any) => {
    setAutoId(dat.id);
    setDeleteAutomation(!deleteAutomation);
  };
  const onConfirmDelete = async () => {
    setDeleteAutomation(!deleteAutomation);
    await dispatch(removeAutomation(autoId));
    setAutoId(0);
  };
  const tableColumns = [
    ...reportColumns.map(x => ({ ...x, title: translate(lang, x.title) })),
    {
      key: 'delete',
      title: '',
      render: (x: IAutomation) => (
        <Button
          size={0}
          color='red'
          type='fill'
          width='small'
          onClick={() => onDelete(x)}
        >
          {translate(lang, 'Delete')}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(loadAutomationData());
    dispatch(loadUserBoats());
  }, [dispatch]);

  return (
    <div className='budget bg-secondary min-height'>
      <div className='container'>
        <div className='d-flex justify-content-between align-items-center'>
          <Title
            className={width > 768 ? 'pl-21 pt-32 pb-34' : 'pb-14 pt-20'}
            size={5}
            color='black-3'
            align='default'
            fontWeight={700}
          >
            {translate(lang, 'Automation')}
          </Title>
          {isEditable && width > 768 && (
            <Button
              color='blue'
              size={1}
              width='middle'
              type='fill'
              onClick={showAutomationAddModal}
            >
              {translate(lang, 'Add Automation')}
            </Button>
          )}
          {isEditable && width <= 768 && (
            <Button
              color='blue'
              size={0}
              width='default'
              type='fill'
              iconOnly
              onClick={showAutomationAddModal}
            >
              <PlusIcon />
            </Button>
          )}
        </div>
        <div
          className={
            width > 768 ? 'd-flex justify-content-between pl-15 pr-15' : ''
          }
        >
          {!isSpinner && (
            <div className='width-100 pos-relative'>
              <AutomationTable
                data={automations}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </div>
          )}
          {isSpinner && (
            <div className='w-100 d-flex justify-content-center'>
              <Spinner />
            </div>
          )}
        </div>
        <div style={{ overflow: 'auto' }} className='mt-32 pl-15 pr-15'>
          <div className='d-flex justify-content-between align-items-center'>
            <Title
              className='ml-7'
              size={6}
              color='black-3'
              align='default'
              fontWeight={700}
            >
              {translate(lang, 'Automate reports')}
            </Title>
            {isEditable && (
              <Button
                className='mr-17'
                color='green'
                size={1}
                width='middle'
                type='fill'
                onClick={() => setShowReport(true)}
              >
                {translate(lang, 'Automate report sending')}
              </Button>
            )}
          </div>
          <div
            className='ant-table-wrapper table mt-17'
            style={{ width: '1200px', minWidth: '100%', maxWidth: '1200px' }}
          >
            <Table
              rowKey='id'
              className='table table--isFarm pl-0 pr-0'
              pagination={false}
              columns={tableColumns}
              dataSource={autoReports}
            />
          </div>
        </div>
      </div>
      {visAuto &&
        (isTagType ? (
          <TagScheduleModal
            visible={true}
            updateID={autoData?.id}
            data={autoData}
            onClose={() => {
              setVisAuto(false);
              setAutoData(undefined);
              setIsTagType(false);
            }}
          />
        ) : (
          <AutomationModal
            visible={true}
            editId={autoData?.id}
            data={autoData}
            onCancel={() => {
              setVisAuto(false);
              setAutoData(undefined);
            }}
            onConfirm={() => {
              setVisAuto(false);
              setAutoData(undefined);
            }}
            onSelectTag={() => setIsTagType(true)}
          />
        ))}
      <ModalComponent
        visible={deleteAutomation}
        onCancel={() => {
          setDeleteAutomation(!deleteAutomation);
          setAutoData(undefined);
        }}
        type='delete'
        title={translate(lang, 'Delete')}
        text={translate(lang, 'Are you sure to delete this data?')}
        onConfirm={onConfirmDelete}
      />
      {showReport && (
        <AutoReportModal visible={true} onClose={() => setShowReport(false)} />
      )}
    </div>
  );
};

export default AutomationPage;

import { useSelector } from 'react-redux';
import { ISeaweedLineDetail } from '../../entities/farms.entities';
import { ISeaweedCycle } from '../../entities/growing.entities';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import {
  BreadcrumbComponent,
  Button,
  Caret,
  DropdownMenu,
  Pen,
  Subtitle,
  Title,
} from '../../components/shared';
import prevIcon from '../../images/prev-image.png';
import nextIcon from '../../images/next-image.png';
import { Link } from 'react-router-dom';
import LineTagButton from '../../components/lines/LineTagButton';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { averageValue, rangeLabel } from '../../util/farmUtil';
import { amountDays } from '../../lib/farm.helpers';
import HarvestPredictButton from '../../components/lines/HarvestPredictButton';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import {
  selectAllFarms,
  SelectIsEditable,
  SelectIsGrower,
} from '../../store/extra/extra.selector';

interface Props {
  lineData: ISeaweedLineDetail;
  cyclePart: ISeaweedCycle | null;
  isPrevEnabled: boolean;
  isNextEnabled: boolean;
  onClickPrevGroup: () => void;
  onClickNextGroup: () => void;
  showEditLineModal: () => void;
  showEditSeedModal: () => void;
  onSeedingClick: () => void;
  onCatchSpatClick: () => void;
  onAssessmentClick: () => void;
  onMaintenanceClick: () => void;
  onHarvestClick: () => void;
  onExtraSeedClick: () => void;
  loadLine: () => void;
}

const SeaweedLineDesktop = ({
  lineData,
  cyclePart: curCycle,
  isPrevEnabled,
  isNextEnabled,
  onClickPrevGroup,
  onClickNextGroup,
  showEditLineModal,
  showEditSeedModal,
  onSeedingClick,
  onCatchSpatClick,
  onAssessmentClick,
  onMaintenanceClick,
  onHarvestClick,
  onExtraSeedClick,
  loadLine,
}: Props) => {
  const lang = useSelector(selectLang);
  const isEditable = SelectIsEditable(lineData.farm_id);
  const isGrower = SelectIsGrower(lineData.farm_id);
  const { redirectToLine } = useMenuHandler();
  const curFarm = useSelector(selectAllFarms).find(
    x => x.id === lineData.farm_id,
  );
  const lines = curFarm?.lines ?? [];
  const breadcrumbItems = getBreadcrumbMenu('FARM_LINE', {
    FARM_ID: lineData.farm_id,
    LINE_ID: lineData.id,
    FARM_NAME: lineData?.farm_name,
    LINE_NAME: lineData?.line_name,
  }).slice(0, -1);

  const curL = lines.findIndex(x => x.id === lineData.id);
  const prevL = (curL - 1 + lines.length) % lines.length;
  const nextL = (curL + 1) % lines.length;

  const mainSeed = curCycle?.main_seed,
    lastAssessment =
      curCycle && curCycle.assessments.length > 0
        ? curCycle.assessments.reduce((p, c) =>
            !p || p.assessment_date < c.assessment_date ? c : p,
          )
        : null;

  return (
    <div className='farm-line-desktop-template'>
      <div className='pt-28 pb-28 d-flex justify-content-between align-items-center'>
        <div className='d-flex' style={{ alignItems: 'baseline' }}>
          <BreadcrumbComponent items={breadcrumbItems} />
          {lines && (
            <div className='ml-17'>
              <span
                className='prev-link'
                onClick={() =>
                  redirectToLine(lineData.farm_id, lines[prevL].id)
                }
              >
                <img src={prevIcon} alt='prev' />
              </span>
              <span className='line-name'>
                {translate(lang, 'Line %s', lineData.line_name)}
              </span>
              <span
                className='next-link'
                onClick={() =>
                  redirectToLine(lineData.farm_id, lines[nextL].id)
                }
              >
                <img src={nextIcon} alt='next' />
              </span>
            </div>
          )}
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center white-card-small pt-3 pb-3'>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='mr-26'
              name='prev'
              onClick={onClickPrevGroup}
              disabled={!isPrevEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='left' />
            </Button>
            <Subtitle
              size={4}
              color='black'
              align='left'
              fontWeight={500}
              disabled={!curCycle}
            >
              {mainSeed?.season_name ?? translate(lang, 'Empty')}
            </Subtitle>
            <Button
              color='blue'
              size={3}
              width='default'
              type='transparent'
              className='ml-26'
              name='next'
              onClick={onClickNextGroup}
              disabled={!isNextEnabled}
              onlyIconDisabled
            >
              <Caret color='#5A607F' direction='right' />
            </Button>
          </div>
          <Button
            color='blue'
            size={0}
            width='default'
            type='bordered'
            className='ml-8'
            iconOnly
            onClick={showEditSeedModal}
            disabled={!mainSeed?.is_growing || !isEditable}
          >
            <Pen />
          </Button>
          <Button
            color='blue'
            size={1}
            width='middle'
            type='bordered'
            className='ml-16'
            onClick={onHarvestClick}
            disabled={!mainSeed?.is_growing || !isEditable}
          >
            {translate(lang, 'Harvest Complete')}
          </Button>
        </div>
      </div>
      <div className='white-card pt-28 pr-16 pb-28 pl-24 mb-16'>
        <div className='d-flex justify-content-between'>
          <div>
            <Title size={5} color='black-3' align='default' fontWeight={500}>
              {translate(lang, 'Line %s', lineData.line_name)}
            </Title>
            {!!lineData.growing_cycle?.last_harvest ? (
              <div style={{ marginTop: '20px' }}>
                <Link
                  to={`/harvest/${lineData.growing_cycle.last_harvest.id}`}
                  style={{
                    fontStyle: 'italic',
                    textDecorationLine: 'underline',
                  }}
                >
                  <Subtitle
                    size={5}
                    color='gray'
                    align='default'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line is partially harvested')}
                  </Subtitle>
                </Link>
                <Subtitle
                  size={5}
                  color='#000'
                  align='default'
                  fontWeight={500}
                >
                  {translate(
                    lang,
                    'Seeded %s m² and harvested %s m² so far',
                    lineData.growing_cycle.total_seeded_tank_area,
                    lineData.growing_cycle.total_harvested_tank_area,
                  )}
                </Subtitle>
              </div>
            ) : (
              !!lineData.growing_cycle?.last_harvest && (
                <div style={{ marginTop: '10px' }}>
                  <Link
                    to={`/harvest/${lineData.growing_cycle.last_harvest.id}`}
                    style={{
                      fontStyle: 'italic',
                      textDecorationLine: 'underline',
                    }}
                  >
                    <Subtitle
                      size={5}
                      color='gray'
                      align='default'
                      fontWeight={400}
                    >
                      {translate(lang, 'View last harvest')}
                    </Subtitle>
                  </Link>
                </div>
              )
            )}
            <div className='mt-17'>
              <LineTagButton lineData={lineData} loadLine={loadLine} />
            </div>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(lang, 'Tank area')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {`${lineData.growing_cycle?.total_seeded_tank_area ?? '-'}/${
                lineData.tank_area
              } m²`}
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Line name')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {lineData.line_name}
            </Subtitle>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(lang, 'Date seeded')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {defaultDateFormat(mainSeed?.planned_date_seed, '-')}
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Seeded Spat Size')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {rangeLabel(mainSeed?.spat_size, mainSeed?.spat_size_max, 'mm')}
            </Subtitle>
          </div>
          <div>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4'
            >
              {translate(
                lang,
                mainSeed?.is_growing ? 'Planned harvest date' : 'Harvest Date',
              )}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {defaultDateFormat(
                lastAssessment?.planned_date_harvest ??
                  mainSeed?.planned_date_harvest,
                '-',
              )}
            </Subtitle>
            <Subtitle
              size={3}
              color='black'
              align='left'
              fontWeight={400}
              className='mb-4 mt-28'
            >
              {translate(lang, 'Avg. Size')}
            </Subtitle>
            <Subtitle size={4} color='black' align='left' fontWeight={500}>
              {mainSeed
                ? `${
                    lastAssessment?.shell_size.avg ??
                    averageValue(mainSeed?.spat_size, mainSeed?.spat_size_max)
                  } mm`
                : '-'}
            </Subtitle>
          </div>
          {isEditable && (
            <div>
              <DropdownMenu
                data={{ ...lineData, isRedirectLine: true }}
                onEdit={showEditLineModal}
                column='isFarm'
              />
            </div>
          )}
        </div>
        {!!lineData.growing_cycle?.predicted_date_harvest && (
          <div style={{ textAlign: 'right' }}>
            <HarvestPredictButton lineData={lineData} />
          </div>
        )}
      </div>
      <div className='d-flex justify-content-end align-items-center white-card pt-12 pr-16 pb-12 mb-8'>
        {isEditable && (
          <div className='d-flex justify-content-end'>
            {mainSeed ? (
              <>
                {mainSeed.is_growing ? (
                  <>
                    <Button
                      className='mr-10'
                      color='green'
                      size={1}
                      width='middle'
                      type='fill'
                      onClick={onMaintenanceClick}
                    >
                      {translate(lang, 'Add maintenance')}
                    </Button>
                    {!mainSeed.is_catch_spat && (
                      <Button
                        color='blue'
                        size={1}
                        width='middle'
                        type='fill'
                        onClick={onExtraSeedClick}
                      >
                        {translate(lang, 'Add extra seed')}
                      </Button>
                    )}
                    <Button
                      className='ml-10'
                      color='green'
                      size={1}
                      width='middle'
                      type='fill'
                      onClick={onAssessmentClick}
                    >
                      {translate(lang, 'Add assessment')}
                    </Button>
                  </>
                ) : (
                  <span></span>
                )}
              </>
            ) : (
              <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center'>
                  <Subtitle
                    size={3}
                    color='black'
                    align='left'
                    fontWeight={400}
                  >
                    {translate(lang, 'Line empty for')}
                  </Subtitle>
                  <Subtitle
                    size={4}
                    color='black'
                    align='left'
                    fontWeight={600}
                    className='ml-4 mr-27'
                  >
                    {amountDays(lineData?.line_idle)}
                  </Subtitle>
                </div>
                {!isGrower && (
                  <Button
                    className='mr-8'
                    color='blue'
                    size={1}
                    width='small'
                    type='fill'
                    onClick={onCatchSpatClick}
                  >
                    {translate(lang, 'Catch Spat')}
                  </Button>
                )}
                <Button
                  className='mr-8'
                  color='blue'
                  size={1}
                  width='small'
                  type='fill'
                  onClick={onSeedingClick}
                >
                  {translate(lang, 'Seed the line')}
                </Button>
                <Button
                  color='green'
                  size={1}
                  width='middle'
                  type='fill'
                  onClick={onMaintenanceClick}
                >
                  {translate(lang, 'Add maintenance')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SeaweedLineDesktop;

import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';

export const selectInventories = createSelector(
  (state: RootState) => state.inventories.inventories,
  inventories => inventories,
);

export const selectFloatInventories = createSelector(
  (state: RootState) =>
    state.inventories.inventories.filter(x => x.type === 'FLOAT'),
  inventories => inventories,
);

export const selectIvtPositions = createSelector(
  (state: RootState) => state.farms.inventoryPositions,
  pos => pos,
);

export const selectSubtypes = createSelector(
  (state: RootState) => state.inventories.subtypes,
  subtypes => subtypes,
);

import { IBreadcrumb } from '../types/basicComponentsTypes';

type PAGE_NAME =
  | 'OVERVIEW'
  | 'FARMS'
  | 'FARM_LINE'
  | 'FARM_DETAIL'
  | 'FARM_VISUAL'
  | 'INVENTORIES'
  | 'LINE_GROUPS'
  | 'LINE_GROUP_DETAIL'
  | 'SPAT_STORAGES'
  | 'SUMMARY_PAGE'
  | 'HARVEST_DETAIL';

const getBreadcrumbKeys = () => ({
  OVERVIEW: {
    id: 1,
    link: '/',
    linkName: 'Overview',
  },
  FARMS: {
    id: 2,
    link: '/farms',
    linkName: 'Farms',
  },
  FARM_DETAIL: {
    id: 3,
    link: '/farms/{FARM_ID}',
    linkName: '{FARM_NAME}',
  },
  FARM_VISUAL: {
    id: 4,
    link: '/farms/visual-line/{FARM_ID}',
    linkName: 'Visual Line',
  },
  FARM_LINE: {
    id: 5,
    link: '/farms/{FARM_ID}/{LINE_ID}',
    linkName: 'Line {LINE_NAME}',
  },
  INVENTORIES: {
    id: 6,
    link: '/inventory',
    linkName: 'Inventory',
  },
  LINE_GROUPS: {
    id: 7,
    link: '/line-group',
    linkName: 'Line Group',
  },
  LINE_GROUP_DETAIL: {
    id: 8,
    link: '/line-group/{LINE_GROUP_ID}',
    linkName: '{LINE_GROUP_NAME}',
  },
  SPAT_STORAGES: {
    id: 9,
    link: '/spat-storage',
    linkName: 'Spat Storage',
  },
  SUMMARY_PAGE: {
    id: 10,
    link: '/summary/harvest',
    linkName: 'Summary',
  },
  HARVEST_DETAIL: {
    id: 11,
    link: '/harvest/{HARVEST_ID}',
    linkName: 'Harvest {HARVEST_ID} Detail',
  },
});

interface Node {
  key: PAGE_NAME;
  children?: Node[] | null;
}

const getRootNode = (): Node => ({
  key: 'OVERVIEW',
  children: [
    {
      key: 'FARMS',
      children: [
        {
          key: 'FARM_DETAIL',
          children: [
            {
              key: 'FARM_LINE',
              children: null,
            },
            {
              key: 'FARM_VISUAL',
              children: null,
            },
          ],
        },
      ],
    },
    {
      key: 'INVENTORIES',
      children: null,
    },
    {
      key: 'LINE_GROUPS',
      children: [{ key: 'LINE_GROUP_DETAIL', children: null }],
    },
    {
      key: 'SPAT_STORAGES',
      children: null,
    },
    {
      key: 'SUMMARY_PAGE',
      children: [{ key: 'HARVEST_DETAIL', children: null }],
    },
  ],
});

const getNodes = (current: Node, key: PAGE_NAME): IBreadcrumb[] | null => {
  const keys = getBreadcrumbKeys();
  let result: IBreadcrumb[] = [keys[current.key]];
  if (current.key === key) {
    return result;
  }
  if (!current.children) return null;
  for (let x of current.children) {
    const c = getNodes(x, key);
    if (c && c.length > 0) {
      result.push(...c);
      return result;
    }
  }
  return null;
};

const convertBreadcrumbs = (items: IBreadcrumb[], params: any) => {
  if (!params || !items) return items;
  for (let i in items) {
    for (let k in params) {
      items[i].link = items[i].link.replaceAll(`{${k}}`, params[k]);
      items[i].linkName = items[i].linkName.replaceAll(`{${k}}`, params[k]);
    }
  }
  return items;
};

const getBreadcrumbMenu = (
  pageName: PAGE_NAME,
  params: any = null,
): IBreadcrumb[] => {
  const root = getRootNode();
  let result = getNodes(root, pageName);
  result = convertBreadcrumbs(result ?? [], params);
  return result;
};

export default getBreadcrumbMenu;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CloseIcon,
  DotsIcon,
  Input,
  Paragrapgh,
  Subtitle,
  Title,
  Dropdown as Select,
} from '../../components/shared';
import { updateTaskSettings } from '../../store/auth/auth.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { selectCustomTasks } from '../../store/tasks/tasks.selector';
import { ITaskSetting } from '../../entities/user.entities';
import { Dropdown, Menu, Modal, Table } from 'antd';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import './styles.scss';

interface ModalProps {
  visible: boolean;
  onClose: () => void;
  data?: ITaskSetting;
}

const TaskSettingModal = ({ visible, onClose, data }: ModalProps) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const customTasks = useSelector(selectCustomTasks);

  const [form, setForm] = useState<ITaskSetting>(
    data ?? { name: '', action: null },
  );
  const [disabled, setDisabled] = useState(false);

  const actionOptions = [
    { id: 'NONE', value: '', label: translate(lang, 'None') },
    { id: 'SEEDING', value: 'SEEDING', label: translate(lang, 'Seeding') },
    { id: 'HARVEST', value: 'HARVEST', label: translate(lang, 'Harvest') },
    {
      id: 'ASSESSMENT',
      value: 'ASSESSMENT',
      label: translate(lang, 'Assessment'),
    },
  ];

  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessageModal: true,
        message,
        type: 'error',
      }),
    );
  const confirmClick = async () => {
    if (!form.name) {
      showError(translate(lang, 'Task name is required'));
      return;
    }
    const tasks = data
      ? customTasks?.map(x => (x.name === data.name ? form : x)) ?? []
      : [...(customTasks ?? []), form];
    setDisabled(true);
    const r = await dispatch(updateTaskSettings(tasks));
    setDisabled(false);
    if (r === true) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Updated successfully'),
        }),
      );
      onClose();
    } else {
      showError(translate(lang, r));
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      footer={null}
      width={650}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, data ? 'Edit task setting' : 'Add task setting')}
          </Subtitle>
        </div>
        <div className='mt-17 mb-17'>
          <Input
            label={translate(lang, 'Task name')}
            type='text'
            value={form.name}
            onChange={e => setForm({ ...form, name: e.target.value })}
          />
        </div>
        <div className='mb-24'>
          <Select
            label={translate(lang, 'Action')}
            options={actionOptions}
            value={form.action ?? ''}
            onChange={(v: any) =>
              setForm({ ...form, action: v === '' ? null : v })
            }
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center mt-32'>
        <Button
          width='small'
          size={2}
          type='fill'
          color='red'
          className='rsp-btn'
          onClick={onClose}
          disabled={disabled}
        >
          {translate(lang, 'Close')}
        </Button>
        <Button
          width='small'
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
      <ModalFeedbackView />
    </Modal>
  );
};

const TaskSettings = () => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const customTasks = useSelector(selectCustomTasks);

  const [disabled, setDisabled] = useState(false);
  const [editItem, setEditItem] = useState<ITaskSetting>();
  const [visibleAdd, setVisibleAdd] = useState(false);

  const deleteItem = async (name: string) => {
    const tmp = customTasks?.filter(x => x.name !== name) ?? [];
    setDisabled(true);
    const r = await dispatch(updateTaskSettings(tmp));
    setDisabled(false);
    if (r === true) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Updated successfully'),
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(lang, r),
        }),
      );
    }
  };

  const columns = [
    {
      title: translate(lang, 'Name'),
      key: 'name',
      render: (x: ITaskSetting) => <span>{x.name}</span>,
    },
    {
      title: translate(lang, 'Action'),
      key: 'action',
      render: (x: ITaskSetting) => <span>{x.action}</span>,
    },
    {
      title: '',
      key: 'edit-remove',
      render: (x: ITaskSetting) => (
        <div className='dropdown' onClick={e => e.stopPropagation()}>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => setEditItem(x)}>
                  {translate(lang, 'Edit')}
                </Menu.Item>
                <Menu.Item onClick={() => deleteItem(x.name)}>
                  {translate(lang, 'Delete')}
                </Menu.Item>
              </Menu>
            }
            placement='bottomRight'
            trigger={['click']}
          >
            <div>
              <DotsIcon />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <div className='content pb-32 task-settings'>
      <div className='mb-16 mt-16'>
        <Title size={6} color='black-3' align='default' fontWeight={500}>
          {translate(lang, 'Custom Tasks')}
        </Title>
        <div className='text-right'>
          <Button
            className='ml-17'
            type='fill'
            color='green'
            width='small'
            size={1}
            disabled={disabled}
            onClick={() => setVisibleAdd(true)}
          >
            {translate(lang, 'Add')}
          </Button>
        </div>
      </div>
      <div className='mt-16'>
        {customTasks && customTasks.length > 0 ? (
          <Table
            className={'table table--units table--is__cursor'}
            rowKey={'name'}
            columns={columns}
            dataSource={customTasks}
            pagination={false}
          />
        ) : (
          <div>
            <Paragrapgh size={1} color='black-5' align='left' fontWeight={400}>
              {translate(lang, 'No data available')}
            </Paragrapgh>
          </div>
        )}
      </div>
      {visibleAdd && (
        <TaskSettingModal visible={true} onClose={() => setVisibleAdd(false)} />
      )}
      {editItem && (
        <TaskSettingModal
          visible={true}
          onClose={() => setEditItem(undefined)}
          data={editItem}
        />
      )}
    </div>
  );
};

export default TaskSettings;

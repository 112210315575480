import { useSelector } from 'react-redux';
import { DropdownMenu, Subtitle } from '../../components/shared';
import { IInventoryResource } from '../../entities/inventory.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface Props {
  data: IInventoryResource[];
  showEditModal: (evt: any, x: any) => void;
  showDeleteModal: (evt: any, x: any) => void;
}

const InventoryMobileTable = ({
  data,
  showEditModal,
  showDeleteModal,
}: Props) => {
  const lang = useSelector(selectLang);

  return (
    <div className='table-mobile'>
      {data.map((item, idx) => (
        <div key={idx} className='table-mobile__card'>
          <div className='d-flex justify-content-between align-items-center'>
            <Subtitle size={5} color='black-3' align='left' fontWeight={600}>
              {translate(lang, 'Inventory')} {item.id}
            </Subtitle>
            <div className='table-mobile__card-dot'>
              <DropdownMenu
                data={item}
                column='isUtil'
                onEdit={evt => showEditModal(evt, item)}
                onDeleteRow={evt => showDeleteModal(evt, item)}
                type='isModal'
              />
            </div>
          </div>
          <div className='pt-16'>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Type')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.type}</span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Subtype')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.subtype}</span>
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Quantity')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.quantity}</span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Available Quantity')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.available_quantity}</span>
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  ID
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.id}</span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Price')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>${item.price}</span>
                </Subtitle>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InventoryMobileTable;

import { useSelector } from 'react-redux';
import footerLogo from '../../images/mussel-app.png';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const Footer = () => {
  const lang = useSelector(selectLang);

  return (
    <footer>
      <div className='container'>
        <img className='footer-logo' src={footerLogo} alt='footer-logo' />
        <div className='copyright'>{translate(lang, '_copyright')}</div>
      </div>
    </footer>
  );
};

export default Footer;

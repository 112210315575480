import { useState, useEffect, useRef } from 'react';
import { compose, withProps } from 'recompose';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  withScriptjs,
  withGoogleMap,
  Polygon,
  Polyline,
} from 'react-google-maps';
import mapPin from '../../../images/map-pin.svg';
import { ILocation } from '../../../entities/general.entities';
import Spinner from '../spinner/Spinner';
import Dropdown from '../dropdown/Dropdown';
import './styles.scss';

const Loading = () => (
  <div className='mt-20'>
    <Spinner />
  </div>
);

const mapOptions = [
  { id: 'roadmap', label: 'Roadmap', value: 'roadmap' },
  { id: 'satellite', label: 'Satellite', value: 'satellite' },
  { id: 'hybrid', label: 'Hybrid', value: 'hybrid' },
  { id: 'terrain', label: 'Terrain', value: 'terrain' },
];

const options = {
  minZoom: 3,
  maxZoom: 20,
};

interface ILine {
  points: ILocation[];
  color: string;
}

interface Props {
  position?: ILocation;
  zoom: number;
  defaultPosition: ILocation;
  onCallback?: (p: ILocation) => void;
  polygons?: Array<ILocation[]>;
  lines?: ILine[];
  isStayLocation?: boolean;
}

const MapWithMarkers = compose<Props, Props>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
    loadingElement: <Loading />,
    containerElement: (
      <div style={{ width: '100%', height: `100%`, position: 'relative' }} />
    ),
    mapElement: (
      <div style={{ width: '100%', height: `100%`, position: 'absolute' }} />
    ),
  }),
  withScriptjs,
  withGoogleMap,
)(
  ({
    position,
    zoom,
    defaultPosition,
    onCallback,
    polygons,
    lines,
    isStayLocation,
  }) => {
    const [state, setState] = useState<ILocation>();
    const [mapType, setMapType] = useState('roadmap');

    const defaultCenter = useRef(defaultPosition);

    useEffect(() => {
      if (position) {
        setState(position);
      }
    }, [position]);

    const handleSetPosition = (event: any) => {
      const newPosition = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      if (onCallback) {
        onCallback(newPosition);
      }
      setState(newPosition);
    };

    return (
      <div>
        <div className='map-type-div'>
          <Dropdown
            label=''
            options={mapOptions}
            value={mapType}
            onChange={v => setMapType(v)}
          />
        </div>
        <GoogleMap
          options={options}
          defaultZoom={zoom}
          defaultCenter={defaultCenter.current}
          center={
            isStayLocation
              ? defaultCenter.current
              : state || defaultCenter.current
          }
          onClick={handleSetPosition}
          mapTypeId={mapType}
        >
          {state && (
            <Marker
              position={state}
              icon={{
                url: mapPin,
              }}
            >
              <InfoWindow>
                <div className='map__pin-label'>
                  <span className='pr-15'>{state && state.lat}</span>
                  <span>{state?.lng}</span>
                </div>
              </InfoWindow>
            </Marker>
          )}
          {polygons?.map((data, idx) => (
            <div key={idx}>
              <Polygon
                path={data}
                options={{
                  strokeColor: '#ff0000',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
                onClick={handleSetPosition}
              />
            </div>
          ))}
          {lines?.map((item, idx) => (
            <div key={idx}>
              <Polyline
                path={item.points}
                options={{
                  strokeColor: item.color,
                  strokeOpacity: 1,
                  strokeWeight: 3,
                }}
              />
            </div>
          ))}
        </GoogleMap>
      </div>
    );
  },
);

export default MapWithMarkers;

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner, Title } from '../../components/shared';
import {
  loadFarmsData,
  loadInventoryPositions,
} from '../../store/farms/farms.actions';
import { IMusselLineResource } from '../../entities/farms.entities';
import { InputModal } from '../../components/shared';
import FarmLineInfoModal from '../../components/farm-modals/FarmLineInfoModal';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import { Pagination } from 'antd';
import InputModalTwo from '../../components/shared/modal/InputModalTwo';
import { sendSingleRequest } from '../../apis';
import { hashColor } from '../../entities/util-functions';
import { showFeedback } from '../../store/ui/ui.actions';
import { IInventoryPosition } from '../../entities/inventory.entities';
import FloatManageModal from '../../components/farm-modals/FloatManageModal';
import { selectMusselFarms } from '../../store/farms/farms.selector';
import { selectUserMeta } from '../../store/auth/auth.selector';
import { selectIvtPositions } from '../../store/inventories/inventories.selector';
import {
  getLineStatus,
  getLocalFloatsPosition,
  setFarmVisualFloatsPosition,
  setLocalFloatsPosition,
  updateLineColumn,
  updateLinePriority,
} from '../../lib/farm.helpers';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import './styles.scss';

const getMarkedLength = (seed_id: number, inventory_id: number) => {
  const x = localStorage.getItem(
    `visual_farm_marked_length_${seed_id}_${inventory_id}`,
  );
  if (!x) return null;
  return JSON.parse(x);
};

const removeMarkedLength = (
  seed_id: number,
  inventory_id: number,
  idx: number,
) => {
  let tmp = getMarkedLength(seed_id, inventory_id);
  if (!tmp) return;
  tmp = tmp.filter((x: number) => x !== idx);
  if (tmp.length <= 0) {
    localStorage.removeItem(
      `visual_farm_marked_length_${seed_id}_${inventory_id}`,
    );
  } else {
    localStorage.setItem(
      `visual_farm_marked_length_${seed_id}_${inventory_id}`,
      JSON.stringify(tmp),
    );
  }
};

const setMarkedLength = (
  seed_id: number,
  inventory_id: number,
  ids: number[],
) => {
  if (ids.length <= 0) {
    localStorage.removeItem(
      `visual_farm_marked_length_${seed_id}_${inventory_id}`,
    );
  } else {
    localStorage.setItem(
      `visual_farm_marked_length_${seed_id}_${inventory_id}`,
      JSON.stringify(ids),
    );
  }
};

interface Props {
  line: IMusselLineResource;
  showModal: (x: any) => void;
  moveColumn: (i: number, c: number) => void;
  moveOrder: (i: number, p: number) => void;
}

const LineRowItem = ({ line, showModal, moveColumn, moveOrder }: Props) => {
  const userMeta = useSelector(selectUserMeta);
  const floatsPositions: IInventoryPosition[] | undefined =
    useSelector(selectIvtPositions);
  const [floatsX, setFloatsX] = useState(0);
  const [allowDrag, setAllowDrag] = useState(false);

  const handleClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    evt.preventDefault();
    evt.stopPropagation();
    showModal(line);
  };
  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    if (!allowDrag) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setAllowDrag(false);
    event.dataTransfer.setData('farm_id', line.farm_id.toString());
    event.dataTransfer.setData('line_id', line.id.toString());
    event.dataTransfer.setData('col_no', line.col_no.toString());
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const line_id = parseInt(event.dataTransfer.getData('line_id'));
    const farm_id = parseInt(event.dataTransfer.getData('farm_id'));
    const col_no = parseInt(event.dataTransfer.getData('col_no'));

    if (farm_id === line.farm_id && line_id !== line.id) {
      if (col_no !== parseInt(line.col_no.toString())) {
        moveColumn(line_id, parseInt(line.col_no.toString()));
      }
      moveOrder(line_id, line.id);
    } else {
      const zeroX = (event.target as any)?.getBoundingClientRect()?.left ?? 0;
      const floatTypeId = parseInt(event.dataTransfer.getData('float_type_id'));
      const floatId = parseInt(event.dataTransfer.getData('float_id'));
      const clientX = parseFloat(event.dataTransfer.getData('float_init_x'));
      if (isNaN(floatTypeId) || isNaN(floatId) || isNaN(clientX)) return;
      if (!line.growing_cycle) return;

      const seedingId = line.growing_cycle.main_seed.id;
      let positions = getLocalFloatsPosition(seedingId, floatTypeId);
      if (!positions || positions.length <= floatId) return;

      positions[floatId] =
        (Math.max(2, positions[floatId]) / (clientX - zeroX)) *
        (event.clientX - zeroX);
      positions[floatId] = Math.min(100, positions[floatId]);
      positions[floatId] = Math.max(2, positions[floatId]);

      setLocalFloatsPosition(seedingId, floatTypeId, positions);
      setFarmVisualFloatsPosition(seedingId, floatTypeId, positions).then();
      setFloatsX(floatsX + 1);
    }
    event.dataTransfer.clearData();
  };
  const lineStatus = getLineStatus(line, userMeta?.line_assess_expire_days);
  const color = `--${lineStatus.toLowerCase()}`;

  const floats = useMemo(() => {
    if (!line.growing_cycle?.inventories_sum) return [];
    let floats = [];
    const seedID = line.growing_cycle.main_seed.id;
    for (let x of line.growing_cycle.inventories_sum) {
      const colCode = hashColor(x.inventory_subtype);
      let positions: any = getLocalFloatsPosition(seedID, x.inventory_id);
      let flag = false;

      if (!positions) {
        positions = [...Array(x.quantity).keys()].map(
          i => (i / (x.quantity - 1)) * 98 + 2,
        );
        flag = true;
      } else if (positions.length > x.quantity) {
        positions = positions.slice(0, x.quantity);
        flag = true;
      } else if (positions.length < x.quantity) {
        const tps = [...Array(x.quantity - positions.length).keys()].map(
          i => ((i + positions.length) / (x.quantity - 1)) * 98 + 2,
        );
        const ids = [...Array(x.quantity - positions.length).keys()].map(
          i => i + positions.length,
        );
        setMarkedLength(seedID, x.inventory_id, ids);
        positions.push(...tps);
        flag = true;
      }
      if (flag) {
        setLocalFloatsPosition(seedID, x.inventory_id, positions);
        setFarmVisualFloatsPosition(
          seedID,
          x.inventory_id,
          positions,
          true,
        ).then();
        setFloatsX(floatsX + 1);
      }
      const old_len = getMarkedLength(seedID, x.inventory_id) ?? [];

      floats.push({
        id: x.inventory_id,
        quantity: x.quantity,
        color: colCode,
        positions,
        old_len,
      });
    }
    return floats;
  }, [line.growing_cycle]);

  const handleDragStartPoint = (
    c: any,
    j: number,
    event: React.DragEvent<HTMLDivElement>,
  ) => {
    removeMarkedLength(line.growing_cycle?.main_seed.id ?? 0, c.id, j);
    event.stopPropagation();
    event.dataTransfer.setData('float_type_id', c.id);
    event.dataTransfer.setData('float_id', j.toString());
    event.dataTransfer.setData('float_init_x', event.clientX.toString());
  };
  const handleDragOverPoint = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClickFloatsPoint = (
    c: any,
    j: number,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    removeMarkedLength(line.growing_cycle?.main_seed.id ?? 0, c.id, j);
    setFloatsX(floatsX + 1);
  };

  useEffect(() => {
    if (line.growing_cycle) {
      for (let x of line.growing_cycle.inventories_sum) {
        const r = floatsPositions?.find(
          y =>
            y.seeding_id === line.growing_cycle?.main_seed.id &&
            y.inventory_id === x.inventory_id,
        );
        if (r && line.growing_cycle.main_seed) {
          setLocalFloatsPosition(
            line.growing_cycle.main_seed.id,
            x.inventory_id,
            r?.points ?? [],
          );
          setFloatsX(floatsX + 1);
        }
      }
    }

    return () => {};
  }, [line.growing_cycle]);

  return (
    <div
      className={`line-item`}
      draggable
      onDragStart={handleDragStart.bind(this)}
      onDrop={handleDrop.bind(this)}
      onDragOver={handleDragOverPoint}
    >
      <div
        className='line-label'
        style={{ marginTop: `${floats.length * 5.5 - 5.5}px` }}
        onMouseDown={() => setAllowDrag(true)}
      >
        {line.line_name}
      </div>
      {floats.map((c, i) => (
        <div key={i} className='line-floats'>
          {[...Array(c.quantity).keys()].map(j => (
            <div
              className='floats-item'
              key={j}
              style={{ left: `${c.positions[j]}%` }}
            >
              <div
                className={
                  c.old_len.includes(j) ? 'floats-dot flash-dot' : 'floats-dot'
                }
                style={{ backgroundColor: c.color }}
                draggable
                onDragStart={handleDragStartPoint.bind(this, c, j)}
                onClick={handleClickFloatsPoint.bind(this, c, j)}
              ></div>
              <div
                className='floats-leg'
                style={{ height: `${(floats.length - i - 1) * 15}px` }}
              ></div>
            </div>
          ))}
        </div>
      ))}
      <div className='line-outer' onClick={handleClick}>
        <div className={`line-inner ${color}`}></div>
      </div>
    </div>
  );
};

const NewColumn: React.FC<{ moveColumn: (i: number, g: number) => void }> = ({
  moveColumn,
}) => {
  const onDragOverEvent = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const onDropEvent = (event: React.DragEvent<HTMLDivElement>) => {
    const line_id = parseInt(event.dataTransfer.getData('line_id'));
    moveColumn(line_id, -1);
  };

  return (
    <div
      className='new-column'
      onDragOver={onDragOverEvent}
      onDrop={onDropEvent}
    >
      <span>Drop Here</span>
    </div>
  );
};

const FarmVisualItem: React.FC<{
  farm: any;
  showLineModal: (x: any) => void;
  goToFarm: (x: any) => void;
  moveColumn: (i: number, c: number) => void;
  moveOrder: (i: number, p: number) => void;
  lang: TLang | undefined;
}> = ({ farm, showLineModal, goToFarm, moveColumn, moveOrder, lang }) => {
  const [onCol, setOnCol] = useState<string | null>(null);

  const viewClickHandle = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    evt.preventDefault();
    evt.stopPropagation();
    goToFarm(farm);
  };
  const filterLineColumns = () => {
    const lines = farm.lines;
    let result: any = [];
    lines.sort((a: any, b: any) => a.col_no - b.col_no);
    let g = -1,
      p = -1;
    for (let i in lines) {
      if (lines[i].col_no > p) {
        g++;
        p = lines[i].col_no;
      }
      if (!result[g]) result[g] = [];
      result[g].push(lines[i]);
    }
    for (let i in result) {
      result[i].sort((a: any, b: any) => b.priority - a.priority);
    }
    return result;
  };
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if ((event.target as any).classList.contains('floats-dot')) return;

    let element: any = null;
    for (
      element = event.target;
      !element.classList.contains('line-column');
      element = element.parentNode
    );
    const colNo = element.getAttribute('col-id');
    setOnCol(colNo);
  };
  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOnCol(null);
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setOnCol(null);

    let element: any = null;
    for (
      element = event.target;
      !element.classList.contains('line-column');
      element = element.parentNode
    );
    const targetColNo = parseInt(element.getAttribute('col-no'));

    const farmId = parseInt(event.dataTransfer.getData('farm_id'));
    const lineId = parseInt(event.dataTransfer.getData('line_id'));
    const colNo = parseInt(event.dataTransfer.getData('col_no'));
    if (farmId !== farm.id) return;
    if (!isNaN(targetColNo) && colNo !== targetColNo) {
      moveColumn(lineId, targetColNo);
    }
  };

  return (
    <div className='farm-visual-item'>
      <div className='farm-item-name'>{farm.name}</div>
      <div className='farm-item-body'>
        {filterLineColumns().map((lineColumn: any, idx: number) => (
          <div
            key={idx}
            className={`line-column ${
              `${farm.id}-${idx}` === onCol ? 'dashed-area' : ''
            }`}
            col-id={`${farm.id}-${idx}`}
            col-no={lineColumn[0].col_no}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className='column-name'>{String.fromCharCode(65 + idx)}</div>
            {lineColumn.map((line: any) => (
              <LineRowItem
                key={line.id}
                line={line}
                showModal={showLineModal}
                moveColumn={moveColumn}
                moveOrder={moveOrder}
              />
            ))}
          </div>
        ))}
        <NewColumn moveColumn={moveColumn} />
      </div>
      <div className='farm-item-action'>
        <Button
          color='green'
          size={5}
          width='default'
          type='fill'
          onClick={viewClickHandle}
        >
          {translate(lang, 'View Detail')}
        </Button>
      </div>
    </div>
  );
};

const VisualFarmsPage = () => {
  const dispatch = useDispatch<any>();
  const { redirectToLine, redirectToFarm } = useMenuHandler();
  const farmsData = useSelector(selectMusselFarms);
  const lang = useSelector(selectLang);

  const [selectedLine, setSelectedLine] = useState<IMusselLineResource>();
  const [lineVisible, setLineVisible] = useState(false);
  const pageSize = 20;
  const [curPage, setCurPage] = useState(farmsData.length > 0 ? 1 : 0);
  const [loading, setLoading] = useState(false);
  const [visibleIvtModal, setVisibleIvtModal] = useState(false);

  const goToLineDetail = () => {
    if (!selectedLine) return;
    redirectToLine(selectedLine.farm_id.toString(), selectedLine.id.toString());
  };
  const showLineModal = (data: any) => {
    setLineVisible(true);
    setSelectedLine(data);
  };
  const goToFarmDetail = (farm: any) => {
    redirectToFarm(farm.id);
  };
  const handlePageChange = (page: number, pageSize: number | undefined) => {
    setCurPage(page);
  };
  const moveColumn = (lineId: number, colNo: number) => {
    setLoading(true);
    const finish = () => {
      setLoading(false);
      dispatch(loadFarmsData());
    };
    updateLineColumn(lineId, colNo).then(finish).catch(finish);
  };
  const moveOrder = (lineId: number, position: number) => {
    setLoading(true);
    const finish = () => {
      setLoading(false);
      dispatch(loadFarmsData());
    };
    updateLinePriority(lineId, position).then(finish).catch(finish);
  };
  const confirmInventorySeed = (form: any) => {
    if (!selectedLine || !selectedLine.growing_cycle) return;
    sendSingleRequest(
      form,
      'POST',
      `api/farm/line/seed/inventory-seeds/${selectedLine.growing_cycle.main_seed.id}`,
      true,
    ).then(r => {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(
            lang,
            r.data?.message ?? 'Floats added successfully',
          ),
        }),
      );
      dispatch(loadFarmsData()).then(() => {
        setVisibleIvtModal(false);
      });
    });
  };

  useEffect(() => dispatch(loadInventoryPositions()), [dispatch]);

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative'>
        {loading && (
          <div className='d-flex justify-content-center align-items-center min-height pos-absolute w-100'>
            <Spinner />
          </div>
        )}
        <div className='visual-farms-page'>
          <div className='farms__header d-flex justify-content-between align-items-center'>
            <Title size={5} color='black-3' align='default' fontWeight={700}>
              {translate(lang, 'Farms (Visual)')}
            </Title>
          </div>
          <div className='farms-content'>
            {farmsData
              .filter(
                (x, i) =>
                  i >= pageSize * (curPage - 1) && i < pageSize * curPage,
              )
              .map(item => (
                <FarmVisualItem
                  key={item.id}
                  farm={item}
                  showLineModal={showLineModal}
                  goToFarm={goToFarmDetail}
                  moveOrder={moveOrder}
                  moveColumn={moveColumn}
                  lang={lang}
                />
              ))}
            <div className='paginator'>
              <Pagination
                total={farmsData.length}
                current={curPage}
                pageSize={pageSize}
                onChange={handlePageChange}
              />
            </div>
          </div>
          <div className='farms-footer'>
            <div className='float-right pt-32 pr-32'>
              <div className='legend'>
                <Title
                  size={6}
                  color='black-3'
                  align='default'
                  fontWeight={500}
                >
                  {translate(lang, 'Legend')}
                </Title>
                <div className='legend-item mt-17'>
                  <div className='legend-inner --empty'></div>
                  <div className='legend-label'>{translate(lang, 'Empty')}</div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --ready_harvest'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Ready to harvest')}
                  </div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --require_assessment'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Requires assessment')}
                  </div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --catch_spat'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Catch spat')}
                  </div>
                </div>
                <div className='legend-item'>
                  <div className='legend-inner --normal'></div>
                  <div className='legend-label'>
                    {translate(lang, 'Growing mussel')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {lineVisible &&
        selectedLine &&
        (selectedLine.growing_cycle ? (
          <InputModalTwo
            title={`Line Detail - ${selectedLine.line_name}`}
            visible={lineVisible}
            onCancel={() => setLineVisible(false)}
            onConfirm={goToLineDetail}
            confirmNameBtn={translate(lang, 'View Detail')}
            onConfirmTwo={() => {
              setVisibleIvtModal(true);
              setLineVisible(false);
            }}
            confirmNameBtnTwo={translate(lang, 'Manage Floats')}
            hideCancel={true}
          >
            <FarmLineInfoModal lineData={selectedLine} />
          </InputModalTwo>
        ) : (
          <InputModal
            title={`Line Detail - ${selectedLine.line_name}`}
            type='confirm'
            visible={lineVisible}
            onCancel={() => setLineVisible(false)}
            onConfirm={goToLineDetail}
            confirmNameBtn={translate(lang, 'View Detail')}
          >
            <FarmLineInfoModal lineData={selectedLine} />
          </InputModal>
        ))}
      {visibleIvtModal && selectedLine?.growing_cycle && (
        <FloatManageModal
          title='Inventory management'
          visible={visibleIvtModal}
          onCancel={() => setVisibleIvtModal(false)}
          onConfirm={() => {}}
          lineData={selectedLine}
          overConfirm={confirmInventorySeed}
        />
      )}
    </div>
  );
};

export default VisualFarmsPage;

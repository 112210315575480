import { Link } from 'react-router-dom';
import { Spinner, Subtitle } from '../../components/shared';
import { IFarmResource } from '../../entities/farms.entities';
import { Button, Table } from 'antd';
import { useEffect, useState } from 'react';
import { sendSingleRequest } from '../../apis';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { formatNumber } from '../../entities/util-functions';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';

interface ILineData {
  farm: {
    id: number;
    name: string;
    farm_number: string;
  };
  line: {
    id: number;
    line_name: string;
    length: number;
  };
  previous?: {
    harvest_amount: number;
  };
}

interface IRow {
  id: number;
  estimated_date: number;
  farm: string;
  line: string;
  last_assessment_date: string;
  harvest_amount: string;
  link: string;
}

const columns = (lang: TLang | undefined) => [
  {
    key: 'estimated_date',
    dataIndex: 'estimated_date',
    title: translate(lang, 'Estimated Harvest Date'),
    render: (x: number) => <span>{defaultDateFormat(x)}</span>,
  },
  {
    key: 'farm',
    title: translate(lang, 'Farm/Line'),
    render: (x: any) => <span>{`${x.farm}/${x.line}`}</span>,
  },
  {
    key: 'last_assessment_date',
    dataIndex: 'last_assessment_date',
    title: translate(lang, 'Last Assessment Date'),
    render: (x: any) => <span>{x}</span>,
  },
  {
    key: 'harvest_amount',
    dataIndex: 'harvest_amount',
    title: translate(lang, 'Last Season Harvest'),
    render: (x: any) => <span>{x}</span>,
  },
  {
    key: 'link',
    title: '',
    dataIndex: 'link',
    render: (x: any) => (
      <Link to={x}>
        <Button type='primary' size='small'>
          {translate(lang, 'View Line')}
        </Button>
      </Link>
    ),
  },
];

interface Props {
  farms: IFarmResource[];
}

const UpcomingHarvests = ({ farms }: Props) => {
  const lang = useSelector(selectLang);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await sendSingleRequest(
        {},
        'POST',
        'api/overview/lines-performance',
        true,
      );
      setLoading(false);
      if (res.status) {
        const performs: ILineData[] = res.data;
        let result: IRow[] = [];
        for (let farm of farms) {
          for (let line of farm.lines) {
            if (!line.growing_cycle) continue;
            const lastAss = line.growing_cycle.last_assessment;
            const pfm = performs.find(
              x => x.farm.id === farm.id && x.line.id === line.id,
            );

            result.push({
              id: line.id,
              estimated_date:
                lastAss?.planned_date_harvest ??
                line.growing_cycle.main_seed.planned_date_harvest,
              farm: farm.name,
              line: line.line_name,
              last_assessment_date: lastAss
                ? defaultDateFormat(lastAss.assessment_date) ?? '-'
                : '-',
              harvest_amount:
                pfm && pfm.previous
                  ? `${formatNumber(pfm.previous.harvest_amount)} ${
                      farm.type === 'MUSSEL' ? ' kg' : ' dozens'
                    }`
                  : '-',
              link: `/farms/${farm.id}/${line.id}`,
            });
          }
        }
        result.sort((a, b) => a.estimated_date - b.estimated_date);
        result = result.slice(0, 7);
        setRows(result);
      } else {
        window.alert(
          translate(lang, res.data?.message ?? 'Something went wrong'),
        );
      }
    })();
  }, [farms]);

  return (
    <div className='pb-32 pt-32'>
      <Subtitle size={4} color='black' align='center' fontWeight={600}>
        {translate(lang, 'Upcoming Harvests')}
      </Subtitle>
      {loading ? (
        <div className='mt-32 mb-32'>
          <Spinner />
        </div>
      ) : (
        <div className='mt-16'>
          <Table
            rowKey={'id'}
            className='table table--isFarms'
            columns={columns(lang)}
            dataSource={rows}
            pagination={false}
          />
        </div>
      )}
      <Link className='mt-16' to='/lines'>
        <Button type='primary'>{translate(lang, 'View Lines')}</Button>
      </Link>
    </div>
  );
};

export default UpcomingHarvests;

import { IFloatResource, IFloatSum } from '../entities/farms.entities';

export const LineInventoriesLabel = (inventories: IFloatResource[]) =>
  inventories.map(x => (
    <span key={`ivt-${x.id}`}>
      <span>
        {x.subtype} - {x.pending_quantity}
      </span>
      <br />
    </span>
  ));

export const SeedInventoriesLabel = (ivt_seeds: IFloatSum[]) =>
  ivt_seeds.map(x => (
    <span key={`seed-${x.inventory_id}`}>
      <span>
        {x.inventory_subtype} - {x.quantity}
      </span>
      <br />
    </span>
  ));

import { DropdownMenu, Subtitle } from '../../components/shared';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import { ISpatStorageResource } from '../../entities/farms.entities';
import { useSelector } from 'react-redux';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const rangeLabel = (st: any, en: any) =>
  en === null || en === undefined ? st : `${st} - ${en}`;

interface Props {
  data: ISpatStorageResource[];
  showEditModal: (evt: any, x: any) => void;
  showDeleteModal: (evt: any, x: any) => void;
}

const SpatStorageMobileTable = ({
  data,
  showEditModal,
  showDeleteModal,
}: Props) => {
  const lang = useSelector(selectLang);

  return (
    <div className='table-mobile'>
      {data.map((item, idx) => (
        <div key={idx} className='table-mobile__card'>
          <div className='d-flex justify-content-between align-items-center'>
            <Subtitle size={5} color='black-3' align='left' fontWeight={600}>
              {item.source}
            </Subtitle>
            <div className='table-mobile__card-dot'>
              <DropdownMenu
                data={item}
                column='isUtil'
                onEdit={evt => showEditModal(evt, item)}
                onDeleteRow={evt => showDeleteModal(evt, item)}
                type='isModal'
              />
            </div>
          </div>
          <div className='pt-16'>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Source line')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>
                    {item?.source_line
                      ? `${item?.source_line.farm.name} - ${item?.source_line.line_name}`
                      : `${item?.src_line_name ?? '-'}`}
                  </span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Destination Line')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>
                    {item?.destination_line
                      ? `${item?.destination_line.farm.name} - ${item?.destination_line.line_name}`
                      : `${item?.dst_line_name ?? '-'}`}
                  </span>
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Collection date')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{defaultDateFormat(item.collection_date)}</span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Seeded date')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{defaultDateFormat(item.seeded_date)}</span>
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Condition')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.condition ?? '-'}</span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  ID
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.id}</span>
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Weight')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.weight}</span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Available weight')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>{item.available_weight}</span>
                </Subtitle>
              </div>
            </div>
            <div className='d-flex pb-23'>
              <div className='flex-basis-50'>
                <Subtitle
                  size={3}
                  color='black-2'
                  align='left'
                  fontWeight={400}
                >
                  {translate(lang, 'Size')}
                </Subtitle>
                <Subtitle
                  size={5}
                  color='black-5'
                  align='left'
                  fontWeight={400}
                >
                  <span>
                    {rangeLabel(
                      item.harvest?.shell_length,
                      item.harvest?.shell_length_max,
                    )}
                  </span>
                </Subtitle>
              </div>
              <div className='flex-basis-50 ml-24'></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SpatStorageMobileTable;

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectMusselFarms } from '../../store/farms/farms.selector';
import { selectInventories } from '../../store/inventories/inventories.selector';
import { useMemo, useState } from 'react';
import { showFeedback } from '../../store/ui/ui.actions';
import { Button, Datepicker, Dropdown, Input } from '../../components/shared';
import { sendSingleRequest } from '../../apis';
import { loadFarmsData } from '../../store/farms/farms.actions';
import { toMillisecond, toSecond } from '../../util/toggleSecondMillisecond';
import moment from 'moment';
import { toSecTimeTracks } from '../../components/shared/input/TimeTrackInput';
import DateTimePicker from '../../components/shared/datepicker/DateTimePicker';
import { IMusselLineResource } from '../../entities/farms.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import { TLang } from '../../entities/ui.entities';

const typeOptions = (lang: TLang | undefined) => [
  {
    id: 'IN',
    value: 'IN',
    label: translate(lang, 'Remove from line to inventory'),
  },
  { id: 'OUT', value: 'OUT', label: translate(lang, 'Added to the line') },
];

interface IForm {
  quantity?: number;
  type: 'IN' | 'OUT';
  manage_date: number;
  start_time: number;
  finish_time: number;
}

interface Props {
  formID: number;
  data: any;
  lineData: IMusselLineResource;
}

const PaperFloatingForm = ({ formID, data, lineData }: Props) => {
  const dispatch = useDispatch<any>();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectMusselFarms);
  const inventories = useSelector(selectInventories);

  const [curFarm, setCurFarm] = useState(
    farmsData.find(f => f.id === lineData.farm_id),
  );
  const [curLine, setCurLine] = useState<IMusselLineResource | undefined>(
    lineData,
  );
  const [curIvt, setCurIvt] = useState(
    inventories.find(i => i.id === data.inventory_id),
  );
  const [formData, setFormData] = useState<IForm>({
    quantity: data.quantity,
    type: data.type ?? 'IN',
    manage_date: toMillisecond(data.manage_date),
    start_time: toMillisecond(data.start_time),
    finish_time: toMillisecond(data.finish_time),
  });
  const [disabled, setDisabled] = useState(false);

  const availableQty = useMemo(() => {
    if (!curIvt) return 0;
    if (formData.type === 'OUT') {
      return curIvt.available_quantity;
    } else {
      return (
        curLine?.growing_cycle?.inventories_sum.find(
          x => x.inventory_id === curIvt.id,
        )?.quantity ?? 0
      );
    }
  }, [curIvt, formData]);

  const selectInventory = (id: string) => {
    const ivt = inventories.find(x => x.id === Number(id));
    setCurIvt(ivt);
  };
  const updateForm = (key: keyof IForm, value: any) => {
    if (key === 'quantity') {
      value = value.length <= 0 ? undefined : Number(value);
    }
    setFormData(prev => ({ ...prev, [key]: value }));
  };
  const showError = (message: string) =>
    dispatch(
      showFeedback({
        isMessage: true,
        type: 'error',
        message: translate(lang, message),
      }),
    );
  const selectFarm = (v: string) => {
    const farm = farmsData.find(x => x.id === Number(v));
    setCurFarm(farm);
    setCurLine(undefined);
  };
  const selectLine = (v: string) => {
    const line = curFarm?.lines.find(x => x.id === Number(v));
    setCurLine(line);
  };
  const confirmClick = async () => {
    if (!curFarm || !curLine) {
      showError('Please select farm and line');
      return;
    }
    if (!curIvt) {
      showError('Please select inventory');
      return;
    }
    if (!formData.quantity) {
      showError('Please input quantity');
      return;
    }
    if (formData.quantity < 0 || formData.quantity > availableQty) {
      showError('Quantity is out of range');
      return;
    }
    const time_tracks = toSecTimeTracks([
      { start_time: formData.start_time, finish_time: formData.finish_time },
    ]);
    if (time_tracks === false) {
      showError('_invalid_time_range');
      return;
    }
    const submitData = {
      inventory_id: curIvt.id,
      quantity: formData.quantity,
      type: formData.type,
      seeding_id: curLine.growing_cycle?.main_seed.id ?? data.seeding_id,
      manage_date: toSecond(formData.manage_date),
      time_tracks,
      id: formID,
    };
    if (curLine.id !== lineData.id && !curLine.growing_cycle) {
      showError('The line is not seeded yet');
      return;
    }
    if (!submitData.seeding_id) {
      showError('The line is not seeded yet');
      return;
    }
    setDisabled(true);
    const res = await sendSingleRequest(
      submitData,
      'POST',
      'api/queue/confirm-inventory_seed',
      true,
    );
    if (res.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, 'Confirmed successfully'),
        }),
      );
      history.goBack();
    } else {
      setDisabled(false);
      showError(res.data?.message ?? 'Unknown error');
    }
  };

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = curFarm?.lines.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: x.line_name,
  }));

  return (
    <div>
      <div className='mb-17 d-flex'>
        <div className='w-100 mr-7'>
          <Dropdown
            label={translate(lang, 'Select farm')}
            options={farmOptions}
            value={curFarm?.id.toString()}
            onChange={v => selectFarm(v)}
          />
        </div>
        <div className='w-100 ml-7'>
          <Dropdown
            label={translate(lang, 'Select line')}
            options={lineOptions ?? []}
            value={curLine?.id.toString()}
            onChange={v => selectLine(v)}
          />
        </div>
      </div>
      <div className='mb-17'>
        <Datepicker
          label={translate(lang, 'Manage date')}
          defaultValue={formData.manage_date}
          onChange={e =>
            updateForm(
              'manage_date',
              e?.toDate().getTime() ?? moment().toDate().getTime(),
            )
          }
          required
        />
      </div>
      <div className='mb-17 d-flex'>
        <div className='w-100 mr-7'>
          <DateTimePicker
            label={translate(lang, 'Start time')}
            defaultValue={formData.start_time}
            onChange={e => updateForm('start_time', e?.toDate().getTime())}
            required
          />
        </div>
        <div className='w-100 ml-7'>
          <DateTimePicker
            label={translate(lang, 'Finish time')}
            defaultValue={formData.finish_time}
            onChange={e => updateForm('finish_time', e?.toDate().getTime())}
            required
          />
        </div>
      </div>
      <div className='mb-17'>
        <Dropdown
          label={translate(lang, 'Select inventory')}
          options={inventories.map(x => ({
            id: x.id.toString(),
            value: x.id.toString(),
            label: x.subtype,
          }))}
          value={curIvt?.id.toString()}
          onChange={v => selectInventory(v)}
        />
      </div>
      <div className='mb-17'>
        <Dropdown
          label={translate(lang, 'Type')}
          options={typeOptions(lang)}
          value={formData.type}
          onChange={v => updateForm('type', v)}
        />
      </div>
      <div className='mb-17'>
        <Input
          label={translate(lang, 'Quantity')}
          type='number'
          placeholder={translate(
            lang,
            'Maximum allowed value is %s',
            availableQty,
          )}
          value={formData.quantity?.toString() ?? ''}
          onChange={e => updateForm('quantity', e.target.value)}
        />
      </div>
      <div className='mt-17 d-flex justify-content-end'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={confirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </div>
  );
};

export default PaperFloatingForm;

import { useCallback, useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { Button, Spinner, Title } from '../../components/shared';
import { adminLoginAsUser } from '../../store/auth/auth.actions';
import { useHistory } from 'react-router-dom';
import { IAdminUserResource } from '../../entities/user.entities';
import { showFeedback } from '../../store/ui/ui.actions';
import moment from 'moment';
import './styles.scss';

const AdminUsersPage = () => {
  const dispatch = useDispatch<any>();
  const history = useHistory();

  const [users, setUsers] = useState<IAdminUserResource[]>();
  const [loading, setLoading] = useState(false);

  const loadUsers = useCallback(() => {
    setLoading(true);
    sendSingleRequest({}, 'GET', 'api/admin/users', true).then(res => {
      setLoading(false);
      if (res.status) {
        setUsers(res.data);
      } else {
        dispatch(
          showFeedback({
            isMessage: true,
            type: 'error',
            message: res.data?.message ?? 'Something went wrong',
          }),
        );
      }
    });
  }, []);

  const loginAsUser = (user: IAdminUserResource) => {
    setLoading(true);
    dispatch(adminLoginAsUser({ user_id: user.id })).then((r: any) => {
      if (r === true) {
        history.replace('/');
      } else {
        setLoading(false);
        dispatch(showFeedback({ isMessage: true, type: 'error', message: r }));
      }
    });
  };

  useEffect(() => loadUsers(), [loadUsers]);

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
      render: (x: any) => <span>{x}</span>,
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (x: any) => <span>{x}</span>,
    },
    {
      title: 'Email',
      key: 'email',
      render: (x: IAdminUserResource) =>
        x.last_location ? (
          <span>
            <div>{x.email}</div>
            <div style={{ color: 'gray' }}>
              {`Last activated on mobile device at ${moment(
                1000 * x.last_location.content?.rec_time ??
                  x.last_location.created_at,
              ).format('lll')}`}
            </div>
          </span>
        ) : (
          <span>{x.email}</span>
        ),
    },
    {
      title: 'Company name',
      key: 'company_name',
      dataIndex: 'company_name',
      render: (x: any) => <span>{x}</span>,
    },
    {
      title: 'Company address',
      key: 'company_address',
      dataIndex: 'company_address',
      render: (x: any) => <span>{x}</span>,
    },
    {
      title: 'Phone number',
      key: 'phone_number',
      dataIndex: 'phone_number',
      render: (x: any) => <span>{x}</span>,
    },
    {
      title: 'Account ID',
      key: 'account_id',
      dataIndex: 'account_id',
      render: (x: any) => <span>{x}</span>,
    },
    {
      title: 'Activated',
      key: 'active',
      dataIndex: 'active',
      render: (x: any) => <span>{x ? 'Yes' : 'No'}</span>,
    },
    {
      title: 'Verified',
      key: 'is_verified',
      dataIndex: 'is_verified',
      render: (x: any) => <span>{x ? 'Yes' : 'No'}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: '',
      render: (x: IAdminUserResource) => (
        <Button
          color='blue'
          size={0}
          width='small'
          type='fill'
          onClick={loginAsUser.bind(this, x)}
        >
          Login
        </Button>
      ),
    },
    {
      title: '',
      key: 'right',
      dataIndex: '',
      render: (x: any) => <span></span>,
    },
  ];

  return (
    <div className='bg-secondary min-height'>
      <div className='container'>
        <div className='queued-page-header justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            All Users
          </Title>
        </div>
        <div className='width-100 mt-17 pb-32'>
          {loading ? (
            <div className='d-flex justify-content-center align-items-center min-height'>
              <Spinner />
            </div>
          ) : (
            <Table
              rowKey='id'
              className='table table--isFarm'
              pagination={false}
              columns={columns}
              dataSource={users}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminUsersPage;

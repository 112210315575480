import React, { useEffect, useState } from 'react';
import { Modal, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import {
  Button,
  CloseIcon,
  Dropdown,
  Input,
  RadioButton,
  Subtitle,
} from '../../components/shared';
import {
  loadInventories,
  loadSubtypes,
} from '../../store/inventories/inventories.actions';
import { showFeedback } from '../../store/ui/ui.actions';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { selectFarmingMethods } from '../../store/utils/utils.selector';
import { selectSubtypes } from '../../store/inventories/inventories.selector';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

interface IForm {
  type: string;
  subtype: string;
  quantity: string;
  price: string;
  subOption: string;
  level: number;
}

const defaultForm = {
  type: 'FLOAT',
  subtype: '',
  quantity: '',
  price: '',
  subOption: '',
  level: 0,
};

interface Props {
  visible: boolean;
  title: string;
  data: any;
  onConfirm: (data: any) => void;
  onCancel: () => void;
  updateId?: number;
}

const InventoryModal = ({
  visible,
  title,
  data,
  onConfirm,
  onCancel,
  updateId,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const farmingMethods = useSelector(selectFarmingMethods);
  const subtypes = useSelector(selectSubtypes);

  const typeOptions = [
    { id: 'FLOAT', label: 'Float', value: 'FLOAT' },
    { id: 'LONGLINE', label: 'Longline', value: 'LONGLINE' },
    { id: 'TIES', label: 'Ties', value: 'TIES' },
    ...farmingMethods.map(x => ({
      id: x.name,
      label: x.name,
      value: x.name,
    })),
  ];
  const [disabled, setDisabled] = useState(false);
  const [form, setForm] = useState<IForm>(defaultForm);
  const [errors, setErrors] = useState<{
    subtype: string | null;
    quantity: string | null;
  }>({ subtype: null, quantity: null });

  const updateForm = (key: keyof IForm, val: any) => {
    let temp: any = { ...form };
    let ek = key;
    temp[key] = val;
    if (key === 'subOption') {
      temp.subtype = val;
      ek = 'subtype';
    }
    setForm(temp);
    setErrors({ ...errors, [ek]: null });
  };
  const inputHandle = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const {
      dataset: { type },
      value,
    } = event.target;
    updateForm(type as any, value);
  };
  const subtypesOptions = [
    ...subtypes.map((x, i) => ({ id: i.toString(), value: x, label: x })),
    {
      id: '-1',
      value: '',
      label: translate(lang, 'Other'),
    },
  ];
  const validForm = () => {
    let tmpErr = { ...errors };
    let res = true;
    if (form.subtype.length <= 0) {
      tmpErr.subtype = 'Please put subtype';
      res = false;
    }
    if (form.quantity.length <= 0 || parseInt(form.quantity) <= 0) {
      tmpErr.quantity = 'Please put valid quantity';
      res = false;
    }
    setErrors(tmpErr);
    if (!res) return null;

    let tmp: any = { ...form };
    if (!tmp.price) delete tmp.price;

    return tmp;
  };
  const handleConfirmClick = async () => {
    const valid = validForm();
    if (!valid) return;
    setDisabled(true);
    const res = !updateId
      ? await sendSingleRequest(
          valid,
          'POST',
          'api/inventory/inventories',
          true,
        )
      : await sendSingleRequest(
          valid,
          'PUT',
          `api/inventory/inventories/${updateId}`,
          true,
        );
    if (res.status) {
      dispatch(loadInventories());
      onConfirm(res.data);
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
    } else {
      setDisabled(false);
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, res.data?.message ?? 'Unknown error'),
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(loadSubtypes());
  }, [dispatch]);

  useEffect(() => {
    if (!updateId) {
      setForm(defaultForm);
    } else if (data) {
      let temp: any = {};
      for (let i in form) if (data[i]) temp[i] = data[i];
      if (data.subtype) temp.subOption = data.subtype;

      setForm({ ...defaultForm, ...temp });
    }
  }, [data, updateId]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <ModalFeedbackView />
      <div className='wrap'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title)}
          </Subtitle>
        </div>
        <div className='spat-storage-modal'>
          <Dropdown
            label={translate(lang, 'Type')}
            className='mb-17'
            value={form.type}
            options={typeOptions}
            onChange={(value, evt) => updateForm('type', value)}
          />
          <div className={errors.subtype ? 'invalid-form mb-17' : 'mb-17'}>
            <Dropdown
              label={translate(lang, 'Subtype')}
              className={`mb-${form.subOption === '' ? '5' : '0'}`}
              value={form.subOption}
              options={subtypesOptions}
              onChange={(value, evt) => updateForm('subOption', value)}
            />
            {form.subOption === '' && (
              <Input
                label=''
                placeholder={translate(lang, 'Subtype')}
                type='text'
                dataType='subtype'
                value={form.subtype}
                onChange={inputHandle}
              />
            )}
            {errors.subtype && (
              <div className='invalid-feedback'>
                {translate(lang, errors.subtype)}
              </div>
            )}
          </div>
          <div className={`mb-17 ${errors.quantity ? 'invalid-form' : ''}`}>
            <Input
              label={translate(lang, 'Quantity')}
              type='number'
              dataType='quantity'
              value={form.quantity}
              onChange={inputHandle}
            />
            {errors.quantity && (
              <div className='invalid-feedback'>
                {translate(lang, errors.quantity)}
              </div>
            )}
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Price')}
              type='number'
              dataType='price'
              value={form.price}
              onChange={inputHandle}
              unit='$'
            />
          </div>
          <div className='mt-32 mb-17'>
            <Radio.Group
              className='d-flex'
              onChange={e => updateForm('level', Number(e.target.value))}
              value={form.level}
            >
              <RadioButton label={translate(lang, 'Submerged')} value={0} />
              <RadioButton
                className='ml-34'
                label={translate(lang, 'Surface')}
                value={1}
              />
            </Radio.Group>
          </div>
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default InventoryModal;

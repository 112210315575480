import { Table } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { BreadcrumbComponent, Button } from '../../components/shared';
import { showFeedback } from '../../store/ui/ui.actions';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import { useWidth } from '../../util/useWidth';
import { ILineGroup } from '../../entities/farms.entities';
import LineGroupModal from './LineGroupModal';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';

const columns = [
  {
    title: 'ID',
    key: 'id',
    render: (x: ILineGroup) => <span>{x.id}</span>,
  },
  {
    title: 'Farm Name',
    key: 'farm',
    render: (x: ILineGroup) => <span>{x.farm.name}</span>,
  },
  {
    title: 'Group Name',
    key: 'name',
    render: (x: ILineGroup) => <span>{x.name}</span>,
  },
  {
    title: 'Description',
    key: 'description',
    render: (x: ILineGroup) => <span>{x.description}</span>,
  },
  {
    title: 'Line Count',
    key: 'lines',
    render: (x: ILineGroup) => <span>{x.lines_count}</span>,
  },
];

const LineGroups = () => {
  const width = useWidth();
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);

  const breadcrumbItems = getBreadcrumbMenu('LINE_GROUPS');

  const [groups, setGroups] = useState<ILineGroup[]>([]);
  const [addGroup, setAddGroup] = useState(false);
  const [editGroup, setEditGroup] = useState<ILineGroup>();

  const loadData = useCallback(async () => {
    const response = await sendSingleRequest({}, 'GET', 'api/line-group', true);
    if (response.status) {
      setGroups(response.data);
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: translate(
            lang,
            response.data?.message ?? 'Something went wrong',
          ),
        }),
      );
    }
  }, []);

  const deleteClick = async (d: ILineGroup) => {
    if (!window.confirm(translate(lang, 'Are you sure to delete this data?')))
      return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/line-group/${d.id}`,
      true,
    );
    if (response.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(lang, 'Deleted successfully'),
          type: 'success',
        }),
      );
      loadData();
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(lang, response.data?.message ?? 'Unknown error'),
          type: 'error',
        }),
      );
    }
  };

  const groupColumns = [
    ...columns.map(x => ({ ...x, title: translate(lang, x.title) })),
    {
      title: '',
      key: 'action',
      render: (x: ILineGroup) => (
        <>
          <Button
            className='mr-7'
            color='blue'
            size={5}
            width='small'
            type='fill'
            onClick={() => setEditGroup(x)}
          >
            {translate(lang, 'Edit')}
          </Button>
          <Button
            className='ml-7'
            color='red'
            size={5}
            width='small'
            type='fill'
            onClick={() => deleteClick(x)}
          >
            {translate(lang, 'Delete')}
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    loadData();

    return () => {};
  }, [loadData]);

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative farms__line'>
        {width > 768 && (
          <div className='d-flex justify-content-between align-items-center pl-21 pr-15 pt-28 pb-28'>
            <BreadcrumbComponent items={breadcrumbItems} />
            <Button
              color='blue'
              size={1}
              width='middle'
              type='fill'
              onClick={() => setAddGroup(true)}
            >
              {translate(lang, 'Add Line Group')}
            </Button>
          </div>
        )}
        <div className='farms__line-content'>
          <div className='d-flex farms__main'>
            <div className='width-100 pos-relative'>
              <Table
                rowKey='id'
                className='table table--isFarm'
                columns={groupColumns}
                pagination={false}
                dataSource={groups}
              />
            </div>
          </div>
        </div>
      </div>
      {(addGroup || editGroup) && (
        <LineGroupModal
          visible={true}
          lineGroup={editGroup}
          onClose={() => {
            setAddGroup(false);
            setEditGroup(undefined);
            loadData();
          }}
        />
      )}
    </div>
  );
};

export default LineGroups;

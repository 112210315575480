import { Modal } from 'antd';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Feedback,
  Input,
  Paragrapgh,
  Subtitle,
} from '../../shared';
import {
  IOysterFarm,
  IOysterLineResource,
} from '../../../entities/farms.entities';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import { toStrSlug } from '../../../lib/common.helpers';
import SignatureCanvas from 'react-signature-canvas';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { sendSingleRequest } from '../../../apis';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { loadInventories } from '../../../store/inventories/inventories.actions';
import { showFeedback } from '../../../store/ui/ui.actions';
import { ITimeRange } from '../../../entities/general.entities';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import { selectProfile } from '../../../store/auth/auth.selector';
import { translate } from '../../../lib/lang.helper';
import { selectLang } from '../../../store/ui/ui.selector';
import {
  SelectOysterFarmsByFarmID,
  SelectUtilsByFarmID,
} from '../../../store/extra/extra.selector';

interface IPickedItem {
  inventory_id: number;
  seeding_id: number;
  quantity: number | null;
}

interface InventoryProps {
  lineData: IOysterLineResource;
  form: IPickedItem[];
  setForm: (form: IPickedItem[]) => void;
}

const ReturningInventories = ({ lineData, form, setForm }: InventoryProps) => {
  const lang = useSelector(selectLang);
  const usedInventories = lineData.growing_cycle?.inventories_sum ?? [];

  const updateForm = (ivt_id: number, val: string) => {
    const ms = lineData.growing_cycle?.main_seed;
    if (!ms) return;

    const v = val.length <= 0 ? null : Number(val);
    let tmp = [...form];
    const i = tmp.findIndex(x => x.inventory_id === ivt_id);
    if (i < 0) {
      tmp.push({ inventory_id: ivt_id, seeding_id: ms.id, quantity: v });
    } else {
      tmp[i].quantity = v;
    }
    setForm(tmp);
  };

  return usedInventories.length > 0 ? (
    <div className='mb-32 mt-17'>
      <Paragrapgh
        size={2}
        color='black'
        align='left'
        fontWeight={400}
        className='pb-12'
      >
        {translate(
          lang,
          'Please input quantity of inventories that you are returning',
        )}
      </Paragrapgh>
      <div className='d-flex justify-content-between align-items-center'>
        {usedInventories.map(item => (
          <div className='mb-17' key={item.inventory_id}>
            <Input
              type='number'
              onChange={e => updateForm(item.inventory_id, e.target.value)}
              value={
                form
                  .find(x => x.inventory_id === item.inventory_id)
                  ?.quantity?.toString() ?? ''
              }
              label={`${item.inventory_type} ${item.inventory_subtype}`}
              placeholder={translate(
                lang,
                'Maximum value is %s',
                item.quantity,
              )}
              max={item.quantity}
            />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

interface ILineData {
  line_id: number;
  amount_dz: number | null;
  amount_pcs: number | null;
  income?: number | null;
  is_full: boolean;
  line_length: number | null;
  return_amount_dz: number | null;
  return_amount_pcs: number | null;
  inventories?: IPickedItem[];
  [key: string]: any;
}

interface IFormData {
  complete_date: number;
  shell_length: number | null;
  shell_length_max: number | null;
  comment?: string;
  images: any[];
  cost?: number;
  signature?: string;
  time_tracks?: ITimeRange[];
  port_of_unload?: string;
  delivered_to?: string;
  growing_area?: string;
  crop_owner?: string;
  packhouse?: string;
  shell_condition?: string;
  harvester_name?: string;
  oyster_type?: string;
  harvest_number?: string;
}

const defaultForm: IFormData = {
  complete_date: Date.now(),
  shell_length: null,
  shell_length_max: null,
  images: [],
};

const getNewLineData = (lines: IOysterLineResource[], lineId: number) => {
  const line = lines.find(y => y.id === lineId);
  return {
    line_id: lineId,
    is_full: true,
    amount_dz: line?.growing_cycle
      ? line.growing_cycle.total_seeded_dozens -
        line.growing_cycle.total_harvested_dozens
      : null,
    amount_pcs: line?.growing_cycle
      ? line.growing_cycle.total_seeded_pieces -
        line.growing_cycle.total_harvested_pieces
      : null,
    line_length: line?.growing_cycle
      ? line.growing_cycle.total_seeded_basket_count -
        line.growing_cycle.total_harvested_basket_count
      : null,
    return_amount_dz: null,
    return_amount_pcs: null,
  };
};

interface Props {
  visible: boolean;
  title?: string;
  data?: any;
  farm?: IOysterFarm;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  overConfirm?: (data: any) => void;
  onlyView?: boolean;
  confirmID?: number;
}

const OysterBulkHarvestModal = ({
  visible,
  title,
  farm: farmData,
  data: paramData,
  onCancel,
  onConfirm,
  overConfirm,
  onlyView,
  confirmID,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const farmsData = SelectOysterFarmsByFarmID(farmData?.id);
  const farmUtils = SelectUtilsByFarmID(farmData?.id);
  const profile = useSelector(selectProfile);
  const port_of_unloads = farmUtils.filter(x => x.type === 'port_of_unload'),
    delivered_tos = farmUtils.filter(x => x.type === 'delivered_to'),
    packhouses = farmUtils.filter(x => x.type === 'packhouse');

  const allLines = useMemo(() => {
    if (farmData) return farmData.lines;

    let result: IOysterLineResource[] = [];
    if (paramData && paramData.lines.length > 0) {
      let farm = farmsData.find(x =>
        x.lines.some(y => y.id === paramData.lines[0].line_id),
      );
      if (farm) {
        result = [...farm.lines];
      }
    }
    return result;
  }, [farmData, farmsData, paramData]);

  const linesOptions = allLines
    .filter(x => !!x.growing_cycle)
    .map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: `${x.farm_name} - ${x.line_name}`,
      is_growing: !!x.growing_cycle,
      farming_method: x.farming_method,
    }));
  const metaFields = farmUtils
    .filter(x => x.type === 'oyster_harvest')
    .map(x => ({
      key: toStrSlug(x.name),
      label: x.name,
    }));
  const metaDefaults = metaFields.reduce((p, c) => {
    p[c.key] = 0;
    return p;
  }, {} as any);

  const signatureCanvas = useRef<SignatureCanvas | null>();
  const [formData, setFormData] = useState<IFormData>(
    paramData
      ? {
          ...defaultForm,
          ...paramData,
          complete_date: paramData.complete_date
            ? toMillisecond(paramData.complete_date)
            : Date.now(),
          time_tracks: paramData.time_tracks
            ? paramData.time_tracks.map((x: any) => ({
                start_time: toMillisecond(x.start_time),
                finish_time: toMillisecond(x.finish_time),
              }))
            : [],
        }
      : {
          ...defaultForm,
          port_of_unload:
            port_of_unloads && port_of_unloads.length > 0
              ? port_of_unloads[0].name
              : defaultForm.port_of_unload,
          delivered_to:
            delivered_tos && delivered_tos.length > 0
              ? delivered_tos[0].name
              : defaultForm.delivered_to,
          packhouse:
            packhouses && packhouses.length > 0
              ? packhouses[0].name
              : defaultForm.packhouse,
          crop_owner:
            farmData && farmData.owners && farmData.owners.length > 0
              ? farmData.owners[0].title
              : null,
          growing_area: farmData?.name,
          harvester_name: profile?.name ?? null,
        },
  );
  const [harvestedLines, setHarvestedLines] = useState<ILineData[]>(
    paramData && paramData.lines ? paramData.lines : [],
  );
  const [disabled, setDisabled] = useState(false);
  const [sigCanLoaded, setSigCanLoaded] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [showPort, setShowPort] = useState(
    !port_of_unloads || port_of_unloads?.length <= 0,
  );
  const [showDel, setShowDel] = useState(
    !delivered_tos || delivered_tos?.length <= 0,
  );
  const [showPac, setShowPac] = useState(
    !packhouses || packhouses?.length <= 0,
  );

  const updateHarvestedLine = (lineId: number, key: string, value: any) => {
    if (
      !['is_full', 'inventories', 'amount_dz', 'return_amount_dz'].includes(key)
    ) {
      value = value.length <= 0 ? null : Number(value);
    }
    let tmp = [...harvestedLines];
    const i = harvestedLines.findIndex(x => x.line_id === lineId);
    if (i > -1) {
      tmp[i] = { ...tmp[i], [key]: value };
      if (metaFields.some(x => x.key === key)) {
        const amountSum = metaFields.reduce(
          (p, c) => p + (tmp[i][c.key] ?? 0),
          0,
        );
        tmp[i] = {
          ...tmp[i],
          amount_dz: amountSum,
          amount_pcs: 12 * amountSum,
        };
      }
      if (key === 'amount_dz') {
        tmp[i] = { ...tmp[i], amount_pcs: value ? 12 * value : null };
      } else if (key === 'return_amount_dz') {
        tmp[i] = { ...tmp[i], return_amount_pcs: value ? 12 * value : null };
      } else if (key === 'is_full') {
        const dt = getNewLineData(allLines, lineId);
        tmp[i].amount_dz = value ? dt.amount_dz : null;
        tmp[i].amount_pcs = value ? dt.amount_pcs : null;
        tmp[i].line_length = value ? dt.line_length : null;
      }
    }
    setHarvestedLines(tmp);
  };
  const selectLines = (val: string[]) => {
    let tmp: ILineData[] = val.map(x => ({
      ...metaDefaults,
      ...getNewLineData(allLines, Number(x)),
    }));
    for (let t of harvestedLines) {
      const i = tmp.findIndex(x => x.line_id === t.line_id);
      if (i > -1) {
        tmp[i] = { ...t };
      }
    }
    setHarvestedLines(tmp);
  };
  const updateForm = (key: keyof IFormData, value: any) => {
    if (
      key === 'shell_length' ||
      key === 'shell_length_max' ||
      key === 'cost'
    ) {
      value = value.length <= 0 ? null : Number(value);
    }
    setFormData({ ...formData, [key]: value });
  };
  const confirmClick = async () => {
    if (harvestedLines.length <= 0) {
      setErrorMsg(translate(lang, 'Please select at least one line'));
      return;
    }
    for (let line of harvestedLines) {
      const fd = allLines.find(x => x.id === line.line_id);
      if (!line.line_length) {
        setErrorMsg(
          translate(
            lang,
            'Please input number of %s',
            `${fd?.farming_method} of ${fd?.farm_name} - ${fd?.line_name}`,
          ),
        );
        return;
      }
      if (confirmID) {
        const nu = metaFields.find(
          x => line[x.key] === null || line[x.key] === undefined,
        );
        if (nu) {
          setErrorMsg(
            translate(
              lang,
              'Please input %s of %s',
              nu.label,
              `${fd?.farm_name} - ${fd?.line_name}`,
            ),
          );
          return;
        }
        if (!line.amount_dz) {
          setErrorMsg(
            translate(
              lang,
              'Please input amount of %s',
              `${fd?.farm_name} - ${fd?.line_name}`,
            ),
          );
          return;
        }
      }
      if (line.inventories && line.inventories.length > 0) {
        if (!fd?.growing_cycle?.inventories_sum) {
          setErrorMsg(
            translate(
              lang,
              'No inventories for %s',
              `${fd?.farm_name} - ${fd?.line_name}`,
            ),
          );
          return;
        }
        for (let ivt of line.inventories) {
          const ivtData = fd.growing_cycle.inventories_sum.find(
            x => x.inventory_id === ivt.inventory_id,
          );
          if (!ivtData) {
            setErrorMsg(
              translate(
                lang,
                'Inventory %s not found in %s',
                ivt.inventory_id,
                `${fd?.farm_name} - ${fd?.line_name}`,
              ),
            );
            return;
          }
          if (ivtData.quantity < (ivt.quantity ?? 0)) {
            setErrorMsg(
              translate(
                lang,
                'Inventory %s quantity is over in %s',
                `${ivtData.inventory_type} ${ivtData.inventory_subtype}`,
                `${fd?.farm_name} - ${fd?.line_name}`,
              ),
            );
            return;
          }
        }
      }
    }
    if (
      formData.shell_length_max !== null &&
      (formData.shell_length === null ||
        formData.shell_length > formData.shell_length_max)
    ) {
      setErrorMsg(
        translate(
          lang,
          'Shell length must be less than or equal to shell length max',
        ),
      );
      return;
    }
    const tt =
      formData.time_tracks && formData.time_tracks.length > 0
        ? toSecTimeTracks(formData.time_tracks)
        : undefined;
    const data = {
      ...formData,
      complete_date: toSecond(formData.complete_date),
      lines: harvestedLines,
      signature: signatureCanvas.current?.toDataURL() ?? null,
      time_tracks: tt ? tt : undefined,
      track_code: paramData?.track_code,
    };

    if (overConfirm) {
      overConfirm(data);
      return;
    }

    setDisabled(true);
    const res = confirmID
      ? await sendSingleRequest(
          { ...data, id: confirmID },
          'POST',
          'api/queue/confirm-bulk-harvest/oyster',
          true,
        )
      : await sendSingleRequest(
          data,
          'POST',
          'api/farm/line/bulk-oyster-harvest',
          true,
        );
    if (res.status) {
      dispatch(loadFarmsData());
      dispatch(loadInventories());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, res.data?.message ?? 'Success'),
        }),
      );
      onConfirm(res.data);
    } else {
      setErrorMsg(translate(lang, res.data?.message ?? 'Request failed'));
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (sigCanLoaded && paramData && paramData.signature) {
      signatureCanvas.current?.fromDataURL(paramData.signature);
    }
  }, [paramData, sigCanLoaded, signatureCanvas.current]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={1100}
    >
      <div className='grower-harvest-modal wrap'>
        {!!errorMsg && (
          <Feedback
            message={errorMsg}
            type={'error'}
            theme='light'
            position={10}
            isGlobal
            onClose={() => setErrorMsg(undefined)}
          />
        )}
        <div className='d-flex align-items-center mb-17'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Oyster bulk harvest')}
          </Subtitle>
        </div>
        <div className='section'>
          <div className='mb-17'>
            <Dropdown
              label={translate(lang, 'Select harvested lines')}
              mode='multiple'
              options={linesOptions}
              value={harvestedLines.map(x => x.line_id.toString()) as any}
              onChange={(v: any) => selectLines(v)}
              showSearch={true}
              disabled={disabled}
            />
          </div>
        </div>
        {harvestedLines.map(line => (
          <div className='section' key={line.line_id}>
            <div className='mb-17'>
              <Subtitle color='black-2' size={0} fontWeight={600} align='left'>
                {translate(
                  lang,
                  'Detail of %s',
                  linesOptions.find(x => Number(x.id) === line.line_id)
                    ?.label ?? '',
                )}
              </Subtitle>
            </div>
            <div className='mb-17 d-flex align-items-center'>
              <div className='mr-7' style={{ flex: 1 }}>
                <Dropdown
                  label='F / P'
                  value={!!line.is_full ? 'full' : 'partial'}
                  options={[
                    {
                      id: 'full',
                      value: 'full',
                      label: translate(lang, 'Full'),
                    },
                    {
                      id: 'partial',
                      value: 'partial',
                      label: translate(lang, 'Partial'),
                    },
                  ]}
                  onChange={v =>
                    updateHarvestedLine(line.line_id, 'is_full', v === 'full')
                  }
                />
              </div>
              <div className='ml-7 mr-7' style={{ flex: 1 }}>
                <Input
                  type='number'
                  label={translate(
                    lang,
                    'Number of %s',
                    linesOptions.find(x => Number(x.id) === line.line_id)
                      ?.farming_method ?? '',
                  )}
                  value={line.line_length?.toString() ?? ''}
                  onChange={e =>
                    updateHarvestedLine(
                      line.line_id,
                      'line_length',
                      e.target.value,
                    )
                  }
                />
              </div>
              <div className='ml-7 mr-7' style={{ flex: 1 }}>
                <Input
                  type='number'
                  label={translate(lang, 'Amount')}
                  value={line.amount_dz?.toString() ?? ''}
                  onChange={e =>
                    updateHarvestedLine(
                      line.line_id,
                      'amount_dz',
                      e.target.value,
                    )
                  }
                  unit='dozens'
                />
              </div>
            </div>
            <ReturningInventories
              lineData={
                allLines.find(x => x.id === line.line_id) as IOysterLineResource
              }
              form={line.inventories ?? []}
              setForm={v => updateHarvestedLine(line.line_id, 'inventories', v)}
            />
            <div className='d-flex-wrap mb-17'>
              {metaFields.map(meta => (
                <div key={meta.key} className='mb-17'>
                  <Input
                    type='number'
                    label={meta.label}
                    unit='dozens'
                    value={line[meta.key] ?? ''}
                    onChange={e =>
                      updateHarvestedLine(
                        line.line_id,
                        meta.key,
                        e.target.value,
                      )
                    }
                  />
                </div>
              ))}
            </div>
            <div className='d-flex'>
              <div className='mb-17' style={{ flex: 1 }}>
                <Input
                  type='number'
                  label={translate(lang, '_graded_oysters')}
                  value={line.return_amount_dz?.toString() ?? ''}
                  onChange={e =>
                    updateHarvestedLine(
                      line.line_id,
                      'return_amount_dz',
                      e.target.value,
                    )
                  }
                  unit='dozens'
                />
              </div>
              <div style={{ flex: 1 }} />
            </div>
          </div>
        ))}
        <div className='section'>
          <div className='item-row mb-17'>
            <div className='item-cell'>
              <Datepicker
                label={translate(lang, 'Harvest complete date')}
                defaultValue={formData.complete_date}
                onChange={e =>
                  e && updateForm('complete_date', e.toDate().getTime())
                }
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                label={translate(lang, 'Shell length min')}
                value={formData.shell_length?.toString() ?? ''}
                onChange={e => updateForm('shell_length', e.target.value)}
                unit='mm'
              />
            </div>
            <div className='item-cell'>
              <Input
                type='number'
                label={translate(lang, 'Shell length max')}
                value={formData.shell_length_max?.toString() ?? ''}
                onChange={e => updateForm('shell_length_max', e.target.value)}
                unit='mm'
              />
            </div>
          </div>
          <div className='item-row mb-17'>
            <div className='item-cell'>
              <Dropdown
                label={translate(lang, 'Port of unload')}
                placeholder={translate(lang, 'Select port of unload')}
                options={[
                  ...(port_of_unloads?.map(x => ({
                    id: x.id.toString(),
                    value: x.name,
                    label: x.name,
                  })) ?? []),
                  { id: '', value: '', label: translate(lang, 'Other') },
                ]}
                value={showPort ? '' : formData.port_of_unload ?? undefined}
                onChange={v => {
                  updateForm('port_of_unload', v);
                  setShowPort(v.length <= 0);
                }}
              />
              {showPort && (
                <Input
                  label=''
                  placeholder={translate(lang, 'Port of unload')}
                  type='text'
                  className='mt-7'
                  onChange={e => updateForm('port_of_unload', e.target.value)}
                  value={formData.port_of_unload ?? ''}
                  disabled={onlyView}
                />
              )}
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Growing area')}
                type='text'
                onChange={e => updateForm('growing_area', e.target.value)}
                value={formData.growing_area ?? ''}
                disabled={onlyView}
              />
            </div>
          </div>
          <div className='item-row mb-17'>
            <div className='item-cell'>
              <Dropdown
                label={translate(lang, 'Packhouse')}
                options={[
                  ...(packhouses?.map(x => ({
                    id: x.id.toString(),
                    value: x.name,
                    label: x.name,
                  })) ?? []),
                  { id: '', value: '', label: translate(lang, 'Other') },
                ]}
                value={showPac ? '' : formData.packhouse ?? undefined}
                onChange={v => {
                  updateForm('packhouse', v);
                  setShowPac(v.length <= 0);
                }}
                placeholder={translate(lang, 'Select packhouse')}
              />
              {showPac && (
                <Input
                  label=''
                  placeholder={translate(lang, 'Packhouse')}
                  type='text'
                  className='mt-7'
                  onChange={e => updateForm('packhouse', e.target.value)}
                  value={formData.packhouse ?? ''}
                  disabled={onlyView}
                />
              )}
            </div>
            <div className='item-cell'>
              <Dropdown
                label={translate(lang, 'Delivered to')}
                options={[
                  ...(delivered_tos?.map(x => ({
                    id: x.id.toString(),
                    value: x.name,
                    label: x.name,
                  })) ?? []),
                  { id: '', value: '', label: translate(lang, 'Other') },
                ]}
                value={showDel ? '' : formData.delivered_to ?? undefined}
                onChange={v => {
                  updateForm('delivered_to', v);
                  setShowDel(v.length <= 0);
                }}
                placeholder={translate(lang, 'Select delivered to')}
              />
              {showDel && (
                <Input
                  label=''
                  placeholder={translate(lang, 'Delivered to')}
                  type='text'
                  className='mt-7'
                  onChange={e => updateForm('delivered_to', e.target.value)}
                  value={formData.delivered_to ?? ''}
                  disabled={onlyView}
                />
              )}
            </div>
          </div>
          <div className='item-row mb-17'>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Oyster type')}
                type='text'
                onChange={e => updateForm('oyster_type', e.target.value)}
                value={formData.oyster_type ?? ''}
                disabled={onlyView}
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Shell condition')}
                type='text'
                onChange={e => updateForm('shell_condition', e.target.value)}
                value={formData.shell_condition ?? ''}
                disabled={onlyView}
              />
            </div>
          </div>
          <div className='item-row mb-17'>
            <div className='item-cell'>
              <Input
                label={translate(lang, 'Crop owner')}
                type='text'
                onChange={e => updateForm('crop_owner', e.target.value)}
                value={formData.crop_owner ?? ''}
                disabled={onlyView}
              />
            </div>
            <div className='item-cell'>
              <Input
                label={translate(lang, "User's name")}
                type='text'
                onChange={e => updateForm('harvester_name', e.target.value)}
                value={formData.harvester_name ?? ''}
                disabled={onlyView}
              />
            </div>
          </div>
          {!!formData.time_tracks && (
            <div className='pb-7'>
              <TimeTrackInput
                data={formData.time_tracks}
                onChange={t => updateForm('time_tracks', t)}
                disabled={onlyView}
              />
            </div>
          )}
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Harvest number')}
              type='number'
              value={formData.harvest_number ?? ''}
              onChange={e => updateForm('harvest_number', e.target.value)}
              disabled={onlyView}
            />
          </div>
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Comment')}
              placeholder={translate(lang, 'Comment')}
              type='textarea'
              onChange={e => updateForm('comment', e.target.value)}
              value={formData.comment ?? ''}
              name='comment'
            />
          </div>
          <div className='mb-17'>
            <PicturesWall
              label={translate(lang, 'Images')}
              fileList={formData.images}
              handleChangeImages={v => updateForm('images', v)}
              disabled={onlyView}
            />
          </div>
          <div className='mb-20'>
            <p className='mb-4 d-block paragrapgh paragrapgh--2 paragrapgh--400 paragrapgh--black-2 paragrapgh--default'>
              {translate(lang, 'Signature')}
            </p>
            <div
              style={{
                border: '1px solid #EFEFEF',
                borderRadius: '4px',
                height: '200px',
              }}
            >
              <SignatureCanvas
                ref={r => {
                  signatureCanvas.current = r;
                  setSigCanLoaded(true);
                }}
                penColor='green'
                canvasProps={{ width: 550, height: 200 }}
              />
            </div>
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width='small'
            className='rsp-btn'
            size={2}
            type='fill'
            color='red'
            onClick={onCancel}
            disabled={disabled}
          >
            {translate(lang, 'Cancel')}
          </Button>
          <Button
            width='small'
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={confirmClick}
            disabled={disabled}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OysterBulkHarvestModal;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { ILineResource } from '../../entities/farms.entities';
import { useWidth } from '../../util/useWidth';
import {
  Button,
  TablesCard,
  BreadcrumbComponent,
  Title,
  Subtitle,
  DropdownMenu,
  BgIcon,
  ExpandArrowIcon,
  OwnerIcon,
  ClockIcon,
  Spinner,
  SortIcon,
  Dropdown,
  Input,
} from '../../components/shared';
import NameWithPercent from '../../components/shared/name-with-percent/NameWithPercent';
import Tooltip from '../../components/shared/tooltip/Tooltip';
import { getInterest } from '../../util/farmUtil';
import { sendSingleRequest } from '../../apis';
import { Image } from 'antd';
import farm_image from '../../images/farm.png';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import { loadSpatStorages } from '../../store/farms/farms.actions';
import { selectLang, selectSpin } from '../../store/ui/ui.selector';
import LinesTable from '../../components/shared/tables/LinesTable';
import { tableLineSorting } from '../../lib/farm.helpers';
import { randomKey } from '../../lib/common.helpers';
import BulkHarvestModal from '../../components/farm-modals/BulkHarvestModal';
import LinesMobile from '../../components/shared/table-mobile/LinesMobile';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import {
  selectAllFarms,
  SelectIsEditable,
} from '../../store/extra/extra.selector';

interface IApiFarmCard {
  name: string;
  this_year: number;
  last_year: number;
}

const lineFilterOptions = (lang: TLang | undefined) => [
  { id: 'all', value: 'all', label: translate(lang, 'All') },
  {
    id: 'empty_lines',
    value: 'empty_lines',
    label: translate(lang, 'Empty Lines'),
  },
  {
    id: 'ready_harvest',
    value: 'ready_harvest',
    label: translate(lang, 'Ready To Harvest'),
  },
  {
    id: 'recently_seeded',
    value: 'recently_seeded',
    label: translate(lang, 'Recently Seeded'),
  },
];

const Farm = () => {
  const width = useWidth();
  const history = useHistory();
  const dispatch = useDispatch();

  const lang = useSelector(selectLang);
  const isSpinner = useSelector(selectSpin);
  const farmsData = useSelector(selectAllFarms);

  const params = useParams<{ idFarm: string }>();
  const currentFarm = farmsData.find(x => x.id === Number(params.idFarm));
  const isEditable = SelectIsEditable(Number(params.idFarm));

  const [generalStats, setGeneralStats] = useState<any[]>([]);
  const [isCardsSpinner, setIsCardsSpinner] = useState(false);
  const [farmLines1, setFarmLines1] = useState<ILineResource[]>([]);
  const [selectedFilterOption, setSelectedFilterOption] = useState('all');
  const [showBulkHarvest, setShowBulkHarvest] = useState(false);
  const [filterName, setFilterName] = useState('');

  useEffect(() => {
    dispatch(loadSpatStorages());
  }, [dispatch]);

  useEffect(() => {
    if (currentFarm?.lines.length) {
      (async () => {
        const farmId: string = params?.idFarm;
        const data = { farmId };
        const res = await sendSingleRequest(
          data,
          'POST',
          'api/farm/get-line-sorting',
          true,
        );
        if (res.data?.ack === 1) {
          const columnName = res.data.column_name;
          const columnOrder = res.data.column_order;
          const lineSort = tableLineSorting(
            currentFarm.lines,
            columnOrder,
            columnName,
          );
          setFarmLines1(lineSort);
        } else {
          setFarmLines1(currentFarm.lines);
        }
      })();
    }
  }, [currentFarm]);

  const breadcrumbItems = getBreadcrumbMenu('FARM_DETAIL', {
    FARM_ID: params.idFarm,
    FARM_NAME: currentFarm?.name,
  });

  const getGeneralStats = async () => {
    setIsCardsSpinner(true);
    const response = await sendSingleRequest(
      { farm_id: params.idFarm },
      'GET',
      'api/overview/farm-budget-info',
      true,
    );
    if (response.status) {
      const newGeneralStats = response.data?.map((item: IApiFarmCard) => {
        const interest = getInterest(
          item?.this_year,
          item?.last_year ? item?.last_year : null,
        );
        const general = [
          {
            name: `${item?.name} this year`,
            value: item?.this_year,
            interest,
          },
          {
            name: `${item?.name} last year`,
            value: item?.last_year,
          },
        ];
        return general;
      });
      setGeneralStats(newGeneralStats);
    }
    setIsCardsSpinner(false);
  };

  useEffect(() => {
    getGeneralStats();
  }, []);

  const handleOnEdit = () => {
    history.push(`/farms/farm-edit/${params.idFarm}`);
  };

  const tooltipItems =
    currentFarm?.owners.map((x, i) => {
      return {
        title: `${x.title} - ${x.percent}%`,
        id: i + 1,
      };
    }) ?? [];

  const getFilteredLines = (lines: ILineResource[]) => {
    let result: ILineResource[] = [];
    if (selectedFilterOption === 'empty_lines') {
      result = lines.filter(x => !x.growing_cycle);
    } else if (selectedFilterOption === 'ready_harvest') {
      let res = lines.filter(
        x => x.growing_cycle && !!x.growing_cycle.ready_harvest,
      );
      if (res)
        res.sort((a, b) =>
          (a.growing_cycle?.ready_harvest ?? 0) >
          (b.growing_cycle?.ready_harvest ?? 0)
            ? -1
            : 1,
        );
      result = res;
    } else if (selectedFilterOption === 'recently_seeded') {
      let res = lines.filter(x => !!x.growing_cycle);
      if (res)
        res.sort((a, b) =>
          (a.growing_cycle?.main_seed.planned_date_seed ?? 0) >
          (b.growing_cycle?.main_seed.planned_date_seed ?? 0)
            ? -1
            : 1,
        );
      result = res;
    } else {
      result = lines;
    }
    if (filterName) {
      result = result.filter(x =>
        x.line_name.toLowerCase().includes(filterName.toLowerCase()),
      );
    }
    return result;
  };

  return (
    <div className='h-calc-80 bg-secondary'>
      <div className='container pos-relative farms__line'>
        {width > 768 && (
          <div className='d-flex justify-content-between align-items-center pl-21 pr-15 pt-28 pb-28'>
            <BreadcrumbComponent items={breadcrumbItems} />
            <div className='d-flex align-items-center'>
              {currentFarm?.type === 'OYSTER' && (
                <div className='mr-12'>
                  <Button
                    color='green'
                    size={1}
                    width='middle'
                    type='fill'
                    onClick={() => setShowBulkHarvest(true)}
                  >
                    {translate(lang, 'Bulk Harvest')}
                  </Button>
                </div>
              )}
              {isEditable && (
                <Link to={`/farms/${params.idFarm}/add-line`}>
                  <Button color='blue' size={1} width='middle' type='fill'>
                    {translate(lang, 'Add Line')}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        )}

        <div className='farms__line-content'>
          <div className='d-flex justify-content-between white-card farms__line--header'>
            <div className='farms__line--header__name'>
              <div>
                <Title
                  size={5}
                  color='black-3'
                  align='default'
                  fontWeight={500}
                >
                  {currentFarm?.name}
                </Title>
                <Subtitle size={4} color='black' align='left' fontWeight={400}>
                  <span className='pr-6'>{currentFarm?.farm_number}</span>
                </Subtitle>
              </div>
              {width <= 768 && isEditable && (
                <div className='d-flex align-items-center'>
                  <DropdownMenu
                    data={currentFarm}
                    column='isFarms'
                    onEdit={handleOnEdit}
                    type='isRedirect'
                    isRedirect='isFarms'
                  />
                </div>
              )}
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <BgIcon color='orange' icon={<ExpandArrowIcon />} />
              <div className='pl-12'>
                <Subtitle size={3} color='black' align='left' fontWeight={400}>
                  {translate(lang, 'Area')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {currentFarm?.area} ha
                </Subtitle>
              </div>
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <BgIcon color='blue' icon={<OwnerIcon />} />
              <div className='pl-12'>
                <Subtitle
                  size={3}
                  color='black'
                  align='left'
                  fontWeight={400}
                  className='pb-4'
                >
                  {translate(lang, 'Owner')}
                </Subtitle>
                <Tooltip content={tooltipItems} position='bottom'>
                  <div className='tooltip d-flex'>
                    {currentFarm?.owners.map((x, i) => {
                      const key = randomKey();
                      return (
                        <div key={key} className='table__owner'>
                          <NameWithPercent
                            title={x.title}
                            percent={x.percent}
                            index={i + 1}
                          />
                        </div>
                      );
                    })}
                  </div>
                </Tooltip>
              </div>
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <BgIcon color='green' icon={<ClockIcon />} />
              <div className='pl-12'>
                <Subtitle size={3} color='black' align='left' fontWeight={400}>
                  {translate(lang, 'Average line empty per year')}
                </Subtitle>
                <Subtitle size={4} color='black' align='left' fontWeight={500}>
                  {currentFarm?.idle_avg === 1
                    ? '1 day'
                    : `${currentFarm?.idle_avg} days`}
                </Subtitle>
              </div>
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <BgIcon color='green' icon={<SortIcon active={true} />} />
              <div className='pl-12' style={{ minWidth: '200px' }}>
                <Dropdown
                  label=''
                  defaultValue={selectedFilterOption}
                  options={lineFilterOptions(lang)}
                  onChange={val => setSelectedFilterOption(val)}
                />
              </div>
            </div>
            <div className='d-flex align-items-center farms__line--header__item'>
              <Link to={`/farms/visual-lines/${params.idFarm}`}>
                <Image src={farm_image} style={{ maxWidth: '30px' }} />
              </Link>
            </div>
            {width > 768 ? (
              <div className={'d-flex align-items-center'}>
                {isEditable && (
                  <DropdownMenu
                    data={currentFarm}
                    column='isFarms'
                    onEdit={handleOnEdit}
                    type='isRedirect'
                    isRedirect='isFarms'
                  />
                )}
              </div>
            ) : (
              <>
                {isEditable && (
                  <Link to={`/farms/${params.idFarm}/add-line`}>
                    <Button
                      color='blue'
                      size={1}
                      width='wide'
                      type='fill'
                      className='farms__line--header__btn'
                    >
                      {translate(lang, 'Add Line')}
                    </Button>
                  </Link>
                )}
              </>
            )}
          </div>
          <div className='d-flex justify-content-between farms__main'>
            {currentFarm && (
              <div className='width-100'>
                {width > 768 ? (
                  <LinesTable
                    type={currentFarm.type}
                    linesData={getFilteredLines(farmLines1)}
                    farmId={currentFarm.id}
                  />
                ) : (
                  <LinesMobile
                    data={getFilteredLines(currentFarm?.lines ?? [])}
                    type={currentFarm.type}
                    isEditable={isEditable}
                  />
                )}
              </div>
            )}
            {generalStats.length > 0 && (
              <div className='farms__statistic'>
                <div className='mb-17'>
                  <Input
                    label={translate(lang, 'Search by line name')}
                    type='text'
                    value={filterName}
                    onChange={e => setFilterName(e.target.value)}
                  />
                </div>
                {!isCardsSpinner ? (
                  <>
                    {generalStats?.map((general, index) => (
                      <TablesCard
                        key={(index * 1000).toString()}
                        data={general}
                      />
                    ))}
                  </>
                ) : (
                  <div className='mt-24'>
                    <Spinner />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isSpinner && <Spinner position='global' />}
      </div>
      {showBulkHarvest && currentFarm && (
        <BulkHarvestModal
          farm={currentFarm}
          visible={true}
          type='OYSTER'
          onConfirm={() => setShowBulkHarvest(false)}
          onCancel={() => setShowBulkHarvest(false)}
        />
      )}
    </div>
  );
};

export default Farm;

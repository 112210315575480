import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ISeaweedLineResource } from '../../../../entities/farms.entities';
import { selectUserMeta } from '../../../../store/auth/auth.selector';
import useMenuHandler from '../useMenuHandler';
import { Dropdown, Menu, Table } from 'antd';
import classNames from 'classnames';
import LineFormModal from '../../../farm-modals/LineFormModal';
import { defaultDateFormat } from '../../../../util/toggleSecondMillisecond';
import Subtitle from '../../subtitle/Subtitle';
import { lineSizeLabel } from '../../../../util/farmUtil';
import DotsIcon from '../../DotsIcon';
import { sendSingleRequest } from '../../../../apis';
import { loadFarmsData } from '../../../../store/farms/farms.actions';
import { showFeedback } from '../../../../store/ui/ui.actions';
import { compareString } from '../../../../lib/common.helpers';
import { amountDays, getLineStatus } from '../../../../lib/farm.helpers';
import { selectLang } from '../../../../store/ui/ui.selector';
import { translate } from '../../../../lib/lang.helper';
import { SelectIsEditable } from '../../../../store/extra/extra.selector';

interface Props {
  linesData: ISeaweedLineResource[];
  farmId?: number;
  isChild?: boolean;
}

const SeaweedLinesTable = ({ linesData, farmId, isChild }: Props) => {
  const dispatch = useDispatch<any>();

  const userMeta = useSelector(selectUserMeta);
  const { redirectToLine } = useMenuHandler();
  const lang = useSelector(selectLang);
  const isEditable = SelectIsEditable(farmId);

  const [editLine, setEditLine] = useState<ISeaweedLineResource>();

  const deleteLine = async (line: ISeaweedLineResource) => {
    if (!window.confirm('Are you sure you want to delete this line?')) return;
    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/line/lines/${line.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Line deleted successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Unknown error',
        }),
      );
    }
  };
  const changeTable = async (pagination: any, filter: any, sorter: any) => {
    if (farmId) {
      const columnKey: any = sorter?.columnKey;
      const orders: any = sorter?.order;
      const data = { columnKey, orders, farmId };
      await sendSingleRequest(data, 'POST', 'api/farm/line-sorting', true);
    }
  };
  const columns = [
    {
      title: translate(lang, 'Line'),
      key: 'line_name',
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) =>
        compareString(a.line_name, b.line_name),
      render: (x: ISeaweedLineResource) => <span>{x.line_name}</span>,
    },
    {
      title: translate(lang, 'Tank area'),
      key: 'tank_area',
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) =>
        a.tank_area - b.tank_area,
      render: (x: ISeaweedLineResource) => <span>{`${x.tank_area} m²`}</span>,
    },
    {
      title: translate(lang, 'Date Seed'),
      key: 'seed_date',
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) => {
        if (a.growing_cycle) {
          return (
            Number(a.growing_cycle.main_seed.planned_date_seed) -
            Number(b.growing_cycle?.main_seed.planned_date_seed)
          );
        } else {
          return (a.line_idle ?? 0) - (b.line_idle ?? 0);
        }
      },
      render: (x: ISeaweedLineResource) =>
        x.growing_cycle ? (
          defaultDateFormat(x.growing_cycle.main_seed.planned_date_seed)
        ) : (
          <div className='d-flex flex-wrap'>
            <span className='min-width-74 pr-4'>
              {translate(lang, 'Line empty for')}
            </span>
            <Subtitle size={5} color='black' align='left' fontWeight={600}>
              {amountDays(x.line_idle)}
            </Subtitle>
          </div>
        ),
    },
    {
      title: translate(lang, 'Last Assessment Date'),
      key: 'last_assessment',
      render: (line: ISeaweedLineResource) => {
        const x = line.growing_cycle?.last_assessment?.assessment_date;
        const d =
          getLineStatus(line, userMeta?.line_assess_expire_days) ===
          'REQUIRE_ASSESSMENT';
        return (
          <span className={d ? 'text-red' : ''}>
            {defaultDateFormat(x, '-')}
          </span>
        );
      },
    },
    {
      title: translate(lang, 'Avg. Size'),
      key: 'condition',
      defaultSortOrder: null,
      sorter: (a: ISeaweedLineResource, b: ISeaweedLineResource) =>
        (a.growing_cycle?.last_assessment?.shell_size.avg ??
          a.growing_cycle?.main_seed.spat_size ??
          0) -
        (b.growing_cycle?.last_assessment?.shell_size.avg ??
          b.growing_cycle?.main_seed.spat_size ??
          0),
      render: (line: ISeaweedLineResource) =>
        line.growing_cycle ? <span>{lineSizeLabel(line)}</span> : '',
    },
    {
      title: '',
      key: 'more',
      render: (d: ISeaweedLineResource) => (
        <div>
          {isEditable && (
            <>
              <div className='dropdown' onClick={e => e.stopPropagation()}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => setEditLine(d)}>
                        {translate(lang, 'Edit')}
                      </Menu.Item>
                      <Menu.Item onClick={() => deleteLine(d)}>
                        {translate(lang, 'Delete')}
                      </Menu.Item>
                    </Menu>
                  }
                  placement='bottomRight'
                  trigger={['click']}
                >
                  <div>
                    <DotsIcon />
                  </div>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={'id'}
        className={classNames(`table table--isFarm`, {
          'table--is__child': isChild,
        })}
        pagination={false}
        columns={columns}
        dataSource={linesData}
        onRow={dataRow => ({
          onClick: () => redirectToLine(dataRow.farm_id, dataRow.id),
        })}
        onChange={changeTable}
      />
      {editLine && (
        <LineFormModal
          visible={true}
          lineData={editLine}
          onClose={() => setEditLine(undefined)}
          title='Edit line details'
          type='SEAWEED'
        />
      )}
    </>
  );
};

export default SeaweedLinesTable;

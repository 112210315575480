import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { ISeedInventoryResource } from '../../entities/farms.entities';
import { Button, CloseIcon, Subtitle } from '../shared';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import { selectAllFarms } from '../../store/extra/extra.selector';

interface Props {
  visible: boolean;
  inventorySeed: ISeedInventoryResource;
  onClose: () => void;
}

const FloatsViewModal = ({ visible, inventorySeed, onClose }: Props) => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectAllFarms);
  const farm = farmsData.find(x =>
    x.lines.some(y => y.id === inventorySeed.line_id),
  );
  const line = farm?.lines.find(x => x.id === inventorySeed.line_id);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      closable
      closeIcon={<CloseIcon />}
      width={600}
      footer={null}
    >
      <div className='wrap view-modal'>
        <div className='d-flex align-items-center mb-16'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, 'Floats Management Details')}:
          </Subtitle>
          <Subtitle
            className='ml-7'
            color='black-3'
            align='left'
            size={1}
            fontWeight={500}
          >
            {`${farm?.name} - ${line?.line_name}`}
          </Subtitle>
        </div>
        <div className='pt-16 pb-24'>
          <div className='info-card'>
            <div className='info-label'>{translate(lang, 'Inventory')}</div>
            <div className='info-value'>{inventorySeed.inventory.subtype}</div>
          </div>
          <div className='info-card'>
            <div className='info-label'>{translate(lang, 'Type')}</div>
            <div className='info-value'>
              {translate(
                lang,
                inventorySeed.status === 'IN'
                  ? 'Removed from line to inventory'
                  : 'Added to the line',
              )}
            </div>
          </div>
          <div className='info-card'>
            <div className='info-label'>{translate(lang, 'Quantity')}</div>
            <div className='info-value'>{inventorySeed.quantity}</div>
          </div>
        </div>
        <div className='modal-button d-flex justify-content-end align-items-center'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={onClose}
          >
            {translate(lang, 'Close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FloatsViewModal;

import React, { useEffect, useMemo, useState } from 'react';
import { Image, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { sendSingleRequest } from '../../apis';
import {
  BreadcrumbComponent,
  Button,
  CaretDownIcon,
  Dropdown,
  DropdownMenu,
  ModalComponent,
  Spinner,
} from '../../components/shared';
import { useWidth } from '../../util/useWidth';
import useMenuHandler from '../../components/shared/tables/useMenuHandler';
import right_icon from '../../images/right-icon.png';
import getBreadcrumbMenu from '../../util/BreadcrumbMenu';
import { useDispatch, useSelector } from 'react-redux';
import { loadSpatStorages } from '../../store/farms/farms.actions';
import { defaultDateFormat } from '../../util/toggleSecondMillisecond';
import SpatStorageMobileTable from './SpatStorageMobileTable';
import { ISpatSeed, ISpatStorageResource } from '../../entities/farms.entities';
import { formatNumber } from '../../entities/util-functions';
import SpatStorageModal from './SpatStorageModal';
import SpatJourneyModal from '../../components/view-modals/SpatJourneyModal';
import { selectSpatStorages } from '../../store/farms/farms.selector';
import { TBusinessType } from '../../entities/general.entities';
import { useLocation } from 'react-router-dom';
import { TLang } from '../../entities/ui.entities';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import './styles.scss';

const rangeLabel = (st: any, en: any) =>
  en === null || en === undefined ? st : `${st} - ${en}`;

const columns = (lang: TLang | undefined) => [
  {
    title: 'ID',
    key: 'id',
    render: (x: ISpatStorageResource) => <span>{x.id}</span>,
  },
  {
    title: translate(lang, 'Source'),
    key: 'source',
    render: (x: ISpatStorageResource) => <span>{x.source}</span>,
  },
  {
    title: translate(lang, 'Seed type'),
    key: 'seed_type',
    render: (x: ISpatStorageResource) => <span>{x.seed_type ?? '-'}</span>,
  },
  {
    title: translate(lang, 'Source line'),
    key: 'source_line',
    render: (x: ISpatStorageResource) => (
      <span>
        {x?.source_line
          ? `${x?.source_line.farm.name} - ${x?.source_line.line_name}`
          : `${x?.src_line_name ?? ''}`}
      </span>
    ),
  },
  {
    title: translate(lang, 'Destination Line'),
    key: 'destination_line',
    render: (x: ISpatStorageResource) => (
      <span>
        {x?.destination_line
          ? `${x?.destination_line.farm.name} - ${x?.destination_line.line_name}`
          : `${x?.dst_line_name ?? ''}`}
      </span>
    ),
  },
  {
    title: translate(lang, 'Collection date'),
    key: 'collection_date',
    render: (x: ISpatStorageResource) => (
      <span>{defaultDateFormat(x.collection_date)}</span>
    ),
  },
  {
    title: translate(lang, 'Seeded date'),
    key: 'seeded_date',
    render: (x: ISpatStorageResource) => (
      <span>{defaultDateFormat(x.seeded_date)}</span>
    ),
  },
  {
    title: translate(lang, 'Condition'),
    key: 'condition',
    render: (x: ISpatStorageResource) => <span>{x.condition}</span>,
  },
  {
    title: translate(lang, 'Amount'),
    key: 'weight',
    render: (x: ISpatStorageResource) => (
      <div style={{ whiteSpace: 'nowrap' }}>
        <div>Total: {formatNumber(x.weight)}</div>
        <div>Available {formatNumber(x.available_weight ?? 0)}</div>
        <div>Harvested: {formatNumber(x.harvested_weight ?? 0)}</div>
        <div>
          {`Seeded: ${formatNumber(
            x.seedings?.reduce((p, c) => p + c.spat_amount, 0),
          )}`}
        </div>
      </div>
    ),
  },
  {
    title: translate(lang, 'Size'),
    key: 'size',
    render: (x: ISpatStorageResource) => (
      <span>
        {rangeLabel(x.harvest?.shell_length, x.harvest?.shell_length_max)}
      </span>
    ),
  },
  {
    title: translate(lang, 'Stage'),
    key: 'stage',
    render: (x: ISpatStorageResource) => <span>{x.stage}</span>,
  },
];

const seedColumns = (type: TBusinessType, lang: TLang | undefined) => [
  {
    title: translate(lang, 'Farm Name'),
    key: 'farm',
    render: (x: ISpatSeed) => <span>{x.line?.farm.name ?? ''}</span>,
  },
  {
    title: translate(lang, 'Line Name'),
    key: 'line',
    render: (x: ISpatSeed) => <span>{x.line?.line_name ?? ''}</span>,
  },
  {
    title: translate(lang, 'Season'),
    key: 'season',
    render: (x: ISpatSeed) => <span>{x.season_name}</span>,
  },
  {
    title:
      type === 'MUSSEL'
        ? `${translate(lang, 'Line Length')} (m)`
        : type === 'OYSTER'
        ? translate(lang, 'Basket count')
        : `${translate(lang, 'Tank Area')} (m²)`,
    key: 'line_length',
    render: (x: ISpatSeed) => (
      <span>
        {type === 'MUSSEL'
          ? x.line_length
          : type === 'OYSTER'
          ? x.basket_count
          : x.tank_area}
      </span>
    ),
  },
  {
    title: translate(lang, 'Amount'),
    key: 'amount',
    render: (x: ISpatSeed) => <span>{formatNumber(x.spat_amount)}</span>,
  },
  {
    title: translate(lang, 'Date seeded'),
    key: 'planned_date_seed',
    render: (x: ISpatSeed) => (
      <span>{defaultDateFormat(x.planned_date_seed)}</span>
    ),
  },
];

const SpatTrackItem: React.FC<{ spat: any; c: boolean }> = ({ spat, c }) => (
  <>
    <div className='mr-17 ml-17 white-card'>
      <span>Name: {spat.source}</span>
      <br />
      <span>Weight: {spat.weight}</span>
      <br />
      {spat.source_line && (
        <span>
          {`Harvested from ${spat.source_line.farm.name} - ${spat.source_line.line_name}`}
        </span>
      )}
    </div>
    {c && (
      <div className='d-flex align-items-center justify-content-center'>
        <Image src={right_icon} />
      </div>
    )}
  </>
);

const SpatTrackRow: React.FC<{ spats: any[] }> = ({ spats }) => (
  <div className='d-flex justify-content-between ml-9 mt-9 mb-9'>
    {spats.map((item: any, idx: number) => (
      <SpatTrackItem
        key={idx}
        spat={spats[spats.length - idx - 1]}
        c={idx < spats.length - 1}
      />
    ))}
  </div>
);

const typeOptions = [
  { id: '', value: '', label: 'All' },
  { id: 'MUSSEL', value: 'MUSSEL', label: 'Mussel' },
  { id: 'OYSTER', value: 'OYSTER', label: 'Oyster' },
  { id: 'SEAWEED', value: 'SEAWEED', label: 'Seaweed' },
];

const SpatStoragesPage = () => {
  const width = useWidth();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);

  const breadcrumbItems = getBreadcrumbMenu('SPAT_STORAGES');
  const { redirectToLine } = useMenuHandler();
  const spatStorages = useSelector(selectSpatStorages);
  const lang = useSelector(selectLang);

  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ISpatStorageResource>();
  const [deleteShow, setDeleteShow] = useState(false);
  const [disable, setDisable] = useState(false);
  const [fltType, setFltType] = useState('AVL');
  const [journeyData, setJourneyData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [pageOptions, setPageOptions] = useState({
    current: 1,
    pageSize: 7,
  });
  const [busType, setBusType] = useState<TBusinessType | ''>(
    (query.get('type') as TBusinessType | undefined) ?? '',
  );

  const onAddClick = () => {
    setSelectedItem(undefined);
    setShowModal(true);
  };
  const showEditModal = (item: ISpatStorageResource) => {
    setShowModal(true);
    setSelectedItem(item);
  };
  const showDeleteModal = (item: ISpatStorageResource) => {
    setSelectedItem(item);
    setDeleteShow(true);
  };
  const deleteSelectedItem = () => {
    if (!selectedItem) return;
    setDisable(true);
    sendSingleRequest(
      {},
      'DELETE',
      `api/farm/spat-storage/${selectedItem.id}`,
      true,
    ).then(res => {
      setDeleteShow(false);
      setDisable(false);
      dispatch(loadSpatStorages());
    });
  };
  const redirectTo = (dataRow: ISpatSeed) => {
    if (dataRow.line) {
      redirectToLine(dataRow.line?.farm.id, dataRow?.line.id);
    }
  };
  const tableColumns = [
    ...columns(lang),
    {
      title: 'More',
      key: 'more',
      align: 'right',
      render: (x: ISpatStorageResource) => (
        <DropdownMenu
          data={x}
          column={'isUtil'}
          onEdit={evt => showEditModal(x)}
          onDeleteRow={evt => showDeleteModal(x)}
          type='isModal'
        />
      ),
    },
  ] as ColumnsType<ISpatStorageResource>;

  const filteredSpats = useMemo(() => {
    let result =
      fltType === 'ACV'
        ? spatStorages.filter(
            x =>
              (x.available_weight ?? 0) <= 0 &&
              (!x.harvest || x.harvest.is_catch_spat === true),
          )
        : spatStorages.filter(x => (x.available_weight ?? 0) > 0);
    if (busType.length > 0) {
      result = result.filter(x => x.type === busType);
    }
    return result;
  }, [fltType, spatStorages, busType]);

  const openJourney = async (id: number) => {
    const spatStorage = spatStorages.find(x => x.id === id);
    if (!spatStorage) return;

    setLoading(true);
    const res = await sendSingleRequest(
      {},
      'GET',
      `api/farm/spats-journey/${id}`,
      true,
    );
    setLoading(false);

    if (res.status) {
      setJourneyData({
        ...res.data,
        spatStorage,
      });
    } else {
      alert(translate(lang, res.data?.message ?? 'Server error'));
    }
  };

  useEffect(() => {
    dispatch(loadSpatStorages());
  }, [dispatch]);

  return (
    <div className='h-calc-80 bg-secondary spat-storages-page'>
      {loading && (
        <div className='loader'>
          <Spinner />
        </div>
      )}
      <div className='container pos-relative farms__line'>
        {width > 768 ? (
          <div className='d-flex justify-content-between align-items-center pl-21 pr-15 pt-28 pb-28'>
            <BreadcrumbComponent items={breadcrumbItems} />
            <Button
              color='blue'
              size={1}
              width='middle'
              type='fill'
              onClick={onAddClick}
            >
              {translate(lang, 'Add Spat Storage')}
            </Button>
          </div>
        ) : (
          <div className='w-100'>
            <Button
              className='w-100 mt-17'
              color='blue'
              size={1}
              width='middle'
              type='fill'
              onClick={onAddClick}
            >
              {translate(lang, 'Add Spat Storage')}
            </Button>
          </div>
        )}
        <div className='farms__line-content'>
          <div className='farms__main'>
            <div className='mb-17 d-flex align-items-center'>
              <div style={{ width: 200, marginRight: 10 }}>
                <Dropdown
                  label=''
                  options={typeOptions}
                  value={busType}
                  onChange={v => setBusType(v as TBusinessType)}
                />
              </div>
              <div style={{ width: 200, marginLeft: 10 }}>
                <Dropdown
                  label=''
                  options={[
                    {
                      id: 'AVL',
                      value: 'AVL',
                      label: translate(lang, 'View Available'),
                    },
                    {
                      id: 'ACV',
                      value: 'ACV',
                      label: translate(lang, 'View Archived'),
                    },
                  ]}
                  value={fltType}
                  onChange={v => setFltType(v)}
                />
              </div>
            </div>
            <div
              style={{ overflow: 'auto' }}
              className='width-100 pos-relative'
            >
              {width > 768 ? (
                <Table
                  rowKey='id'
                  className='table table--isFarm'
                  style={{
                    width: '1200px',
                    minWidth: '100%',
                    maxWidth: '1200px',
                  }}
                  columns={tableColumns}
                  pagination={pageOptions}
                  onChange={po => setPageOptions(po as any)}
                  dataSource={filteredSpats}
                  expandable={{
                    expandedRowRender: d => (
                      <>
                        <Table
                          rowKey='id'
                          className='table table--isFarm is__child'
                          columns={seedColumns(d.type, lang)}
                          pagination={false}
                          dataSource={d.seedings}
                          onRow={dR => ({
                            onClick: redirectTo.bind(null, dR),
                          })}
                        />
                        <SpatTrackRow spats={d.spat_parents ?? []} />
                      </>
                    ),
                    expandIcon: ({ onExpand, record }) => (
                      <div
                        className='pt-20 pb-20'
                        onKeyDown={() => undefined}
                        onClick={event => {
                          event.stopPropagation();
                          onExpand(record, event);
                        }}
                      >
                        <CaretDownIcon />
                      </div>
                    ),
                  }}
                  onRow={(r: ISpatStorageResource) => ({
                    onClick: () => openJourney(r.id),
                  })}
                />
              ) : (
                <SpatStorageMobileTable
                  data={filteredSpats}
                  showEditModal={showEditModal}
                  showDeleteModal={showDeleteModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {!!showModal && (
        <SpatStorageModal
          visible={true}
          data={selectedItem}
          editId={selectedItem?.id}
          onConfirm={() => {
            setShowModal(false);
            setSelectedItem(undefined);
            dispatch(loadSpatStorages());
          }}
          onCancel={() => {
            setSelectedItem(undefined);
            setShowModal(false);
          }}
        />
      )}
      {!!deleteShow && (
        <ModalComponent
          visible={true}
          onCancel={() => setDeleteShow(false)}
          type='delete'
          title={translate(lang, 'Delete')}
          text={translate(lang, 'Are you sure to delete this data?')}
          disabled={disable}
          onConfirm={deleteSelectedItem}
        />
      )}
      {!!journeyData && (
        <SpatJourneyModal
          data={journeyData}
          visible={true}
          onClose={() => setJourneyData(undefined)}
        />
      )}
    </div>
  );
};

export default SpatStoragesPage;

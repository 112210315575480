import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { Menu, Dropdown, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { ILink } from '../../types/basicComponentsTypes';
import { Feedback, Paragrapgh } from '../shared';
import { logOut } from '../../store/auth/auth.actions';
import { useWidth } from '../../util/useWidth';
import MobileMenu from './MobileMenu';
import boat_png from '../../images/boat.png';
import { hideFeedback } from '../../store/ui/ui.actions';
import {
  checkRolePermission,
  is_user_allowed,
} from '../../entities/util-functions';
import BoatMessageMenuItem from '../../pages/boat-message/BoatMessageMenuItem';
import { selectProfile, selectUserMeta } from '../../store/auth/auth.selector';
import { selectFeedbacks, selectLang } from '../../store/ui/ui.selector';
import HeaderWarning from './HeaderWarning';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

const BoatIcon = () => (
  <div>
    <img style={{ width: '20px', height: '20px' }} src={boat_png} alt='boat' />
  </div>
);

const ValidationMenuItem = () => {
  const qCnt = useSelector(selectUserMeta)?.queue_count;
  const lang = useSelector(selectLang);

  return (
    <li
      className='header__link-item'
      style={{ display: 'flex', alignItems: 'flex-start' }}
    >
      <NavLink
        exact={true}
        className='header__link'
        activeClassName='header__link--active'
        to='/queue'
      >
        {translate(lang, 'Validation')}
      </NavLink>
      {!!qCnt && qCnt > 0 && <span className='queue-count-icon'>{qCnt}</span>}
    </li>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const width = useWidth();

  const lang = useSelector(selectLang);
  const profile = useSelector(selectProfile);
  const allFeedbacks = useSelector(selectFeedbacks);

  const [isOpen, setIsOpen] = useState(false);
  const [avatar, setAvatar] = useState<string>();

  const menuItems = useMemo(() => {
    if (profile?.type === 'admin') {
      return [
        { link: '/admin/users', name: 'Users', isExact: true },
        { link: '/profile/security', name: 'Security', isExact: true },
        { link: '/admin/accounts', name: 'Accounts', isExact: true },
      ];
    }
    if (profile?.role === 'processing_plant') {
      return [
        { link: '/farms', name: 'Farms', isExact: false },
        {
          link: '/summary/processing',
          name: 'Processing Stats',
          isExact: true,
        },
        { link: '/qr-scan', name: 'Scan Code', isExact: false },
      ];
    }
    let items: ILink[] = [
      { module: 'home', link: '/', name: 'Overview', isExact: true },
      { module: 'farm', link: '/farms', name: 'Farms', isExact: false },
      { module: 'farm', link: '/farms-map', name: 'Map', isExact: false },
      {
        module: 'home',
        link: '/summary/harvest',
        name: 'Stats',
        isExact: false,
        children: [
          {
            module: 'home',
            link: '/summary/harvest',
            name: 'Harvest',
            isExact: false,
          },
          {
            module: 'home',
            link: '/summary/processing',
            name: 'Processing',
            isExact: false,
          },
          {
            module: 'home',
            link: '/summary/performance',
            name: 'Performance',
            isExact: false,
          },
          {
            module: 'home',
            link: '/summary/biosecurity',
            name: 'Biosecurity',
            isExact: false,
          },
          {
            module: 'home',
            link: '/summary/stages',
            name: 'Stages',
          },
        ],
      },
      { module: 'budget', link: '/budget', name: 'Budget', isExact: false },
      {
        module: 'calendar',
        link: '/calendar',
        name: 'Calendar',
        isExact: false,
        children: [
          {
            module: 'calendar',
            link: '/calendar',
            name: 'Calendar',
            isExact: false,
          },
          {
            module: 'task',
            link: '/tasks',
            name: 'Things to do',
            isExact: false,
          },
          {
            module: 'calendar',
            link: '/suggest',
            name: "Today's suggestions",
            isExact: false,
          },
        ],
      },
      {
        module: 'spat-storage',
        link: '/spat-storage',
        name: 'Spat Storage',
        isExact: false,
      },
      {
        module: 'inventory',
        link: '/inventory',
        name: 'Inventory',
        isExact: false,
      },
      { module: 'report', link: '/report', name: 'Report', isExact: false },
    ];
    if (!profile?.finance_permission) {
      items = items.filter(
        x => x.link !== '/budget' && x.link !== '/budget-log',
      );
    }
    if (!profile?.account?.show_budget_menu) {
      items = items.filter(x => x.name !== 'Budget');
    }
    if (
      !!profile?.is_verified ||
      profile?.role === 'owner' ||
      profile?.role === 'admin'
    ) {
      items.push({
        module: 'validation',
        link: '/queue',
        name: 'Validation',
        isExact: false,
      });
    }
    items = items.filter(x =>
      is_user_allowed(profile?.account_type, x.module ?? 'home'),
    );

    return items;
  }, [profile]);

  useEffect(() => {
    setAvatar(profile?.avatar as string);
  }, [profile?.avatar]);

  const onDropdownClick = () => {
    history.push(`/sign-in`);
    dispatch(logOut());
  };

  useEffect(() => {
    if (isOpen) {
      const bodyElement = document.getElementsByTagName('body');
      bodyElement[0].style.overflow = 'hidden';
    } else {
      const bodyElement = document.getElementsByTagName('body');
      bodyElement[0].style.overflow = 'visible';
    }
  }, [isOpen]);

  const menu = (
    <Menu className='header'>
      {checkRolePermission(
        { deniedRoles: ['processing_plant'] },
        profile?.role,
      ) && (
        <>
          <Menu.Item>
            <Link to='/profile'>
              <Paragrapgh
                size={2}
                color='default'
                align='left'
                fontWeight={400}
              >
                {profile?.name}
              </Paragrapgh>
              <Paragrapgh
                size={3}
                color='black-4'
                align='left'
                fontWeight={400}
              >
                {translate(lang, 'View profile')}
              </Paragrapgh>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/users'>
              <Paragrapgh
                size={2}
                color='default'
                align='left'
                fontWeight={400}
              >
                {translate(lang, 'Users')}
              </Paragrapgh>
            </Link>
          </Menu.Item>
          {is_user_allowed(profile?.account_type, 'boat') && (
            <Menu.Item>
              <Link to='/boats' style={{ display: 'flex' }}>
                <BoatIcon />
                <span className='ml-7'> {translate(lang, 'Boats')} </span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <Link to='/custom-fields'>
              <span> {translate(lang, 'Custom Fields')} </span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/tags'>
              <span> {translate(lang, 'Tags')} </span>
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/external-farm-access'>
              <span>{translate(lang, 'Connect to another account')}</span>
            </Link>
          </Menu.Item>
        </>
      )}
      <Menu.Item onClick={onDropdownClick}>
        {translate(lang, 'Log out')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <HeaderWarning />
      <header className='header'>
        <div className='container h-100'>
          <div className='header__wrapper d-flex h-100 align-items-center justify-content-between'>
            {width < 867 ? (
              <>
                <div
                  className={`header__burger ${
                    isOpen && `header__burger--active`
                  }`}
                  onClick={() => setIsOpen(!isOpen)}
                  onKeyDown={() => setIsOpen(!isOpen)}
                  role='button'
                  tabIndex={0}
                >
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                {isOpen && (
                  <MobileMenu
                    onClick={() => setIsOpen(!isOpen)}
                    menuItems={menuItems}
                    lang={lang}
                  />
                )}
              </>
            ) : (
              <nav className='w-100 align-items-center'>
                <ul
                  className={`header__nav-list d-flex list-reset align-items-center justify-content-between ${
                    menuItems.length === 2 && 'header__nav-list--small'
                  }`}
                >
                  {menuItems.map(item =>
                    item.link === '/queue' ? (
                      <ValidationMenuItem key={item.link} />
                    ) : !item.children ? (
                      <li key={item.link} className='header__link-item'>
                        <NavLink
                          exact={item.isExact}
                          className='header__link'
                          activeClassName='header__link--active'
                          to={item.link}
                        >
                          {translate(lang, item.name)}
                        </NavLink>
                      </li>
                    ) : (
                      <li key={item.link} className='header__link-item'>
                        <Dropdown
                          overlay={
                            <Menu className='header'>
                              {item.children.map(x => (
                                <Menu.Item key={x.name}>
                                  <Link to={x.link}>
                                    <Paragrapgh
                                      size={2}
                                      color='default'
                                      align='left'
                                      fontWeight={400}
                                    >
                                      {translate(lang, x.name)}
                                    </Paragrapgh>
                                  </Link>
                                </Menu.Item>
                              ))}
                            </Menu>
                          }
                          placement='bottomLeft'
                        >
                          <NavLink
                            exact={item.isExact}
                            className='header__link'
                            activeClassName='header__link--active'
                            to={item.link}
                          >
                            {translate(lang, item.name)}
                          </NavLink>
                        </Dropdown>
                      </li>
                    ),
                  )}
                </ul>
              </nav>
            )}

            <div className='d-flex align-items-center ml-10 z-10'>
              <Dropdown
                overlay={menu}
                placement='bottomRight'
                trigger={['click']}
              >
                <Avatar
                  shape='circle'
                  className='ml-24'
                  size={width < 769 ? 32 : 48}
                  src={avatar}
                  icon={<UserOutlined />}
                />
              </Dropdown>
              {checkRolePermission(
                { deniedRoles: ['processing_plant'] },
                profile?.role,
              ) && <BoatMessageMenuItem />}
            </div>
          </div>
          {allFeedbacks &&
            allFeedbacks
              .filter(x => x.isMessage)
              .map((feedback: any, i: number) => (
                <Feedback
                  message={feedback.message}
                  type={feedback.type}
                  theme='light'
                  position={i ? i * 86 : 16}
                  isGlobal
                  key={feedback.id}
                  onClose={() => dispatch(hideFeedback(feedback.id))}
                />
              ))}
        </div>
      </header>
    </>
  );
};

export default Header;

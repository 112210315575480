import { useState, useMemo, useEffect } from 'react';
import { Modal } from 'antd';
import {
  Button,
  CloseIcon,
  Datepicker,
  Dropdown,
  Input,
  Subtitle,
} from '../../components/shared';
import ModalFeedbackView from '../../components/shared/feedback/ModalFeedbackView';
import { useDispatch, useSelector } from 'react-redux';
import { sendSingleRequest } from '../../apis';
import { showFeedback } from '../../store/ui/ui.actions';
import { selectUtils } from '../../store/utils/utils.selector';
import { selectFarmsData } from '../../store/farms/farms.selector';
import { toMillisecond, toSecond } from '../../util/toggleSecondMillisecond';
import { TBusinessType } from '../../entities/general.entities';
import { selectLang } from '../../store/ui/ui.selector';
import { translate } from '../../lib/lang.helper';
import './styles.scss';

interface IFormData {
  type: TBusinessType;
  source: string;
  seed_type: string | null;
  src_line_id?: number | null;
  src_line_name: string | null;
  dst_line_id?: number | null;
  dst_line_name: string | null;
  collection_date: number;
  seeded_date: number;
  condition: string;
  weight: number | null;
  stage: number | null;
}

const defaultForm: IFormData = {
  type: 'MUSSEL',
  source: '',
  seed_type: null,
  src_line_id: undefined,
  src_line_name: null,
  dst_line_id: undefined,
  dst_line_name: null,
  collection_date: new Date().setHours(0, 0, 0, 0),
  seeded_date: new Date().setHours(0, 0, 0, 0),
  condition: '',
  weight: null,
  stage: 1,
};

const conditions = [
  { id: 'Good', label: 'Good', value: 'Good' },
  { id: 'Average', label: 'Average', value: 'Average' },
  { id: 'Bad', label: 'Bad', value: 'Bad' },
];

interface Props {
  visible: boolean;
  title?: string;
  data?: any;
  editId?: number;
  onConfirm: (d: any) => void;
  onCancel: () => void;
}

const SpatStorageModal = ({
  visible,
  title,
  data,
  editId,
  onConfirm,
  onCancel,
}: Props) => {
  const dispatch = useDispatch<any>();
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectFarmsData);
  const seedTypeOptions = useSelector(selectUtils)
    .filter(x => x.type === 'seedtype')
    .map(x => ({ id: x.id.toString(), value: x.name, label: x.name }));

  const [busType, setBusType] = useState<TBusinessType>(
    data?.type
      ? data.type
      : farmsData.every(x => x.type === 'MUSSEL')
      ? 'MUSSEL'
      : farmsData.every(x => x.type === 'OYSTER')
      ? 'OYSTER'
      : undefined,
  );
  const [form, setForm] = useState(defaultForm);
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState<{
    source: string | null;
    condition: string | null;
    weight: string | null;
  }>({ source: null, condition: null, weight: null });

  const updateForm = (key: keyof IFormData, val: any) => {
    if (['weight', 'src_line_id', 'dst_line_id', 'stage'].includes(key)) {
      val = val.length <= 0 ? null : Number(val);
    }
    setForm({ ...form, [key]: val });
    setErrors({ ...errors, [key]: null });
  };
  const validForm = () => {
    let tmpErr = { ...errors };
    let flag = true;
    if (form.source.length <= 0) {
      tmpErr.source = translate(lang, 'Please input source field');
      flag = false;
    }
    if (!form.condition || form.condition.length <= 0) {
      tmpErr.condition = translate(lang, 'Please input condition field');
      flag = false;
    }
    if (!form.weight || form.weight <= 0) {
      tmpErr.weight = translate(lang, 'Please input weight field');
      flag = false;
    }
    if (!form.stage) {
      dispatch(
        showFeedback({
          type: 'error',
          isMessageModal: true,
          message: translate(lang, 'Please input stage field'),
        }),
      );
      flag = false;
    }
    setErrors(tmpErr);
    if (!flag) return null;

    const res = {
      ...form,
      collection_date: toSecond(form.collection_date),
      seeded_date: toSecond(form.seeded_date),
      type: busType,
    };
    return res;
  };
  const confirmClick = async () => {
    const formData = validForm();
    if (!formData) return;

    setDisabled(true);
    const response = !editId
      ? await sendSingleRequest(formData, 'POST', 'api/farm/spat-storage', true)
      : await sendSingleRequest(
          formData,
          'PUT',
          `api/farm/spat-storage/${editId}`,
          true,
        );
    setDisabled(false);

    if (response.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: translate(lang, response.data?.message ?? 'Success'),
        }),
      );
      onConfirm(response.data);
    } else {
      dispatch(
        showFeedback({
          isMessageModal: true,
          type: 'error',
          message: translate(lang, response.data?.message ?? 'Server error'),
        }),
      );
    }
  };
  const availableLines = useMemo(() => {
    let result = [];
    for (let f of farmsData.filter(x => x.type === busType)) {
      for (let l of f.lines)
        result.push({
          id: l.id.toString(),
          value: l.id.toString(),
          label: `${f.name} - ${l.line_name}`,
        });
    }
    result.push({ id: '', value: '', label: 'Other' });

    return result;
  }, [farmsData, busType]);

  useEffect(() => {
    if (data) {
      let tmp: any = { ...defaultForm };
      for (let i in form) {
        if (data[i] !== undefined) tmp[i] = (data as any)[i];
      }
      if (data.collection_date) {
        tmp.collection_date = toMillisecond(data.collection_date);
      }
      if (data.seeded_date) {
        tmp.seeded_date = toMillisecond(data.seeded_date);
      }
      setForm(tmp);
    }
  }, [data]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(
              lang,
              !title ? 'Add new spat storage' : 'Edit spat storage data',
            )}
          </Subtitle>
        </div>
        {!busType ? (
          <div className='spat-storage-modal'>
            <div className='mt-32 mb-32 text-center'>
              <Button
                color='blue'
                size={1}
                width='full'
                type='fill'
                onClick={() => setBusType('MUSSEL')}
              >
                {translate(lang, 'Mussel Spat Storage')}
              </Button>
            </div>
            <div className='mb-32 text-center'>
              <Button
                color='blue'
                size={1}
                width='full'
                type='fill'
                onClick={() => setBusType('OYSTER')}
              >
                {translate(lang, 'Oyster Spat Storage')}
              </Button>
            </div>
          </div>
        ) : (
          <div className='spat-storage-modal'>
            <div className={`mb-17 ${errors.source ? 'invalid-form' : ''}`}>
              <Input
                type='text'
                label={translate(lang, 'Source ID')}
                value={form.source}
                onChange={e => updateForm('source', e.target.value)}
              />
              {errors.source && (
                <div className='invalid-feedback'>{errors.source}</div>
              )}
            </div>
            <div className='mb-17'>
              <Dropdown
                label={translate(lang, 'Seed type')}
                options={seedTypeOptions}
                value={form.seed_type ?? undefined}
                onChange={v => updateForm('seed_type', v)}
              />
            </div>
            <Dropdown
              className={`mb-${form.src_line_id === null ? '5' : '17'}`}
              label={translate(lang, 'Source line')}
              value={form.src_line_id?.toString() ?? undefined}
              options={availableLines}
              onChange={v => updateForm('src_line_id', v)}
            />
            {form.src_line_id === null && (
              <Input
                label=''
                placeholder={translate(lang, 'Source line name')}
                className='mb-17'
                type='text'
                value={form.src_line_name ?? ''}
                onChange={e => updateForm('src_line_name', e.target.value)}
              />
            )}
            <Dropdown
              className={`mb-${form.dst_line_id === null ? '5' : '17'}`}
              label={translate(lang, 'Destination Line')}
              value={form.dst_line_id?.toString() ?? undefined}
              options={availableLines}
              onChange={v => updateForm('dst_line_id', v)}
            />
            {form.dst_line_id === null && (
              <Input
                className='mb-17'
                type='text'
                placeholder={translate(lang, 'Destination line name')}
                label=''
                value={form.dst_line_name ?? ''}
                onChange={e => updateForm('dst_line_name', e.target.value)}
              />
            )}
            <Datepicker
              className='mb-17'
              defaultValue={form.collection_date}
              label={translate(lang, 'Collection date')}
              onChange={e =>
                !!e && updateForm('collection_date', e.toDate().getTime())
              }
              required={true}
            />
            <Datepicker
              className='mb-17'
              defaultValue={form.seeded_date ?? new Date().setHours(0, 0, 0, 0)}
              label={translate(lang, 'Seeded date')}
              onChange={e =>
                !!e && updateForm('seeded_date', e.toDate().getTime())
              }
              required={true}
            />
            <div className={`mb-17 ${errors.condition ? 'invalid-form' : ''}`}>
              <Dropdown
                onChange={v => updateForm('condition', v)}
                label={translate(lang, 'Condition')}
                options={conditions}
                value={form.condition}
              />
              {errors.condition && (
                <div className='invalid-feedback'>{errors.condition}</div>
              )}
            </div>
            <div className={`mb-17 ${errors.weight ? 'invalid-form' : ''}`}>
              <Input
                type='number'
                label={translate(
                  lang,
                  busType === 'OYSTER' ? 'Oyster spat count' : 'Weight',
                )}
                value={form.weight?.toString() ?? ''}
                onChange={e => updateForm('weight', e.target.value)}
                unit={busType === 'OYSTER' ? 'pieces' : 'kg'}
              />
              {errors.weight && (
                <div className='invalid-feedback'>{errors.weight}</div>
              )}
            </div>
            <div className='mb-17'>
              <Input
                type='number'
                label={translate(lang, 'Stage')}
                value={form.stage?.toString() ?? ''}
                onChange={e => updateForm('stage', e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      {!!busType && (
        <div className='modal-button d-flex justify-content-end mt-32 pt-17'>
          <Button
            width={'small'}
            size={2}
            type='fill'
            color='green'
            className='rsp-btn ml-16'
            onClick={confirmClick}
            disabled={disabled}
          >
            {translate(lang, 'Confirm')}
          </Button>
        </div>
      )}
      <ModalFeedbackView />
    </Modal>
  );
};

export default SpatStorageModal;

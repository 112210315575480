import { useState } from 'react';
import { useSelector } from 'react-redux';
import { downloadExcel } from '../../apis';
import { Button, CheckboxButton, Title } from '../../components/shared';
import { selectFarmsData } from '../../store/farms/farms.selector';

const ExportFarmsPage = () => {
  const farmsData = useSelector(selectFarmsData);

  const [disabled, setDisabled] = useState(false);
  const [farms, setFarms] = useState<number[]>([]);

  const toggleCheck = (id: number) =>
    setFarms(farms.includes(id) ? farms.filter(x => x !== id) : [...farms, id]);

  const confirmClick = async (mode: 'lines' | 'cycles') => {
    setDisabled(true);
    const response = await downloadExcel(
      { farms, mode },
      mode === 'lines' ? 'farms-data' : 'cycle-data',
    );
    setDisabled(false);
    if (response !== true) {
      window.alert(response);
    }
  };

  return (
    <div style={{ padding: '30px 50px' }}>
      <div style={{ padding: '30px' }}>
        <Title size={5} color='black-3' align='default' fontWeight={700}>
          Export Farms
        </Title>
      </div>
      <div
        style={{
          maxWidth: '800px',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <div className='d-flex justify-content-between'>
          <div className='mr-16' style={{ flex: 2 }}>
            {farmsData.map(x => (
              <div className='mt-16 mb-16' key={x.id}>
                <CheckboxButton
                  label={`${x.name} - ${x.farm_number} (${x.lines.length} lines)`}
                  className='mr-32'
                  checked={farms.includes(x.id)}
                  onChange={e => toggleCheck(x.id)}
                />
              </div>
            ))}
          </div>
          <div className='ml-16' style={{ flex: 1 }}>
            <div className='mb-17'>
              <Button
                width={'wide'}
                size={2}
                type='fill'
                color='green'
                onClick={() => confirmClick('lines')}
                disabled={disabled}
              >
                Download Farms
              </Button>
            </div>
            <div className='mt-17'>
              <Button
                width={'wide'}
                size={2}
                type='fill'
                color='green'
                onClick={() => confirmClick('cycles')}
                disabled={disabled}
              >
                Download Cycle Data
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportFarmsPage;

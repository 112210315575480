import moment from 'moment';
import { ITimeRange } from '../../../entities/general.entities';
import { ISeaweedLineResource } from '../../../entities/farms.entities';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Modal, Radio } from 'antd';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Feedback,
  Input,
  RadioButton,
  Subtitle,
} from '../../shared';
import { SpatSelectLabel, renderCustomError } from '.';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { toSecTimeTracks } from '../../shared/input/TimeTrackInput';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import { addUtilData } from '../../../store/utils/utils.actions';
import { sendSingleRequest } from '../../../apis';
import { showFeedback } from '../../../store/ui/ui.actions';
import {
  loadFarmsData,
  loadSpatStorages,
} from '../../../store/farms/farms.actions';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import {
  SelectIsBudgetVisible,
  SelectSeasonsByFarmID,
  SelectSeaweedSpatsByFarmID,
} from '../../../store/extra/extra.selector';

interface IFormData {
  season_name: string;
  spat_storage_id: number | null;
  spat_amount: number | null;
  planned_date_seed: number;
  planned_date_harvest: number;
  spat_size: number;
  spat_size_max: number | null;
  tank_area: number | null;
  comment: string;
  time_tracks?: ITimeRange[];
  cost?: number | null;
  images: any[];
  parent_id?: number | null;
}

const defaultForm: IFormData = {
  season_name: '',
  spat_storage_id: null,
  spat_amount: null,
  planned_date_seed: moment().toDate().getTime(),
  planned_date_harvest: moment().toDate().getTime(),
  spat_size: 0,
  spat_size_max: null,
  tank_area: null,
  comment: '',
  images: [],
  parent_id: null,
};

interface Props {
  visible: boolean;
  title?: string;
  lineData: ISeaweedLineResource;
  data?: any;
  updateID?: number;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  parentID?: number;
  onlyView?: boolean;
}

const SeaweedSeedModal = ({
  visible,
  title,
  lineData,
  data,
  updateID,
  onCancel,
  onConfirm,
  parentID,
  onlyView,
}: Props) => {
  const dispatch = useDispatch<any>();
  const showBudget = SelectIsBudgetVisible(lineData.farm_id);
  const lang = useSelector(selectLang);
  const spats = SelectSeaweedSpatsByFarmID(lineData.farm_id);
  const seasonData = SelectSeasonsByFarmID(lineData.farm_id).map(x => ({
    id: x.id.toString(),
    label: x.name,
    value: x.name,
  }));

  const [errorMsg, setErrorMsg] = useState<string>();
  const [isSeasonNew, setIsSeasonNew] = useState<'old' | 'new'>('old');
  const [errors, setErrors] = useState<any>();
  const [formData, setFormData] = useState<IFormData>(defaultForm);
  const [addSpatSizeRange, setAddSpatSizeRange] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const availableWeight = formData.spat_storage_id
    ? spats.find(x => x.id === Number(formData.spat_storage_id))
        ?.available_weight + (updateID ? data.spat_amount : 0)
    : -1;

  const toggleAddRangeCheck = (val: boolean) => {
    setAddSpatSizeRange(val);
    if (val) updateForm('spat_size_max', formData.spat_size);
    else updateForm('spat_size_max', null);
  };
  const updateForm = (key: keyof IFormData, val: any) => {
    if (['spat_amount', 'tank_area', 'cost'].includes(key)) {
      val = val.length <= 0 ? null : Number(val);
    }
    if (key === 'spat_storage_id' || key === 'spat_amount') {
      setErrors((prv: any) => ({
        ...(prv ?? {}),
        spat_storage_id: null,
        spat_amount: null,
      }));
    } else {
      setErrors((prv: any) => ({ ...(prv ?? {}), [key]: null }));
    }
    if (key === 'spat_storage_id') {
      const spat = spats.find(x => x.id === Number(val));
      let tmp = { ...formData, spat_storage_id: val };
      if (spat?.harvest) {
        if (spat.harvest.shell_length) {
          tmp.spat_size = spat.harvest.shell_length;
        }
        if (spat.harvest.shell_length_max) {
          setAddSpatSizeRange(true);
          tmp.spat_size_max = spat.harvest.shell_length_max;
        }
      }
      setFormData(tmp);
    } else {
      setFormData({ ...formData, [key]: val });
    }
  };
  const validFormData = () => {
    let tmpErr = { ...(errors ?? {}) };
    let res = true;
    if (!formData.season_name) {
      tmpErr.season_name = translate(lang, 'Add name');
      res = false;
    }
    if (Number(formData.tank_area) === 0) {
      tmpErr.tank_area = translate(lang, 'Tank area cannot be 0');
      res = false;
    }
    if (formData.planned_date_seed > formData.planned_date_harvest) {
      tmpErr.planned_date_seed = translate(lang, '_invalid_seed_date');
      res = false;
    }
    if (
      data?.is_catch_spat !== true &&
      (!formData.spat_storage_id ||
        !formData.spat_amount ||
        formData.spat_amount < 0)
    ) {
      tmpErr.spat_amount = translate(lang, 'Select spat and weight');
      res = false;
    }
    if (
      data?.is_catch_spat === false &&
      formData.spat_amount &&
      formData.spat_amount > availableWeight
    ) {
      tmpErr.spat_amount = translate(lang, '_max_spat_amount', availableWeight);
      res = false;
    }
    if (
      formData.spat_size_max !== null &&
      formData.spat_size_max < formData.spat_size
    ) {
      tmpErr.spat_size = translate(lang, 'Invalid spat size range');
      res = false;
    }
    if (
      formData.time_tracks &&
      toSecTimeTracks(formData.time_tracks) === false
    ) {
      setErrorMsg('_invalid_time_range');
      res = false;
    }
    setErrors(tmpErr);
    if (!res) return null;

    let validData = {
      ...formData,
      line_id: lineData.id,
      planned_date_seed: toSecond(formData.planned_date_seed),
      planned_date_harvest: toSecond(formData.planned_date_harvest),
    };
    if (!!parentID) {
      validData.parent_id = parentID;
    } else if (!updateID) {
      const ms = lineData.growing_cycle?.main_seed;
      if (ms) {
        if (!window.confirm(translate(lang, '_line_seed_make_sure')))
          return null;
        validData.parent_id = ms.id;
      }
    }
    if (formData.time_tracks) {
      const tt = toSecTimeTracks(formData.time_tracks);
      if (tt) {
        validData.time_tracks = tt;
      } else {
        setErrorMsg('_invalid_time_range');
        return null;
      }
    }
    if (!updateID && !validData.parent_id && lineData.growing_cycle) {
      if (!window.confirm(translate(lang, '_line_seed_make_sure'))) return null;
      validData.parent_id = lineData.growing_cycle.main_seed.id;
    }
    return validData;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    let validData = validFormData();
    if (!validData) return;

    setErrorMsg(undefined);
    setDisabled(true);
    if (!seasonData.some(x => x.value === validData?.season_name)) {
      await dispatch(addUtilData('season', validData.season_name));
    }
    const res = updateID
      ? await sendSingleRequest(
          validData,
          'PUT',
          `api/farm/line/seeding/${updateID}`,
          true,
        )
      : await sendSingleRequest(
          validData,
          'POST',
          `api/farm/line/seaweed-seeding`,
          true,
        );
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          message: translate(
            lang,
            res.data?.message ??
              (updateID
                ? 'Updated data successfully'
                : 'Seed data submitted successfully'),
          ),
          type: 'success',
        }),
      );
      dispatch(loadFarmsData());
      dispatch(loadSpatStorages());
      onConfirm(validData);
    } else {
      setDisabled(false);
      setErrorMsg(res.data?.message ?? 'Unknown error');
      if (res.data?.errors) setErrors(res.data.errors);
    }
  };

  useEffect(() => {
    if (data) {
      let tmp: any = {};
      for (let k in formData) {
        tmp[k] = data[k] ?? (formData as any)[k];
        if (k === 'planned_date_seed' || k === 'planned_date_harvest') {
          tmp[k] = toMillisecond(tmp[k]);
        } else if (
          k === 'images' &&
          (tmp[k] === null || tmp[k] === undefined)
        ) {
          tmp[k] = [];
        }
      }
      if (data?.spat_storage && !tmp.spat_storage_id) {
        tmp.spat_storage_id = data.spat_storage.id;
      }
      if (tmp.time_tracks && tmp.time_tracks.length > 0) {
        tmp.time_tracks = tmp.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      if (showBudget && data.expense_items && data.expense_items.length > 0) {
        tmp.cost = data.expense_items.reduce(
          (p: any, c: any) => p + c.amount,
          0,
        );
      } else if (showBudget && data.cost) {
        tmp.cost = data.cost;
      }
      if (tmp.images) {
        tmp.images = tmp.images.map((x: any) =>
          typeof x === 'object' && x !== null ? x : parseImageUrlToFileItem(x),
        );
      }
      setAddSpatSizeRange(!!data.spat_size_max);
      setFormData(tmp);
    }
  }, [data, showBudget]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={600}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Seaweed seed line')}
          </Subtitle>
        </div>
        {
          <div>
            {!!errorMsg && (
              <Feedback
                message={translate(lang, errorMsg)}
                type={'error'}
                theme='light'
                position={10}
                isGlobal
                onClose={() => setErrorMsg(undefined)}
              />
            )}
            {!data?.is_catch_spat ? (
              <>
                <Radio.Group
                  className='d-flex mt-14 mb-32'
                  onChange={e => setIsSeasonNew(e.target.value)}
                  value={isSeasonNew}
                >
                  <RadioButton
                    label={translate(lang, 'Existing season')}
                    value='old'
                  />
                  <RadioButton
                    className='ml-34'
                    label={translate(lang, 'New season')}
                    value='new'
                  />
                </Radio.Group>
                <div
                  className={
                    errors?.season_name ? 'invalid-form mb-16' : ' mb-16'
                  }
                >
                  {isSeasonNew === 'old' ? (
                    <Dropdown
                      label={translate(lang, 'Season name')}
                      placeholder={translate(lang, 'Choose Season')}
                      className='mr-16 w-100'
                      onChange={value => updateForm('season_name', value)}
                      options={seasonData}
                      value={formData.season_name}
                    />
                  ) : (
                    <Input
                      label={translate(lang, 'New Season')}
                      placeholder={translate(lang, 'Season name')}
                      type='text'
                      value={formData.season_name}
                      onChange={e => updateForm('season_name', e.target.value)}
                    />
                  )}
                  {!!errors?.season_name && (
                    <div className='invalid-feedback'>
                      {errors?.season_name}
                    </div>
                  )}
                </div>
                <div
                  className={
                    errors?.spat_amount || errors?.spat_storage_id
                      ? 'invalid-form mt-17'
                      : ' mt-17'
                  }
                >
                  <Dropdown
                    label={translate(lang, 'Spat')}
                    placeholder={translate(lang, 'Spat')}
                    className={`mb-${
                      formData.spat_storage_id === null ? '16' : '5'
                    }`}
                    value={SpatSelectLabel(
                      spats.find(
                        x => x.id === Number(formData.spat_storage_id),
                      ),
                    )}
                    options={spats
                      .filter(x => Number(x.available_weight) > 0)
                      .map(item => ({
                        id: item.id.toString(),
                        value: item.id.toString(),
                        label: SpatSelectLabel(item),
                      }))}
                    onChange={(value, evt) =>
                      updateForm('spat_storage_id', value)
                    }
                  />
                  {!!formData.spat_storage_id && (
                    <Input
                      label={translate(lang, 'Amount')}
                      placeholder={
                        availableWeight <= 0
                          ? translate(lang, 'Amount')
                          : translate(lang, '_max_spat_amount', availableWeight)
                      }
                      type='number'
                      value={formData.spat_amount?.toString() ?? ''}
                      max={availableWeight > 0 ? availableWeight : undefined}
                      unit='kg'
                      onChange={e => updateForm('spat_amount', e.target.value)}
                    />
                  )}
                  {!!errors?.spat_storage_id && (
                    <div className='invalid-feedback'>
                      {errors?.spat_storage_id}
                    </div>
                  )}
                  {!!errors?.spat_amount && (
                    <div className='invalid-feedback'>
                      {renderCustomError(errors?.spat_amount)}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className='d-flex justify-content-center'>
                {translate(lang, 'This is line catch spat')}
              </div>
            )}
          </div>
        }
        <div
          className={`mb-24 mt-16 ${
            errors?.planned_date_seed ? 'invalid-form' : ''
          }`}
        >
          <Datepicker
            label={translate(lang, 'Line seeding date')}
            defaultValue={formData.planned_date_seed}
            onChange={e =>
              updateForm(
                'planned_date_seed',
                e?.toDate().getTime() ?? moment().toDate().getTime(),
              )
            }
            required
          />
          {errors?.planned_date_seed && (
            <div className='invalid-feedback'>{errors?.planned_date_seed}</div>
          )}
        </div>
        <div className='mb-16'>
          <Datepicker
            label={translate(lang, 'Planned harvest date')}
            defaultValue={formData.planned_date_harvest}
            onChange={e => {
              if (
                formData.planned_date_seed !== undefined &&
                e &&
                e!.toDate().getTime() < formData.planned_date_seed
              ) {
                updateForm('planned_date_harvest', moment().toDate().getTime());
              } else {
                updateForm(
                  'planned_date_harvest',
                  e?.toDate().getTime() ?? moment().toDate().getTime(),
                );
              }
            }}
            required
          />
        </div>
        <div className={`mb-16 ${errors?.tank_area ? 'invalid-form' : ''}`}>
          <Input
            label={translate(lang, 'Tank area')}
            type='number'
            value={formData.tank_area?.toString() ?? ''}
            unit='m²'
            required
            onChange={e => updateForm('tank_area', Number(e.target.value))}
          />
          {errors?.tank_area && (
            <div className='invalid-feedback'>{errors?.tank_area}</div>
          )}
        </div>
        <div className='mb-7'>
          <CheckboxButton
            label={translate(lang, 'Add spat size range')}
            checked={addSpatSizeRange}
            onChange={e => toggleAddRangeCheck(e.target.checked)}
          />
        </div>
        {formData.spat_size_max === null ? (
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Spat Size')}
              type='number'
              value={formData.spat_size.toString()}
              unit='mm'
              required
              onChange={e => updateForm('spat_size', Number(e.target.value))}
            />
          </div>
        ) : (
          <div className='d-flex mb-17 justify-content-between'>
            <div
              className={`w-100 mr-3 ${
                errors?.spat_size ? 'invalid-form' : ''
              }`}
            >
              <Input
                label={translate(lang, 'Minimum spat size')}
                type='number'
                value={formData.spat_size.toString()}
                required
                onChange={e => updateForm('spat_size', Number(e.target.value))}
              />
              {errors?.spat_size && (
                <div className='invalid-feedback'>{errors?.spat_size}</div>
              )}
            </div>
            <div className='w-100 ml-3'>
              <Input
                label={translate(lang, 'Maximum spat size')}
                type='number'
                value={formData.spat_size_max.toString()}
                required
                onChange={e =>
                  updateForm('spat_size_max', Number(e.target.value))
                }
              />
            </div>
          </div>
        )}
        {!!showBudget && (
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Cost')}
              type='number'
              value={formData.cost?.toString() ?? ''}
              dataType='cost'
              unit='$'
              onChange={e => updateForm('cost', Number(e.target.value))}
            />
          </div>
        )}
        <div className='mt-17 mb-17'>
          <Input
            label={translate(lang, 'Comment')}
            placeholder={translate(lang, 'Comment')}
            type='textarea'
            value={formData.comment}
            onChange={e => updateForm('comment', e.target.value)}
          />
        </div>
        <div className='mb-17'>
          <PicturesWall
            label={translate(lang, 'Images')}
            fileList={formData.images}
            handleChangeImages={v => updateForm('images', v)}
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='red'
          className='rsp-btn ml-16'
          onClick={onCancel}
          disabled={disabled}
        >
          {translate(lang, 'Close')}
        </Button>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled || onlyView}
        >
          {translate(lang, 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default SeaweedSeedModal;

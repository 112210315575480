import { useMemo, FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Collapse, Dropdown, Menu } from 'antd';
import { loadFarmsData } from '../../../store/farms/farms.actions';
import { showFeedback } from '../../../store/ui/ui.actions';
import Caret from '../caret/Caret';
import Subtitle from '../subtitle/Subtitle';
import { IFarmResource } from '../../../entities/farms.entities';
import { selectProfile } from '../../../store/auth/auth.selector';
import DotsIcon from '../DotsIcon';
import LinesMobile from './LinesMobile';
import { sendSingleRequest } from '../../../apis';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import './styles.scss';

interface HeaderProp {
  farm: IFarmResource;
  onEditClick?: (d: IFarmResource) => void;
  onDeleteClick?: (d: IFarmResource) => Promise<void>;
}

const TableHeader = ({ farm, onEditClick, onDeleteClick }: HeaderProp) => {
  const history = useHistory();
  const location =
    farm.bounds && farm.bounds.length > 0 ? farm.bounds[0] : farm.location;
  const lang = useSelector(selectLang);

  const menuClickHandler = async (e: any) => {
    if (e.key === 'edit') {
      onEditClick && onEditClick(farm);
    } else if (e.key === 'delete') {
      onDeleteClick && (await onDeleteClick(farm));
    } else {
      history.push(`/farms/${farm.id}`);
    }
  };

  return (
    <div
      className='table-mobile__header table-mobile__card--cursor'
      onKeyDown={() => undefined}
      onClick={() => history.push(`/farms/${farm.id}`)}
    >
      <div className='d-flex justify-content-between pl-48'>
        <div>
          <Subtitle
            size={5}
            color='black-3'
            align='left'
            fontWeight={600}
            className='mb-4'
          >
            {farm.name}
          </Subtitle>
          <Subtitle size={3} color='black-2' align='left' fontWeight={400}>
            <span className='pr-6'>{location?.lat ?? '-'}</span>
            <span>{location?.lng ?? '-'}</span>
          </Subtitle>
        </div>
        <div className={'table-mobile__card-dots'}>
          {!farm.account_id && (
            <div className='dropdown' onClick={e => e.stopPropagation()}>
              <Dropdown
                overlay={
                  <Menu onClick={menuClickHandler}>
                    <Menu.Item key='info'>{translate(lang, 'Info')}</Menu.Item>
                    {!!onEditClick && (
                      <Menu.Item key='edit'>
                        {translate(lang, 'Edit')}
                      </Menu.Item>
                    )}
                    {!!onDeleteClick && (
                      <Menu.Item key='delete'>
                        {translate(lang, 'Delete')}
                      </Menu.Item>
                    )}
                  </Menu>
                }
                placement='bottomRight'
                trigger={['click']}
              >
                <div>
                  <DotsIcon />
                </div>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
      <div className='d-flex pt-16'>
        <div className='flex-basis-50'>
          <Subtitle size={5} color='black-2' align='left' fontWeight={400}>
            {translate(lang, 'Area')}
          </Subtitle>
          <Subtitle size={5} color='black-5' align='left' fontWeight={400}>
            {`${farm.area}ha`}
          </Subtitle>
        </div>
        <div className='flex-basis-50'>
          <Subtitle size={5} color='black-2' align='left' fontWeight={400}>
            {translate(lang, 'Owner')}
          </Subtitle>
          <div className='d-flex flex-wrap'>
            {(farm?.owners ?? []).map((x, i) => (
              <Subtitle
                key={i}
                size={5}
                color='black-5'
                align='left'
                fontWeight={400}
                className='pr-4'
              >
                {x.title}
              </Subtitle>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  data: IFarmResource[];
}

const FarmsMobile: FC<Props> = memo(({ data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(selectProfile);
  const lang = useSelector(selectLang);

  const { Panel } = useMemo(() => Collapse, []);

  const deleteFarm = async (farm: IFarmResource) => {
    if (!profile?.edit_permission || farm.account_id) return;
    if (!window.confirm('Are you sure to delete this farm?')) return;

    const response = await sendSingleRequest(
      {},
      'DELETE',
      `api/farm/farms/${farm.id}`,
      true,
    );
    if (response.status) {
      await dispatch(loadFarmsData());
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'success',
          message: 'Farm deleted successfully',
        }),
      );
    } else {
      dispatch(
        showFeedback({
          isMessage: true,
          type: 'error',
          message: response.data?.message ?? 'Failed to delete farm',
        }),
      );
    }
  };
  const editFarm = (farm: IFarmResource) => {
    if (!profile?.edit_permission || farm.account_id) return;
    history.push(`/farms/farm-edit/${farm.id}`);
  };

  return (
    <div className='table-mobile'>
      {data?.length ? (
        <>
          <Collapse
            expandIcon={(props: any) => (
              <div>
                <Caret
                  color='#131523'
                  direction={props.isActive ? 'top' : 'bottom'}
                  fontWeight='big'
                />
              </div>
            )}
          >
            {data?.map(farm => (
              <Panel
                key={farm.id}
                header={
                  <TableHeader
                    farm={farm}
                    onEditClick={
                      !!profile?.edit_permission && !farm.account_id
                        ? editFarm
                        : undefined
                    }
                    onDeleteClick={
                      !!profile?.edit_permission && !farm.account_id
                        ? deleteFarm
                        : undefined
                    }
                  />
                }
              >
                {farm.lines.length > 0 ? (
                  <LinesMobile
                    data={farm.lines}
                    type={farm.type}
                    isEditable={!!profile?.edit_permission && !farm.account_id}
                  />
                ) : (
                  <div className='table-mobile__not-data'>
                    <Subtitle
                      size={4}
                      color='black-5'
                      align='left'
                      fontWeight={400}
                    >
                      {translate(lang, 'No data available')}
                    </Subtitle>
                  </div>
                )}
              </Panel>
            ))}
          </Collapse>
        </>
      ) : (
        <div className='table-mobile__not-data'>
          <Subtitle size={4} color='black-5' align='left' fontWeight={400}>
            {translate(lang, 'No data available')}
          </Subtitle>
        </div>
      )}
    </div>
  );
});

export default FarmsMobile;

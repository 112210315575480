import { useSelector } from 'react-redux';
import { Spinner, Title } from '../../components/shared';
import { translate } from '../../lib/lang.helper';
import { selectLang } from '../../store/ui/ui.selector';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'antd';
import { selectOysterFarms } from '../../store/farms/farms.selector';
import { formatNumber } from '../../entities/util-functions';
import { sendSingleRequest } from '../../apis';
import { IName } from '../../entities/general.entities';
import ReactExport from 'react-data-export';
import { NavLink } from 'react-router-dom';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = (ExcelFile as any).ExcelSheet;

interface ICol {
  key: string;
  title: string | ReactElement;
  render: (data: any) => string | ReactElement;
  width?: number;
  fixed?: any;
  dataIndex?: string;
  minWidth?: number;
}

interface IRow {
  key: number | string;
  isBold?: boolean;
  label: string;
  [key: string]: any;
}

interface IIvt {
  id: number;
  label: string;
  qty: number;
}

interface IStep {
  step: number;
  amount: number;
}

interface ISpat {
  id: number;
  source: string;
  amount: number;
}

interface ICell {
  key: string;
  onHand: {
    oysters: number;
    ready: number;
    wait: number;
    notFull: number;
    percentFull: number;
    basketCapacity: number;
    basketUsed: number;
  };
  linesCount: {
    ready: number;
    growing: number;
    notFull: number;
    total: number;
  };
  stages: IStep[];
  spats: ISpat[];
  inventories: IIvt[];
}

interface ISeed {
  id: number;
  spat_storage: { id: number; source: string } | null;
  spat_amount: number;
  stage: number;
}

interface IStage {
  farm: IName;
  line: { id: number; line_name: string };
  seedings: ISeed[];
}

const OysterSummaryPage = () => {
  const lang = useSelector(selectLang);
  const farmsData = useSelector(selectOysterFarms);

  const [loading, setLoading] = useState(false);
  const [rotations, setRotations] = useState<IStage[]>([]);

  const { columns, data, excel } = useMemo(() => {
    const headers = [
      { title: 'Location' },
      ...farmsData.map(x => ({ title: `${x.name} (${x.farm_number})` })),
      { title: 'Total' },
    ];
    let totalCell: ICell = {
      key: 'total',
      onHand: {
        oysters: 0,
        ready: 0,
        wait: 0,
        notFull: 0,
        percentFull: 0,
        basketCapacity: 0,
        basketUsed: 0,
      },
      linesCount: { ready: 0, growing: 0, notFull: 0, total: 0 },
      inventories: [],
      stages: [],
      spats: [],
    };
    const farmCells: ICell[] = farmsData.map(farm => {
      let onHand = {
        oysters: 0,
        ready: 0,
        wait: 0,
        notFull: 0,
        percentFull: 0,
        basketCapacity: 0,
        basketUsed: 0,
      };
      let linesCount = {
        ready: 0,
        growing: 0,
        notFull: 0,
        total: 0,
      };
      let inventories: IIvt[] = [];
      for (const line of farm.lines) {
        if (line.growing_cycle) {
          onHand.oysters += line.growing_cycle.total_seeded_dozens;
          if (line.growing_cycle.ready_harvest) {
            onHand.ready += line.growing_cycle.total_seeded_dozens;
            linesCount.ready++;
          } else {
            onHand.wait += line.growing_cycle.total_seeded_dozens;
          }
          if (
            line.basket_count > line.growing_cycle.total_seeded_basket_count
          ) {
            onHand.notFull += line.growing_cycle.total_seeded_basket_count;
            linesCount.notFull++;
          }
          onHand.basketUsed += line.growing_cycle.total_seeded_basket_count;
          linesCount.growing++;
          for (const ivt of line.growing_cycle.inventories_sum) {
            const i = inventories.findIndex(x => x.id === ivt.inventory_id);
            if (i < 0) {
              inventories.push({
                id: ivt.inventory_id,
                label: `${ivt.inventory_type} ${ivt.inventory_subtype}`,
                qty: ivt.quantity,
              });
            } else {
              inventories[i].qty += ivt.quantity;
            }
          }
        }
        onHand.basketCapacity += line.basket_count;
      }
      onHand.percentFull = onHand.basketCapacity
        ? (100 * onHand.basketUsed) / onHand.basketCapacity
        : 0;
      for (const ivt of inventories) {
        const i = totalCell.inventories.findIndex(x => x.id === ivt.id);
        if (i < 0) {
          totalCell.inventories.push({ ...ivt });
        } else {
          totalCell.inventories[i].qty += ivt.qty;
        }
      }
      totalCell.onHand.oysters += onHand.oysters;
      totalCell.onHand.ready += onHand.ready;
      totalCell.onHand.wait += onHand.wait;
      totalCell.onHand.notFull += onHand.notFull;
      totalCell.onHand.basketCapacity += onHand.basketCapacity;
      totalCell.onHand.basketUsed += onHand.basketUsed;
      totalCell.linesCount.ready += linesCount.ready;
      totalCell.linesCount.growing += linesCount.growing;
      totalCell.linesCount.notFull += linesCount.notFull;
      linesCount.total = farm.lines.length;
      totalCell.linesCount.total += farm.lines.length;

      let stages: IStep[] = [];
      let spats: ISpat[] = [];
      const items = rotations.filter(x => x.farm.id === farm.id);
      for (let item of items) {
        for (let sd of item.seedings) {
          let i = stages.findIndex(x => x.step === sd.stage);
          if (i < 0) {
            stages.push({ step: sd.stage, amount: sd.spat_amount });
          } else {
            stages[i].amount += sd.spat_amount;
          }
          i = totalCell.stages.findIndex(x => x.step === sd.stage);
          if (i < 0) {
            totalCell.stages.push({ step: sd.stage, amount: sd.spat_amount });
          } else {
            totalCell.stages[i].amount += sd.spat_amount;
          }
          if (sd.spat_storage) {
            i = spats.findIndex(x => x.id === sd.spat_storage?.id);
            if (i < 0) {
              spats.push({
                id: sd.spat_storage.id,
                source: sd.spat_storage.source,
                amount: sd.spat_amount,
              });
            } else {
              spats[i].amount += sd.spat_amount;
            }
            i = totalCell.spats.findIndex(x => x.id === sd.spat_storage?.id);
            if (i < 0) {
              totalCell.spats.push({
                id: sd.spat_storage.id,
                source: sd.spat_storage.source,
                amount: sd.spat_amount,
              });
            } else {
              totalCell.spats[i].amount += sd.spat_amount;
            }
          }
        }
      }
      stages.sort((a, b) => a.step - b.step);

      return {
        key: `farm-${farm.id}`,
        onHand,
        linesCount,
        inventories,
        stages,
        spats,
      };
    });
    totalCell.onHand.percentFull = totalCell.onHand.basketCapacity
      ? (100 * totalCell.onHand.basketUsed) / totalCell.onHand.basketCapacity
      : 0;
    totalCell.stages.sort((a, b) => a.step - b.step);

    const columns: ICol[] = [
      {
        key: 'label',
        title: translate(lang, 'Location'),
        render: x =>
          x.isBold ? (
            <div style={{ fontWeight: 'bold' }}>{x.label}</div>
          ) : (
            x.label
          ),
        width: 220,
        fixed: 'left',
      },
      ...farmsData.map(x => ({
        key: `farm-${x.id}`,
        title: (
          <>
            <div>{x.name}</div>
            <div>{x.farm_number}</div>
          </>
        ),
        dataIndex: `farm-${x.id}`,
        render: (x: any) =>
          x === undefined || x === null ? (
            <></>
          ) : typeof x === 'string' ? (
            x
          ) : (
            <div style={x.isBold ? { fontWeight: 'bold' } : {}}>{x.value}</div>
          ),
        minWidth: 170,
      })),
      {
        key: 'total',
        title: translate(lang, 'Total'),
        render: (x: any) => <div style={{ fontWeight: 'bold' }}>{x}</div>,
        dataIndex: 'total',
        minWidth: 120,
      },
    ];
    let data: IRow[] = [];
    (() => {
      let row: IRow = {
        key: 'Oysters (per doz) On Hand',
        isBold: true,
        label: translate(lang, 'Oysters (per doz) On Hand'),
      };
      for (const fc of farmCells) {
        row[fc.key] = { isBold: true, value: formatNumber(fc.onHand.oysters) };
      }
      row.total = formatNumber(totalCell.onHand.oysters);
      data.push(row);

      row = {
        key: 'Ready to go',
        isBold: true,
        label: translate(lang, 'Ready to go'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.onHand.ready);
      }
      row.total = formatNumber(totalCell.onHand.ready);
      data.push(row);

      row = {
        key: 'Wait More',
        isBold: true,
        label: translate(lang, 'Wait More'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.onHand.wait);
      }
      row.total = formatNumber(totalCell.onHand.wait);
      data.push(row);

      row = {
        key: 'Line not full',
        isBold: true,
        label: translate(lang, 'Line not full'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.onHand.notFull);
      }
      row.total = formatNumber(totalCell.onHand.notFull);
      data.push(row);

      row = {
        key: '% of lease full',
        isBold: true,
        label: translate(lang, '% of lease full'),
      };
      for (const fc of farmCells) {
        row[fc.key] = `${formatNumber(fc.onHand.percentFull)}%`;
      }
      row.total = `${formatNumber(totalCell.onHand.percentFull)}%`;
      data.push(row);

      row = {
        key: 'empty-1',
        isBold: true,
        label: '',
      };
      data.push(row);

      row = {
        key: 'Bag Capacity of lease',
        label: translate(lang, 'Bag Capacity of lease'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.onHand.basketCapacity);
      }
      row.total = formatNumber(totalCell.onHand.basketCapacity);
      data.push(row);

      row = {
        key: 'Bags Utilized',
        label: translate(lang, 'Bags Utilized'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.onHand.basketUsed);
      }
      row.total = formatNumber(totalCell.onHand.basketUsed);
      data.push(row);

      row = {
        key: 'Unused Capacity in bags',
        label: translate(lang, 'Unused Capacity in bags'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(
          fc.onHand.basketCapacity - fc.onHand.basketUsed,
        );
      }
      row.total = formatNumber(
        totalCell.onHand.basketCapacity - totalCell.onHand.basketUsed,
      );
      data.push(row);

      row = {
        key: 'empty-2',
        isBold: true,
        label: '',
      };
      data.push(row);
      row = {
        key: 'Oyster Rotation (per doz)',
        isBold: true,
        label: translate(lang, 'Oyster Rotation (per doz)'),
      };
      data.push(row);
      for (const st of totalCell.stages) {
        row = {
          key: `Stage ${st.step}`,
          label: `Stage ${st.step}`,
        };
        for (const fc of farmCells) {
          row[fc.key] = formatNumber(
            fc.stages.find(x => x.step === st.step)?.amount ?? 0,
          );
        }
        row.total = formatNumber(st.amount);
        data.push(row);
      }
      row = {
        key: 'Total',
        label: 'Total',
        isBold: true,
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.stages.reduce((p, c) => p + c.amount, 0));
      }
      row.total = formatNumber(
        totalCell.stages.reduce((p, c) => p + c.amount, 0),
      );
      data.push(row);
      row = {
        key: 'empty-3',
        isBold: true,
        label: '',
      };
      data.push(row);

      row = {
        key: 'Number of lines',
        isBold: true,
        label: translate(lang, 'Number of lines'),
      };
      data.push(row);

      row = {
        key: 'Ready for Harvest',
        label: translate(lang, 'Ready for Harvest'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.linesCount.ready);
      }
      row.total = formatNumber(totalCell.linesCount.ready);
      data.push(row);

      row = {
        key: 'Growing',
        label: translate(lang, 'Growing'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.linesCount.growing);
      }
      row.total = formatNumber(totalCell.linesCount.growing);
      data.push(row);

      row = {
        key: 'Not full',
        label: translate(lang, 'Not full'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.linesCount.notFull);
      }
      row.total = formatNumber(totalCell.linesCount.notFull);
      data.push(row);

      row = {
        key: 'Total Lines',
        isBold: true,
        label: translate(lang, 'Total Lines'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.linesCount.total);
      }
      row.total = formatNumber(totalCell.linesCount.total);
      data.push(row);

      row = {
        key: 'empty-4',
        isBold: true,
        label: '',
      };
      data.push(row);
      row = {
        key: 'Number of Bags at Sea',
        isBold: true,
        label: translate(lang, 'Number of Bags at Sea'),
      };
      data.push(row);

      for (const ivt of totalCell.inventories) {
        row = {
          key: ivt.label,
          label: ivt.label,
        };
        for (const fc of farmCells) {
          row[fc.key] = formatNumber(
            fc.inventories.find(x => x.id === ivt.id)?.qty ?? 0,
          );
        }
        row.total = formatNumber(ivt.qty);
        data.push(row);
      }
      row = {
        key: 'Total Bags at Sea',
        isBold: true,
        label: translate(lang, 'Total Bags at Sea'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(
          fc.inventories.reduce((p, c) => p + c.qty, 0),
        );
      }
      row.total = totalCell.inventories.reduce((p, c) => p + c.qty, 0);
      data.push(row);

      row = {
        key: 'empty-5',
        isBold: true,
        label: '',
      };
      data.push(row);
      row = {
        key: 'Origin of Oysters in doz',
        isBold: true,
        label: translate(lang, 'Origin of Oysters in doz'),
      };
      data.push(row);
      for (const sp of totalCell.spats) {
        row = {
          key: sp.source,
          label: sp.source,
        };
        for (const fc of farmCells) {
          row[fc.key] = formatNumber(
            fc.spats.find(x => x.id === sp.id)?.amount ?? 0,
          );
        }
        row.total = formatNumber(sp.amount);
        data.push(row);
      }
      row = {
        key: 'Total Oysters per dozen',
        label: translate(lang, 'Total Oysters per dozen'),
      };
      for (const fc of farmCells) {
        row[fc.key] = formatNumber(fc.spats.reduce((p, c) => p + c.amount, 0));
      }
      row.total = formatNumber(
        totalCell.spats.reduce((p, c) => p + c.amount, 0),
      );
      data.push(row);
    })();

    let grid: any[] = [];
    (() => {
      let row: any[] = [
        { value: translate(lang, 'Oysters (per doz) On Hand') },
        ...farmCells.map(x => ({ value: x.onHand.oysters })),
        { value: totalCell.onHand.oysters },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Ready to go') },
        ...farmCells.map(x => ({ value: x.onHand.ready })),
        { value: totalCell.onHand.ready },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Wait More') },
        ...farmCells.map(x => ({ value: x.onHand.wait })),
        { value: totalCell.onHand.wait },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Line not full') },
        ...farmCells.map(x => ({ value: x.onHand.notFull })),
        { value: totalCell.onHand.notFull },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, '% of lease full') },
        ...farmCells.map(x => ({
          value: `${formatNumber(x.onHand.percentFull)}%`,
        })),
        { value: `${formatNumber(totalCell.onHand.percentFull)}%` },
      ];
      grid.push(row);

      row = [{ value: '' }];
      grid.push(row);

      row = [
        { value: translate(lang, 'Bag Capacity of lease') },
        ...farmCells.map(x => ({ value: x.onHand.basketCapacity })),
        { value: totalCell.onHand.basketCapacity },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Bags Utilized') },
        ...farmCells.map(x => ({ value: x.onHand.basketUsed })),
        { value: totalCell.onHand.basketUsed },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Unused Capacity in bags') },
        ...farmCells.map(x => ({
          value: x.onHand.basketCapacity - x.onHand.basketUsed,
        })),
        {
          value: totalCell.onHand.basketCapacity - totalCell.onHand.basketUsed,
        },
      ];
      grid.push(row);

      row = [{ title: '' }];
      grid.push(row);

      row = [{ value: translate(lang, 'Oyster Rotation (per doz)') }];
      grid.push(row);

      for (let st of totalCell.stages) {
        row = [
          { value: `Stage ${st.step}` },
          ...farmCells.map(x => ({
            value: x.stages.find(y => y.step === st.step)?.amount ?? 0,
          })),
          { value: st.amount },
        ];
        grid.push(row);
      }
      row = [
        { value: translate(lang, 'Total') },
        ...farmCells.map(x => ({
          value: x.stages.reduce((p, c) => p + c.amount, 0),
        })),
        { value: totalCell.stages.reduce((p, c) => p + c.amount, 0) },
      ];

      row = [{ value: '' }];
      grid.push(row);
      row = [{ value: translate(lang, 'Number of lines') }];
      grid.push(row);

      row = [
        { value: translate(lang, 'Ready for Harvest') },
        ...farmCells.map(x => ({ value: x.linesCount.ready })),
        { value: totalCell.linesCount.ready },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Growing') },
        ...farmCells.map(x => ({ value: x.linesCount.growing })),
        { value: totalCell.linesCount.growing },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Not full') },
        ...farmCells.map(x => ({ value: x.linesCount.notFull })),
        { value: totalCell.linesCount.notFull },
      ];
      grid.push(row);

      row = [
        { value: translate(lang, 'Total Lines') },
        ...farmCells.map(x => ({ value: x.linesCount.total })),
        { value: totalCell.linesCount.total },
      ];
      grid.push(row);

      row = [{ value: '' }];
      grid.push(row);
      row = [{ value: translate(lang, 'Number of Bags at Sea') }];
      grid.push(row);

      for (let ivt of totalCell.inventories) {
        row = [
          { value: ivt.label },
          ...farmCells.map(x => ({
            value: x.inventories.find(y => y.id === ivt.id)?.qty ?? 0,
          })),
          { value: ivt.qty },
        ];
        grid.push(row);
      }
      row = [
        { value: translate(lang, 'Total Bags at Sea') },
        ...farmCells.map(x => ({
          value: x.inventories.reduce((p, c) => p + c.qty, 0),
        })),
        { value: totalCell.inventories.reduce((p, c) => p + c.qty, 0) },
      ];
      grid.push(row);

      row = [{ value: '' }];
      grid.push(row);
      row = [{ value: translate(lang, 'Origin of Oysters in doz') }];
      grid.push(row);

      for (let sp of totalCell.spats) {
        row = [
          { value: sp.source },
          ...farmCells.map(x => ({
            value: x.spats.find(y => y.id === sp.id)?.amount ?? 0,
          })),
          { value: sp.amount },
        ];
        grid.push(row);
      }
      row = [
        { value: translate(lang, 'Total Oysters per dozen') },
        ...farmCells.map(x => ({
          value: x.spats.reduce((p, c) => p + c.amount, 0),
        })),
        { value: totalCell.spats.reduce((p, c) => p + c.amount, 0) },
      ];
      grid.push(row);
    })();

    return { columns, data, excel: { columns: headers, data: grid } };
  }, [farmsData, rotations]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await sendSingleRequest(
        { with_spats: true },
        'GET',
        'api/overview/stages',
        true,
      );
      setLoading(false);
      if (!response.status) {
        window.alert(translate(lang, response.data?.message ?? 'Server error'));
        return;
      }
      setRotations(response.data);
    })();
  }, []);

  return (
    <div className='bg-secondary'>
      <div className='container performance-page pb-32'>
        <div className='sub-header'>
          <NavLink className='header__link' to={'/oyster-summary/estimate'}>
            {translate(lang, 'Harvest Delivery Estimates')}
          </NavLink>
          <NavLink
            className='header__link ml-24 mr-24'
            to={'/oyster-summary/calender'}
          >
            {translate(lang, 'Calendar Estimates')}
          </NavLink>
          <NavLink className='header__link' to={'/oyster-summary/growth'}>
            {translate(lang, 'Growth Calculator')}
          </NavLink>
        </div>
        <div className='overview d-flex justify-content-between align-items-center'>
          <Title size={5} color='black' align='default' fontWeight={700}>
            {translate(lang, 'Summary of Stock on Hand')}
          </Title>
          <ExcelFile
            element={
              <Button type='primary'>
                {translate(lang, 'Export to excel')}
              </Button>
            }
          >
            <ExcelSheet dataSet={[excel]} name='Performance' />
          </ExcelFile>
        </div>
        {loading && (
          <div className='loader'>
            <Spinner />
          </div>
        )}
        <div className='content pb-32'>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ y: 600 }}
          />
        </div>
      </div>
    </div>
  );
};

export default OysterSummaryPage;

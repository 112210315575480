import { Modal, Radio } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMusselLineResource } from '../../../entities/farms.entities';
import {
  loadFarmsData,
  loadSpatStorages,
} from '../../../store/farms/farms.actions';
import { addUtilData } from '../../../store/utils/utils.actions';
import { parseImageUrlToFileItem } from '../../../util/getBase64';
import { Link } from 'react-router-dom';
import { toMillisecond, toSecond } from '../../../util/toggleSecondMillisecond';
import {
  Button,
  CheckboxButton,
  CloseIcon,
  Datepicker,
  Dropdown,
  Feedback,
  Input,
  RadioButton,
  Subtitle,
} from '../../shared';
import TimeTrackInput, {
  toSecTimeTracks,
} from '../../shared/input/TimeTrackInput';
import PicturesWall from '../../shared/pictures-wall/PicturesWall';
import { ICustomValue } from '../../../entities/automation.entities';
import { selectUserMeta } from '../../../store/auth/auth.selector';
import { sendSingleRequest } from '../../../apis';
import { showFeedback } from '../../../store/ui/ui.actions';
import { ITimeRange } from '../../../entities/general.entities';
import { SpatSelectLabel, renderCustomError } from '.';
import { loadInventories } from '../../../store/inventories/inventories.actions';
import { selectLang } from '../../../store/ui/ui.selector';
import { translate } from '../../../lib/lang.helper';
import {
  SelectInventoriesByFarmID,
  SelectIsBudgetVisible,
  SelectIsGrower,
  SelectMusselFarmsByFarmID,
  SelectMusselSpatsByFarmID,
  SelectSeasonsByFarmID,
  SelectSeedingFields,
} from '../../../store/extra/extra.selector';

interface IFormData {
  season_name: string;
  spat_storage_id: number | null;
  spat_amount: number | null;
  planned_date_seed: number;
  planned_date_harvest: number;
  line_length: number | null;
  spat_size: number;
  spat_size_max: number | null;
  density: number;
  density_max: number | null;
  density_unit: 'spat per m' | 'kgs per m';
  drop: number;
  submersion: number;
  spacing: number;
  comment: string;
  budget_unit_amount: number | null;
  budget_unit_price: number | null;
  images: any[];
  time_tracks?: ITimeRange[];
  cost?: number | null;
  parent_id?: number | null;
  picked_inventories?: any[] | null;
  custom_values?: ICustomValue[];
}

const defaultFormData: IFormData = {
  season_name: '',
  spat_storage_id: null,
  spat_amount: null,
  planned_date_seed: moment().toDate().getTime(),
  planned_date_harvest: moment().toDate().getTime(),
  line_length: 0,
  spat_size: 0,
  spat_size_max: null,
  density: 0,
  density_max: null,
  density_unit: 'spat per m',
  drop: 0,
  submersion: 0,
  spacing: 0,
  comment: '',
  budget_unit_amount: null,
  budget_unit_price: null,
  images: [],
  time_tracks: [],
};

interface Props {
  visible: boolean;
  className?: string;
  title?: string;
  width?: number;
  lineData: IMusselLineResource;
  data?: any;
  updateID?: number;
  parentID?: number;
  onlyView?: boolean;
  onConfirm: (data: any) => void;
  onCancel: () => void;
  overConfirm?: (data: any) => Promise<any>;
  confirmBtnLabel?: string;
  lineChangeable?: boolean;
  warningMessage?: string | React.ReactNode;
}

const MusselSeedModal = ({
  visible,
  className,
  title,
  width,
  lineData,
  data,
  updateID,
  parentID,
  onlyView,
  onConfirm,
  onCancel,
  overConfirm,
  confirmBtnLabel,
  lineChangeable,
  warningMessage,
}: Props) => {
  const dispatch = useDispatch<any>();

  const lang = useSelector(selectLang);
  const isGrower = SelectIsGrower(lineData.farm_id);
  const spats = SelectMusselSpatsByFarmID(lineData.farm_id);
  const seasonData = SelectSeasonsByFarmID(lineData.farm_id).map(x => ({
    id: x.id.toString(),
    label: x.name,
    value: x.name,
  }));
  const inventories = SelectInventoriesByFarmID(lineData.farm_id);
  const showBudget = SelectIsBudgetVisible(lineData.farm_id);
  const seedStages = useSelector(selectUserMeta)?.seed_stages_size;
  const farmsData = SelectMusselFarmsByFarmID(lineData.farm_id);
  const customFields = SelectSeedingFields(lineData.farm_id);

  const [disabled, setDisabled] = useState(false);
  const [isSeasonNew, setIsSeasonNew] = useState<'old' | 'new'>('old');
  const [errors, setErrors] = useState<any>();
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const [stageMsg, setStageMsg] = useState<string>();
  const [addDensityRange, setAddDensityRange] = useState(false);
  const [addSpatSizeRange, setAddSpatSizeRange] = useState(false);
  const [pickedInventories, setPickedInventories] = useState<any[]>(
    data?.picked_inventories ?? [],
  );
  const [focFarmId, setFocFarmId] = useState(
    farmsData.find(x => x.id === lineData.farm_id)?.id,
  );
  const [focLineId, setFocLineId] = useState(lineData.id);
  const [customValues, setCustomValues] = useState(
    customFields.map(x => {
      const d =
        data && data.custom_values
          ? data.custom_values.find((y: ICustomValue) => y.field_id === x.id)
          : null;
      return {
        input_type: x.input_type,
        field_label: x.name,
        options: x.options
          ?.split(',')
          .map(t => ({ id: t, label: t, value: t })),
        field_id: x.id,
        field_val: d?.field_val ?? '',
        data_type: x.data_type,
        unit: x.unit,
      };
    }),
  );
  const [errorMsg, setErrorMsg] = useState<string>();

  const updateCustomValue = (fieldID: number, val: string) => {
    let tmp = [...customValues];
    const i = tmp.findIndex(x => x.field_id === fieldID);
    if (i >= 0) {
      tmp[i].field_val = val;
    }
    setCustomValues(tmp);
  };
  const updateForm = (key: keyof IFormData, val: any) => {
    if (
      [
        'spat_amount',
        'line_length',
        'budget_unit_amount',
        'budget_unit_price',
      ].includes(key)
    ) {
      val = val.length <= 0 ? null : Number(val);
    }
    if (key === 'spat_storage_id' || key === 'spat_amount') {
      setErrors((prv: any) => ({
        ...(prv ?? {}),
        spat_storage_id: null,
        spat_amount: null,
      }));
    } else {
      setErrors((prv: any) => ({ ...(prv ?? {}), [key]: null }));
    }
    if (key === 'spat_storage_id') {
      const spat = spats.find(x => x.id === Number(val));
      let tmp = { ...formData, spat_storage_id: val };
      if (spat?.harvest) {
        if (spat.harvest.shell_length) {
          tmp.spat_size = spat.harvest.shell_length;
        }
        if (spat.harvest.shell_length_max) {
          setAddSpatSizeRange(true);
          tmp.spat_size_max = spat.harvest.shell_length_max;
        }
      }
      setFormData(tmp);
    } else {
      setFormData({ ...formData, [key]: val });
    }
  };
  const toggleAddRangeCheck = (type: 'DENSITY' | 'SPAT', val: boolean) => {
    if (type === 'DENSITY') {
      setAddDensityRange(val);
      if (val) updateForm('density_max', formData.density);
      else updateForm('density_max', null);
    } else {
      setAddSpatSizeRange(val);
      if (val) updateForm('spat_size_max', formData.spat_size);
      else updateForm('spat_size_max', null);
    }
  };
  const selectInventoryIds = (val: string[]) => {
    let pArr = val.map(x => ({ id: parseInt(x), quantity: '' }));
    for (let p of pickedInventories) {
      let i = pArr.findIndex(x => x.id === p.id);
      if (i > -1) pArr[i].quantity = p.quantity;
    }
    setPickedInventories(pArr);
  };
  const updateInventoryQuantity = (i: number, val: string) => {
    let tmp = [...pickedInventories];
    tmp[i].quantity = val;
    setPickedInventories(tmp);
  };
  const validFormData = () => {
    let tmpErr = { ...(errors ?? {}) };
    let res = true;
    if (!formData.season_name) {
      tmpErr.season_name = translate(lang, 'Add name');
      res = false;
    }
    if (Number(formData.line_length) === 0) {
      tmpErr.line_length = translate(
        lang,
        'Line length must be greater than 0',
      );
      res = false;
    }
    if (
      Number(formData.planned_date_seed) > Number(formData.planned_date_harvest)
    ) {
      tmpErr.planned_date_seed = translate(lang, '_invalid_seed_date');
      res = false;
    }
    if (
      data?.is_catch_spat !== true &&
      (!formData.spat_storage_id ||
        !formData.spat_amount ||
        formData.spat_amount < 0)
    ) {
      tmpErr.spat_amount = translate(lang, 'Select spat and weight');
      res = false;
    }
    if (
      data?.is_catch_spat !== true &&
      formData.spat_amount &&
      formData.spat_amount > availableWeight
    ) {
      tmpErr.spat_amount = translate(lang, '_max_spat_amount', availableWeight);
      res = false;
    }
    for (let px of pickedInventories) {
      const ivt = inventories.find(x => x.id === px.id);
      if (!ivt) {
        setErrorMsg('Inventory is not found');
        res = false;
        break;
      }
      if (isNaN(Number(px.quantity)) || Number(px.quantity) <= 0) {
        setErrorMsg(translate(lang, '_invalid_ivt_qty', ivt.subtype ?? ''));
        res = false;
        break;
      }
      if (Number(px.quantity) > ivt.available_quantity) {
        setErrorMsg(
          translate(
            lang,
            '_seed_floats_over',
            px.quantity,
            ivt.subtype,
            ivt.available_quantity,
          ),
        );
        res = false;
        break;
      }
    }
    if (
      formData.density_max !== null &&
      formData.density_max < formData.density
    ) {
      tmpErr.density = translate(lang, 'Invalid density range');
      res = false;
    }
    if (
      formData.spat_size_max !== null &&
      formData.spat_size_max < formData.spat_size
    ) {
      tmpErr.spat_size = translate(lang, 'Invalid spat size range');
      res = false;
    }
    if (
      formData.time_tracks &&
      toSecTimeTracks(formData.time_tracks) === false
    ) {
      setErrorMsg('_invalid_time_range');
      res = false;
    }
    setErrors(tmpErr);
    if (!res) return null;

    let validData = {
      ...formData,
      line_id: focLineId,
      planned_date_seed: toSecond(formData.planned_date_seed),
      planned_date_harvest: toSecond(formData.planned_date_harvest),
    };
    if (!!parentID) {
      validData.parent_id = parentID;
    } else if (!updateID) {
      const ms = farmsData
        .find(x => x.id === focFarmId)
        ?.lines.find(x => x.id === focLineId)?.growing_cycle?.main_seed;
      if (ms) {
        if (!window.confirm(translate(lang, '_line_seed_make_sure')))
          return null;
        validData.parent_id = ms.id;
      }
    }
    if (pickedInventories.length > 0) {
      validData.picked_inventories = pickedInventories;
    }
    if (formData.time_tracks) {
      const tt = toSecTimeTracks(formData.time_tracks);
      if (tt) {
        validData.time_tracks = tt;
      } else {
        setErrorMsg('_invalid_time_range');
        return null;
      }
    }
    if (
      lineData.seedings?.some(
        x => x.id !== updateID && x.season_name === formData.season_name,
      ) &&
      !window.confirm(translate(lang, '_seeding_season_confirm'))
    )
      return null;
    const cvs = customValues
      .filter(x => !!x.field_val)
      .map(({ field_id, field_val }) => ({ field_id, field_val }));
    if (cvs.length > 0) {
      validData.custom_values = cvs;
    }
    if (!updateID && !validData.parent_id && lineData.growing_cycle) {
      if (!window.confirm(translate(lang, '_line_seed_make_sure'))) return null;
      validData.parent_id = lineData.growing_cycle.main_seed.id;
    }

    return validData;
  };
  const handleConfirmClick = async () => {
    if (onlyView) return;
    let validData = validFormData();
    if (!validData) return;

    setErrorMsg(undefined);
    setDisabled(true);
    if (!seasonData.some(x => x.value === validData?.season_name)) {
      await dispatch(addUtilData('season', validData.season_name));
    }
    if (!!overConfirm) {
      const ovc = await overConfirm(validData);
      if (ovc !== true) {
        setDisabled(false);
        if (ovc.errors) setErrors(ovc.errors);
        if (ovc.message) setErrorMsg(ovc.message);
      }
      return;
    }
    const res = updateID
      ? await sendSingleRequest(
          validData,
          'PUT',
          `api/farm/line/seeding/${updateID}`,
          true,
        )
      : await sendSingleRequest(
          validData,
          'POST',
          'api/farm/line/seeding',
          true,
        );
    if (res.status) {
      dispatch(
        showFeedback({
          isMessage: true,
          message:
            res.data?.message ??
            (updateID
              ? 'Updated data successfully'
              : 'Seed data submitted successfully'),
          type: 'success',
        }),
      );
      dispatch(loadFarmsData());
      dispatch(loadSpatStorages());
      if (pickedInventories) {
        dispatch(loadInventories());
      }
      onConfirm(validData);
    } else {
      setDisabled(false);
      setErrorMsg(res.data?.message ?? 'Unknown error');
      if (res.data?.errors) setErrors(res.data.errors);
    }
  };

  const availableWeight = formData.spat_storage_id
    ? (spats.find(x => x.id === Number(formData.spat_storage_id))
        ?.available_weight ?? 0) + (updateID ? data.spat_amount : 0)
    : -1;

  const farmOptions = farmsData.map(x => ({
    id: x.id.toString(),
    value: x.id.toString(),
    label: `${x.name} - ${x.farm_number}`,
  }));
  const lineOptions = farmsData
    .find(x => x.id === focFarmId)
    ?.lines.map(x => ({
      id: x.id.toString(),
      value: x.id.toString(),
      label: x.line_name,
    }));

  useEffect(() => {
    const spat = spats.find(x => x.id === Number(formData.spat_storage_id));
    if (!seedStages || seedStages.length <= 0 || !spat || !spat.harvest) {
      setStageMsg(undefined);
      return;
    }
    let i = 0;
    for (
      i = 0;
      i < seedStages.length &&
      seedStages[i].size <= Number(spat.harvest.shell_length);
      i++
    );
    if (i > 0) {
      const { time } = seedStages[i - 1];
      setStageMsg(
        translate(
          lang,
          'Calculated based on detected stage %s months',
          `${i} + ${time}`,
        ),
      );
      updateForm(
        'planned_date_harvest',
        moment(formData.planned_date_seed)
          .add(time, 'months')
          .toDate()
          .getTime(),
      );
    } else {
      setStageMsg(undefined);
    }
  }, [seedStages, formData.planned_date_seed, formData.spat_storage_id]);

  useEffect(() => {
    if (data) {
      let tmp: any = {};
      for (let k in formData) {
        tmp[k] = data[k] ?? (formData as any)[k];
        if (k === 'planned_date_seed' || k === 'planned_date_harvest') {
          tmp[k] = toMillisecond(tmp[k]);
        } else if (
          k === 'images' &&
          (tmp[k] === null || tmp[k] === undefined)
        ) {
          tmp[k] = [];
        }
      }
      if (data?.spat_storage && !tmp.spat_storage_id) {
        tmp.spat_storage_id = data.spat_storage.id;
      }
      if (tmp.time_tracks && tmp.time_tracks.length > 0) {
        tmp.time_tracks = tmp.time_tracks.map((x: any) => ({
          start_time: toMillisecond(x.start_time),
          finish_time: toMillisecond(x.finish_time),
        }));
      }
      if (showBudget && data.expense_items && data.expense_items.length > 0) {
        tmp.cost = data.expense_items.reduce(
          (p: any, c: any) => p + c.amount,
          0,
        );
      } else if (showBudget && data.cost) {
        tmp.cost = data.cost;
      }
      if (tmp.images) {
        tmp.images = tmp.images.map((x: any) =>
          typeof x === 'object' && x !== null ? x : parseImageUrlToFileItem(x),
        );
      }
      setAddDensityRange(!!data.density_max);
      setAddSpatSizeRange(!!data.spat_size_max);
      setFormData(tmp);
    }
  }, [data, showBudget]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      className={className}
      footer={null}
      closable
      closeIcon={<CloseIcon />}
      width={width !== undefined ? width : 600}
    >
      <div className='wrap'>
        <div className='d-flex align-items-center mb-32'>
          <Subtitle color='black-1' align='left' size={1} fontWeight={600}>
            {translate(lang, title ?? 'Mussel seed line')}
          </Subtitle>
        </div>
        {isGrower ? (
          <div className='header-alert'>
            {translate(lang, 'Permission denied')}
          </div>
        ) : (
          <div>
            {!!warningMessage && (
              <div className='header-alert'>{warningMessage}</div>
            )}
            {!!errorMsg && (
              <Feedback
                message={translate(lang, errorMsg)}
                type={'error'}
                theme='light'
                position={10}
                isGlobal
                onClose={() => setErrorMsg(undefined)}
              />
            )}
            {!parentID && !!lineChangeable && (
              <div className='mb-17 d-flex'>
                <div className='w-100 mr-7'>
                  <Dropdown
                    label={translate(lang, 'Select farm')}
                    options={farmOptions}
                    value={focFarmId?.toString()}
                    onChange={v => setFocFarmId(Number(v))}
                  />
                </div>
                <div className='w-100 ml-7'>
                  <Dropdown
                    label={translate(lang, 'Select line')}
                    options={lineOptions ?? []}
                    value={
                      !!lineOptions &&
                      lineOptions.some(x => Number(x.id) === focLineId)
                        ? focLineId.toString()
                        : undefined
                    }
                    onChange={v => setFocLineId(Number(v))}
                  />
                </div>
              </div>
            )}
            {!data?.is_catch_spat ? (
              <>
                <Radio.Group
                  className='d-flex mt-14 mb-32'
                  onChange={e => setIsSeasonNew(e.target.value)}
                  value={isSeasonNew}
                  disabled={onlyView}
                >
                  <RadioButton
                    label={translate(lang, 'Existing season')}
                    value='old'
                    disabled={onlyView}
                  />
                  <RadioButton
                    className='ml-34'
                    label={translate(lang, 'New season')}
                    value='new'
                    disabled={onlyView}
                  />
                </Radio.Group>
                <div
                  className={
                    errors?.season_name ? 'invalid-form mb-16' : ' mb-16'
                  }
                >
                  {isSeasonNew === 'old' ? (
                    <Dropdown
                      label={translate(lang, 'Season name')}
                      placeholder={translate(lang, 'Choose Season')}
                      className='mr-16 w-100'
                      onChange={value => updateForm('season_name', value)}
                      options={seasonData}
                      value={formData.season_name}
                      disabled={onlyView}
                    />
                  ) : (
                    <Input
                      label={translate(lang, 'New Season')}
                      placeholder={translate(lang, 'Season name')}
                      type='text'
                      value={formData.season_name}
                      onChange={e => updateForm('season_name', e.target.value)}
                      disabled={onlyView}
                    />
                  )}
                  {!!errors?.season_name && (
                    <div className='invalid-feedback'>
                      {errors?.season_name}
                    </div>
                  )}
                </div>
                <div
                  className={
                    errors?.spat_amount || errors?.spat_storage_id
                      ? 'invalid-form mt-17'
                      : ' mt-17'
                  }
                >
                  <Dropdown
                    placeholder={translate(lang, 'Spat')}
                    label={translate(lang, 'Spat')}
                    className={`mb-${
                      formData.spat_storage_id === null ? '16' : '5'
                    }`}
                    value={SpatSelectLabel(
                      spats.find(
                        x => x.id === Number(formData.spat_storage_id),
                      ),
                    )}
                    options={spats
                      .filter(x => Number(x.available_weight) > 0)
                      .map(item => ({
                        id: item.id.toString(),
                        value: item.id.toString(),
                        label: SpatSelectLabel(item),
                      }))}
                    onChange={(value, evt) =>
                      updateForm('spat_storage_id', value)
                    }
                    disabled={onlyView}
                  />
                  {!!formData.spat_storage_id && (
                    <Input
                      label={translate(lang, 'Amount')}
                      placeholder={
                        availableWeight <= 0
                          ? translate(lang, 'Amount')
                          : translate(lang, '_max_spat_amount', availableWeight)
                      }
                      type='number'
                      value={formData.spat_amount?.toString() ?? ''}
                      max={availableWeight > 0 ? availableWeight : undefined}
                      unit='kg'
                      onChange={e => updateForm('spat_amount', e.target.value)}
                      disabled={onlyView}
                    />
                  )}
                  {!!errors?.spat_storage_id && (
                    <div className='invalid-feedback'>
                      {errors?.spat_storage_id}
                    </div>
                  )}
                  {!!errors?.spat_amount && (
                    <div className='invalid-feedback'>
                      {renderCustomError(errors?.spat_amount)}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div className='d-flex justify-content-center'>
                {translate(lang, 'This is line catch spat')}
              </div>
            )}
          </div>
        )}
        <div
          className={`mb-24 mt-16 ${
            errors?.planned_date_seed ? 'invalid-form' : ''
          }`}
        >
          <Datepicker
            label={translate(lang, 'Line seeding date')}
            defaultValue={formData.planned_date_seed}
            onChange={e =>
              updateForm(
                'planned_date_seed',
                e?.toDate().getTime() ?? moment().toDate().getTime(),
              )
            }
            required
            disabled={onlyView}
          />
          {errors?.planned_date_seed && (
            <div className='invalid-feedback'>{errors?.planned_date_seed}</div>
          )}
        </div>
        <div className='mb-16'>
          <Datepicker
            label={translate(lang, 'Planned harvest date')}
            defaultValue={formData.planned_date_harvest}
            onChange={e => {
              if (
                formData.planned_date_seed !== undefined &&
                e &&
                e!.toDate().getTime() < formData.planned_date_seed
              ) {
                updateForm('planned_date_harvest', moment().toDate().getTime());
              } else {
                updateForm(
                  'planned_date_harvest',
                  e?.toDate().getTime() ?? moment().toDate().getTime(),
                );
              }
            }}
            required
            disabled={onlyView}
          />
          {stageMsg && (
            <div className='text-red mt-7'>{translate(lang, stageMsg)}</div>
          )}
        </div>
        <div className={`mb-16 ${errors?.line_length ? 'invalid-form' : ''}`}>
          <Input
            label={translate(lang, 'Line Length')}
            type='number'
            value={formData.line_length?.toString() ?? ''}
            unit='m'
            required
            onChange={e => updateForm('line_length', Number(e.target.value))}
            disabled={onlyView}
          />
          {errors?.line_length && (
            <div className='invalid-feedback'>{errors?.line_length}</div>
          )}
        </div>
        {!!formData.time_tracks && (
          <TimeTrackInput
            data={formData.time_tracks}
            onChange={t => updateForm('time_tracks', t)}
            disabled={onlyView}
          />
        )}
        <div className='mb-16'>
          <Input
            label={translate(lang, 'Drop')}
            type='number'
            value={formData.drop.toString()}
            unit='m'
            required
            onChange={e => updateForm('drop', Number(e.target.value))}
            disabled={onlyView}
          />
        </div>
        <div className='mb-7'>
          <CheckboxButton
            label={translate(lang, 'Add spat size range')}
            checked={addSpatSizeRange}
            onChange={e => toggleAddRangeCheck('SPAT', e.target.checked)}
            disabled={onlyView}
          />
        </div>
        {formData.spat_size_max === null ? (
          <div className='mb-17'>
            <Input
              label={translate(lang, 'Spat Size')}
              type='number'
              value={formData.spat_size.toString()}
              unit='mm'
              required
              onChange={e => updateForm('spat_size', Number(e.target.value))}
              disabled={onlyView}
            />
          </div>
        ) : (
          <div className='d-flex mb-17 justify-content-between'>
            <div
              className={`w-100 mr-3 ${
                errors?.spat_size ? 'invalid-form' : ''
              }`}
            >
              <Input
                label={translate(lang, 'Minimum spat size')}
                type='number'
                value={formData.spat_size.toString()}
                required
                onChange={e => updateForm('spat_size', Number(e.target.value))}
                disabled={onlyView}
              />
              {errors?.spat_size && (
                <div className='invalid-feedback'>{errors?.spat_size}</div>
              )}
            </div>
            <div className='w-100 ml-3'>
              <Input
                label={translate(lang, 'Maximum spat size')}
                type='number'
                value={formData.spat_size_max.toString()}
                required
                onChange={e =>
                  updateForm('spat_size_max', Number(e.target.value))
                }
                disabled={onlyView}
              />
            </div>
          </div>
        )}
        <div className='d-flex pb-17'>
          <div className='mr-3 w-100'>
            <Input
              label={translate(lang, 'Submersion')}
              type='number'
              value={formData.submersion.toString()}
              unit='m'
              required
              onChange={e => updateForm('submersion', Number(e.target.value))}
              disabled={onlyView}
            />
          </div>
          <div className='ml-3 w-100'>
            <Input
              label={translate(lang, 'Spacing')}
              type='number'
              value={formData.spacing.toString()}
              unit='mm'
              required
              onChange={e => updateForm('spacing', Number(e.target.value))}
              disabled={onlyView}
            />
          </div>
        </div>
        <div className='d-flex mt-17 pb-7 justify-content-between'>
          <div className='mr-17'>
            <CheckboxButton
              label={translate(lang, 'Add density range')}
              checked={addDensityRange}
              onChange={e => toggleAddRangeCheck('DENSITY', e.target.checked)}
              disabled={onlyView}
            />
          </div>
          <Radio.Group
            className='d-flex align-items-center'
            onChange={e => updateForm('density_unit', e.target.value)}
            value={formData.density_unit}
          >
            <RadioButton label='spat per m' value='spat per m' />
            <RadioButton
              className='ml-17'
              label='kgs per m'
              value='kgs per m'
            />
          </Radio.Group>
        </div>
        <div className='d-flex pb-17'>
          <div className='w-100'>
            {formData.density_max === null ? (
              <Input
                label={translate(lang, 'Density')}
                type='number'
                value={formData.density.toString()}
                required
                onChange={e => updateForm('density', Number(e.target.value))}
                disabled={onlyView}
                unit={formData.density_unit}
              />
            ) : (
              <div className='d-flex justify-content-between'>
                <div
                  className={`w-100 mr-3 ${
                    errors?.density ? 'invalid-form' : ''
                  }`}
                >
                  <Input
                    label={translate(lang, 'Density Min')}
                    type='number'
                    value={formData.density.toString()}
                    required
                    onChange={e =>
                      updateForm('density', Number(e.target.value))
                    }
                    disabled={onlyView}
                    unit={formData.density_unit}
                  />
                  {errors?.density && (
                    <div className='invalid-feedback'>{errors?.density}</div>
                  )}
                </div>
                <div className='w-100 ml-3'>
                  <Input
                    label={translate(lang, 'Density Max')}
                    type='number'
                    value={formData.density_max.toString()}
                    required
                    onChange={e =>
                      updateForm('density_max', Number(e.target.value))
                    }
                    unit={formData.density_unit}
                    disabled={onlyView}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {customValues.map(x => (
          <div key={x.field_id} className='mb-17'>
            {x.input_type === 'select' ? (
              <>
                <Dropdown
                  label={x.field_label}
                  options={x.options ?? []}
                  value={x.field_val}
                  onChange={v => updateCustomValue(x.field_id, v)}
                />
                {(!x.options || x.options.length <= 0) && (
                  <Link to={'/custom-fields'}>
                    <span style={{ color: '#008ed1' }}>Set up field</span>
                  </Link>
                )}
              </>
            ) : (
              <Input
                type={x.data_type === 'number' ? 'number' : 'text'}
                label={x.field_label}
                value={x.field_val ?? ''}
                onChange={e => updateCustomValue(x.field_id, e.target.value)}
                unit={x.unit}
              />
            )}
          </div>
        ))}
        {!updateID && (
          <div className='pb-17'>
            <Dropdown
              label={translate(lang, 'Select inventory')}
              placeholder={translate(lang, 'Select inventory')}
              mode='multiple'
              options={inventories.map(x => ({
                id: x.id.toString(),
                value: x.id.toString(),
                label: `${x.subtype} - $${x.price}`,
              }))}
              value={pickedInventories.map(x => x.id.toString()) as any}
              onChange={(val: any) => selectInventoryIds(val)}
              disabled={onlyView}
            />
          </div>
        )}
        {pickedInventories.length > 0 && (
          <div className='mb-17'>
            {pickedInventories.map((item, idx) => (
              <Input
                className='mb-15'
                key={item.id}
                type='number'
                label={`${
                  inventories.find(t => t.id === item.id)?.subtype
                } - $${inventories.find(t => t.id === item.id)?.price}`}
                placeholder={translate(
                  lang,
                  'Maximum value is %s',
                  inventories.find(t => t.id === item.id)?.available_quantity ??
                    '',
                )}
                value={item.quantity?.toString() ?? ''}
                onChange={e => updateInventoryQuantity(idx, e.target.value)}
                max={
                  inventories.find(t => t.id === item.id)?.available_quantity
                }
                disabled={onlyView}
              />
            ))}
          </div>
        )}
        {!!showBudget && (
          <>
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Cost')}
                type='number'
                value={formData.cost?.toString() ?? ''}
                dataType='cost'
                unit='$'
                onChange={e => updateForm('cost', Number(e.target.value))}
                disabled={onlyView}
              />
            </div>
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Budgeted kg/m')}
                type='number'
                value={formData.budget_unit_amount?.toString() ?? ''}
                unit='kg/m'
                onChange={e => updateForm('budget_unit_amount', e.target.value)}
                disabled={onlyView}
              />
            </div>
            <div className='mb-17'>
              <Input
                label={translate(lang, 'Budgeted $/m')}
                type='number'
                value={formData.budget_unit_price?.toString() ?? ''}
                unit='$/m'
                onChange={e => updateForm('budget_unit_price', e.target.value)}
                disabled={onlyView}
              />
            </div>
          </>
        )}
        <div className='mt-17 mb-17'>
          <Input
            label={translate(lang, 'Comment')}
            placeholder={translate(lang, 'Comment')}
            type='textarea'
            value={formData.comment}
            onChange={e => updateForm('comment', e.target.value)}
            disabled={onlyView}
          />
        </div>
        <div className='mb-17'>
          <PicturesWall
            label={translate(lang, 'Images')}
            fileList={formData.images}
            handleChangeImages={v => updateForm('images', v)}
          />
        </div>
      </div>
      <div className='modal-button d-flex justify-content-end align-items-center'>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='red'
          className='rsp-btn ml-16'
          onClick={onCancel}
          disabled={disabled}
        >
          {translate(lang, 'Close')}
        </Button>
        <Button
          width={'small'}
          size={2}
          type='fill'
          color='green'
          className='rsp-btn ml-16'
          onClick={handleConfirmClick}
          disabled={disabled || onlyView}
        >
          {translate(lang, confirmBtnLabel ?? 'Confirm')}
        </Button>
      </div>
    </Modal>
  );
};

export default MusselSeedModal;
